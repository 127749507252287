.admin-dashboard-user-text2,
.admin-dashboard-user-text3 {
  position: relative;
}

.admin-dashboard-user-text2 {
  text-decoration: none;
  line-height: 28px;
  font-weight: 600;
  color: inherit;
  white-space: nowrap;
}

.admin-dashboard-user-text3 {
  line-height: 18px;
  font-weight: 500;
}

.admin-dashboard-user-badge,
.admin-dashboard-user-text-and-badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.admin-dashboard-user-badge {
  border-radius: 9999px;
  background-color: #f9f5ff;
  border: 1px solid #e9d7fe;
  padding: 0 7px;
  white-space: nowrap;
  text-align: center;
  font-size: 12px;
  color: #6941c6;
}

.admin-dashboard-user-text-and-badge {
  align-self: stretch;
  padding: 0 242px 0 0;
  gap: 8px;
}

.admin-dashboard-user-supporting-text1 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.admin-dashboard-user-text-and-supporting-text1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
  max-width: 100%;
}

.admin-dashboard-user-placeholder-icon3 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 20px;
}

.admin-dashboard-user-text4 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.admin-dashboard-user-text-padding2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 14px;
}

.admin-dashboard-user-button {
  border-radius: 8px;
  overflow: hidden;
  display: none;
  padding: 10px 14px;
  gap: 4px;
}

.admin-dashboard-user-button,
.admin-dashboard-user-button1,
.admin-dashboard-user-text-padding3 {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.admin-dashboard-user-text-padding3 {
  display: flex;
  padding: 0 13px;
}

.admin-dashboard-user-button1 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d6bbfb;
  overflow: hidden;
  display: none;
  padding: 10px 14px;
  gap: 4px;
  color: #6941c6;
}

.admin-dashboard-user-upload-cloud-02-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 20px;
}

.admin-dashboard-user-text6 {
  text-decoration: none;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 45px;
}

.admin-dashboard-user-button2,
.admin-dashboard-user-text-padding4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.admin-dashboard-user-button2 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 13px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  gap: 4px;
}

.admin-dashboard-user-text7 {
  text-decoration: none;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 92px;
  white-space: nowrap;
}

.admin-dashboard-user-actions1,
.admin-dashboard-user-button3 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.admin-dashboard-user-button3 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 13px;
  background-color: #7e56d9;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  justify-content: center;
  gap: 4px;
}

.admin-dashboard-user-actions1 {
  justify-content: flex-start;
  gap: 12px;
  font-size: 14px;
  color: #475467;
}

.admin-dashboard-user-dots-vertical-icon {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.admin-dashboard-user-content1,
.admin-dashboard-user-dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}


.admin-dashboard-user-dropdown1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 140px;
  /* padding-left: 10px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.admin-dashboard-user-content1 {
  flex-direction: row;
  padding: 20px 0 0 0;
  box-sizing: border-box;
  gap: 16px;
}

.admin-dashboard-user-card-header,
.admin-dashboard-user-content1,
.admin-dashboard-user-divider-icon {
  align-self: stretch;
  max-width: 100%;
}

.admin-dashboard-user-divider-icon {
  position: relative;
  overflow: hidden;
  max-height: 100%;
}

.admin-dashboard-user-card-header {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.admin-dashboard-user-text8 {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 51px;
}

.admin-dashboard-user-button-group-base {
  width: 83px;
  background-color: #f9fafb;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  min-height: 40px;
}

.admin-dashboard-user-text9 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 105px;
}

.admin-dashboard-user-button-group-base1 {
  background-color: #fff;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  min-height: 40px;
  white-space: nowrap;
}

.admin-dashboard-user-text10 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.admin-dashboard-user-button-group-base2,
.admin-dashboard-user-button-group-base3,
.admin-dashboard-user-button-group-base4,
.admin-dashboard-user-button-group-base5,
.admin-dashboard-user-button-group-base6,
.admin-dashboard-user-button-group-base7 {
  align-self: stretch;
  background-color: #fff;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  min-height: 40px;
  z-index: 6;
}

.admin-dashboard-user-button-group-base3,
.admin-dashboard-user-button-group-base4,
.admin-dashboard-user-button-group-base5,
.admin-dashboard-user-button-group-base6,
.admin-dashboard-user-button-group-base7 {
  z-index: 5;
}

.admin-dashboard-user-button-group-base4,
.admin-dashboard-user-button-group-base5,
.admin-dashboard-user-button-group-base6,
.admin-dashboard-user-button-group-base7 {
  z-index: 4;
}

.admin-dashboard-user-button-group-base5,
.admin-dashboard-user-button-group-base6,
.admin-dashboard-user-button-group-base7 {
  z-index: 3;
}

.admin-dashboard-user-button-group-base6,
.admin-dashboard-user-button-group-base7 {
  z-index: 2;
}

.admin-dashboard-user-button-group-base7 {
  z-index: 1;
}

.admin-dashboard-user-text16 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 61px;
}

.admin-dashboard-user-button-group-base8 {
  width: 93px;
  background-color: #fff;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  min-height: 40px;
}

.admin-dashboard-user-button-group {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-dashboard-user-label {
  width: 47px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.admin-dashboard-user-search-lg-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.admin-dashboard-user-supporting-text2,
.admin-dashboard-user-text17 {
  position: relative;
  line-height: 24px;
}

.admin-dashboard-user-text17 {
  width: calc(100% - 282px);
  border: 0;
  outline: 0;
  font-weight: 500;
  font-family: Inter;
  font-size: 16px;
  background-color: transparent;
  height: 24px;
  flex: 1;
  color: #101828;
  text-align: left;
  display: inline-block;
  min-width: 49px;
  padding: 0;
}

.admin-dashboard-user-supporting-text2 {
  align-self: stretch;
  width: 54px;
  display: none;
}

.admin-dashboard-user-content3,
.admin-dashboard-user-input,
.admin-dashboard-user-input-with-label {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.admin-dashboard-user-content3 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0 262px 0 0;
  box-sizing: border-box;
  gap: 8px;
}

.admin-dashboard-user-input,
.admin-dashboard-user-input-with-label {
  align-self: stretch;
}

.admin-dashboard-user-input {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05),
    0 0 0 4px rgba(158, 119, 237, 0.24);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d6bbfb;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
  font-size: 16px;
  color: #475467;
}

.admin-dashboard-user-input-with-label {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.admin-dashboard-user-hint-text {
  width: 320px;
  position: relative;
  line-height: 20px;
  color: #475467;
  display: none;
  white-space: nowrap;
}

.admin-dashboard-user-input-dropdown {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  max-width: 400px;
}

.admin-dashboard-user-text18 {
  text-decoration: none;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 43px;
}

.admin-dashboard-user-actions2,
.admin-dashboard-user-button4 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.admin-dashboard-user-button4 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 10px 13px;
  background-color: #fff;
  width: max-content;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
  gap: 4px;
}

.admin-dashboard-user-actions2 {
  /* width: 511px; */
  justify-content: flex-start;
  gap: 12px;
  max-width: 100%;
}

.admin-dashboard-user-content2,
.admin-dashboard-user-filters-bar {
  align-self: stretch;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}

.admin-dashboard-user-content2 {
  border-radius: 12px;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 16px;
  gap: 20px;
}

.admin-dashboard-user-filters-bar {
  background-color: #fff;
  border-bottom: 1px solid #eaecf0;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 14px;
  color: #344054;
}

.admin-dashboard-user-main,
.admin-dashboard-user-table-header-title,
.admin-dashboard-user-text19 {
  font-family: Inter;
  color: #101828;
  text-align: left;
}

.admin-dashboard-user-main {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
  max-width: 100%;
  font-size: 26px;
}

.admin-dashboard-user-table-header-title,
.admin-dashboard-user-text19 {
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  min-width: 123px;
}

.admin-dashboard-user-text19 {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #6941c6;
  text-align: center;
  min-width: 56px;
}

.admin-dashboard-user-badge1,
.admin-dashboard-user-text-and-badge1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.admin-dashboard-user-badge1 {
  width: 72px;
  border-radius: 9999px;
  background-color: #f9f5ff;
  border: 1px solid #e9d7fe;
  box-sizing: border-box;
  padding: 0 7px;
  white-space: nowrap;
}

.admin-dashboard-user-text-and-badge1 {
  align-self: stretch;
  padding: 0 729px 0 0;
  gap: 8px;
}

.admin-dashboard-user-supporting-text3 {
  width: 697px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.admin-dashboard-user-text-and-supporting-text2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 12px 0;
  box-sizing: border-box;
  gap: 4px;
  max-width: 100%;
}

.admin-dashboard-user-text20 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #475467;
  text-align: left;
}

.admin-dashboard-user-button5,
.admin-dashboard-user-text-padding7 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.admin-dashboard-user-text-padding7 {
  align-self: stretch;
  padding: 0 2px;
}

.admin-dashboard-user-button5 {
  height: 40px;
  border-radius: 8px;
  overflow: hidden;
  padding: 10px 14px;
  box-sizing: border-box;
  gap: 4px;
}

.admin-dashboard-user-text21 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #6941c6;
  text-align: left;
}

.admin-dashboard-user-button6 {
  height: 40px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d6bbfb;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 13px;
  gap: 4px;
}

.admin-dashboard-user-text22 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
}

.admin-dashboard-user-button7 {
  height: 40px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 13px;
  gap: 4px;
}

.admin-dashboard-user-text23 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
}

.admin-dashboard-user-button8 {
  height: 40px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #7e56d9;
  border: 1px solid #7e56d9;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 13px;
  gap: 4px;
}

.admin-dashboard-user-actions3,
.admin-dashboard-user-card-header1,
.admin-dashboard-user-content4 {
  justify-content: flex-start;
  max-width: 100%;
}

.admin-dashboard-user-actions3 {
  display: none;
  flex-direction: row;
  align-items: center;
  padding: 0 24px 0 0;
  box-sizing: border-box;
  gap: 12px;
}

.admin-dashboard-user-card-header1,
.admin-dashboard-user-content4 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}

.admin-dashboard-user-content4 {
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 20px 24px 0;
  box-sizing: border-box;
  gap: 16px;
}

.admin-dashboard-user-card-header1 {
  background-color: #fff;
  flex-direction: column;
  gap: 20px;
  flex-shrink: 0;
}

.admin-dashboard-user-checkbox-wrapper {
  margin: 0;
  height: 20px;
  width: 28px;
}

.admin-dashboard-user-text24 {
  text-decoration: none;
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 34px;
}

.admin-dashboard-user-header-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1px 0 0;
}

.admin-dashboard-user-arrow-down-icon {
  width: 16px;
  height: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.admin-dashboard-user-header-icon,
.admin-dashboard-user-table-header-cell {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 0 0;
}

.admin-dashboard-user-table-header-cell {
  width: 441px;
  margin: 0 !important;
  position: absolute;
  top: 0;
  left: -360px;
  background-color: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  flex-direction: row;
  padding: 12px 24px 10px;
  gap: 4px;
}

.admin-dashboard-user-checkbox {
  margin: 0;
  width: 20px;
  height: 20px;
}

.admin-dashboard-user-cell-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 0 0;
}

.admin-dashboard-user-avatar-online-indicator {
  height: 13px;
  width: 13px;
  position: relative;
  border-radius: 9999px;
  background-color: #17b26a;
  border: 1.5px solid #fff;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}


.admin-dashboard-user-contrast-border {
  height: 40px;
  width: 40px;
  border-radius: 9999px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.admin-dashboard-user-contrast-border {
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: flex-end;
  padding: 30px 0 0 26px;
}

.admin-dashboard-user-avatar {
  height: 35px;
  width: 42px;
  border-radius: 9999px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  justify-content: flex-start;
  background-image: url("../../../../icons/Gerator/dashboard/user-placeholder2.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.admin-dashboard-user-cell-title,
.admin-dashboard-user-supporting-text4 {
  position: relative;
  font-size: 12px;
  line-height: 20px;
  font-family: Inter;
  text-align: left;
  display: inline-block;
}

.admin-dashboard-user-cell-title {
  font-weight: 500;
  color: #101828;
  min-width: 75px;
}

.admin-dashboard-user-supporting-text4 {
  color: #475467;
  min-width: 97px;
}

.admin-dashboard-user-contrast-border1,
.admin-dashboard-user-table-cell {
  position: absolute;
  box-sizing: border-box;
}

.admin-dashboard-user-table-cell {
  width: 405px;
  margin: 0 !important;
  top: 44px;
  left: -360px;
  border-bottom: 1px solid #eaecf0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.admin-dashboard-user-contrast-border1 {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 9999px;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  overflow: hidden;
}

.admin-dashboard-user-avatar1 {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 9999px;
  background-image: url("../../../../icons/Gerator/contacts/avatar1@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.admin-dashboard-user-text25 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #101828;
  text-align: left;
  display: inline-block;
  min-width: 96px;
}

.admin-dashboard-user-table-cell1 {
  width: 467px;
  margin: 0 !important;
  position: absolute;
  top: 116px;
  left: -360px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.admin-dashboard-user-text26 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #101828;
  text-align: left;
  display: inline-block;
  min-width: 84px;
}

.admin-dashboard-user-table-cell2 {
  width: 428px;
  margin: 0 !important;
  position: absolute;
  top: 188px;
  left: -360px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.admin-dashboard-user-avatar3 {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 9999px;
  background-image: url("../../../../icons/Gerator/contacts/avatar2@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.admin-dashboard-user-text27 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #101828;
  text-align: left;
  display: inline-block;
  min-width: 103px;
  white-space: nowrap;
}

.admin-dashboard-user-contrast-border4,
.admin-dashboard-user-table-cell3 {
  position: absolute;
  margin: 0 !important;
  box-sizing: border-box;
}

.admin-dashboard-user-table-cell3 {
  width: 411px;
  top: 260px;
  left: -360px;
  border-bottom: 1px solid #eaecf0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.admin-dashboard-user-contrast-border4 {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 9999px;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  overflow: hidden;
}

.admin-dashboard-user-text28 {
  flex: 1;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #667085;
  text-align: center;
  z-index: 1;
}

.admin-dashboard-user-avatar4 {
  height: 40px;
  width: 40px;
  border-radius: 9999px;
  background-color: #f2f4f7;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 0;
  box-sizing: border-box;
  position: relative;
}

.admin-dashboard-user-text29 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #101828;
  text-align: left;
  display: inline-block;
  min-width: 82px;
}

.admin-dashboard-user-table-cell4 {
  width: 428px;
  margin: 0 !important;
  position: absolute;
  top: 332px;
  left: -360px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.admin-dashboard-user-text30 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #101828;
  text-align: left;
  display: inline-block;
  min-width: 78px;
}

.admin-dashboard-user-table-cell5 {
  width: 428px;
  margin: 0 !important;
  position: absolute;
  bottom: 288px;
  left: -360px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.admin-dashboard-user-avatar6 {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 9999px;
  background-image: url("../../../../icons/Gerator/contacts/avatar3@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.admin-dashboard-user-text31 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #101828;
  text-align: left;
  display: inline-block;
  min-width: 74px;
}

.admin-dashboard-user-table-cell6,
.admin-dashboard-user-table-cell7 {
  width: 411px;
  margin: 0 !important;
  position: absolute;
  bottom: 216px;
  left: -360px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.admin-dashboard-user-table-cell7 {
  width: 428px;
  bottom: 144px;
}

.admin-dashboard-user-avatar8 {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 9999px;
  background-image: url("../../../../icons/Gerator/contacts/avatar4@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.admin-dashboard-user-text34 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #101828;
  text-align: left;
  display: inline-block;
  min-width: 67px;
}

.admin-dashboard-user-table-cell8 {
  width: 428px;
  margin: 0 !important;
  position: absolute;
  bottom: 72px;
  left: -360px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.admin-dashboard-user-avatar9 {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 9999px;
  background-image: url("../../../../icons/Gerator/contacts/avatar5@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.admin-dashboard-user-text35 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #101828;
  text-align: left;
  display: inline-block;
  min-width: 95px;
}

.admin-dashboard-user-table-cell9 {
  width: 428px;
  margin: 0 !important;
  position: absolute;
  bottom: 0;
  left: -360px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
  z-index: 1;
}

.admin-dashboard-user-column-title {
  text-decoration: none;
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 50px;
}

.admin-dashboard-user-arrow-down-icon1 {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.admin-dashboard-user-table-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.admin-dashboard-user-table-header {
  gap: 4px;
}


.admin-dashboard-user-text36 {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Inter;
  color: #344054;
  text-align: center;
  display: inline-block;
  /* min-width: 93px; */
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

.admin-dashboard-user-location-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.admin-dashboard-user-location-content {
  gap: 8px;
  overflow: hidden;
}




.admin-dashboard-user-text37 {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Inter;
  color: #344054;
  text-align: center;
  display: inline-block;
  min-width: 85px;
}

.admin-dashboard-user-marker-pin-02-parent,
.admin-dashboard-user-table-cell11 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.admin-dashboard-user-marker-pin-02-parent {
  gap: 7px;
}

.admin-dashboard-user-table-cell11 {
  width: 247px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 26px 24px 24px;
}

.admin-dashboard-user-text38 {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Inter;
  color: #344054;
  text-align: center;
  display: inline-block;
  min-width: 91px;
}

.admin-dashboard-user-table-cell15,
.admin-dashboard-user-table-cell16,
.admin-dashboard-user-table-cell18 {
  width: 279px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 26px 24px 24px;
}

.admin-dashboard-user-table-cell16,
.admin-dashboard-user-table-cell18 {
  width: 271px;
}

.admin-dashboard-user-table-cell18 {
  width: 239px;
}

.admin-dashboard-user-column {
  width: 120px;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  box-sizing: border-box;
  z-index: 2;
}

.admin-dashboard-user-column,
.admin-dashboard-user-table-header-cell-parent,
.admin-dashboard-user-table-row {
  display: flex;
  justify-content: start;
}

.admin-dashboard-user-table-header-cell-parent {
  flex-direction: row;
  align-items: flex-start;
  /* padding: 0 0 0 20px; */
  position: relative;
}

.admin-dashboard-user-table-row {

  flex-shrink: 0;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
}

.admin-dashboard-user-description-column-title {
  width: calc(100% - 16px);
  border: 0;
  outline: 0;
  font-weight: 500;
  font-family: Inter;
  font-size: 12px;
  background-color: transparent;
  height: 18px;
  flex: 1;
  position: relative;
  line-height: 18px;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 24px;
  padding: 0;
}

.admin-dashboard-user-help-icon {
  height: 16px;
  width: 16px;
  position: relative;
}


.admin-dashboard-user-table-header1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.admin-dashboard-user-table-header1 {
  width: 60px;
  gap: 4px;
}



.admin-dashboard-user-text46 {
  width: 101px;
  border: 0;
  outline: 0;
  font-family: Inter;
  font-size: 14px;
  background-color: transparent;
  height: 20px;
  position: relative;
  line-height: 20px;
  color: #475467;
  text-align: left;
  display: inline-block;
  padding: 0;
}



/* .admin-dashboard-user-table-cell40 {
  width: 191px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 26px 24px 24px 26px;
} */

.admin-dashboard-user-text47 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 87px;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

.admin-dashboard-user-table-cell21 {
  width: 407px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 26px 23px 24px;
  max-width: 232%;
  flex-shrink: 0;
}

.admin-dashboard-user-text48 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 93px;
}

.admin-dashboard-user-table-cell22 {
  width: 407px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 26px 24px 24px;
  max-width: 232%;
  flex-shrink: 0;
}

.admin-dashboard-user-text49 {
  min-width: 64px;
}

.admin-dashboard-user-text49,
.admin-dashboard-user-text50,
.admin-dashboard-user-text51 {
  font-family: Inter;
  font-size: 14px;
  position: relative;
  line-height: 20px;
  color: #475467;
  text-align: left;
  display: inline-block;
}

.admin-dashboard-user-text50 {
  min-width: 84px;
}

.admin-dashboard-user-text51 {
  width: 82px;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 20px;
  padding: 0;
}

.admin-dashboard-user-table-cell25 {
  width: 328px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 26px 24px 24px;
  max-width: 187%;
  flex-shrink: 0;
}

.admin-dashboard-user-text52 {
  width: 92px;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 20px;
  display: inline-block;
  padding: 0;
}

.admin-dashboard-user-text52,
.admin-dashboard-user-text53,
.admin-dashboard-user-text54 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
}

.admin-dashboard-user-text53 {
  display: inline-block;
  min-width: 68px;
}

.admin-dashboard-user-text54 {
  white-space: nowrap;
}

.admin-dashboard-user-column1,
.admin-dashboard-user-table-cell29 {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
}

.admin-dashboard-user-table-cell29 {
  width: 287px;
  border-bottom: 1px solid #eaecf0;
  flex-direction: row;
  align-items: center;
  padding: 26px 24px 24px;
}

.admin-dashboard-user-column1 {
  width: 176px;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  z-index: 1;
}

.admin-dashboard-user-action-column-title {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 37px;
}

.admin-dashboard-user-arrow-down-icon2 {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}


.admin-dashboard-user-table-header2 {
  display: flex;
  flex-direction: row;
  align-items: center;

  flex: 1;
  justify-content: center;
  gap: 4px;
}



.admin-dashboard-user-dropdown6 {
  width: 21px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-dashboard-user-column2,
.admin-dashboard-user-table-content {
  display: flex;
  box-sizing: border-box;
}

.admin-dashboard-user-column2 {
  width: 120px;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0;
  z-index: 1;
}

.admin-dashboard-user-table-content {
  width: 1014px;
  height: 607px;
  overflow-y: auto;
  scrollbar-width: none;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: space-between; */
  padding: 0 1px 0 0;
  /* gap: 20px; */
  max-width: 100%;
}

.admin-dashboard-user-text56 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 59px;
}

.admin-dashboard-user-buttonsbutton2 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 6px 11px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.admin-dashboard-user-button-wrap,
.admin-dashboard-user-pagination-wrapper {
  display: flex;
  justify-content: flex-start;
}

.admin-dashboard-user-button-wrap {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
}

.admin-dashboard-user-pagination-wrapper {
  width: 326px;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-start;
  padding: 2px 0 0;
  box-sizing: border-box;
}

.admin-dashboard-user-number {
  width: 7px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #18222f;
  text-align: center;
  display: inline-block;
  min-width: 7px;
}

.admin-dashboard-user-content5,
.admin-dashboard-user-pagination-number-base {
  flex: 1;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-dashboard-user-content5 {
  padding: 10px 16px;
}

.admin-dashboard-user-pagination-number-base {
  background-color: #f9fafb;
  overflow: hidden;
}

.admin-dashboard-user-number1 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #475467;
  text-align: center;
  display: inline-block;
  min-width: 9px;
}

.admin-dashboard-user-content6,
.admin-dashboard-user-pagination-number-base1 {
  flex: 1;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-dashboard-user-content6 {
  padding: 10px 15px;
}

.admin-dashboard-user-pagination-number-base1 {
  overflow: hidden;
}

.admin-dashboard-user-number2 {
  width: 10px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #475467;
  text-align: center;
  display: inline-block;
  min-width: 10px;
}

.admin-dashboard-user-content7,
.admin-dashboard-user-pagination-number-base2 {
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-dashboard-user-content7 {
  padding: 10px 15px;
}

.admin-dashboard-user-pagination-number-base2 {
  overflow: hidden;
}

.admin-dashboard-user-number3 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #475467;
  text-align: center;
  display: inline-block;
  min-width: 13px;
}

.admin-dashboard-user-content8 {
  flex: 1;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 13px;
}

.admin-dashboard-user-number6 {
  width: 16px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #475467;
  text-align: center;
  display: inline-block;
  min-width: 16px;
}

.admin-dashboard-user-content11,
.admin-dashboard-user-pagination-numbers {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-dashboard-user-content11 {
  border-radius: 8px;
  padding: 10px 12px;
}

.admin-dashboard-user-pagination-numbers {
  width: 292px;
  flex-shrink: 0;
  gap: 2px;
}

.admin-dashboard-user-text57 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 32px;
}

.admin-dashboard-user-button-wrap1,
.admin-dashboard-user-buttonsbutton3 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.admin-dashboard-user-buttonsbutton3 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 6px 11px;
  background-color: #fff;
  width: 84px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
  gap: 4px;
}

.admin-dashboard-user-button-wrap1 {
  align-self: stretch;
  justify-content: flex-end;
}

.admin-dashboard-user-pagination,
.admin-dashboard-user-prsopective-buyers,
.admin-dashboard-user-table {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-dashboard-user-pagination {
  border-top: 1px solid #eaecf0;
  overflow-x: auto;
  flex-direction: row;
  padding: 12px 24px 16px;
  gap: 12px;
  z-index: 3;
}

.admin-dashboard-user-prsopective-buyers,
.admin-dashboard-user-table {
  background-color: #fff;
  max-width: 100%;
  overflow: hidden;
  flex-direction: column;
  box-sizing: border-box;
}

.admin-dashboard-user-table {
  margin: 0;
  align-self: stretch;
  box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
  border: 1px solid #eaecf0;
}

.admin-dashboard-user-prsopective-buyers {
  /* width: 1013px; */
  padding: 0px 0px 93px;
  /* margin: auto; */
  gap: 14px;
  line-height: normal;
  letter-spacing: normal;
}


.admin-dashboard-user-table-header-cell1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  width: 184px;
  background-color: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 13px 17px 11px;


  text-align: left;
  color: #475467;
}

.admin-dashboard-user-table-cell10 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  width: 184px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 20px 10px 10.5px 15px;
  gap: 12px;
}

.admin-dashboard-user-table-header-cell2 {

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 160px;
  background-color: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 13px 19px 11px;
}

.admin-dashboard-user-table-cell20 {
  width: 160px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 28px 24px 24px 19px;
}

.admin-dashboard-user-table-header-cell3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 155px;
  background-color: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 13px 27px 11px;

}

.admin-dashboard-user-table-cell30 {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #eaecf0;
  justify-content: flex-start;
  width: 155px;
  padding: 26px 50px 24px 26px;
}


.admin-dashboard-user-table-header-cell4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 140px;
  background-color: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 13px 27px 11px;

}

.admin-dashboard-user-table-cell40 {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #eaecf0;
  justify-content: flex-start;
  width: 140px;
  z-index: 1;
  padding: 26px 50px 24px 26px;
}


.admin-dashboard-user-table-header-cell5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 140px;
  background-color: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 13px 27px 11px;

}

.admin-dashboard-user-table-cell50 {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #eaecf0;
  justify-content: flex-start;
  width: 140px;
  padding: 26px 50px 24px 26px;
}


.admin-dashboard-user-table-header-cell6 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 145px;
  background-color: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 13px 27px 11px;

}

.admin-dashboard-user-table-cell60 {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #eaecf0;
  justify-content: flex-start;
  width: 145px;
  padding: 26px 50px 24px 26px;
}


.admin-dashboard-user-table-header-cell7 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90px;
  background-color: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 13px 27px 11px;

}

.admin-dashboard-user-table-cell70 {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #eaecf0;
  justify-content: flex-start;
  width: 90px;
  padding: 26px 50px 24px 26px;
}




@media screen and (max-width: 925px) {
  .admin-dashboard-user-text-and-badge1 {
    flex-wrap: wrap;
    padding-right: 364px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 700px) {

  .admin-dashboard-user-text-and-badge,
  .admin-dashboard-user-text-and-badge1 {
    padding-right: 121px;
    box-sizing: border-box;
  }

  .admin-dashboard-user-text-and-badge1 {
    padding-right: 182px;
  }

  .admin-dashboard-user-text-and-supporting-text2 {
    min-width: 100%;
  }

  .admin-dashboard-user-actions3 {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 450px) {

  .admin-dashboard-user-content3,
  .admin-dashboard-user-text-and-badge {
    padding-right: 20px;
    box-sizing: border-box;
  }

  .admin-dashboard-user-input-dropdown {
    max-width: 100%;
  }
}