.custom-z-index {
    z-index: 4;
}

.radar-job-component-component-listing-container {
    box-sizing: border-box;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: initial;
    padding: 0 29px;
    align-items: flex-start;
    width: 100%;
    display: flex;
    max-height: 95vh;
    /* overflow-y: auto; */
    overflow-y: scroll;
    /* Enable vertical scrolling */
    scrollbar-width: none;
    /* For Firefox */
    -ms-overflow-style: none;
    /* For Internet Explorer and Edge */
}


.radar-job-component-component-listing-container::-webkit-scrollbar {
    display: none;
    /* For Chrome, Safari, and Opera */
}

.radar-job-component-dashboard-content-items {
    box-sizing: border-box;

    align-self: stretch;
    flex-direction: row;
    padding: 0 30px 24px 32px;
    /* padding-bottom: 24px; */
    font-size: 18px;
    z-index: 0;
    align-self: stretch;
    flex-direction: row;
    
    font-size: 18px;
}

.radar-job-component-dashboard-rectangle-parent10 {
    flex: 1;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 12px 15px 12px 23px;
    max-width: 100%;
    gap: 20px;
    z-index: 1;
}

.radar-job-component-dashboard-frame-child12 {
    position: relative;
    border-radius: 12px;
    display: none;

    height: 208px;
    width: 1016px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    max-width: 100%;
}

.radar-job-component-dashboard-frame-wrapper25 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;

    width: 648px;
    flex-direction: column;
    padding: 10px 0 0;
    box-sizing: border-box;
}

.radar-job-component-dashboard-element-details-parent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;

    align-self: stretch;
    flex-direction: row;
    gap: 24px;
}

.radar-job-component-dashboard-element-details {
    border-radius: 12px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 38px;
    box-sizing: border-box;
    min-width: 160px;
    z-index: 2;
}

.radar-job-component-dashboard-element-details-child {
    position: relative;
    border-radius: 12px;
    display: none;

    height: 160px;
    width: 160px;
    background-color: #f5f5f5;
}

.radar-job-component-dashboard-f4b711e503bc72fe396e5043e0c2-1-icon {
    height: 84px;
    width: 84px;
    position: relative;
    object-fit: cover;
    z-index: 3;
}

.radar-job-component-dashboard-element-actions {
    flex: 1;
    flex-direction: column;
    gap: 8px;
    min-width: 302px;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
}



.radar-job-component-dashboard-text78 {
    position: relative;
    z-index: 2;
    width: 640px;
    line-height: 28px;
    font-weight: 600;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: inherit !important;
}

.radar-job-component-dashboard-text78:hover {
    text-decoration: underline;
}

.radar-job-component-dashboard-action-icons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    align-self: stretch;
    max-width: 100%;
    row-gap: 20px;
    font-size: 16px;
    color: #475467;
}

.radar-job-component-dashboard-calendar-icon6 {
    flex: 1;
    gap: 8px;
    min-width: 218px;
    max-width: 100%;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.radar-job-component-dashboard-date-icon {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    flex: 1;
    gap: 16px;
    min-width: 114px;
}

.radar-job-component-dashboard-badge-parent {
    display: flex;
    align-items: flex-start;
    gap: 20px;

    flex-direction: row;
    justify-content: flex-start;
}

.radar-job-component-dashboard-supporting-text12 {
    position: relative;
    letter-spacing: -0.06px;
    line-height: 24px;
    z-index: 2;
}

.radar-job-component-dashboard-search-input {
    display: flex;
    justify-content: flex-start;

    flex-direction: column;
    align-items: flex-start;
    padding: 2px 0 0;
}

.radar-job-component-dashboard-marker-pin-02-icon2 {
    width: 20px;
    height: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 2;
}


.radar-job-component-dashboard-type-badge-elements {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    gap: 8px;
    color: #fff;
}

.radar-job-component-dashboard-end-label {
    width: 50px;
    border-radius: 8px;
    background-color: #fdb022;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1px 4px;
    box-sizing: border-box;
    z-index: 2;
}

.radar-job-component-dashboard-search-icon-input {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 41px;
}

.radar-job-component-dashboard-type-support {
    flex-direction: column;
    padding: 1px 0 0;
    color: #475467;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.radar-job-component-dashboard-supporting-text9 {
    position: relative;
    line-height: 24px;
    display: inline-block;
    min-width: 117px;
    z-index: 2;
}

.radar-job-component-dashboard-type-label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

}

.radar-job-component-dashboard-text99 {
    position: relative;
    line-height: 23px;
    font-weight: 500;
    z-index: 2;
}

.radar-job-component-dashboard-supporting-text14 {
    position: relative;
    font-size: 12px;
    line-height: 19px;
    color: #475467;
    display: inline-block;
    min-width: 104px;
    z-index: 3;
}

.radar-job-component-dashboard-work-type-icon {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    width: 152px;
    flex-direction: column;
    gap: 58px;
    min-width: 152px;
}

.radar-job-component-dashboard-location3 {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 124px;
    z-index: 2;


    text-wrap: nowrap;
}

.radar-job-component-dashboard-work-type-value {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    align-self: stretch;
    flex-direction: row;
    padding: 0 0 0 1px;
    font-size: 14px;
    color: #101828;
}

.radar-job-component-dashboard-price-parent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex: 1;
    flex-direction: column;
}

.radar-job-component-dashboard-text100 {
    align-self: stretch;
    position: relative;
    line-height: 23px;
    font-weight: 500;
    z-index: 2;
}

.radar-job-component-dashboard-supporting-text15 {
    width: 104px;
    position: relative;
    font-size: 12px;
    line-height: 19px;
    color: #475467;
    display: inline-block;
    z-index: 3;
}

.radar-job-component-dashboard-work-type-label {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    gap: 8px;
    margin-left: -9px;
}

.radar-job-component-dashboard-type-of-work2 {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 124px;
    z-index: 2;

    min-width: 109px;
}

.radar-job-component-dashboard-dropdown-parent1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    align-items: flex-end;

    width: 153px;
    gap: 22px;
    text-align: center;
    font-size: 12px;
    color: #475467;
}

.radar-job-component-dashboard-dropdown10 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 2;
    position: relative
}

.radar-job-component-dashboard-dots-vertical-icon {
    width: 20px;
    height: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
}

.radar-job-component-dashboard-filter-elements-inner {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 10px 0 0;
    z-index: 0;
}

.radar-job-component-dashboard-frame-parent31 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;


    align-items: flex-end;

    flex: 1;
}

.radar-job-component-dashboard-text-wrapper10 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 57px;
}

.radar-job-component-dashboard-text101 {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 29px;
    z-index: 3;
}

.radar-job-component-dashboard-price4 {
    align-self: stretch;
    position: relative;
    font-size: 20px;
    letter-spacing: -0.02em;
    line-height: 32px;
    font-weight: 600;
    color: #6941c6;
    white-space: nowrap;
    z-index: 2;
}

.radar-job-component-dashboard-trend-chart-inner {
    display: flex;
    justify-content: flex-start;

    align-self: stretch;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 0 0 3px;
}

.radar-job-component-dashboard-trend-chart {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    width: 113px;
    align-items: flex-start;
    gap: 4px;
}

.radar-job-component-dashboard-text83 {
    position: relative;

    line-height: 20px;
    display: inline-block;
    min-width: 83px;
    z-index: 3;
}

.radar-job-component-dashboard-frame-parent32 {
    display: flex;
    justify-content: flex-start;

    flex: 1;
    flex-direction: column;
    align-items: flex-end;
    gap: 19px;
}

.radar-job-component-dashboard-frame-wrapper27 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 34px 0 0;
}

.radar-job-component-dashboard-radar-1-1-container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    flex: 1;
    align-items: flex-start;
    gap: 8px;
    z-index: 2;
}

.radar-job-component-dashboard-magnifier-1-icon {
    height: 20px;
    width: 20px;
    position: relative;
    object-fit: cover;
    min-height: 20px;
}

.radar-job-component-dashboard-arrow-square-up-right-icon1 {
    width: 24px;
    height: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 2;
}

.custom-z-index {
    z-index: 4;
}

.radar-job-component-component-listing-container {
    box-sizing: border-box;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: initial;
    padding: 0 29px;
    align-items: flex-start;
    width: 100%;
    display: flex;
    max-height: 85vh;
    /* overflow-y: auto; */
    overflow-y: scroll;
    /* Enable vertical scrolling */
    scrollbar-width: none;
    /* For Firefox */
    -ms-overflow-style: none;
    /* For Internet Explorer and Edge */
}


.radar-job-component-component-listing-container::-webkit-scrollbar {
    display: none;
    /* For Chrome, Safari, and Opera */
}

.radar-job-component-dashboard-content-items {
    box-sizing: border-box;

    align-self: stretch;
    flex-direction: row;
    padding: 0 30px 24px 32px;
    font-size: 18px;
    z-index: 0;
    align-self: stretch;
    flex-direction: row;
    padding: 0 30px 24px 32px;
    font-size: 18px;
}

.radar-job-component-dashboard-rectangle-parent10 {
    flex: 1;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 12px 15px 12px 23px;
    max-width: 100%;
    gap: 20px;
    z-index: 1;
    height: 300px;
}

.radar-job-component-dashboard-frame-child12 {
    position: relative;
    border-radius: 12px;
    display: none;

    height: 208px;
    width: 1016px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    max-width: 100%;
}

.radar-job-component-dashboard-frame-wrapper25 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;

    width: 648px;
    flex-direction: column;
    padding: 10px 0 0;
    box-sizing: border-box;
}

.radar-job-component-dashboard-element-details-parent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;

    align-self: stretch;
    flex-direction: row;
    gap: 24px;
}

.radar-job-component-dashboard-element-details {
    border-radius: 12px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 38px;
    box-sizing: border-box;
    min-width: 160px;
    z-index: 2;
}

.radar-job-component-dashboard-element-details-child {
    position: relative;
    border-radius: 12px;
    display: none;

    height: 160px;
    width: 160px;
    background-color: #f5f5f5;
}

.radar-job-component-dashboard-f4b711e503bc72fe396e5043e0c2-1-icon {
    height: 84px;
    width: 84px;
    position: relative;
    object-fit: cover;
    z-index: 3;
}

.radar-job-component-dashboard-element-actions {
    flex: 1;
    flex-direction: column;
    gap: 8px;
    min-width: 302px;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
}



.radar-job-component-dashboard-text78 {
    position: relative;
    z-index: 2;
    width: 640px;
    line-height: 28px;
    font-weight: 600;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: inherit !important;
}

.radar-job-component-dashboard-text78:hover {
    text-decoration: underline;
}

.radar-job-component-dashboard-action-icons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    align-self: stretch;
    max-width: 100%;
    row-gap: 20px;
    font-size: 16px;
    color: #475467;
}

.radar-job-component-dashboard-calendar-icon6 {
    flex: 1;
    gap: 8px;
    min-width: 218px;
    max-width: 100%;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.radar-job-component-dashboard-date-icon {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    flex: 1;
    gap: 16px;
    min-width: 114px;
}

.radar-job-component-dashboard-badge-parent {
    display: flex;
    align-items: flex-start;
    gap: 20px;

    flex-direction: row;
    justify-content: flex-start;
}

.radar-job-component-dashboard-supporting-text12 {
    position: relative;
    letter-spacing: -0.06px;
    line-height: 24px;
    z-index: 2;
}

.radar-job-component-dashboard-search-input {
    display: flex;
    justify-content: flex-start;

    flex-direction: column;
    align-items: flex-start;
    padding: 2px 0 0;
}

.radar-job-component-dashboard-marker-pin-02-icon2 {
    width: 20px;
    height: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 2;
}


.radar-job-component-dashboard-type-badge-elements {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    gap: 8px;
    color: #fff;
}

.radar-job-component-dashboard-end-label {
    width: 50px;
    border-radius: 8px;
    background-color: #fdb022;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1px 4px;
    box-sizing: border-box;
    z-index: 2;
}

.radar-job-component-dashboard-search-icon-input {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 41px;
}

.radar-job-component-dashboard-type-support {
    flex-direction: column;
    padding: 1px 0 0;
    color: #475467;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.radar-job-component-dashboard-supporting-text9 {
    position: relative;
    line-height: 24px;
    display: inline-block;
    min-width: 117px;
    z-index: 2;
}

.radar-job-component-dashboard-type-label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

}

.radar-job-component-dashboard-text99 {
    position: relative;
    line-height: 23px;
    font-weight: 500;
    z-index: 2;
}

.radar-job-component-dashboard-supporting-text14 {
    position: relative;
    font-size: 12px;
    line-height: 19px;
    color: #475467;
    display: inline-block;
    min-width: 104px;
    z-index: 3;
}

.radar-job-component-dashboard-work-type-icon {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    width: 152px;
    flex-direction: column;
    gap: 58px;
    min-width: 152px;
}

.radar-job-component-dashboard-location3 {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 124px;
    z-index: 2;
}

.radar-job-component-dashboard-work-type-value {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    align-self: stretch;
    flex-direction: row;
    padding: 0 0 0 1px;
    font-size: 14px;
    color: #101828;
}

.radar-job-component-dashboard-price-parent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex: 1;
    flex-direction: column;
}

.radar-job-component-dashboard-text100 {
    align-self: stretch;
    position: relative;
    line-height: 23px;
    font-weight: 500;
    z-index: 2;
}

.radar-job-component-dashboard-supporting-text15 {
    width: 104px;
    position: relative;
    font-size: 12px;
    line-height: 19px;
    color: #475467;
    display: inline-block;
    z-index: 3;
}

.radar-job-component-dashboard-work-type-label {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    gap: 8px;
    margin-left: -9px;
}

.radar-job-component-dashboard-type-of-work2 {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 124px;
    z-index: 2;

    min-width: 109px;
}

.radar-job-component-dashboard-dropdown-parent1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    align-items: flex-end;

    width: 153px;
    gap: 22px;
    text-align: center;
    font-size: 12px;
    color: #475467;
}

.radar-job-component-dashboard-dropdown10 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 2;
    position: relative
}

.radar-job-component-dashboard-dots-vertical-icon {
    width: 20px;
    height: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
}

.radar-job-component-dashboard-filter-elements-inner {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 10px 0 0;
    z-index: 0;
}

.radar-job-component-dashboard-frame-parent31 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;


    align-items: flex-end;

    flex: 1;
}

.radar-job-component-dashboard-text-wrapper10 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 57px;
}

.radar-job-component-dashboard-text101 {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 29px;
    z-index: 3;
}

.radar-job-component-dashboard-price4 {
    align-self: stretch;
    position: relative;
    font-size: 20px;
    letter-spacing: -0.02em;
    line-height: 32px;
    font-weight: 600;
    color: #6941c6;
    white-space: nowrap;
    z-index: 2;
}

.radar-job-component-dashboard-trend-chart-inner {
    display: flex;
    justify-content: flex-start;

    align-self: stretch;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 0 0 3px;
}

.radar-job-component-dashboard-trend-chart {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    width: 113px;
    align-items: flex-start;
    gap: 4px;
}

.radar-job-component-dashboard-text83 {
    position: relative;

    line-height: 20px;
    display: inline-block;
    min-width: 83px;
    z-index: 3;
}

.radar-job-component-dashboard-frame-parent32 {
    display: flex;
    justify-content: flex-start;

    flex: 1;
    flex-direction: column;
    align-items: flex-end;
    gap: 19px;
}

.radar-job-component-dashboard-frame-wrapper27 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 34px 0 0;
}

.radar-job-component-dashboard-radar-1-1-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    flex: 1;
    align-items: flex-start;
    gap: 8px;
    z-index: 2;
}

.radar-job-component-dashboard-magnifier-1-icon {
    height: 20px;
    width: 20px;
    position: relative;
    object-fit: cover;
    min-height: 20px;
}

.radar-job-component-dashboard-arrow-square-up-right-icon1 {
    width: 24px;
    height: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 2;
}

.radar-job-component-radar-frame-parent71 {
    display: flex;
    align-items: flex-start;


    width: 968px;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    font-size: 14px;
    color: #344054;

}

.radar-job-component-radar-content-items {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
    z-index: 0;
    box-sizing: border-box;

    align-self: stretch;
    flex-direction: row;
    padding: 0 30px 24px 32px;
    font-size: 18px;
}

.radar-job-component-radar-divider-container1 {
    display: flex;
    align-items: flex-start;
    max-width: 100%;
    position: relative;
    flex: 1;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: flex-start;
    padding: 12px 13px 22px 23px;
    gap: 24.0px;
    z-index: 0;
}

.radar-job-component-radar-divider-container-child {
    width: 1016px;
    height: 493px;
    position: relative;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    display: none;
    max-width: 100%;
}

.radar-job-component-radar-rectangle-parent5 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 100%;

    align-self: stretch;
    align-items: flex-end;
    gap: 24px;
}

.radar-job-component-radar-frame-parent1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    /* max-width: 100%; */
    max-width: 65%;
    flex: 1;
    align-items: flex-start;
    gap: 15px;
    /* min-width: 524px; */
}


.radar-job-component-radar-filter-elements {
    /* width: 153px; */
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 26px;
    text-align: center;
    font-size: 20px;
    color: #6941c6;
}

.radar-job-component-radar-dropdown10 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 2;
    margin-right: 10px;
}

.radar-job-component-radar-dots-vertical-icon {
    width: 20px;
    height: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    cursor: pointer;
}

.radar-job-component-radar-divider-icon8 {
    width: 968px;
    position: relative;
    max-height: 100%;
    max-width: 100%;
    z-index: 2;
}

.radar-job-component-radar-frame-parent71 {
    display: flex;
    align-items: flex-start;
    max-width: 100%;

    width: 968px;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    font-size: 14px;
    color: #344054;
}

.radar-job-component-radar-buttonsbutton-parent1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: max-content;
    /* width: 710px; */
    flex-direction: row;
    gap: 24px;
    max-width: calc(100% - 44px);
}

.radar-job-component-radar-legend-series {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
}


.radar-job-component-main-buttons-group {
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.radar-job-component-radar-buttonsbutton13 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    z-index: 2;
    cursor: pointer;
    width: 90px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px 12px;
    gap: 6px;
}

.radar-job-component-radar-arrow-narrow-left-icon {
    height: 24px;
    width: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    min-height: 24px;
}

.radar-job-component-radar-search-input {
    display: flex;
    justify-content: flex-start;

    flex-direction: column;
    align-items: flex-start;
    padding: 2px 0 0;
}

.radar-job-component-radar-text67 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 34px;
}

.radar-job-component-radar-buttonsbutton25 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    cursor: pointer;
    padding: 6px 11px;
    gap: 5.0px;
    min-width: 92px;
    z-index: 2;
}

.radar-job-component-radar-buttonsbutton26 {

    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    cursor: pointer;
    padding: 6px 11px;
    gap: 5.0px;
    min-width: 92px;
    z-index: 2;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    padding: 6px 10px 6px 12px;
    gap: 5.0px;
}

.radar-job-component-radar-icon-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 3.0px 0 0;
}

.radar-job-component-radar-icon1 {
    width: 16.0px;
    height: 16.0px;
    position: relative;
}


.radar-job-component-radar-text68 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 56px;
}

.radar-job-component-radar-buttonsbutton27 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    flex-direction: row;
    padding: 8px 12px;
    position: relative;
    gap: 6px;
    min-width: 79px;
    z-index: 2;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.radar-job-component-radar-text145 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 70px;
}

.radar-job-component-radar-upload-04-icon {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    min-height: 20px;
}

.radar-job-component-radar-ellipse-parent {
    height: 24px;
    width: 17px;
    position: absolute;
    margin: 0 !important;
    top: -9px;
    right: -2.0px;
    font-size: 10px;
    color: #fff;
}

.radar-job-component-radar-ellipse-div {
    position: absolute;
    top: 0;
    left: 3px;
    border-radius: 50%;
    background-color: #17b26a;
    width: 17px;
    height: 17px;
    z-index: 1;
}

.radar-job-component-radar-div {
    color: white;
    z-index: 2;
    position: absolute;
    top: 3px;
    left: 8px;

}

.radar-job-component-radar-arrow-square-up-right-wrapper1 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-direction: column;
    padding: 8px 0 0;
}

.radar-job-component-radar-arrow-square-up-right-icon1 {
    width: 24px;
    height: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 2;
}

.archive-job-frame-parent11 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    gap: 7.3px;
}



@media screen and (max-width: 1100px) {
    .radar-job-component-dashboard-rectangle-parent10 {
        flex-wrap: wrap;
    }
}

@media screen and (max-width:750px) {

    .radar-job-component-dashboard-action-icons,
    .radar-job-component-dashboard-element-details-parent {
        flex-wrap: wrap;
    }

    .radar-job-component-dashboard-element-details {
        flex: 1;
    }

    .radar-job-component-dashboard-f4b711e503bc72fe396e5043e0c2-1-icon {
        flex: 1;
    }

}

@media screen and (max-width:450px) {

    .radar-job-component-dashboard-calendar-icon6 {
        flex-wrap: wrap;
    }

    .radar-job-component-dashboard-work-type-icon {
        flex: 1;
    }

    .radar-job-component-dashboard-work-type-label {
        margin-left: 0;
    }

    .radar-job-component-dashboard-price4 {
        font-size: 16px;
        line-height: 26px;
    }

}