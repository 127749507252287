@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

.business-page-text {
  position: relative;
  line-height: 20px;
  font-weight: 500;
}

.business-page-breadcrumb-button-base {
  width: 28px;
  border-radius: 6px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 7px;
  box-sizing: border-box;
  z-index: 1;
}

.business-page-chevron-right-icon,
.business-page-text1 {
  position: relative;
  flex-shrink: 0;
}

.business-page-chevron-right-icon {
  width: 16px;
  height: 16px;
  overflow: hidden;
  display: none;
  z-index: 2;
}

.business-page-text1 {
  line-height: 20px;
  font-weight: 500;
  white-space: nowrap;
}

.business-page-breadcrumb-button-base1 {
  width: 71px;
  border-radius: 6px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  box-sizing: border-box;
  z-index: 3;
}

.business-page-chevron-right-icon1 {
  width: 16px;
  height: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  z-index: 4;
}

.business-page-divider-icon {
  width: 1216px;
  position: relative;
  max-height: 100%;
  display: none;
  max-width: 100%;
  z-index: 5;
}

.business-page-placeholder-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 20px;
}

.business-page-text2 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.business-page-button,
.business-page-text-padding {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.business-page-text-padding {
  align-self: stretch;
  padding: 0 2px;
}

.business-page-button {
  height: 40px;
  border-radius: 8px;
  overflow: hidden;
  padding: 10px 14px;
  box-sizing: border-box;
  gap: 4px;
}

.business-page-text3 {
  align-self: stretch;
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.business-page-text-padding1 {
  height: 20px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
  box-sizing: border-box;
}

.business-page-button1 {
  flex: 1;
  background-color: #fff;
  border: 1px solid #d6bbfb;
  padding: 8px 13px;
  min-width: 69px;
  color: #6941c6;
}

.business-page-button1,
.business-page-button2,
.business-page-button3 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.business-page-button2 {
  flex: 1;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  padding: 8px 13px;
  min-width: 69px;
  color: #344054;
}

.business-page-button3 {
  height: 40px;
  background-color: #7e56d9;
  border: 1px solid #7e56d9;
  padding: 10px 13px;
  color: #fff;
}

.business-page-actions,
.business-page-frame-child {
  display: none;
  max-width: 100%;
}

.business-page-actions {
  width: 419px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: center;
  gap: 12px;
  z-index: 6;
}

.business-page-frame-child {
  height: 72px;
  width: 1440px;
  position: relative;
  background-color: #fff;
}

.business-page-gerator-04-1-icon {
  height: 32px;
  width: 121px;
  position: relative;
  object-fit: cover;
  display: none;
}

.business-page-logo,
.business-page-logo-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-page-logo {
  align-self: stretch;
  height: 32px;
  flex-direction: row;
  background-image: url("../../../icons/Gerator/business_page/gerator04-1@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;
}

.business-page-logo-wrapper {
  width: 121px;
  flex-direction: column;
  padding: 6px 0 0;
  box-sizing: border-box;
}

.business-page-bar-chart-01-icon,
.business-page-dot-icon {
  height: 10px;
  width: 10px;
  position: relative;
  display: none;
}

.business-page-bar-chart-01-icon {
  height: 24px;
  width: 24px;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.business-page-text6 {
  text-decoration: none;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 30px;
}

.business-page-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.business-page-text7 {
  align-self: stretch;
  position: relative;
  line-height: 18px;
  font-weight: 500;
}

.business-page-badge {
  height: 22px;
  border-radius: 9999px;
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 7px;
  text-align: center;
  font-size: 12px;
}

.business-page-chevron-down-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.business-page-nav-item-base {
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 12px;
  gap: 8px;
}

.business-page-text8,
.business-page-text9 {
  position: relative;
  font-family: Inter;
}

.business-page-text8 {
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #18222f;
  text-align: left;
  display: inline-block;
  min-width: 29px;
}

.business-page-text9 {
  align-self: stretch;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #344054;
  text-align: center;
}

.business-page-badge1,
.business-page-nav-item-base1 {
  background-color: #f9fafb;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.business-page-badge1 {
  height: 22px;
  border-radius: 9999px;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
  padding: 2px 7px;
}

.business-page-nav-item-base1 {
  cursor: pointer;
  border: 0;
  padding: 8px 12px;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  gap: 8px;
}

.business-page-text10 {
  text-decoration: none;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 38px;
}

.business-page-navigation,
.business-page-navigation-wrapper {
  display: flex;
  justify-content: flex-start;
}

.business-page-navigation {
  flex-direction: row;
  align-items: center;
  gap: 4px;
  z-index: 1;
}

.business-page-navigation-wrapper {
  flex-direction: column;
  align-items: flex-start;
  padding: 2px 0 0;
}

.business-page-label {
  width: 47px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.business-page-search-lg-icon,
.business-page-text12 {
  position: relative;
  overflow: hidden;
}

.business-page-search-lg-icon {
  height: 20px;
  width: 20px;
  flex-shrink: 0;
}

.business-page-text12 {
  width: calc(100% - 20px);
  border: 0;
  outline: 0;
  font-family: Inter;
  font-size: 16px;
  background-color: transparent;
  flex: 1;
  line-height: 24px;
  color: #667085;
  text-align: left;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.business-page-content3,
.business-page-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.business-page-content3 {
  flex: 1;
  gap: 8px;
}

.business-page-input {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  padding: 8px 13px;
}

.business-page-frame-container,
.business-page-frame-group,
.business-page-input-with-label {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-page-input-with-label {
  flex: 1;
  flex-direction: column;
  gap: 6px;
  min-width: 208px;
  z-index: 1;
  font-size: 14px;
}

.business-page-frame-container,
.business-page-frame-group {
  flex-direction: row;
  max-width: 100%;
}

.business-page-frame-container {
  flex: 1;
  gap: 38px;
  min-width: 366px;
}

.business-page-frame-group {
  width: 754px;
  gap: 70px;
}

.business-page-icon {
  height: 19px;
  width: 19px;
  position: relative;
}

.business-page-nav-item-button {
  width: 40px;
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  box-sizing: border-box;
  z-index: 1;
}

.business-page-nav-item-button-child {
  height: 18.3px;
  width: 14.6px;
  position: relative;
}

.business-page-nav-item-button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 3px 0 0;
}

.business-page-contrast-border {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 9999px;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
  display: none;
}

.business-page-avatar {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 9999px;
  background-image: url("../../../icons/Gerator//business_page/avatar@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;
}

.business-page-frame-parent1,
.business-page-frame-wrapper,
.business-page-rectangle-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.business-page-frame-parent1 {
  justify-content: flex-start;
  gap: 15px;
}

.business-page-frame-wrapper,
.business-page-rectangle-parent {
  box-sizing: border-box;
  max-width: 100%;
}

.business-page-rectangle-parent {
  flex: 1;
  background-color: #fff;
  justify-content: space-between;
  padding: 14px 64px 14px 31px;
  gap: 20px;
  z-index: 1;
  text-align: left;
  font-size: 16px;
  color: #344054;
  font-family: Inter;
}

.business-page-frame-wrapper {
  align-self: stretch;
  justify-content: flex-end;
  padding: 0 2px 0 0;
}

.business-page-image-wrap-icon {
  height: 240px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  z-index: 2;
}

.business-page-image-wrap-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 2px 14px 0;
  box-sizing: border-box;
  max-width: 100%;
}

.business-page-frame-inner,
.business-page-frame-item {
  position: relative;
  border-radius: 12px;
  display: none;
}

.business-page-frame-item {
  height: 261px;
  width: 261px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
}

.business-page-frame-inner {
  height: 230px;
  width: 230px;
  background-color: #f5f5f5;
}

.business-page-f4b711e503bc72fe396e5043e0c2-7-icon {
  height: 155px;
  width: 160px;
  position: relative;
  object-fit: cover;
  z-index: 4;
}

.business-page-rectangle-container {
  border-radius: 12px;
  background-color: #f5f5f5;
  padding: 38px 35px 37px;
  z-index: 3;
}

.business-page-breadcrumb-button-base2,
.business-page-rectangle-container,
.business-page-rectangle-group {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-page-rectangle-group {
  margin: 0 !important;
  position: absolute;
  top: -56px;
  left: 0;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  padding: 14px 14px 13px 15px;
  z-index: 2;
}

.business-page-breadcrumb-button-base2 {
  border-radius: 6px;
  padding: 4px;
}

.business-page-chevron-right-icon2 {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.business-page-text13 {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  min-width: 40px;
}

.business-page-breadcrumb-button-base3 {
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}

.business-page-text14 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 500;
}

.business-page-breadcrumb-button-base4 {
  align-self: stretch;
  border-radius: 6px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 7px;
}

.business-page-chevron-right-icon4 {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.business-page-text15 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  white-space: nowrap;
}

.business-page-breadcrumb-button-base5 {
  align-self: stretch;
  border-radius: 6px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}

.business-page-text16 {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  min-width: 61px;
}

.business-page-breadcrumb-button-base6 {
  border-radius: 6px;
  background-color: #f9fafb;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}

.business-page-text17 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 72px;
}

.business-page-breadcrumb-button-base7 {
  border-radius: 6px;
  background-color: #f9fafb;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  color: #344054;
}

.business-page-tabs {
  gap: 8px;
}

.business-page-breadcrumbs,
.business-page-tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}

.business-page-divider-icon1 {
  width: 345px;
  position: relative;
  max-height: 100%;
  display: none;
  max-width: 100%;
}

.business-page-page-header {
  background-color: #fff;
  gap: 20px;
}

.business-page-container,
.business-page-header-section,
.business-page-page-header {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.business-page-container {
  padding: 0 32px 0 0;
  box-sizing: border-box;
}

.business-page-header-section {
  z-index: 1;
}

.business-page-text18 {
  margin: 0;
  position: relative;
  font-size: 30px;
  line-height: 38px;
  font-weight: 600;
  font-family: inherit;
  z-index: 1;
  max-width: 500px;
  word-wrap: break-word;
}

.business-page-marker-pin-02-icon,
.business-page-supporting-text {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.business-page-marker-pin-02-icon {
  height: 20px;
  width: 20px;
  flex-shrink: 0;
  min-height: 20px;
}

.business-page-supporting-text {
  flex: 1;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.business-page-marker-pin-02-parent {
  width: 170px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  color: #475467;
}

.business-page-text19 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 41px;
}

.business-page-text-wrapper {
  width: 50px;
  border-radius: 8px;
  background-color: #fdb022;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1px 4px;
  box-sizing: border-box;
  z-index: 1;
}

.business-page-supporting-text1 {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 117px;
  z-index: 1;
}

.business-page-frame-parent7,
.business-page-supporting-text-wrapper,
.business-page-text-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-page-supporting-text-wrapper {
  flex-direction: column;
  padding: 1px 0 0;
  color: #475467;
}

.business-page-frame-parent7,
.business-page-text-parent {
  gap: 8px;
}

.business-page-frame-parent7 {
  flex-direction: row;
  color: #fff;
}

.business-page-text-parent {
  flex-direction: column;
  padding: 0 11px 0 0;
  max-width: 500px;
}

.business-page-bookmark-check-icon {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.business-page-bookmark-check-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 11px 0 0;
}

.business-page-supporting-text2 {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 114px;
  z-index: 1;
}

.business-page-frame-parent6,
.business-page-supporting-text-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-page-supporting-text-container {
  width: 114px;
  flex-direction: column;
  padding: 9px 0 0;
  box-sizing: border-box;
  text-align: center;
  font-size: 12px;
  color: #475467;
}

.business-page-frame-parent6 {
  flex-direction: row;
  gap: 2px;
  max-width: 100%;
}

.business-page-text20 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  /* min-width: 104px; */
  width: max-content;
}

.business-page-buttonsbutton,
.business-page-text-padding4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.business-page-buttonsbutton {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 7px;
  background-color: #fff;
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  gap: 6px;
  min-width: 97px;
}

.business-page-text21 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 104px;
}

.business-page-buttonsbutton1,
.business-page-buttonsbutton2 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.business-page-buttonsbutton1 {
  cursor: pointer;
  padding: 8px 11px;
  flex: 0.9437;
  min-width: 103px;
}

.business-page-buttonsbutton2 {
  width: max-content;
  flex-shrink: 0;
  padding: 8px 9px;
}

.business-page-buttonsbutton2:hover {
  background-color: #eaecf0;
}

.business-page-buttonsbutton1:hover {
  background-color: #eaecf0;
}

.business-page-magnifier-1-icon {
  height: 20px;
  width: 20px;
  position: relative;
  object-fit: cover;
}

.business-page-buttonsbutton-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  z-index: 1;
}

.business-page-frame-parent5 {
  /* width: 460px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 17px;
  /* min-width: 460px; */
  max-width: 100%;
}

.business-page-button4,
.business-page-button5 {
  border-radius: 8px;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.business-page-button4 {
  align-self: stretch;
  display: none;
  padding: 10px 14px;
  gap: 4px;
}

.business-page-button5 {
  width: 40px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  padding: 8px 9px;
}

.business-page-text23 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 96px;
}

.business-page-button6 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 13px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.business-page-text24 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 44px;
}

.business-page-actions1,
.business-page-button7 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.business-page-button7 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 13px;
  background-color: #7e56d9;
  width: 76px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
  gap: 4px;
}

.business-page-actions1 {
  justify-content: flex-start;
  gap: 12px;
  z-index: 1;
  font-size: 14px;
  color: #475467;
}

.business-page-frame-parent4 {
  /* width: 916px; */
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  font-size: 16px;
  color: #101828;
}

.business-page-frame-parent4,
.business-page-frame-wrapper1,
.business-page-header-section-parent {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}

.business-page-header-section-parent {
  width: 957px;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
}

.business-page-frame-wrapper1 {
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
}

.business-page-divider-icon2,
.business-page-frame-parent3 {
  position: relative;
  max-width: 100%;
}

.business-page-frame-parent3 {
  width: 1242px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-page-divider-icon2 {
  width: 1201px;
  max-height: 100%;
  z-index: 1;
}

.business-page-rectangle-div {
  align-self: stretch;
  height: 733px;
  position: relative;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
}

.business-page-heading {
  margin: 0;
  width: 162px;
  position: relative;
  font-size: inherit;
  line-height: 24px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
  flex-shrink: 0;
  z-index: 1;
}

.business-page-heading-parent,
.business-page-text-container {
  display: flex;
  align-items: flex-start;
}

.business-page-text-container {
  flex-direction: column;
  justify-content: flex-start;
  padding: 2px 0 0;
  font-size: 14px;
  color: #6941c6;
}

.business-page-heading-parent {
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  max-width: 100%;
  gap: 20px;
}

.business-page-divider-icon3,
.business-page-frame-wrapper2 {
  align-self: stretch;
  max-width: 100%;
}

.business-page-frame-wrapper2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 14px 0 16px;
  box-sizing: border-box;
}

.business-page-divider-icon3 {
  position: relative;
  overflow: hidden;
  max-height: 100%;
  z-index: 1;
}

.business-page-frame-child1 {
  height: 76px;
  width: 76px;
  position: relative;
  border-radius: 12px;
  background-color: #f5f5f5;
  display: none;
}

.business-page-f4b711e503bc72fe396e5043e0c2-7-icon1 {
  height: 46px;
  width: 46px;
  position: relative;
  object-fit: cover;
  z-index: 1;
}

.business-page-rectangle-parent1 {
  border-radius: 12px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 15px;
  z-index: 1;
}

.business-page-heading1,
.business-page-service-title-one {
  position: relative;
  line-height: 24px;
  z-index: 1;
}

.business-page-service-title-one {
  align-self: stretch;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  font-weight: 600;
}

.business-page-heading1 {
  width: 164px;
  font-size: 14px;
  display: inline-block;
}

.business-page-service-block-one {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  min-width: 165px;
}

.business-page-about-details-divider,
.business-page-about-details-rows {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}

.business-page-about-details-rows {
  flex: 1;
  justify-content: flex-start;
  gap: 12px;
}

.business-page-about-details-divider {
  width: 344px;
  justify-content: flex-end;
  padding: 0 1px;
  box-sizing: border-box;
}

.business-page-divider-parent,
.business-page-group-div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
}

.business-page-divider-parent {
  align-self: stretch;
  gap: 16.5px;
  font-size: 16px;
}

.business-page-group-div {
  width: 360px;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 22px 0 15px;
  gap: 24px;
  min-width: 360px;
  z-index: 1;
}

.business-page-as-lawmakers-seek {
  margin-block-start: 0;
  margin-block-end: 16px;
  font-weight: 600;
}

.business-page-the-texas-hospital {
  margin: 0;
  white-space: pre-wrap;
}

.business-page-paragraph {
  width: 817px;
  position: relative;
  line-height: 24px;
  display: inline-block;
  max-width: 128%;
  flex-shrink: 0;
}

.business-page-text31 {
  align-self: stretch;
  width: 73px;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: none;
}

.business-page-buttonsbutton5 {
  width: 130px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-size: 14px;
  color: #6941c6;
}

.business-page-paragraph-and-button,
.business-page-paragraph-and-social-icons {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-page-paragraph-and-button {
  width: 640px;
  flex-direction: column;
  padding: 0;
  box-sizing: border-box;
  gap: 20px;
  min-width: 480px;
  max-width: 640px;
}

.business-page-paragraph-and-social-icons {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  max-width: 100%;
}

.business-page-label1,
.business-page-text32 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 110px;
}

.business-page-text32 {
  line-height: 24px;
  font-weight: 600;
  min-width: 85px;
}

.business-page-label-and-text,
.business-page-text-and-icon {
  display: flex;
  justify-content: flex-start;
}

.business-page-text-and-icon {
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  color: #344054;
}

.business-page-label-and-text {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.business-page-text33 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 126px;
  white-space: nowrap;
}

.business-page-buttonsbutton6 {
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 16px;
  color: #344054;
}

.business-page-label-and-text1 {
  /* width: 126px; */
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.business-page-text34 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 114px;
}

.business-page-label-and-text2 {
  /* width: 114px; */
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.business-page-text35 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}

.business-page-buttonsbutton8 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-size: 16px;
  color: #6941c6;
}

.business-page-label-and-text3 {
  /* width: 198px; */
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.business-page-details {
  align-self: stretch;
  border-radius: 12px;
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  /* align-content: center; */
  padding: 18px 23px 18px 23px;
  gap: 30px;
  font-size: 14px;
}

.business-page-about,
.business-page-content4,
.business-page-service-content-two {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.business-page-about {
  flex: 1;
  flex-direction: column;
  min-width: 720px;
}

.business-page-content4,
.business-page-service-content-two {
  flex-direction: row;
}

.business-page-content4 {
  flex: 1;
  flex-wrap: wrap;
  align-content: flex-start;
  z-index: 1;
}

.business-page-service-content-two {
  width: 817px;
  padding: 0 0 11px;
  box-sizing: border-box;
  color: #475467;
}

.business-page-divider-icon9 {
  width: 817px;
  position: relative;
  max-height: 100%;
  max-width: 100%;
  z-index: 1;
}

.business-page-heading7,
.business-page-paragraph1 {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  z-index: 1;
}

.business-page-heading7 {
  font-weight: 500;
}

.business-page-paragraph1 {
  color: #475467;
}

.business-page-divider-group,
.business-page-heading-paragraph {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}

.business-page-divider-group {
  gap: 24px;
  max-width: 100%;
}

.business-page-background-shape-icon {
  height: 368px;
  flex: 1;
  position: relative;
  border-radius: 12px;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  z-index: 1;
}

.business-page-background-shape-wrapper {
  width: 816px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 12px;
  box-sizing: border-box;
  max-width: 100%;
}

.business-page-heading8 {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 24px;
  font-weight: 600;
  font-family: inherit;
  z-index: 1;
}

.business-page-divider-container {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 12px;
  box-sizing: border-box;
  gap: 24px;
  max-width: 100%;
  font-size: 18px;
}

.business-page-text36 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 52px;
}

.business-page-button-group-base {
  cursor: pointer;
  border: 0;
  padding: 10px 15px;
  background-color: #f9fafb;
  width: 84px;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 40px;
}

.business-page-text37 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 53px;
}

.business-page-button-group-base1 {
  width: 85px;
  background-color: #fff;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  min-height: 40px;
}

.business-page-text38 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.business-page-button-group-base2,
.business-page-button-group-base3,
.business-page-button-group-base4,
.business-page-button-group-base5,
.business-page-button-group-base6,
.business-page-button-group-base7 {
  align-self: stretch;
  background-color: #fff;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  min-height: 40px;
  z-index: 6;
}

.business-page-button-group-base3,
.business-page-button-group-base4,
.business-page-button-group-base5,
.business-page-button-group-base6,
.business-page-button-group-base7 {
  z-index: 5;
}

.business-page-button-group-base4,
.business-page-button-group-base5,
.business-page-button-group-base6,
.business-page-button-group-base7 {
  z-index: 4;
}

.business-page-button-group-base5,
.business-page-button-group-base6,
.business-page-button-group-base7 {
  z-index: 3;
}

.business-page-button-group-base6,
.business-page-button-group-base7 {
  z-index: 2;
}

.business-page-button-group-base7 {
  z-index: 1;
}

.business-page-text44 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 50px;
}

.business-page-button-group-base8 {
  width: 82px;
  background-color: #fff;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  min-height: 40px;
}

.business-page-button-group {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-page-search-placeholder {
  width: calc(100% - 228px);
  border: 0;
  outline: 0;
  font-weight: 500;
  font-family: Inter;
  font-size: 16px;
  background-color: transparent;
  position: relative;
  line-height: 24px;
  color: #101828;
  text-align: left;
  display: inline-block;
}

.business-page-supporting-text3 {
  align-self: stretch;
  width: 54px;
  position: relative;
  line-height: 24px;
  display: none;
}

.business-page-content6,
.business-page-input-with-label1,
.business-page-input1 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.business-page-content6 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0 208px 0 0;
  box-sizing: border-box;
  gap: 7px;
}

.business-page-input-with-label1,
.business-page-input1 {
  align-self: stretch;
}

.business-page-input1 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05),
    0 0 0 4px rgba(158, 119, 237, 0.24);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d6bbfb;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
  font-size: 16px;
  color: #475467;
}

.business-page-input-with-label1 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.business-page-hint-text {
  width: 320px;
  position: relative;
  line-height: 20px;
  color: #475467;
  display: none;
}

.business-page-input-dropdown {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  max-width: 400px;
}

.business-page-filter-lines-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 20px;
}

.business-page-text45 {
  text-decoration: none;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 43px;
}

.business-page-actions2,
.business-page-button8 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.business-page-button8 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 10px 13px;
  background-color: #fff;
  width: 99px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
  gap: 4px;
}

.business-page-actions2 {
  width: 511px;
  justify-content: flex-start;
  gap: 12px;
  max-width: 100%;
}

.business-page-content5,
.business-page-filters-bar {
  align-self: stretch;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}

.business-page-content5 {
  border-radius: 12px;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 16px;
  gap: 18px;
}

.business-page-filters-bar {
  background-color: #fff;
  border-bottom: 1px solid #eaecf0;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 1;
}

.business-page-b,
.business-page-heading9 {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;
}

.business-page-b {
  height: 44px;
  flex: 1;
  line-height: 24px;
  justify-content: center;
  min-width: 91px;
}

.business-page-heading9 {
  align-self: stretch;
  height: 37px;
  line-height: 7px;
  font-weight: 500;
  flex-shrink: 0;
}

.business-page-heading-wrapper {
  width: 55px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 3px;
  box-sizing: border-box;
  text-align: left;
  font-size: 36px;
  color: #101828;
}

.business-page-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 2px;
}

.business-page-star-background-icon {
  height: 28px;
  width: 28px;
  position: relative;
  border-radius: 0.5px;
  min-height: 28px;
}

.business-page-frame-parent16,
.business-page-review-stars {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-page-review-stars {
  flex-direction: row;
  gap: 2px;
  z-index: 1;
}

.business-page-frame-parent16 {
  width: 148px;
  flex-direction: column;
  gap: 11.5px;
}

.business-page-divider-icon11 {
  height: 1px;
  width: 812px;
  position: absolute;
  margin: 0 !important;
  bottom: 32px;
  left: -180px;
  z-index: 1;
}

.business-page-frame-child7 {
  width: 200px;
  height: 84px;
  position: relative;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
}

.business-page-stars {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  z-index: 1;
}

.business-page-heading10 {
  flex: 1;
  position: relative;
  line-height: 24px;
  z-index: 1;
}

.business-page-rectangle-parent7,
.business-page-review-title {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-page-review-title {
  align-self: stretch;
  flex-direction: row;
  padding: 0 9px;
}

.business-page-rectangle-parent7 {
  flex: 0.7891;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  flex-direction: column;
  padding: 14px 41px;
  gap: 8px;
  min-width: 150px;
}

.business-page-heading-container {
  align-self: stretch;
  padding: 0 4px;
}

.business-page-heading-container,
.business-page-stars-wrapper,
.business-page-stars2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-page-stars2 {
  flex: 1;
  gap: 4px;
  z-index: 1;
}

.business-page-stars-wrapper {
  align-self: stretch;
  padding: 0 15px;
}

.business-page-heading12 {
  position: relative;
  line-height: 24px;
  z-index: 1;
}

.business-page-rectangle-parent9 {
  flex: 1;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 14px 25px 14px 26px;
  gap: 8px;
  min-width: 150px;
}

.business-page-review-heading-block {
  flex: 1;
  gap: 16px;
  z-index: 2;
}

.business-page-divider-parent1,
.business-page-frame-parent15,
.business-page-review-heading-block {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.business-page-divider-parent1 {
  flex: 1;
  position: relative;
  min-width: 411px;
  text-align: left;
  font-size: 14px;
  color: #101828;
}

.business-page-frame-parent15 {
  align-self: stretch;
  gap: 32px;
  text-align: center;
  font-size: 56px;
  color: #000;
}

.business-page-filters-bar-parent {
  width: 812px;
  flex-direction: column;
  gap: 24px;
  font-size: 14px;
  color: #344054;
}

.business-page-filters-bar-parent,
.business-page-frame-parent9,
.business-page-service-block-two {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.business-page-service-block-two {
  flex: 1;
  flex-direction: column;
  gap: 12px;
  min-width: 582px;
  font-size: 16px;
}

.business-page-frame-parent9 {
  align-self: stretch;
  flex-direction: row;
  gap: 24px;
}

.business-page-group-icon {
  height: 50px;
  width: 45.3px;
  position: relative;
  object-fit: cover;
  min-height: 50px;
  z-index: 1;
}

.business-page-heading13 {
  width: 602.7px;
  position: relative;
  line-height: 24px;
  display: inline-block;
  max-width: 100%;
  z-index: 2;
}

.business-page-alvo-medical,
.business-page-heading13 {
  font-weight: 500;
}

.business-page-heading14 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 24px;
  z-index: 1;
}

.business-page-heading-group,
.business-page-stars3 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-page-heading-group {
  flex: 1;
  flex-direction: column;
  padding: 0 9px 0 0;
  box-sizing: border-box;
  min-width: 409px;
  max-width: 100%;
}

.business-page-stars3 {
  flex-direction: row;
  gap: 4px;
  z-index: 1;
}

.business-page-heading15 {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 74px;
  z-index: 1;
}

.business-page-heading-frame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 11px 0 31px;
}

.business-page-stars-parent {
  flex-direction: column;
  gap: 4px;
}

.business-page-frame-parent18,
.business-page-frame-wrapper4,
.business-page-stars-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-page-frame-wrapper4 {
  flex-direction: column;
  padding: 2px 0 0;
  font-size: 12px;
}

.business-page-frame-parent18 {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 10.9px;
  max-width: 100%;
}

.business-page-paragraph2 {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  z-index: 1;
}

.business-page-paragraph-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  color: #344054;
}

.business-page-frame-child10 {
  height: 50px;
  width: 45.3px;
  position: relative;
  object-fit: cover;
  z-index: 1;
}

.business-page-frame-wrapper5,
.business-page-heading-parent1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
}

.business-page-heading-parent1 {
  align-self: stretch;
  justify-content: flex-start;
}

.business-page-frame-wrapper5 {
  flex: 1;
  justify-content: flex-end;
  padding: 0 9px 2px 0;
  box-sizing: border-box;
  min-width: 409px;
}

.business-page-text46 {
  text-decoration: none;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #eaecf0;
  text-align: left;
  display: inline-block;
  min-width: 38px;
}

.business-page-buttonsbutton9 {
  cursor: pointer;
  border: 0;
  padding: 8px 12px;
  background-color: #7e56d9;
  width: 90px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  gap: 6px;
  z-index: 1;
}

.business-page-heading18 {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 78px;
  z-index: 1;
}

.business-page-stars-heading {
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 11px 0 27px;
}

.business-page-frame-wrapper6,
.business-page-stars-group,
.business-page-stars-heading {
  display: flex;
  align-items: flex-start;
}

.business-page-stars-group {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
}

.business-page-frame-wrapper6 {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 8px 0 0;
}

.business-page-buttonsbutton-group {
  width: 124.4px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 26px;
  font-size: 12px;
}

.business-page-frame-parent19,
.business-page-frame-wrapper3 {
  display: flex;
  flex-direction: row;
  max-width: 100%;
}

.business-page-frame-parent19 {
  align-self: stretch;
  align-items: flex-end;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-end;
  gap: 10.9px;
}

.business-page-frame-wrapper3 {
  width: 971px;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 75px;
  box-sizing: border-box;
  font-size: 16px;
}

.business-page-frame-parent2,
.business-page-frame-parent8 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
}

.business-page-frame-parent8 {
  align-self: stretch;
  align-items: flex-end;
  gap: 57px;
  font-size: 18px;
  color: #101828;
}

.business-page-frame-parent2 {
  flex: 1;
  align-items: flex-start;
  gap: 24px;
}

.business-page-frame-section,
.business-page-page-number {
  font-size: 14px;
  font-family: Inter;
  text-align: left;
}

.business-page-frame-section {
  width: 1380px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 50px 22px;
  box-sizing: border-box;
  max-width: 100%;
  color: #475467;
}

.business-page-page-number {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  color: #344054;
  display: inline-block;
  min-width: 59px;
}

.business-page-button-wrap,
.business-page-buttonsbutton11 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.business-page-buttonsbutton11 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 6px 11px;
  background-color: #fff;
  width: 87px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
  gap: 4px;
}

.business-page-button-wrap {
  flex: 1;
  justify-content: flex-start;
}

.business-page-details1,
.business-page-text48 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 44px;
}

.business-page-text48 {
  font-size: 14px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  min-width: 32px;
}

.business-page-buttonsbutton12 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 6px 11px;
  background-color: #fff;
  width: 60px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
  gap: 4px;
}

.business-page-button-wrap1,
.business-page-buttonsbutton12,
.business-page-pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.business-page-button-wrap1 {
  flex: 1;
  justify-content: flex-end;
}

.business-page-pagination {
  width: 820px;
  background-color: #fff;
  border-top: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow-x: auto;
  justify-content: center;
  padding: 12px 24px 16px;
  gap: 12px;
  max-width: 100%;
  z-index: 1;
}

.business-page-frame-child11 {
  width: 817px;
  height: 590px;
  position: relative;
  background-color: #f9fafb;
  display: none;
  max-width: 100%;
}

.business-page-heading19,
.business-page-heading20,
.business-page-heading22 {
  position: relative;
  line-height: 24px;
}

.business-page-heading19 {
  margin: 0;
  align-self: stretch;
  font-size: 18px;
  font-weight: 600;
  font-family: inherit;
  z-index: 1;
}

.business-page-heading20,
.business-page-heading22 {
  display: inline-block;
}

.business-page-heading20 {
  width: 107px;
}

.business-page-heading22 {
  min-width: 116px;
}

.business-page-frame-parent20 {
  width: 526px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  max-width: 100%;
  z-index: 1;
}

.business-page-label6,
.business-page-label7 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
}

.business-page-label6 {
  display: inline-block;
  min-width: 30px;
}

.business-page-label7 {
  width: 77px;
  display: none;
}

.business-page-review-placeholder {
  width: 100%;
  border: 0;
  outline: 0;
  font-family: Inter;
  font-size: 16px;
  background-color: transparent;
  flex: 1;
  position: relative;
  line-height: 24px;
  color: #101828;
  text-align: left;
  display: inline-block;
  max-width: 100%;
}

.business-page-input-with-label3,
.business-page-input2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-page-input2 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: row;
  padding: 10px 13px 2px;
  max-width: 100%;
}

.business-page-input-with-label3 {
  width: 762px;
  flex-direction: column;
  gap: 6px;
  max-width: 149%;
  flex-shrink: 0;
}

.business-page-hint-text1 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  color: #475467;
}

.business-page-textarea-input-field {
  width: 512px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
  gap: 6px;
  min-width: 480px;
  max-width: 512px;
}

.business-page-content7 {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
}

.business-page-content7,
.business-page-input-field,
.business-page-review-input {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.business-page-input-field {
  flex: 1;
  flex-direction: column;
  gap: 6px;
  z-index: 1;
}

.business-page-review-input {
  width: 772px;
  flex-direction: row;
  padding: 0 6px;
  box-sizing: border-box;
  color: #344054;
}

.business-page-contact-placeholder,
.business-page-label8 {
  position: relative;
  display: inline-block;
}

.business-page-label8 {
  line-height: 20px;
  font-weight: 500;
  min-width: 49px;
}

.business-page-contact-placeholder {
  height: 158px;
  flex: 1;
  line-height: 24px;
  max-width: 100%;
}

.business-page-input-with-label5,
.business-page-input3 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-page-input3 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: row;
  padding: 10px 13px;
  max-width: 100%;
  font-size: 16px;
  color: #101828;
}

.business-page-input-with-label5 {
  width: 765px;
  flex-direction: column;
  gap: 6px;
  max-width: 150%;
  flex-shrink: 0;
}

.business-page-hint-text3 {
  width: 848px;
  position: relative;
  line-height: 20px;
  color: #475467;
  display: inline-block;
  max-width: 166%;
  flex-shrink: 0;
}

.business-page-contact-input,
.business-page-input-field1,
.business-page-textarea-input-field1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}

.business-page-textarea-input-field1 {
  height: 128px;
  width: 512px;
  flex-direction: column;
  padding: 0;
  gap: 6px;
  min-width: 480px;
  max-width: 512px;
}

.business-page-contact-input,
.business-page-input-field1 {
  max-width: 100%;
}

.business-page-input-field1 {
  flex: 1;
  flex-direction: column;
  padding: 0 0 54px;
  gap: 6px;
  min-height: 208px;
  z-index: 1;
}

.business-page-contact-input {
  width: 774px;
  flex-direction: row;
  padding: 0 6px;
  color: #344054;
}

.business-page-log-in-04-icon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.business-page-submit-label {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 48px;
}

.business-page-button9,
.business-page-submit-button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.business-page-button9 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 14px;
  background-color: #7e56d9;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  justify-content: flex-start;
  gap: 6px;
  z-index: 1;
}

.business-page-submit-button {
  justify-content: flex-end;
  padding: 0 25px;
}

.business-page-rectangle-parent10,
.business-page-review-form {
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
}

.business-page-rectangle-parent10 {
  flex: 1;
  background-color: #f9fafb;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 24px 0 24px 24px;
  gap: 50px;
  z-index: 1;
}

.business-page-review-form {
  width: 823.4px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 3px;
  flex-shrink: 0;
  color: #101828;
}

.business-page-divider-icon12 {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 1;
}

.business-page-form-divider {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 3px 0 0;
  box-sizing: border-box;
  max-width: 100%;
}

.business-page-reviews-header {
  width: 1204.4px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 24px;
  max-width: 100%;
}

.business-page-reviews-header-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 33px 22px 20px;
  box-sizing: border-box;
  max-width: 100%;
  text-align: left;
  font-size: 14px;
  color: #344054;
  font-family: Inter;
}

.business-page-heading23,
.business-page-testimonial-carousel-labels {
  position: relative;
  display: inline-block;
  z-index: 1;
}

.business-page-heading23 {
  margin: 0;
  width: 802px;
  font-size: inherit;
  line-height: 24px;
  font-weight: 600;
  font-family: inherit;
  flex-shrink: 0;
  max-width: 100%;
}

.business-page-testimonial-carousel-labels {
  text-decoration: none;
  line-height: 20px;
  font-weight: 500;
  color: inherit;
  min-width: 54px;
}

.business-page-testimonial-carousel-controls {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 0 0;
  font-size: 14px;
  color: #7e56d9;
}

.business-page-frame-wrapper7,
.business-page-heading-parent5 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}

.business-page-heading-parent5 {
  flex: 1;
  justify-content: space-between;
  gap: 20px;
}

.business-page-frame-wrapper7 {
  align-self: stretch;
  justify-content: flex-end;
  padding: 0 83px 22px 80px;
  box-sizing: border-box;
}

/* .business-page-carousel-background {
  height: 5836px;
  width: 1440px;
  position: absolute;
  margin: 0 !important;
  top: -2902px;
  left: -80px;
  background-color: #fff;
} */

.business-page-frame-child12,
.business-page-testimonial-card-child {
  align-self: stretch;
  position: relative;
  display: none;
}

.business-page-frame-child12 {
  height: 449px;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
}

.business-page-testimonial-card-child {
  width: 322px;
  border-radius: 12px 12px 0 0;
  max-height: 100%;
  object-fit: cover;
  min-height: 172px;
  max-width: 100%;
}

.business-page-text49 {
  position: relative;
  line-height: 18px;
  font-weight: 500;
  display: inline-block;
  min-width: 48px;
}

.business-page-badge3 {
  width: 62px;
  border-radius: 9999px;
  background-color: #fff;
  border: 1px solid #fff;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 6px;
  mix-blend-mode: normal;
  z-index: 3;
}

.business-page-badge-label {
  position: relative;
  line-height: 18px;
  font-weight: 500;
  display: inline-block;
  min-width: 65px;
}

.business-page-badge4 {
  width: 77px;
  border-radius: 9999px;
  background-color: #fff;
  border: 1px solid #fff;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 5px;
  mix-blend-mode: normal;
  white-space: nowrap;
  z-index: 3;
}

.business-page-badge4,
.business-page-buttonsbutton13,
.business-page-overlay {
  display: flex;
  flex-direction: row;
}

.business-page-buttonsbutton13 {
  width: 44px;
  backdrop-filter: blur(16px);
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.6);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: 10px 11px;
  z-index: 3;
}

.business-page-overlay {
  align-self: stretch;
  align-items: flex-end;
  justify-content: space-between;
  gap: 20px;
  color: #8e8e8e;
}

.business-page-image-overlay,
.business-page-testimonial-card {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.business-page-image-overlay {
  flex: 1;
  background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.2));
  flex-direction: column;
  align-items: flex-end;
  padding: 12px 9px 8px 8px;
  box-sizing: border-box;
  gap: 86px;
  z-index: 2;
}

.business-page-testimonial-card {
  align-self: stretch;
  border-radius: 12px 12px 0 0;
  flex-direction: row;
  align-items: flex-start;
  background-image: url("../../../icons/Gerator/business_page/rectangle-95@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;
}

.business-page-business {
  flex: 1;
  position: relative;
  line-height: 28px;
  font-weight: 600;
  z-index: 1;
}

.business-page-business-name {
  width: 296px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 15px;
  box-sizing: border-box;
  font-size: 16px;
}

.business-page-marker-pin-02-icon1 {
  width: 16px;
  height: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.business-page-location-pin {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4px 0 0;
}

.business-page-location {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 93px;
  z-index: 1;
}

.business-page-pin-icon {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.business-page-type-of-work {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 82px;
  z-index: 1;
}

.business-page-job-location {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}

.business-page-job-name {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 36px;
}

.business-page-job-title {
  border-radius: 8px;
  background-color: #fdb022;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1px 7px;
  z-index: 1;
}

.business-page-supporting-text4 {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 102px;
  z-index: 1;
}

.business-page-bottom-content,
.business-page-job-description {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-page-job-description {
  padding: 0 20px 0 0;
  gap: 8px;
  font-size: 14px;
  color: #fff;
}

.business-page-bottom-content {
  padding: 0 15px;
}

.business-page-job-meta {
  position: relative;
  line-height: 30px;
  font-weight: 600;
  white-space: nowrap;
  z-index: 1;
}

.business-page-supporting-text5 {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 77px;
  z-index: 1;
}

.business-page-supporting-text-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4px 0 0;
  font-size: 12px;
  color: #475467;
}

.business-page-job-details {
  flex-direction: row;
  gap: 8px;
}

.business-page-bottom-info,
.business-page-card-details,
.business-page-job-details {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-page-bottom-info {
  flex-direction: row;
  padding: 0 15px;
  font-size: 18px;
  color: #6941c6;
}

.business-page-card-details {
  align-self: stretch;
  flex-direction: column;
  padding: 0 0 8px;
  gap: 15px;
  color: #475467;
}

.business-page-name {
  margin-bottom: -4px;
  flex: 1;
  position: relative;
  line-height: 23px;
  font-weight: 600;
  flex-shrink: 0;
  z-index: 1;
}

.business-page-testimonial-member-name-role {
  align-self: stretch;
  height: 19px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 10px 0 0;
  box-sizing: border-box;
}

.business-page-role {
  align-self: stretch;
  height: 19px;
  position: relative;
  font-size: 10px;
  line-height: 24px;
  color: #6941c6;
  display: inline-block;
  flex-shrink: 0;
  z-index: 1;
}

.business-page-testimonial-member-name-role-parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-page-icon1 {
  width: 18px;
  height: 18px;
  position: relative;
  z-index: 1;
}

.business-page-testimonial-member-icon {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px 0 0;
}

.business-page-testimonial-member-details {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}

.business-page-card-info,
.business-page-testimonial-team-member {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-page-testimonial-team-member {
  align-self: stretch;
  flex-direction: row;
  padding: 0 9px 0 12px;
}

.business-page-card-info {
  flex: 1;
  flex-direction: column;
  gap: 8px;
  max-width: 100%;
}

.business-page-card-content {
  align-self: stretch;
  flex-direction: row;
  padding: 0 1px;
  box-sizing: border-box;
  text-align: left;
  color: #101828;
}

.business-page-card-content,
.business-page-rectangle-parent11,
.business-page-rectangle-parent15 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.business-page-rectangle-parent11 {
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  flex-direction: column;
  padding: 0 0 14px;
  gap: 20px;
}

.business-page-rectangle-parent15 {
  align-self: stretch;
  border-radius: 12px 12px 0 0;
  flex-direction: row;
  background-image: url("../../../icons/Gerator/business_page/rectangle-95@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;
  text-align: center;
}

.business-page-text57 {
  align-self: stretch;
  position: relative;
  line-height: 28px;
  font-weight: 600;
  z-index: 1;
}

.business-page-frame-parent32 {
  flex-direction: row;
  padding: 0 20px 0 0;
  gap: 16px;
  font-size: 12px;
  color: #475467;
}

.business-page-frame-parent32,
.business-page-frame-wrapper12,
.business-page-text-parent6 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-page-text-parent6 {
  flex: 1;
  flex-direction: column;
  gap: 8px;
}

.business-page-frame-wrapper12 {
  width: 298px;
  flex-direction: row;
  padding: 0 16px;
  box-sizing: border-box;
  font-size: 16px;
  color: #101828;
}

.business-page-review-member-details-child {
  height: 42px;
  width: 42px;
  position: relative;
  object-fit: cover;
  z-index: 1;
}

.business-page-review-member-details,
.business-page-review-team-member-card {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-page-review-member-details {
  width: 137px;
  gap: 8px;
}

.business-page-review-team-member-card {
  align-self: stretch;
  padding: 0 9px 0 12px;
  font-size: 12px;
  color: #101828;
}

.business-page-rectangle-parent14,
.business-page-testimonial-content,
.business-page-testimonial-content-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.business-page-testimonial-content {
  flex: 1;
  flex-direction: column;
  gap: 15px;
}

.business-page-rectangle-parent14,
.business-page-testimonial-content-wrapper {
  box-sizing: border-box;
}

.business-page-testimonial-content-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 1px;
  font-size: 18px;
  color: #6941c6;
}

.business-page-rectangle-parent14 {
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  flex-direction: column;
  padding: 0 0 14px;
  gap: 18px;
  text-align: left;
}

.business-page-buttonsbutton16 {
  width: 44px;
  margin: 0 !important;
  position: absolute;
  top: -22px;
  right: 0;
  backdrop-filter: blur(16px);
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.6);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 11px;
  z-index: 3;
}

.business-page-badge-container,
.business-page-image-overlay3 {
  display: flex;
  justify-content: flex-start;
}

.business-page-badge-container {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  color: #8e8e8e;
}

.business-page-image-overlay3 {
  flex: 1;
  background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.2));
  flex-direction: column;
  align-items: flex-end;
  padding: 12px 9px 8px 8px;
  box-sizing: border-box;
  gap: 108px;
  max-width: 100%;
  z-index: 2;
}

.business-page-icon4 {
  height: 18px;
  width: 18px;
  position: absolute;
  margin: 0 !important;
  right: 9px;
  bottom: 12px;
  z-index: 1;
}

.business-page-frame-wrapper14 {
  padding: 0 15px 8px;
  color: #475467;
}

.business-page-frame-wrapper14,
.business-page-frame-wrapper15,
.business-page-team-divider {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-page-frame-wrapper15 {
  padding: 0 15px 6px;
  font-size: 18px;
  color: #6941c6;
}

.business-page-team-divider {
  align-self: stretch;
  padding: 0 0 8px;
  box-sizing: border-box;
  max-width: 100%;
}

.business-page-member-card-content {
  flex: 1;
  display: flex;
  gap: 8px;
}

.business-page-icon-parent,
.business-page-member-card-content,
.business-page-team-member-card,
.business-page-testimonials {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-page-team-member-card {
  width: 161px;
  display: flex;
  padding: 0 12px;
  box-sizing: border-box;
}

.business-page-icon-parent,
.business-page-testimonials {
  flex: 1;
  max-width: 100%;
}

.business-page-icon-parent {
  display: flex;
  position: relative;
}

.business-page-testimonials {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(4, minmax(242px, 1fr));
  z-index: 1;
}

.business-page-arrow-left-icon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.business-page-testiomonial-carousel-arrow {
  height: 40px;
  width: 40px;
  margin: 0 !important;
  position: absolute;
  bottom: 204px;
  left: -19px;
  border-radius: 9999px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.business-page-carousel-container {
  position: absolute;
  top: 0;
  left: 20px;
  width: 1340px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.business-page-testiomonial-carousel-arrow1 {
  position: absolute;
  top: 205px;
  left: 1342px;
  border-radius: 9999px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.business-page-testimonial-carousel {
  width: 1382px;
  height: 471px;
  position: relative;
  max-width: 100%;
  text-align: center;
  font-size: 12px;
  color: #fdb022;
  font-family: Inter;
}

.business-page-heading-parent6,
.business-page-supporting-description {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.business-page-heading-parent6 {
  flex: 1;
  justify-content: space-between;
  max-width: 100%;
  gap: 20px;
}

.business-page-supporting-description {
  justify-content: flex-start;
  gap: 8px;
  font-size: 14px;
  color: #fff;
}

.business-page-name-container,
.business-page-testiomonial-carousel-arrow2 {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}

.business-page-name-container {
  width: 77px;
  height: 19px;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
}

.business-page-testiomonial-carousel-arrow2 {
  height: 40px;
  width: 40px;
  margin: 0 !important;
  position: absolute;
  top: 204px;
  right: -22px;
  border-radius: 9999px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.business-page-frame-parent40 {
  position: absolute;
  top: 0;
  left: 19px;
  width: 1340px;
  display: grid;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  max-width: 100%;
  grid-template-columns: repeat(4, minmax(242px, 1fr));
  z-index: 1;
}

.business-page-testiomonial-carousel-arrow3 {
  position: absolute;
  top: 204px;
  left: 0;
  border-radius: 9999px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.business-page-frame-parent39 {
  height: 449px;
  flex: 1;
  position: relative;
  max-width: 100%;
}

.business-page-frame-wrapper17 {
  width: 1403px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 22px 22px;
  box-sizing: border-box;
  max-width: 100%;
  text-align: center;
  font-size: 12px;
  color: #fdb022;
  font-family: Inter;
}

.business-page-frame-parent56,
.business-page-frame-wrapper20,
.business-page-text-wrapper12 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-page-text-wrapper12 {
  width: 296px;
  flex-direction: row;
  padding: 0 15px;
  box-sizing: border-box;
}

.business-page-frame-parent56,
.business-page-frame-wrapper20 {
  align-self: stretch;
}

.business-page-frame-parent56 {
  flex-direction: column;
  gap: 15.3px;
  font-size: 12px;
  color: #475467;
}

.business-page-frame-wrapper20 {
  flex-direction: row;
  padding: 0 1px;
  box-sizing: border-box;
  max-width: 100%;
  text-align: left;
  font-size: 16px;
  color: #101828;
}

.business-page-testiomonial-carousel-arrow4,
.business-page-testiomonial-carousel-arrow5 {
  position: absolute;
  top: 203px;
  border-radius: 9999px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.business-page-testiomonial-carousel-arrow4 {
  margin: 0 !important;
  right: -22px;
}

.business-page-testiomonial-carousel-arrow5 {
  left: 0;
}

.business-page-frame-wrapper35,
.business-page-team-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-page-frame-wrapper35 {
  width: 1317px;
  padding: 0 19px;
  box-sizing: border-box;
  max-width: 100%;
}

.business-page-team-wrapper {
  align-self: stretch;
  padding: 0 9px 0 12px;
  color: #101828;
}

.business-page-icon16 {
  width: 18px;
  height: 18px;
  position: absolute;
  margin: 0 !important;
  right: 9px;
  bottom: 12px;
  z-index: 1;
}

.business-page-team3 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  gap: 16px;
}

.business-page-testiomonial-carousel-arrow6,
.business-page-testiomonial-carousel-arrow7 {
  position: absolute;
  border-radius: 9999px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.business-page-testiomonial-carousel-arrow6 {
  margin: 0 !important;
  right: -22px;
  bottom: 197px;
}

.business-page-testiomonial-carousel-arrow7 {
  top: 212px;
  left: 0;
}

.business-page-frame-parent90 {
  align-self: stretch;
  height: 449px;
  position: relative;
  max-width: 100%;
  text-align: center;
  font-size: 12px;
  color: #fdb022;
}

.business-page-frame-wrapper34,
.business-page-logo1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.business-page-frame-wrapper34 {
  width: 1403px;
  justify-content: flex-end;
  padding: 0 22px 102px;
  box-sizing: border-box;
  max-width: 100%;
  text-align: left;
  font-size: 18px;
  color: #101828;
  font-family: Inter;
}

.business-page-logo1 {
  width: 121px;
  height: 32px;
  justify-content: flex-start;
  background-image: url("../../../icons/Gerator/business_page/gerator04-1@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.business-page-supporting-text36 {
  align-self: stretch;
  position: relative;
  line-height: 24px;
}

.business-page-logo-and-supporting-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  min-width: 320px;
  max-width: 320px;
}

.business-page-linkedin-logo-2-icon {
  height: 34px;
  width: 34px;
  position: relative;
  object-fit: cover;
  min-height: 34px;
}

.business-page-footer-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  min-width: 335px;
  max-width: 100%;
}

.business-page-text122 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 62px;
}

.business-page-buttonsbutton29,
.business-page-footer-link {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.business-page-buttonsbutton29 {
  overflow: hidden;
  justify-content: center;
  gap: 8px;
}

.business-page-footer-link {
  justify-content: flex-start;
}

.business-page-text123,
.business-page-text124,
.business-page-text125 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 54px;
}

.business-page-text124,
.business-page-text125 {
  min-width: 68px;
}

.business-page-text125 {
  line-height: 18px;
  font-weight: 500;
  min-width: 26px;
}

.business-page-badge35,
.business-page-footer-link2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.business-page-badge35 {
  width: 42px;
  border-radius: 9999px;
  border: 1.5px solid #7e56d9;
  box-sizing: border-box;
  padding: 0 6px;
  text-align: center;
  font-size: 12px;
}

.business-page-footer-link2 {
  gap: 8px;
}

.business-page-text126,
.business-page-text127,
.business-page-text128 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 71px;
}

.business-page-text127,
.business-page-text128 {
  min-width: 97px;
}

.business-page-text128 {
  min-width: 81px;
}

.business-page-footer-links,
.business-page-footer-links-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-page-footer-links {
  padding: 0 26px 0 0;
  gap: 12px;
  font-size: 16px;
  color: #6941c6;
}

.business-page-footer-links-column {
  flex: 1;
  gap: 16px;
  min-width: 96px;
}

.business-page-text129,
.business-page-text130,
.business-page-text131,
.business-page-text132,
.business-page-text133,
.business-page-text134 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
}

.business-page-text129 {
  min-width: 100px;
  white-space: nowrap;
}

.business-page-text130,
.business-page-text131,
.business-page-text132,
.business-page-text133,
.business-page-text134 {
  min-width: 58px;
}

.business-page-text131,
.business-page-text132,
.business-page-text133,
.business-page-text134 {
  min-width: 66px;
}

.business-page-text132,
.business-page-text133,
.business-page-text134 {
  min-width: 90px;
}

.business-page-text133,
.business-page-text134 {
  min-width: 55px;
}

.business-page-text134 {
  min-width: 63px;
}

.business-page-footer-links1,
.business-page-links {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-page-footer-links1 {
  flex-direction: column;
  padding: 0 44px 0 0;
  gap: 12px;
  font-size: 16px;
  color: #6941c6;
}

.business-page-links {
  width: 320px;
  flex-direction: row;
  gap: 32px;
  min-width: 320px;
  flex-shrink: 0;
}

.business-page-label10 {
  width: 102px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.business-page-text135 {
  flex: 1;
  position: relative;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.business-page-help-icon {
  height: 16px;
  width: 16px;
  position: relative;
  display: none;
}

.business-page-input-with-label6,
.business-page-input4 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}

.business-page-input4 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
  gap: 8px;
  font-size: 16px;
  color: #667085;
}

.business-page-input-with-label6 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.business-page-hint-text5 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  color: #475467;
  display: none;
}

.business-page-input-field2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  min-width: 149px;
}

.business-page-text136 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 79px;
}

.business-page-buttonsbutton41 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 15px;
  background-color: #7e56d9;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.business-page-email-capture,
.business-page-newsletter {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}

.business-page-email-capture {
  align-self: stretch;
  flex-direction: row;
}

.business-page-newsletter {
  width: 360px;
  flex-direction: column;
  max-width: 100%;
  flex-shrink: 0;
}

.business-page-content11,
.business-page-links-and-newsletter {
  justify-content: flex-start;
  box-sizing: border-box;
}

.business-page-links-and-newsletter {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 32px;
  min-width: 640px;
  max-width: 712px;
  margin-left: -2px;
  font-size: 14px;
  color: #101828;
}

.business-page-content11 {
  width: 1207px;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 0 2px 0 0;
  max-width: 100%;
}

.business-page-container1,
.business-page-content11,
.business-page-footer,
.business-page-footer1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.business-page-container1 {
  flex: 1;
  justify-content: flex-start;
  max-width: 1280px;
}

.business-page-footer,
.business-page-footer1 {
  box-sizing: border-box;
  max-width: 100%;
}

.business-page-footer1 {
  flex: 1;
  background-color: #fff;
  overflow: hidden;
  justify-content: flex-start;
  padding: 64px 80px 48px;
  z-index: 1;
}

.business-page-footer {
  align-self: stretch;
  justify-content: flex-end;
  padding: 0 2px 36px 0;
  text-align: left;
  font-size: 16px;
  color: #475467;
  font-family: Inter;
}

.business-page-footer-text {
  position: relative;
  line-height: 20px;
}

.business-page-logomark-container-child {
  width: calc(100% - 38px);
  border: 0;
  outline: 0;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-size: 16px;
  color: #475467;
}

.business-page-gerator-10-2-icon {
  height: 38px;
  width: 38px;
  position: relative;
  object-fit: cover;
}

.business-page-logomark1 {
  box-shadow: 0 1.3px 3.75px rgba(16, 24, 40, 0.1),
    0 1.3px 2.5px rgba(16, 24, 40, 0.06);
  align-items: flex-start;
  justify-content: flex-start;
}

.business-page-logomark,
.business-page-logomark-container,
.business-page-logomark1 {
  display: flex;
  flex-direction: row;
}

.business-page-logomark {
  filter: drop-shadow(0 1.3px 3.75px rgba(16, 24, 40, 0.1)) drop-shadow(0 1.3px 2.5px rgba(16, 24, 40, 0.06));
  align-items: flex-start;
  justify-content: flex-start;
}

.business-page-logomark-container {
  align-items: center;
  justify-content: center;
  gap: 29px;
}

.business-page-content13,
.business-page-logo2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-page-content13 {
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: center;
  gap: 20px;
}

.business-page-container2,
.business-page-footer3 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
}

.business-page-container2 {
  width: 100%;
  align-items: flex-start;
  padding: 0 32px;
  max-width: 1280px;
}

.business-page-footer3 {
  flex: 1;
  background-color: #fff;
  overflow: hidden;
  align-items: center;
  padding: 48px 80px;
  max-width: 100%;
  z-index: 1;
}

.business-page-footer-divider,
.business-page-footer2,
.business-page-frame-parent {
  align-self: stretch;
  display: flex;
  max-width: 100%;
}

.business-page-footer2 {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 2px 0 0;
  box-sizing: border-box;
  text-align: left;
  font-size: 16px;
  color: #475467;
  font-family: Inter;
}

.business-page-footer-divider,
.business-page-frame-parent {
  justify-content: flex-start;
}

.business-page-frame-parent {
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;
  text-align: left;
  font-size: 18px;
  color: #101828;
  font-family: Inter;
}

.business-page-footer-divider {
  flex-direction: row;
  align-items: flex-start;
  padding: 0 2px 10807px 0;
  box-sizing: border-box;
  margin-top: -5469px;
}

.business-page-business-page,
.business-page-divider-icon30 {
  position: relative;
  overflow: hidden;
}

.business-page-divider-icon30 {
  align-self: stretch;
  max-width: 100%;
  max-height: 100%;
  z-index: 2;
  /* margin-top: -5469px; */
}

.business-page-business-page {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  font-size: 14px;
  color: #475467;
  font-family: Inter;
}

.business-page-sharethis{
  display: flex;
  position: absolute;
  flex-direction: row;
  width: 400px;
  bottom: 105%;
 
 
 }
 
 .business-page-sharethis-parent{
   position: relative;
  
  }
 
 .business-page-sharethis-follow{
   display: flex;
   position: absolute;
   flex-direction: row;
   width: 600px;
   bottom: 105%;
  
  
  
  }
 
  .business-page-sharethis-follow-parent{
  position: relative;
  
  }

@media screen and (max-width: 1275px) {
  .business-page-frame-section {
    padding-left: 25px;
    padding-right: 25px;
    box-sizing: border-box;
  }

  .business-page-container1,
  .business-page-container2 {
    max-width: 100%;
  }

  .business-page-footer-divider {
    padding-bottom: 2968px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 1100px) {
  .business-page-rectangle-parent {
    flex-wrap: wrap;
  }

  .business-page-frame-parent5 {
    flex: 1;
  }

  .business-page-frame-parent4 {
    flex-wrap: wrap;
  }

  .business-page-group-div,
  .business-page-heading-parent {
    flex: 1;
  }

  .business-page-details {
    padding-right: 39px;
    box-sizing: border-box;
  }

  .business-page-content5,
  .business-page-frame-parent9 {
    flex-wrap: wrap;
  }

  .business-page-frame-wrapper3 {
    padding-left: 37px;
    padding-right: 37px;
    box-sizing: border-box;
  }

  .business-page-heading-parent5 {
    flex-wrap: wrap;
    justify-content: center;
  }

  .business-page-testimonials {
    justify-content: center;
    grid-template-columns: repeat(2, minmax(242px, 420px));
  }

  .business-page-heading-parent6 {
    flex-wrap: wrap;
  }

  .business-page-frame-parent40 {
    justify-content: center;
    grid-template-columns: repeat(2, minmax(242px, 420px));
  }

  .business-page-footer-divider {
    padding-bottom: 1929px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 750px) {
  .business-page-frame-container {
    gap: 19px;
    flex-wrap: wrap;
    min-width: 100%;
  }

  .business-page-frame-group {
    gap: 35px;
    flex-wrap: wrap;
  }

  .business-page-rectangle-parent {
    padding-right: 32px;
    box-sizing: border-box;
  }

  .business-page-text18 {
    font-size: 24px;
    line-height: 30px;
  }

  .business-page-frame-parent5,
  .business-page-group-div {
    min-width: 100%;
  }

  .business-page-paragraph-and-button {
    max-width: 100%;
  }

  .business-page-paragraph-and-social-icons {
    gap: 32px;
  }

  .business-page-actions2 {
    flex-wrap: wrap;
  }

  .business-page-b {
    font-size: 45px;
    line-height: 19px;
  }

  .business-page-heading9 {
    font-size: 29px;
    line-height: 6px;
  }

  .business-page-review-heading-block {
    flex-wrap: wrap;
  }

  .business-page-divider-parent1 {
    min-width: 100%;
  }

  .business-page-frame-parent15 {
    flex-wrap: wrap;
  }

  .business-page-frame-wrapper5,
  .business-page-heading-group,
  .business-page-service-block-two {
    min-width: 100%;
  }

  .business-page-frame-parent8 {
    gap: 28px;
  }

  .business-page-frame-parent20 {
    flex-wrap: wrap;
  }

  .business-page-textarea-input-field {
    max-width: 100%;
  }

  .business-page-content7 {
    gap: 16px;
  }

  .business-page-textarea-input-field1 {
    max-width: 100%;
  }

  .business-page-rectangle-parent10 {
    padding-top: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
  }

  .business-page-frame-wrapper7 {
    padding-left: 40px;
    padding-right: 41px;
    box-sizing: border-box;
  }

  .business-page-frame-parent40 {
    grid-template-columns: minmax(242px, 1fr);
  }

  .business-page-frame-wrapper34 {
    padding-bottom: 66px;
    box-sizing: border-box;
  }

  .business-page-links {
    flex: 1;
  }

  .business-page-links-and-newsletter {
    gap: 16px;
    flex-wrap: wrap;
    max-width: 100%;
    min-width: 100%;
  }

  .business-page-container1 {
    gap: 24px;
  }

  .business-page-footer1 {
    gap: 32px;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
  }

  .business-page-container2 {
    gap: 16px;
  }

  .business-page-footer3 {
    gap: 32px;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
  }

  .business-page-footer-divider {
    padding-bottom: 1254px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 450px) {
  .business-page-frame-group {
    gap: 17px;
  }

  .business-page-tabs {
    flex-wrap: wrap;
  }

  .business-page-text18 {
    font-size: 18px;
    line-height: 23px;
  }

  .business-page-frame-parent6 {
    flex-wrap: wrap;
  }

  .business-page-buttonsbutton1 {
    flex: 1;
  }

  .business-page-about-details-rows,
  .business-page-buttonsbutton-parent,
  .business-page-heading-parent {
    flex-wrap: wrap;
  }

  .business-page-content4,
  .business-page-paragraph-and-social-icons {
    gap: 16px;
  }

  .business-page-content6 {
    padding-right: 20px;
    box-sizing: border-box;
  }

  .business-page-input-dropdown {
    max-width: 100%;
  }

  .business-page-b {
    font-size: 34px;
    line-height: 14px;
  }

  .business-page-heading9 {
    font-size: 22px;
    line-height: 4px;
  }

  .business-page-rectangle-parent7 {
    flex: 1;
  }

  .business-page-frame-parent15 {
    gap: 16px;
  }

  .business-page-rectangle-parent10 {
    gap: 25px;
  }

  .business-page-testimonials {
    grid-template-columns: minmax(242px, 1fr);
  }

  .business-page-links,
  .business-page-logo-and-supporting-text {
    gap: 16px;
  }

  .business-page-email-capture {
    flex-wrap: wrap;
  }

  .business-page-footer1 {
    gap: 16px;
    padding-top: 42px;
    padding-bottom: 31px;
    box-sizing: border-box;
  }

  .business-page-footer3 {
    gap: 16px;
  }
}