.start-campaign-featured-icon2 {
    height: 40px;
    width: 40px;
    position: relative;
    border-radius: 8px;
    padding-top: 3px;
  }
  .start-campaign-supporting-text91,
  .start-campaign-title9 {
    align-self: stretch;
    position: relative;
    white-space: nowrap;
  }
  .start-campaign-title9 {
    text-decoration: none;
    line-height: 30px;
    font-weight: 600;
    font-size: 18px;
    color: inherit;
  }
  .start-campaign-supporting-text91 {
    font-size: 14px;
    line-height: 20px;
    color: #475467;
  }
  .start-campaign-content71,
  .start-campaign-text-and-supporting-text26 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
  }
  .start-campaign-content71 {
    margin: 0 !important;
    position: absolute;
    top: 24px;
    left: 24px;
    flex-direction: row;
    gap: 16px;
  }
  .start-campaign-button-close-x6,
  .start-campaign-slide-out-menu-header {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
  }
  .start-campaign-button-close-x6 {
    height: 20px;
    margin: 0 !important;
    position: absolute;
    top: 27px;
    right: 26px;
    border-radius: 8px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
    z-index: 1;
    cursor: pointer;
  }
  .start-campaign-slide-out-menu-header {
    align-self: stretch;
    height: 78px;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 24px;
    position: relative;
    gap: 8px;
    text-align: left;
    font-size: 20px;
    color: #101828;
    font-family: Inter;
  }
  .start-campaign-label9 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 67px;
  }
  .start-campaign-content72 {
    width: 100%;
    border: 0;
    outline: 0;
    background-color: transparent;
    height: 24px;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-family: Inter;
    font-size: 16px;
    color: #101828;
    min-width: 194px;
    max-width: 100%;
  }
  .start-campaign-help-icon2 {
    height: 16px;
    width: 16px;
    position: relative;
    display: block;
  }
  .start-campaign-input6 {
    align-self: stretch;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 13px;
    gap: 8px;
    max-width: 100%;
  cursor: pointer;
 
  }

  .start-campaign-input6:focus {
    border-color: #7e56d9; /* Set to your preferred active color */
    box-shadow: 0 0 5px rgba(126, 86, 217, 0.5); /* Optional glow effect */
  }

  .start-campaign-input6:hover {
    border-color: #7e56d9; /* Slightly darker border on hover */
  }
  
  .start-campaign-input6a{
    align-self: stretch;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 13px;
    gap: 8px;
    max-width: 100%;

    outline: none; 
    appearance: none; 
    position: relative; 
    background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"%3E%3Cpath fill="gray" d="M10 12l-6-6h12z"/%3E%3C/svg%3E'); /* Custom dropdown arrow */
    background-repeat: no-repeat;
    background-position: 95%; 
    background-size: 15px;
  }

  .start-campaign-input6a:focus {
    border-color: #7e56d9; 
    box-shadow: 0 0 5px rgba(126, 86, 217, 0.5); 
  }
  
 
  .start-campaign-input6a:hover {
    border-color: #7e56d9; 
  }

  .start-campaign-hint-text6 {
    width: 320px;
    position: relative;
    line-height: 20px;
    color: #475467;
    display: none;
  }
  .start-campaign-input-field {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
    max-width: 100%;
  }
  .start-campaign-label10 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 94px;
  }
  .start-campaign-mail-01-icon,
  .start-campaign-text274 {
    position: relative;
    overflow: hidden;
  }
  .start-campaign-mail-01-icon {
    height: 20px;
    width: 20px;
    flex-shrink: 0;
  }
  .start-campaign-text274 {
    /* width: calc(100% - 36px); */
    width: 80%;
    border: 0;
    outline: 0;
    font-family: Inter;
    font-size: 16px;
    background-color: transparent;
    height: 24px;
    flex: 1;
    line-height: 24px;
    color: #101828;
    text-align: left;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* min-width: 163px; */
    padding: 0;
  }
  .start-campaign-content73 {
    /* flex: 1; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    width: 100%;
  }
  .start-campaign-help-icon3 {
    height: 16px;
    width: 16px;
    position: relative;
  }
  .start-campaign-form,
  .start-campaign-input-field1,
  .start-campaign-input7 {
    align-self: stretch;
    display: flex;
    justify-content: flex-start;
  }
  .start-campaign-input7 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    flex-direction: row;
    align-items: center;
    padding: 8px 13px;
    gap: 8px;
    width: 50%;
  }

  .start-campaign-email-domain {
    width: 50%;
    position: relative;
    line-height: 20px;
    color: #475467;
    padding: 9px 0px 0px 8px;
    
  }

  .start-campaign-email-card{
    flex-direction: row;
    display: flex;
  }

  .start-campaign-form,
  .start-campaign-input-field1 {
    flex-direction: column;
  }
  .start-campaign-input-field1 {
    align-items: flex-start;
    gap: 6px;
  }
  .start-campaign-form {
    align-items: flex-end;
  }
  .start-campaign-label11,
  .start-campaign-label12 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 108px;
  }
  .start-campaign-label12 {
    min-width: 69px;
  }
  .start-campaign-section2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    max-width: 100%;
  }
  .start-campaign-divider12,
  .start-campaign-section-container {
    align-self: stretch;
    max-width: 100%;
  }
  .start-campaign-section-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 24px;
    box-sizing: border-box;
  }
  .start-campaign-divider12 {
    flex: 1;
    position: relative;
    display: none;
    background-color: #eaecf0;
  }
  .start-campaign-divider-wrapper,
  .start-campaign-slide-out-menu-header-parent {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  .start-campaign-divider-wrapper {
    height: 1px;
    flex-direction: row;
    padding: 0 24px;
    box-sizing: border-box;
  }
  .start-campaign-slide-out-menu-header-parent {
    flex-direction: column;
    gap: 24px;
    text-align: left;
    font-size: 14px;
    color: #344054;
    font-family: Inter;
  }
  .start-campaign-placeholder-icon91 {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
    min-height: 20px;
  }
  .start-campaign-text275 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #344054;
    text-align: left;
    display: inline-block;
    min-width: 48px;
  }
  .start-campaign-buttonsbutton49,
  .start-campaign-text-padding51 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
  }
  .start-campaign-buttonsbutton49 {
    cursor: pointer;
    border: 1px solid #d0d5dd;
    padding: 8px 13px;
    background-color: #fff;
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    gap: 4px;
  }

  .start-campaign-buttonsbutton49:hover{
 background-color: #f9fafb;
  }

  .start-campaign-text276 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #fff;
    text-align: left;
    display: inline-block;
    min-width: 55px;
  }
  .start-campaign-actions19,
  .start-campaign-buttonsbutton50 {
    display: flex;
    flex-direction: row;
  }
  .start-campaign-buttonsbutton50 {
    cursor: pointer;
    border: 1px solid #7e56d9;
    padding: 8px 13px;
    background-color: #7e56d9;
    /* flex: 1; */
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }

  .start-campaign-buttonsbutton50:hover{
    background-color: #6941c6;
  }

  .start-campaign-actions19 {
    width: max-content;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
  }
  .start-campaign-content76,
  .start-campaign-footer2 {
    align-self: stretch;
    display: flex;
    align-items: center;
  }
  .start-campaign-content76 {
    flex-direction: row;
    justify-content: flex-end;
    padding: 16px 24px;
  }
  .start-campaign-footer2 {
    border-top: 1px solid #eaecf0;
    flex-direction: column;
    justify-content: flex-start;
  }
  .start-campaign-panel {
    background-color: #fff;
    max-width: 100%;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
  }
  .start-campaign-panel {
    width: 400px;
    box-shadow: 0 20px 24px -4px rgba(16, 24, 40, 0.08),
      0 8px 8px -4px rgba(16, 24, 40, 0.03);
    /* border-left: 1px solid #eaecf0; */
    border: 1px solid #7e56d9;
    border-radius: 8px;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: flex-start;
    /* gap: 433px; */
  }
  .start-campaign-slide-out-menu {
    flex-direction: row;
    justify-content: flex-end;
    /* padding: 0 0 0 40px; */
    line-height: normal;
    letter-spacing: normal;
    
    border-radius: 10px;
    background-color: #fff;
    max-width: 100%;
    /* overflow: hidden; */
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
    
    position: absolute;
   top: 120px;
    z-index: 3;
    right: 3px;
    /* transform: translateX(100%);  */
    transition: transform 0.3s ease-in-out;

  
  }

  .start-campaign-slide-out-menu.open {
    transform: translateX(0); 
  }


  /* New css for start campaign  */
  .start-campaign-searchbox-container{
   
     align-self: stretch;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: white;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  border: 1px #D0D5DD solid;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex; /* Use flex to enable responsiveness */
  margin: 0 23px;
  }

  .start-campaign-searchbox{
    /* flex: '1 1 0'; */
     height: 24px; 
     justify-content:flex-start; 
     align-items:center;
     gap:8px; 
     display:flex;
  }

  .start-campaign-searchbox-input-field{
    width: 20px; 
    height: 20px; 
    padding: 2.50px; 
    justify-content: center; 
    align-items: center; 
    display: flex;
  }

  .start-campaign-searchbox-search-icon{
    top: 2px;
    height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
 
  }

  .start-campaign-searchbox-placeholder{
  
    
    /* flex: 1;  */
    border: none; 
    outline: none; 
    background-color: transparent; 
    color: #667085;
    font-size: 16px;
    font-family: Inter, sans-serif;
    font-weight: 400;
    line-height: 24px;
    padding: 0 8px; 
  }

  .start-campaign-contact-header{
     color: #344054; 
     font-size: 14px; 
     font-family: Inter; 
     font-weight: 500;  
     word-wrap:break-word;
  }

  .start-campaign-contact-container{
    align-self: stretch; 
    height: 196px; 
    flex-direction:column; 
    justify-content: flex-start; 
    align-items: flex-start; 
    gap: 12px; 
    display:flex;
  }

  .start-campaign-contact-container-menu{
    align-self: stretch; 
    justify-content: space-between; 
    align-items: flex-start; 
    display: inline-flex;
  }

  .start-campaign-contact-main{
    justify-content: flex-start; 
    align-items: center; 
    gap: 12px;
    display: flex
  }

  .start-campaign-contact-avatar-menu{
    width: 40px; 
    height: 40px; 
    position: relative; 
    background: linear-gradient(0deg, #CFCBDC 0%, #CFCBDC 100%); 
    border-radius: 9999px; 
    background-image: url(https://via.placeholder.com/40x40);
  }
  
  .start-campaign-contact-avatar{
    width: 40px; 
    height: 40px; 
    left: 0px; 
    top: 0px; 
    position: absolute; 
    border-radius: 9999px; 
    border:0.75px rgba(0, 0, 0, 0.08) solid;
  }

  .start-campaign-contact-avatar-online-indicator{
    width: 10px; 
    height: 10px; 
    left: 30px; 
    top: 30px; 
    position: absolute; 
    background: #17B26A; 
    border-radius: 9999px; 
    border: 1.50px white solid;
  }

  .start-campaign-contact-name-and-work-title{
    flex-direction: column; 
    justify-content: flex-start; 
    align-items: flex-start; 
    display: inline-flex;
  }

.start-campaign-contact-name{
color:#344054; 
font-size: 14px; 
font-family: Inter;
 font-weight: 600; 
 line-height: 20px; 
 word-wrap: break-word;
 white-space: nowrap;
 text-overflow: ellipsis;
 overflow: hidden;
 width: 220px;
}

.start-campaign-contact-work-title{
color: #475467; 
font-size: 14px; 
font-family: Inter; 
font-weight: 400; 
line-height: 20px; 
word-wrap: break-word;
white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
width: 220px;

}

.start-campaign-contact-checkbox-container{
  padding: 8px; 
  border-radius: 8px; 
  overflow: hidden; 
  justify-content: center; 
  align-items: center; 
  gap: 8px; 
  display: flex;
}

.start-campaign-contact-checkbox{
  width: 20px;
  height: 20px;
  padding-left: 1.67px; 
  padding-right: 1.67px; 
  padding-top: 3.33px; 
  padding-bottom: 3.33px; 
  justify-content: center;
  align-items: center;
  display:flex;
}

.start-campaign-input-field-container{
  align-self:stretch;
  padding:10px 3px;
  overflow: hidden;  
  margin: 0 23px;
}

  @media screen and (max-width: 400px) {
    .start-campaign-panel {
      gap: 216px;
    }
  }
  