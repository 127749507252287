.custom-z-index {
    z-index: 4;
}

.sales-iq-job-component-listing-container {
    box-sizing: border-box;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: initial;
    padding: 0 29px;
    align-items: flex-start;
    width: 100%;
    display: flex;
    height: max-content;
    /* overflow-y: auto; */
    overflow-y: scroll;
    /* Enable vertical scrolling */
    scrollbar-width: none;
    /* For Firefox */
    -ms-overflow-style: none;
    /* For Internet Explorer and Edge */
}


.sales-iq-job-component-listing-container::-webkit-scrollbar {
    display: none;
    /* For Chrome, Safari, and Opera */
}

.sales-iq-job-component-sales-iq-content-items {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    /* max-width: 100%; */
    width: 100%;
    z-index: 0;
    box-sizing: border-box;

    align-self: stretch;
    flex-direction: row;
    /* padding: 0 30px 24px 32px; */
    font-size: 18px;
}

.sales-iq-job-component-sales-iq-rectangle-parent10 {
    display: flex;
    align-items: flex-start;
    max-width: 100%;
    position: relative;
    flex: 1;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: flex-start;
    padding: 12px 13px 22px 23px;
    gap: 24.0px;
    z-index: 1;
}

.sales-iq-job-component-sales-iq-frame-child12 {
    position: relative;
    border-radius: 12px;
    display: none;

    height: 208px;
    width: 1016px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    max-width: 100%;
}

.sales-iq-job-component-sales-iq-frame-wrapper25 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
    gap: 24px;
    /* width: 648px; */
    flex-direction: column;
    padding: 10px 0 0;
    box-sizing: border-box;
}

.sales-iq-job-component-sales-iq-element-details-parent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;

    align-self: stretch;
    flex-direction: row;
    gap: 24px;
}

.sales-iq-job-component-sales-iq-element-details {
    border-radius: 12px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 38px;
    box-sizing: border-box;
    min-width: 160px;
    z-index: 2;
}

.sales-iq-job-component-sales-iq-element-details-child {
    position: relative;
    border-radius: 12px;
    display: none;

    height: 160px;
    width: 160px;
    background-color: #f5f5f5;
}

.sales-iq-job-component-sales-iq-f4b711e503bc72fe396e5043e0c2-1-icon {
    height: 84px;
    width: 84px;
    position: relative;
    object-fit: cover;
    z-index: 3;
}

.sales-iq-job-component-sales-iq-element-actions {
    flex: 1;
    flex-direction: column;
    gap: 8px;
    /* min-width: 302px; */

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
}



.sales-iq-job-component-sales-iq-text78 {
    position: relative;
    z-index: 2;
    width: 640px;
    line-height: 28px;
    font-weight: 600;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: inherit !important;
}

.sales-iq-job-component-sales-iq-text78:hover {
    text-decoration: underline;
}

.sales-iq-job-component-sales-iq-action-icons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: max-content;
    align-self: stretch;
    max-width: 100%;
    gap: 20px;
    font-size: 16px;
    color: #475467;
}

.sales-iq-job-component-sales-iq-calendar-icon6 {
    /* flex: 1; */
    gap: 8px;
    /* min-width: 218px; */
    max-width: 100%;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.sales-iq-job-component-sales-iq-date-icon {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    /* flex: 1; */
    gap: 16px;
    width: max-content;
}

.sales-iq-job-component-sales-iq-badge-parent {
    display: flex;
    align-items: flex-start;
    gap: 20px;

    flex-direction: row;
    justify-content: flex-start;
}

.sales-iq-job-component-sales-iq-supporting-text12 {
    position: relative;
    letter-spacing: -0.06px;
    line-height: 24px;
    z-index: 2;
}

.sales-iq-job-component-sales-iq-search-input {
    display: flex;
    justify-content: flex-start;

    flex-direction: column;
    align-items: flex-start;
    padding: 2px 0 0;
}

.sales-iq-job-component-sales-iq-marker-pin-02-icon2 {
    width: 20px;
    height: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 2;
}


.sales-iq-job-component-sales-iq-type-badge-elements {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    gap: 8px;
    color: #fff;
}

.sales-iq-job-component-sales-iq-end-label {
    width: 50px;
    border-radius: 8px;
    background-color: #fdb022;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1px 4px;
    box-sizing: border-box;
    z-index: 2;
}

.sales-iq-job-component-sales-iq-search-icon-input {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 41px;
}

.sales-iq-job-component-sales-iq-type-support {
    flex-direction: column;
    padding: 1px 0 0;
    color: #475467;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.sales-iq-job-component-sales-iq-supporting-text9 {
    position: relative;
    line-height: 24px;
    display: inline-block;
    min-width: 117px;
    z-index: 2;
}

.sales-iq-job-component-sales-iq-type-label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

}

.sales-iq-job-component-sales-iq-text99 {
    position: relative;
    line-height: 23px;
    font-weight: 500;
    z-index: 2;
}

.sales-iq-job-component-sales-iq-supporting-text14 {
    position: relative;
    font-size: 12px;
    line-height: 19px;
    color: #475467;
    display: inline-block;
    min-width: 104px;
    z-index: 3;
}

.sales-iq-job-component-sales-iq-work-type-icon {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    width: max-content;
    flex-direction: row;
    gap: 8px;
    /* min-width: 152px; */
}

.sales-iq-job-component-sales-iq-location3 {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    /* min-width: 124px; */
    width: 163px;
    z-index: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space:nowrap;
}

.sales-iq-job-component-sales-iq-work-type-value {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    align-self: stretch;
    flex-direction: row;
    padding: 0 0 0 1px;
    font-size: 14px;
    color: #101828;
}

.sales-iq-job-component-sales-iq-price-parent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex: 1;
    flex-direction: column;
}

.sales-iq-job-component-sales-iq-text100 {
    align-self: stretch;
    position: relative;
    line-height: 23px;
    font-weight: 500;
    z-index: 2;
}

.sales-iq-job-component-sales-iq-supporting-text15 {
    width: 104px;
    position: relative;
    font-size: 12px;
    line-height: 19px;
    color: #475467;
    display: inline-block;
    z-index: 3;
}

.sales-iq-job-component-sales-iq-work-type-label {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    gap: 8px;
    margin-left: -9px;
}

.sales-iq-job-component-sales-iq-type-of-work2 {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 124px;
    z-index: 2;

    min-width: 109px;
}

.sales-iq-job-component-sales-iq-dropdown-parent1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    align-items: flex-end;

    width: 153px;
    gap: 22px;
    text-align: center;
    font-size: 12px;
    color: #475467;
}

.sales-iq-job-component-sales-iq-dropdown10 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 2;
    position: relative
}

.sales-iq-job-component-sales-iq-dots-vertical-icon {
    width: 20px;
    height: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
}

.sales-iq-job-component-sales-iq-filter-elements-inner {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 10px 0 0;
    z-index: 0;
}

.sales-iq-job-component-sales-iq-frame-parent31 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;


    align-items: flex-end;

    flex: 1;
}

.sales-iq-job-component-sales-iq-text-wrapper10 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 57px;
}

.sales-iq-job-component-sales-iq-text101 {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 29px;
    z-index: 3;
}

.sales-iq-job-component-sales-iq-price4 {
    align-self: stretch;
    position: relative;
    font-size: 20px;
    letter-spacing: -0.02em;
    line-height: 32px;
    font-weight: 600;
    color: #6941c6;
    white-space: nowrap;
    z-index: 2;
}

.sales-iq-job-component-sales-iq-trend-chart-inner {
    display: flex;
    justify-content: flex-start;

    align-self: stretch;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 0 0 3px;
}

.sales-iq-job-component-sales-iq-trend-chart {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    width: 113px;
    align-items: flex-start;
    gap: 4px;
}

.sales-iq-job-component-sales-iq-text83 {
    position: relative;

    line-height: 20px;
    display: inline-block;
    /* min-width: 83px; */
    width: 85px;
    z-index: 3;
}

.sales-iq-job-component-sales-iq-frame-parent32 {
    display: flex;
    justify-content: flex-start;

    flex: 1;
    flex-direction: column;
    align-items: flex-end;
    gap: 19px;
}

.sales-iq-job-component-sales-iq-frame-wrapper27 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 34px 0 0;
}

.sales-iq-job-component-sales-iq-radar-1-1-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    flex: 1;
    align-items: flex-start;
    gap: 8px;
    z-index: 2;
}

.sales-iq-job-component-sales-iq-magnifier-1-icon {
    height: 20px;
    width: 20px;
    position: relative;
    object-fit: cover;
    min-height: 20px;
}

.sales-iq-job-component-sales-iq-arrow-square-up-right-icon1 {
    width: 24px;
    height: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 2;
}

.sales-iq-job-component-sales-iq-small-card{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 34px;
    padding-top: 20px;
    overflow-x: scroll;
    scrollbar-width: none;
}


@media screen and (max-width: 1100px) {
    .sales-iq-job-component-sales-iq-rectangle-parent10 {
        flex-wrap: wrap;
    }
}

@media screen and (max-width:750px) {

    .sales-iq-job-component-sales-iq-action-icons,
    .sales-iq-job-component-sales-iq-element-details-parent {
        flex-wrap: wrap;
    }

    .sales-iq-job-component-sales-iq-element-details {
        flex: 1;
    }

    .sales-iq-job-component-sales-iq-f4b711e503bc72fe396e5043e0c2-1-icon {
        flex: 1;
    }

}

@media screen and (max-width:450px) {

    .sales-iq-job-component-sales-iq-calendar-icon6 {
        flex-wrap: wrap;
    }

    .sales-iq-job-component-sales-iq-work-type-icon {
        flex: 1;
    }

    .sales-iq-job-component-sales-iq-work-type-label {
        margin-left: 0;
    }

    .sales-iq-job-component-sales-iq-price4 {
        font-size: 16px;
        line-height: 26px;
    }

}