.add-software-step-section-header-parent {
    display: flex;
    align-items: flex-start;
    max-width: 100%;
  }
  
  .add-software-step-section-header-parent {
    margin: 0;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    gap: 24px;
  }
  
  .add-software-step-section-header {
    align-self: stretch;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    z-index: 1;
  }
  
  .add-software-step-content7 {
    width: 100%;
    border: 0;
    outline: 0;
    background-color: transparent;
    height: 28px;
    flex-direction: row;
    font-family: Inter;
    font-weight: 600;
    font-size: 18px;
    color: #101828;
    min-width: 250px;
  }
  .add-software-step-content7
  {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  }
  
  .add-software-step-divider-icon2 {
    align-self: stretch;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  
  }
  
  .add-software-step-input-field-parent {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 24px;
    max-width: 100%;
    /* z-index: 1; */
  }
  
  .add-software-step-input-field {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
    min-width: 322px;
    max-width: 100%;
  }
  
  .add-software-step-first-upload-inner {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 100%;
    align-items: flex-start;
    gap: 6px;
  
  }
  
  .add-software-step-label1 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-family: Inter;
    text-align: left;
    display: inline-block;
    min-width: 127px;
  }
  
  .add-software-step-transaction-type {
    color: #344054;
  }
  
  .add-software-step-span {
    color: #f04438;
  }
  
  .add-software-step-input1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  
    align-self: stretch;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    max-width: 100%;
    row-gap: 20px;
  }
  
  .add-software-step-content8 {
    flex: 1;
    padding: 8px 0 8px 12px;
    box-sizing: border-box;
    min-width: 295px;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  
  .add-software-step-text-input {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .add-software-step-first-nested-input {
  position: relative;
  font-family: Inter;
  text-align: left;
  display: inline-block;
  
  flex: 1;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  }
  
  .add-software-step-dropdown
  {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  
  overflow: hidden;
  padding: 8px 9px 8px 12px;
  }
  
  .add-software-step-search-lg-icon {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .add-software-step-label2 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-family: Inter;
    text-align: left;
  }
  
  .add-software-step-your-role-in {
    color: #344054;
    white-space: pre-wrap;
  }
  
  .add-software-step-label3
  {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  text-align: left;
  display: inline-block;
  min-width: 123px;
  }
  
  .add-software-step-label5 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-family: Inter;
    text-align: left;
    display: inline-block;
    min-width: 123px;
  
    min-width: 96px;
  }
  
  .add-software-step-input5 {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .add-software-step-input5 {
    align-self: stretch;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
  }
  
  .add-software-step-content12
  {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  
  width: 100%;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 40px;
  flex: 1;
  padding: 8px 12px;
  font-family: Inter;
  font-size: 16px;
  color: #667085;
  /* min-width: 250px; */
  }
  
  .add-software-step-label6
  {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  
  text-decoration: none;
  position: relative;
  font-family: Inter;
  text-align: left;
  min-width: 62px;
  }
  
  .add-software-step-label7 {
  color: #344054;
  
  position: relative;
  font-family: Inter;
  text-align: left;
  min-width: 100px;
  
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  }
  
  .add-software-step-label8 {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
  }
  
  
  .add-software-step-label8 {
    color: #344054;
  }
  
  .add-software-step-label8 {
    min-width: 96px;
  }
  
  .add-software-step-label8
  {
    position: relative;
    font-family: Inter;
    text-align: left;
  }
  
  .add-software-step-single-input-parent {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
    flex-direction: row;
    align-self: stretch;
    align-items: flex-start;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 24px;
  }
  
  .add-software-step-single-input {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  
    flex: 1;
    flex-direction: column;
    gap: 6px;
    min-width: 241px;
    max-width: 100%;
  }
  
  .add-software-step-label9
  {
  position: relative;
  font-family: Inter;
  text-align: left;
  
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  z-index: 1;
  }
  
  .add-software-step-input9
  {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  row-gap: 20px;
  z-index: 1;
  }
  
  .add-software-step-content16 {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 0 8px 12px;
    box-sizing: border-box;
    gap: 8px;
    min-width: 159px;
  }
  
  .add-software-step-text-input5 {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }
  
  .add-software-step-leading-text,
  .add-software-step-text34 {
  position: relative;
  font-family: Inter;
  text-align: left;
  }
  
  .add-software-step-leading-text,
  .add-software-step-text34 {
  font-size: 16px;
  line-height: 24px;
  }
  .add-software-step-leading-text {
  color: #344054;
  display: inline-block;
  min-width: 11px;
  }
  .add-software-step-text34 {
  flex: 1;
  color: #667085;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  }
  
  
  .add-software-step-help-icon {
    height: 16px;
    width: 16px;
    position: relative;
  }
  
  .add-software-step-dropdown5 {
    width: 78px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    box-sizing: border-box;
    gap: 0;
    row-gap: 20px;
  }
  
  .add-software-step-dropdown-text {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-family: Inter;
    color: #344054;
    text-align: left;
    display: inline-block;
    min-width: 34px;
  }
  
  .add-software-step-input-field8
  {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
  
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  min-width: 241px;
  z-index: 1;
  }
  
  .add-software-step-input-with-label9,
  .add-software-step-input10 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  }
  
  .add-software-step-input10 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  align-items: center;
  row-gap: 20px;
  }
  
  .add-software-step-input-with-label9 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  }
  
  .add-software-step-label10 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-family: Inter;
    text-align: left;
    display: inline-block;
    min-width: 118px;
  }
  
  
  .add-software-step-content17 {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 0 8px 12px;
    box-sizing: border-box;
    min-width: 183px;
  }
  
  .add-software-step-step-title {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .add-software-step-content18
  {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
  
  flex-direction: row;
  
  width: 100%;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 40px;
  flex: 1;
  align-items: center;
  padding: 8px 12px;
  box-sizing: border-box;
  font-family: Inter;
  font-size: 16px;
  color: #667085;
  min-width: 193px;
  }
  
  .add-software-step-nested-input-fields,
  .add-software-step-nested-inputs {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  }
  .add-software-step-nested-input-fields {
  flex: 1;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 24px;
  z-index: 1;
  }
  .add-software-step-nested-inputs {
  align-self: stretch;
  padding: 0 0 12px;
  box-sizing: border-box;
  }
  
  .add-software-step-label12 {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    min-width: 73px;
  }
  
  .add-software-step-label12 {
    position: relative;
    font-family: Inter;
    text-align: left;
    display: inline-block;
  }
  
  .add-software-step-label13 {
    position: relative;
    font-family: Inter;
    text-align: left;
    display: inline-block;
  }
  
  .add-software-step-label13 {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    min-width: 78px;
  }
  
  .add-software-step-input-tags {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    max-width: 100%;
  }
  
  .add-software-step-section-header1 {
    align-self: stretch;
    background-color: #fff;
    display: flex;
    gap: 20px;
    max-width: 100%;
    z-index: 1;
  
    flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  }
  
  
  .add-software-step-divider
  {
  align-self: stretch;
  position: relative;
  }
  
  .add-software-step-divider {
  height: 1px;
  background-color: #eaecf0;
  z-index: 1;
  }
  
  .add-software-step-content-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  
  flex-direction: row;
  
  align-self: stretch;
  gap: 16px;
  max-width: 100%;
  }
  
  .add-software-step-text-and-supporting-text5 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 100%;
  }
  
  .add-software-step-uploads-title {
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    font-family: Inter;
    color: #101828;
    text-align: left;
  
    align-self: stretch;
    position: relative;
  }
  
  .add-software-step-dropdown9 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  
    display: none;
  }
  
  .add-software-step-dots-vertical-icon {
    width: 20px;
    height: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .add-software-step-tagged-inputs {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
  
    align-items: flex-start;
  
    flex: 1;
    flex-direction: column;
    gap: 6px;
  
    align-self: stretch;
    flex-direction: row;
    z-index: 1;
  }
  
  .add-software-step-input-field12 {
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
    flex: 1;
    flex-direction: column;
    gap: 6px;
  }
  
  .add-software-step-input14 {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
  
    align-self: stretch;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
    row-gap: 20px;
  }
  
  .add-software-step-content22
  {
  flex: 1;
  flex-direction: row;
  align-items: center;
  
  
  padding: 8px 0 8px 12px;
  box-sizing: border-box;
  min-width: 633px;
  
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
  }
  
  .add-software-step-input-field13 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
    max-width: 100%;
    /* z-index: 1; */
  }
  
  .add-software-step-label15 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-family: Inter;
    text-align: left;
    display: inline-block;
    min-width: 87px;
  }
  
  .add-software-step-label16 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-family: Inter;
    text-align: left;
    display: inline-block;
    min-width: 129px;
  }
  
  .add-software-step-actions2
  {
  display: flex;
  flex-direction: row;
  align-items: center;
  
  justify-content: flex-end;
  max-width: 100%;
  
  flex: 1;
  gap: 12px;
  }
  
  .add-software-step-button9
  {
  display: flex;
  flex-direction: row;
  align-items: center;
  
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 13px;
  background-color: #7e56d9;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  justify-content: center;
  gap: 4px;
  }
  
  .add-software-step-chevron-down-icon {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  
  .add-software-step-home-line-icon {
    position: relative;
    overflow: hidden;
  
    height: 24px;
    width: 24px;
    flex-shrink: 0;
  }
  
  
  .add-software-step-text-padding6 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
  }
  
  .add-software-step-text44 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #fff;
    text-align: left;
    display: inline-block;
    min-width: 106px;
  }
  
  
  .add-software-step-first-upload-container {
    flex: 1;
    flex-direction: column;
    gap: 24px;
    min-width: 322px;
    max-width: 100%;
    display: flex;
    /* flex-direction: row; */
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
  }
  
  
  
  .add-software-step-label20 {
    min-width: 116px;
    z-index: 1;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-family: Inter;
    text-align: left;
    display: inline-block;
  }
  
  .add-software-step-file-upload-base {
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    padding: 14px 23px;
    z-index: 1;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  
  .add-software-step-content36
  {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  
  gap: 12px;
  }
  
  .add-software-step-featured-icon {
    width: 40px;
    height: 40px;
    position: relative;
    border-radius: 8px;
  }
  
  .add-software-step-text-and-supporting-text6 {
    gap: 4px;
  
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  
  .add-software-step-action {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 0 20px;
    gap: 4px;
  }
  
  .add-software-step-buttonsbutton3 {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }
  
  .add-software-step-placeholder-icon {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
    min-height: 20px;
  }
  
  .add-software-step-text46 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #6941c6;
    text-align: left;
    display: inline-block;
    min-width: 101px;
  }
  
  .add-software-step-text47 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-family: Inter;
    color: #475467;
    text-align: left;
    display: inline-block;
    min-width: 112px;
  }
  
  .add-software-step-supporting-text13 {
    align-self: stretch;
    position: relative;
    font-size: 12px;
    line-height: 18px;
    font-family: Inter;
    color: #475467;
    text-align: center;
  }
  
  .add-software-step-file-upload-item-base
  {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
  
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  align-items: flex-end;
  padding: 14px 15px;
  gap: 4px;
  z-index: 1;
  }
  
  .add-software-step-first-upload-item-content,
  .add-software-step-first-upload-item-info {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  }
  .add-software-step-first-upload-item-info {
  flex: 1;
  flex-direction: column;
  min-width: 267px;
  }
  .add-software-step-first-upload-item-content {
  align-self: stretch;
  flex-direction: row;
  gap: 12px;
  }
  
  .add-software-step-file-type-icon {
    height: 40px;
    width: 40px;
    position: relative;
  }
  
  .add-software-step-page-icon {
    position: absolute;
    height: 100%;
    width: 80%;
    top: 0;
    right: 2.5%;
    bottom: 0;
    left: 17.5%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }
  
  .add-software-step-file-type-wrap {
    position: absolute;
    height: 40%;
    width: 65%;
    top: 45%;
    right: 32.5%;
    bottom: 15%;
    left: 2.5%;
    border-radius: 2px;
    background-color: #da2d20;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2px 3px;
    box-sizing: border-box;
    z-index: 1;
  }
  
  .add-software-step-first-upload-item-inner {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    max-width: 100%;
  }
  
  .add-software-step-first-upload-item {
    flex: 1;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-family: Inter;
    color: #344054;
    text-align: left;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
  }
  
  .add-software-step-checkbox-base-icon {
    height: 16px;
    width: 16px;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .add-software-step-checkbox {
    margin: 0 !important;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .add-software-step-supporting-text14 {
    align-self: stretch;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-family: Inter;
    color: #475467;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .add-software-step-progress-bar {
    width: 411px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 11px;
    max-width: 100%;
  }
  
  .add-software-step-final-upload-progress-bar {
    flex: 1;
    flex-direction: column;
    padding: 6px 0 0;
    box-sizing: border-box;
    min-width: 235px;
  
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  
  .add-software-step-progress-bar2 {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  
  height: 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  }
  
  .add-software-step-background {
    height: 100%;
    width: 100%;
    position: absolute;
    margin: 0 !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 9999px;
    background-color: #eaecf0;
  }
  
  .add-software-step-progress
   {
    align-self: stretch;
    position: relative;
    max-width: 100%;
  }
  
  .add-software-step-progress {
    flex: 1;
    border-radius: 9999px;
    background-color: #7e56d9;
    z-index: 1;
  }
  
  .add-software-step-percentage {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-family: Inter;
    color: #344054;
    text-align: left;
    display: inline-block;
    min-width: 38px;
  }
  
  .add-software-step-input-with-label19 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 20px 0 0;
  
  }
  
  .add-software-step-label21 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-family: Inter;
    text-align: left;
    display: inline-block;
    min-width: 50px;
  }
  
  .add-software-step-file-button-container
  {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  }
  
  .add-software-step-buttonsbutton4 {
    border-radius: 8px;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 16px;
    gap: 6px;
  }
  
  .add-software-step-file-upload-base1,
  .add-software-step-input-field19 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  }
  .add-software-step-file-upload-base1 {
  align-self: stretch;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  align-items: center;
  padding: 14px 23px;
  }
  .add-software-step-input-field19 {
  flex: 1;
  align-items: flex-start;
  gap: 6px;
  min-width: 322px;
  max-width: 100%;
  z-index: 1;
  }
  
  .add-software-step-label22 {
    text-decoration: none;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-family: Inter;
    text-align: left;
    display: inline-block;
    min-width: 66px;
  }
  
  .add-software-step-file-type {
    position: relative;
    font-size: 10px;
    display: inline-block;
    font-family: Inter;
    color: #fff;
    text-align: center;
    min-width: 20px;
  
  }
  
  .add-software-step-content7 {
    width: 100%;
    border: 0;
    outline: 0;
    background-color: transparent;
    height: 28px;
    flex-direction: row;
    font-family: Inter;
    font-weight: 600;
    font-size: 18px;
    color: #101828;
    min-width: 250px;
  
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .add-software-step-input20 {
    align-self: stretch;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    align-items: flex-start;
    flex-wrap: wrap;
    align-content: flex-start;
    max-width: 100%;
    row-gap: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  
  .add-software-step-content39 {
    flex: 1;
    align-items: center;
    padding: 8px 0 8px 12px;
    box-sizing: border-box;
    min-width: 630px;
    max-width: 100%;
  
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  
  .add-software-step-dropdown13
  {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  
  overflow: hidden;
  align-items: center;
  padding: 8px 12px;
  }
  
  .add-software-step-hint-text21
  {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  
  align-self: stretch;
  }
  
  
  
  .add-software-step-add-it,
  .add-software-step-cant-find-an {
  font-family: Inter;
  color: #475467;
  }
  .add-software-step-add-it {
  font-weight: 600;
  color: #7e56d9;
  }
  
  .add-software-step-section-footer1,
  .add-software-step-section-footer2 {
  display: flex;
  align-items: center;
  max-width: 100%;
  }
  .add-software-step-actions3 {
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 0 0 742px;
  box-sizing: border-box;
  gap: 12px;
  }
  .add-software-step-section-footer1,
  .add-software-step-section-footer2 {
  align-self: stretch;
  background-color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  }
  .add-software-step-section-footer2 {
  gap: 20px;
  }
  .add-software-step-section-footer1 {
  z-index: 1;
  }
  
  .add-software-step-content31{
  display: flex;
  flex-direction: row;
  align-items: center;
  
  justify-content: flex-end;
  max-width: 100%;
  
  align-self: stretch;
  gap: 20px;
  }
  
  .add-software-step-buttonsbutton1 {
    height: 20px;
    overflow: hidden;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }
  
  .add-software-step-actions3
  {
  display: flex;
  align-items: center;
  max-width: 100%;
  
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 0 0 742px;
  box-sizing: border-box;
  gap: 12px;
  }
  
  .add-software-step-button11 {
    cursor: pointer;
    border: 1px solid #d0d5dd;
    padding: 8px 13px;
    background-color: #fff;
    width: 94px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
  
  .text79 {
  flex: 1;
  position: relative;
  line-height: 24px;
  font-weight: 500;
  }
  .arrow-narrow-left-icon5 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
  }
  .text-group {
  width: 237px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  }
  .step-icon-base16 {
  height: 24px;
  width: 24px;
  position: relative;
  border-radius: 9999px;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
  }
  .step-title1 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  }
  .step-icon-base15,
  .step-title-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  }
  .step-title-wrapper {
  flex: 1;
  flex-direction: column;
  padding: 2px 0 0;
  }
  .step-icon-base15 {
  align-self: stretch;
  flex-direction: row;
  gap: 12px;
  }
  .connector15 {
  width: 2px;
  flex: 1;
  position: relative;
  border-radius: 2px;
  background-color: #7e56d9;
  }
  .connector-wrapper8 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6px 0 0;
  }
  .supporting-text25 {
  flex: 1;
  position: relative;
  line-height: 20px;
  }
  .frame-parent5,
  .step-base16 {
  align-self: stretch;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  }
  .frame-parent5 {
  flex-direction: row;
  align-items: flex-start;
  padding: 0 0 0 11px;
  gap: 23px;
  margin-top: -2px;
  font-size: 12px;
  color: #475467;
  }
  .step-base16 {
  flex-direction: column;
  align-items: flex-end;
  padding: 0 0 4px;
  }
  .step-icon-base18 {
  width: 24px;
  height: 24px;
  position: relative;
  border-radius: 9999px;
  overflow: hidden;
  flex-shrink: 0;
  }
  .connector17 {
  align-self: stretch;
  width: 2px;
  position: relative;
  border-radius: 2px;
  background-color: #eaecf0;
  }
  .connector-wrap5,
  .connector-wrapper10 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  }
  .connector-wrapper10 {
  flex: 1;
  flex-direction: row;
  padding: 0 11px;
  }
  .connector-wrap5 {
  align-self: stretch;
  flex-direction: column;
  padding: 0 0 4px;
  gap: 4px;
  }
  .supporting-text27 {
  align-self: stretch;
  position: relative;
  font-size: 12px;
  line-height: 20px;
  color: #7e56d9;
  }
  .step-base18,
  .text-and-supporting-text12 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  }
  .text-and-supporting-text12 {
  flex-direction: column;
  padding: 2px 0 24px;
  }
  .step-base18 {
  align-self: stretch;
  flex-direction: row;
  gap: 12px;
  color: #6941c6;
  }
  .step-base15 {
  margin: 0;
  align-self: stretch;
  flex: 1;
  gap: 38px;
  text-align: left;
  font-size: 14px;
  color: #344054;
  font-family: Inter;
  }
  .add-software-step-3-inner,
  .frame-parent4,
  .step-base15 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  }
  .frame-parent4 {
  align-self: stretch;
  flex: 1;
  gap: 16px;
  }
  .add-software-step-3-inner {
  height: 412px;
  width: 250px;
  padding: 38px 0 0;
  box-sizing: border-box;
  }
  .hint-text46 {
  width: 320px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  display: none;
  z-index: 1;
  }
  .content61 {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: transparent;
  align-self: stretch;
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: Inter;
  font-weight: 600;
  font-size: 18px;
  color: #101828;
  min-width: 250px;
  }
  .divider-icon14 {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  }
  .section-header7 {
  align-self: stretch;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  }
  .label45,
  .label46 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  }
  .label45 {
  text-decoration: none;
  color: inherit;
  display: inline-block;
  min-width: 66px;
  }
  .label46 {
  width: 77px;
  display: none;
  }
  .text82 {
  width: 100%;
  border: 0;
  outline: 0;
  font-family: Inter;
  font-size: 16px;
  background-color: transparent;
  height: 78px;
  flex: 1;
  position: relative;
  line-height: 24px;
  color: #101828;
  text-align: left;
  display: inline-block;
  min-width: 250px;
  max-width: 100%;
  padding: 0;
  }
  .input-with-label43,
  .input31 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  }
  .input31 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: row;
  padding: 10px 13px;
  max-width: 100%;
  }
  .input-with-label43 {
  width: 1014px;
  flex-direction: column;
  gap: 6px;
  max-width: 199%;
  flex-shrink: 0;
  }
  .hint-text47 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  color: #475467;
  }
  .content62,
  .input-with-label42,
  .textarea-input-field7 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  }
  .textarea-input-field7 {
  width: 512px;
  flex-direction: column;
  padding: 0;
  box-sizing: border-box;
  gap: 6px;
  min-width: 480px;
  max-width: 512px;
  }
  .content62,
  .input-with-label42 {
  align-self: stretch;
  max-width: 100%;
  }
  .content62 {
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  }
  .input-with-label42 {
  flex-direction: column;
  gap: 6px;
  }
  .hint-text48 {
  width: 320px;
  position: relative;
  line-height: 20px;
  color: #475467;
  display: none;
  }
  .frame-section,
  .input-field34 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  }
  .input-field34 {
  flex: 1;
  flex-direction: column;
  gap: 6px;
  }
  .frame-section {
  align-self: stretch;
  flex-direction: row;
  text-align: left;
  font-size: 14px;
  color: #344054;
  font-family: Inter;
  }
  .label47 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  max-width: 100%;
  }
  .input-field35 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  max-width: 100%;
  text-align: left;
  font-size: 14px;
  color: #344054;
  font-family: Inter;
  }
  .label49,
  .label51 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  }
  .label49 {
  display: inline-block;
  min-width: 83px;
  }
  .label51 {
  min-width: 62px;
  }
  .label51,
  .label53,
  .label55 {
  display: inline-block;
  }
  .label53 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  min-width: 103px;
  }
  .label55 {
  min-width: 81px;
  }
  .label55,
  .label57 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  }
  .input-with-label55 {
  border: 1px solid #d0d5dd;
  background-color: #fff;
  height: 102px;
  width: 1014px;
  outline: 0;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px 13px;
  font-family: Inter;
  font-size: 16px;
  color: #101828;
  max-width: 199%;
  flex-shrink: 0;
  }
  .hint-text59 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  }
  .content68 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  max-width: 100%;
  color: #475467;
  }
  .label58 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 77px;
  }
  .featured-icon5 {
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 8px;
  }
  .placeholder-icon60 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 20px;
  }
  .text88 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 101px;
  }
  .buttonsbutton16 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
  }
  .text89 {
  position: relative;
  line-height: 20px;
  color: #475467;
  display: inline-block;
  min-width: 112px;
  }
  .action5 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 20px;
  gap: 4px;
  }
  .pdf1 {
  font-weight: 600;
  }
  .supporting-text28 {
  align-self: stretch;
  position: relative;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #475467;
  }
  .text-and-supporting-text13 {
  gap: 4px;
  }
  .content69,
  .file-upload-base5,
  .text-and-supporting-text13 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  }
  .content69 {
  gap: 12px;
  }
  .file-upload-base5 {
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  padding: 14px 23px;
  color: #6941c6;
  }
  .page-icon3 {
  position: absolute;
  height: 100%;
  width: 80%;
  top: 0;
  right: 2.5%;
  bottom: 0;
  left: 17.5%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  }
  .file-type3 {
  position: relative;
  display: inline-block;
  min-width: 20px;
  }
  .file-type-wrap3 {
  position: absolute;
  height: 40%;
  width: 65%;
  top: 45%;
  right: 32.5%;
  bottom: 15%;
  left: 2.5%;
  border-radius: 2px;
  background-color: #da2d20;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 3px;
  box-sizing: border-box;
  z-index: 1;
  }
  .file-type-icon3 {
  height: 40px;
  width: 40px;
  position: relative;
  }
  .checkbox-base-icon3,
  .text90 {
  position: relative;
  overflow: hidden;
  }
  .text90 {
  flex: 1;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  }
  .checkbox-base-icon3 {
  height: 16px;
  width: 16px;
  border-radius: 4px;
  flex-shrink: 0;
  }
  .checkbox3 {
  margin: 0 !important;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  }
  .supporting-text29,
  .text-parent1 {
  align-self: stretch;
  position: relative;
  }
  .text-parent1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  }
  .supporting-text29 {
  line-height: 20px;
  color: #475467;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  }
  .file-type-icon-parent,
  .frame-parent7 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  }
  .frame-parent7 {
  flex: 1;
  flex-direction: column;
  min-width: 267px;
  text-align: left;
  font-size: 14px;
  color: #344054;
  }
  .file-type-icon-parent {
  align-self: stretch;
  flex-direction: row;
  gap: 12px;
  }
  .background3 {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 9999px;
  background-color: #eaecf0;
  }
  .progress3 {
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: 9999px;
  background-color: #7e56d9;
  max-width: 100%;
  z-index: 1;
  }
  .progress-bar-wrapper,
  .progress-bar9 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  }
  .progress-bar9 {
  align-self: stretch;
  height: 8px;
  border-radius: 8px;
  flex-direction: row;
  position: relative;
  }
  .progress-bar-wrapper {
  flex: 1;
  flex-direction: column;
  padding: 6px 0 0;
  box-sizing: border-box;
  min-width: 235px;
  }
  .percentage3 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 38px;
  }
  .file-upload,
  .file-upload-item-base3,
  .progress-bar8 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
  }
  .progress-bar8 {
  width: 411px;
  flex-direction: row;
  align-items: flex-start;
  gap: 11px;
  text-align: left;
  font-size: 14px;
  color: #344054;
  }
  .file-upload,
  .file-upload-item-base3 {
  flex-direction: column;
  }
  .file-upload-item-base3 {
  align-self: stretch;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  align-items: flex-end;
  padding: 14px 15px;
  gap: 4px;
  text-align: center;
  font-size: 10px;
  color: #fff;
  }
  .file-upload {
  width: 495px;
  align-items: flex-start;
  gap: 6px;
  }
  .text91 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  white-space: nowrap;
  }
  .buttonsbutton17 {
  height: 20px;
  overflow: hidden;
  display: none;
  gap: 6px;
  }
  .button20,
  .buttonsbutton17,
  .text-padding24 {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  }
  .text-padding24 {
  align-self: stretch;
  display: flex;
  padding: 0 2px;
  }
  .button20 {
  height: 40px;
  border-radius: 8px;
  overflow: hidden;
  display: none;
  padding: 10px 14px;
  box-sizing: border-box;
  gap: 4px;
  }
  .chevron-left-icon3,
  .placeholder-icon66 {
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  }
  .placeholder-icon66 {
  height: 20px;
  width: 20px;
  display: none;
  }
  .chevron-left-icon3 {
  height: 24px;
  width: 24px;
  }
  .text93 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 34px;
  }
  .button21,
  .text-padding25 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
  }
  .button21 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 13px;
  background-color: #fff;
  width: 94px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  gap: 4px;
  }
  .text94 {
  text-decoration: none;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 55px;
  }
  .button22 {
  cursor: pointer;
  border: 0;
  padding: 10px 14px;
  background-color: #fdb022;
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 4px;
  min-width: 75px;
  }
  .text95 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 48px;
  }
  .actions6,
  .button23 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  }
  .button23 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 13px;
  background-color: #7e56d9;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  justify-content: center;
  gap: 4px;
  min-width: 70px;
  }
  .actions6 {
  justify-content: flex-end;
  padding: 0 0 0 673px;
  gap: 12px;
  max-width: 100%;
  }
  .content70,
  .section-footer7,
  .section-footer8 {
  align-self: stretch;
  display: flex;
  align-items: center;
  max-width: 100%;
  }
  .content70 {
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
  }
  .section-footer7,
  .section-footer8 {
  background-color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  }
  .section-footer8 {
  gap: 20px;
  }
  .section-footer7 {
  color: #475467;
  }
  .add-software-step-3,
  .section-header-group {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-family: Inter;
  }
  .section-header-group {
  flex: 1;
  flex-direction: column;
  gap: 24px;
  min-width: 659px;
  max-width: 100%;
  font-size: 14px;
  color: #344054;
  }
  .add-software-step-3 {
  width: 100%;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  /* padding: 29px 32px 29px 16px; */
  box-sizing: border-box;
  gap: 128px;
  line-height: normal;
  letter-spacing: normal;
  font-size: 16px;
  color: #101828;
  }
  
  
  
  @media screen and (max-width: 1025px) {
  .actions6 {
    padding-left: 336px;
    box-sizing: border-box;
  }
  }
  @media screen and (max-width: 975px) {
  .actions6 {
    flex-wrap: wrap;
  }
  .section-header-group {
    min-width: 100%;
  }
  .add-software-step-3 {
    gap: 64px;
  }
  }
  @media screen and (max-width: 725px) {
  .textarea-input-field7 {
    max-width: 100%;
  }
  .content62,
  .content68 {
    gap: 16px;
  }
  .actions6 {
    padding-left: 168px;
    box-sizing: border-box;
  }
  }
  @media screen and (max-width: 450px) {
  .step-base15 {
    gap: 19px;
  }
  .action5,
  .file-type-icon-parent,
  .progress-bar8 {
    flex-wrap: wrap;
  }
  .actions6 {
    padding-left: 20px;
    box-sizing: border-box;
  }
  .add-software-step-3 {
    gap: 32px;
  }
  }
  
  
  @media screen and (max-width:1100px) {
    
    .add-software-step-actions3 {
      box-sizing: border-box;
    
      flex-wrap: wrap;
      padding-left: 371px;
    }
  }
  
  @media screen and (max-width:780px) {
    
    .add-software-step-actions3{
        padding-left: 185px;
        box-sizing: border-box;
    }
  
    .add-software-step-content22
     {
      min-width: 100%;
    }
  }
  
  @media screen and (max-width:450px) {
  
    .add-software-step-actions3
     {
    padding-left: 20px;
    box-sizing: border-box;
    }
   
    .add-software-step-first-upload-item-content,
    .add-software-step-action,
    .add-software-step-input1,
    .add-software-step-input10,
    .add-software-step-input9 {
      flex-wrap: wrap;
    }
    
  }