@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");

.three-dots-admin-filter-dropdown-avatar-online-indicator6,
.three-dots-admin-filter-dropdown-contrast-border22 {
  position: absolute;
  border-radius: 9999px;
  box-sizing: border-box;
  overflow: hidden;
}

.three-dots-admin-filter-dropdown-contrast-border22 {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
}

.three-dots-admin-filter-dropdown-avatar-online-indicator6 {
  right: -1.5px;
  bottom: -1.5px;
  background-color: #17b26a;
  border: 1.5px solid #fff;
  width: 13px;
  height: 13px;
  z-index: 1;
}

.three-dots-admin-filter-dropdown-avatar22 {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 9999px;
  background-image: url(../../icons/Gerator/dashboard/avatar2@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.three-dots-admin-filter-dropdown-supporting-text84,
.three-dots-admin-filter-dropdown-text246 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}

.three-dots-admin-filter-dropdown-text246 {
  font-weight: 600;
}

.three-dots-admin-filter-dropdown-supporting-text84 {
  color: #475467;
  white-space: nowrap;
}

.three-dots-admin-filter-dropdown-text-and-supporting-text19 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.three-dots-admin-filter-dropdown-avatar-label-group,
.three-dots-admin-filter-dropdown-dropdown-list-header {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}

.three-dots-admin-filter-dropdown-avatar-label-group {
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.three-dots-admin-filter-dropdown-dropdown-list-header {
  background-color: #fff;
  border-bottom: 1px solid #F9FAFB;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 16px 10px;
}

.three-dots-admin-filter-dropdown-user-01-icon {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.three-dots-admin-filter-dropdown-text247 {
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  color: inherit;
}



.three-dots-admin-filter-dropdown-icon-and-text {
  flex: 1;
  gap: 8px;
}

.three-dots-admin-filter-dropdown-content48,
.three-dots-admin-filter-dropdown-dropdown-list-item,
.three-dots-admin-filter-dropdown-icon-and-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.three-dots-admin-filter-dropdown-content48 {
  flex: 1;
  border-radius: 6px;
  padding: 9px 10px;
}

.three-dots-admin-filter-dropdown-dropdown-list-item {
  align-self: stretch;
  background-color: #fff;
  padding: 1px 6px;
}

.three-dots-admin-filter-dropdown-dropdown-list-item:hover {
  background-color: #F9FAFB;
  cursor: pointer;
}

.three-dots-admin-filter-dropdown-divider-icon16 {
  height: 1px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
}

.three-dots-admin-filter-dropdown-dropdown-list-item10,
.three-dots-admin-filter-dropdown-dropdown-list-item3 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* padding: 4px 0; */
}

.three-dots-admin-filter-dropdown-dropdown-list-item10 {
  background-color: #fff;
  padding: 1px 6px;
  top: 0;
  /* z-index: 99; */
  position: sticky;
}

.three-dots-admin-filter-dropdown-dropdown-list-item10:hover {
  background-color: #F9FAFB;
  cursor: pointer;
}

.three-dots-admin-filter-dropdown-text256 {
  width: calc(100% - 16px);
  border: 0;
  outline: 0;
  font-weight: 500;
  font-family: Inter;
  font-size: 14px;
  background-color: transparent;
  height: 20px;
  flex: 1;
  position: relative;
  line-height: 20px;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 110px;
  padding: 0;
}

.three-dots-admin-filter-dropdown-dropdown-list-item11,
.three-dots-admin-filter-dropdown-dropdown-list-item12,
.three-dots-admin-filter-dropdown-dropdown-list-item13 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.three-dots-admin-filter-dropdown-dropdown-list-item13:hover {
  background-color: #F9FAFB;
  cursor: pointer;
}

.three-dots-admin-filter-dropdown-dropdown-list-item11 {
  background-color: #fff;
  padding: 1px 6px;
  z-index: 1;
}

.three-dots-admin-filter-dropdown-dropdown-list-item11:hover {
  background-color: #F9FAFB;
  cursor: pointer;
}

.three-dots-admin-filter-dropdown-dropdown-list-item12,
.three-dots-admin-filter-dropdown-dropdown-list-item13 {
  padding: 4px 0;
  z-index: 2;
}

.three-dots-admin-filter-dropdown-dropdown-list-item13 {
  background-color: #fff;
  padding: 1px 6px;
  z-index: 3;
}

.three-dots-admin-filter-dropdown-menu-items,
.three-dots-admin-filter-dropdown-three-dots-admin-filter-all-options-fixed {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: 14px;
  color: #344054 !important;
  font-family: Inter;

  /* z-index: 5; */
}

.three-dots-admin-filter-dropdown-menu-items {
  align-self: stretch;
  /* padding: 4px 0; */
}

.three-dots-admin-filter-dropdown-three-dots-admin-filter-all-options-fixed {
  /* width: 140; */
  width: max-content;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #F9FAFB;
  box-sizing: border-box;
  overflow: hidden;
  line-height: normal;
  letter-spacing: normal;
  right: 58px;
  position: absolute;
  top: 45px;
  z-index: 1000;

}