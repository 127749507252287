
/* Import the font */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
.chatB-frame-child,
.chatB-messaging-with-list-view-car-child {
  position: relative;
  background-color: #fff;
  display: none;
}
.chatB-messaging-with-list-view-car-child {
  align-self: stretch;
  height: 1906px;
}
.chatB-frame-child {
  height: 72px;
  width: 1440px;
  max-width: 100%;
}
.chatB-gerator-04-1-icon {
  height: 32px;
  width: 121px;
  position: relative;
  object-fit: cover;
  display: none;
}
.chatB-logo,
.chatB-logo-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.chatB-logo {
  align-self: stretch;
  height: 32px;
  flex-direction: row;
  background-image: url(../../icons/Gerator/chat_page/gerator04-1@2x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;
}
.chatB-logo-container {
  width: 121px;
  flex-direction: column;
  padding: 6px 0 0;
  box-sizing: border-box;
}
.chatB-bar-chart-01-icon,
.chatB-dot-icon {
  height: 10px;
  width: 10px;
  position: relative;
  display: none;
}
.chatB-bar-chart-01-icon {
  height: 24px;
  width: 24px;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}
.chatB-text {
  text-decoration: none;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 30px;
}
.chatB-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}
.chatB-text1 {
  align-self: stretch;
  position: relative;
  line-height: 18px;
  font-weight: 500;
}
.chatB-badge {
  height: 22px;
  border-radius: 9999px;
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 7px;
  text-align: center;
  font-size: 12px;
}
.chatB-chevron-down-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.chatB-nav-item-base {
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 12px;
  gap: 8px;
}
.chatB-text2 {
  text-decoration: none;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 29px;
}
.chatB-badge1,
.chatB-nav-item-base1 {
  background-color: #f9fafb;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.chatB-badge1 {
  height: 22px;
  border-radius: 9999px;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
  padding: 2px 7px;
  text-align: center;
  font-size: 12px;
  color: #344054;
}
.chatB-nav-item-base1 {
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  padding: 8px 12px;
  gap: 8px;
  color: #18222f;
}
.chatB-text4 {
  text-decoration: none;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 38px;
}
.chatB-navbar,
.chatB-navigation {
  display: flex;
  justify-content: flex-start;
}
.chatB-navigation {
  flex-direction: row;
  align-items: center;
  gap: 4px;
  z-index: 1;
}
.chatB-navbar {
  flex-direction: column;
  align-items: flex-start;
  padding: 2px 0 0;
}
.chatB-label {
  width: 47px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}
.chatB-search-lg-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.chatB-search-placeholder {
  text-decoration: none;
  flex: 1;
  position: relative;
  line-height: 24px;
  color: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chatB-input {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 13px;
  font-size: 16px;
  color: #667085;
}
.chatB-input-with-label,
.chatB-logo-container-parent,
.chatB-navbar-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.chatB-input-with-label {
  flex: 1;
  flex-direction: column;
  gap: 6px;
  z-index: 1;
  font-size: 14px;
}
.chatB-logo-container-parent,
.chatB-navbar-parent {
  flex-direction: row;
  max-width: 100%;
}
.chatB-navbar-parent {
  flex: 1;
  gap: 38px;
}
.chatB-logo-container-parent {
  width: 754px;
  gap: 70px;
}
.chatB-icon {
  height: 19px;
  width: 19px;
  position: relative;
}
.chatB-nav-item-button {
  width: 40px;
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  box-sizing: border-box;
  z-index: 1;
}
.chatB-nav-item-button-child {
  height: 18.3px;
  width: 14.6px;
  position: relative;
}
.chatB-profile-button1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 3px 0 0;
}
.chatB-contrast-border {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 9999px;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
  display: none;
}
.chatB-avatar {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 9999px;
  background-image: url(../../icons/Gerator/chat_page/avatar@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;
}
.chatB-rectangle-parent,
.chatB-user-actions {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
}
.chatB-rectangle-parent {
  align-self: stretch;
  background-color: #fff;
  justify-content: space-between;
  padding: 14px 64px 14px 31px;
  box-sizing: border-box;
  top: 0;
  z-index: 99;
  position: sticky;
  max-width: 100%;
  gap: 20px;
  text-align: left;
  font-size: 16px;
  color: #344054;
  font-family: Inter;
}
.chatB-divider-icon,
.chatB-home-line-icon {
  position: relative;
  overflow: hidden;
}
.chatB-divider-icon {
  align-self: stretch;
  max-width: 100%;
  max-height: 100%;
  z-index: 1;
}
.chatB-home-line-icon {
  height: 24px;
  width: 24px;
  flex-shrink: 0;
}
.chatB-nav-item-button2,
.chatB-nav-item-button3 {
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px;
}
.chatB-nav-item-button3 {
  background-color: #f9fafb;
}
.chatB-navigation2 {
  align-self: stretch;
  padding: 0 16px;
  gap: 8px;
}
.chatB-navigation1,
.chatB-navigation2,
.chatB-navigation3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.chatB-navigation1 {
  align-self: stretch;
  padding: 32px 0 0;
}
.chatB-navigation3 {
  gap: 8px;
}
.chatB-contrast-border1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 9999px;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
}
.chatB-avatar1 {
  width: 48px;
  height: 48px;
  position: relative;
  border-radius: 9999px;
  background-image: url(../../icons/Gerator/chat_page/avatar1@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.chatB-content4,
.chatB-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.chatB-footer {
  justify-content: flex-start;
  padding: 0 16px 24px;
  gap: 24px;
}
.chatB-content4 {
  align-self: stretch;
  flex: 1;
  border-right: 1px solid #eaecf0;
  justify-content: space-between;
}
.chatB-breadcrumb-button-base,
.chatB-sidebar-navigation {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.chatB-sidebar-navigation {
  height: 1260px;
  width: 80px;
  background-color: #fff;
  border-right: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.chatB-breadcrumb-button-base {
  border-radius: 6px;
  padding: 4px;
}
.chatB-chevron-right-icon {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.chatB-crumb-labels {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  min-width: 74px;
}
.chatB-breadcrumb-button-base1 {
  flex: 1;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}
.chatB-text6 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 500;
}
.chatB-breadcrumb-button-base2 {
  align-self: stretch;
  border-radius: 6px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 7px;
}
.chatB-chevron-right-icon2 {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.chatB-text7 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  white-space: nowrap;
}
.chatB-breadcrumb-button-base3 {
  align-self: stretch;
  border-radius: 6px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}
.chatB-text8 {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 75px;
}
.chatB-breadcrumb-button-base4,
.chatB-breadcrumbs,
.chatB-tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.chatB-breadcrumb-button-base4 {
  flex: 1;
  border-radius: 6px;
  background-color: #f9fafb;
  justify-content: center;
  padding: 4px 8px;
  color: #344054;
}
.chatB-breadcrumbs,
.chatB-tabs {
  justify-content: flex-start;
}
.chatB-tabs {
  flex: 1;
  gap: 8px;
}
.chatB-breadcrumbs {
  width: 273px;
}
.chatB-divider-icon1 {
  width: 273px;
  height: 1px;
  position: relative;
  display: none;
}
.chatB-page-header {
  align-self: stretch;
  background-color: #fff;
  flex-direction: column;
  gap: 20px;
}
.chatB-container,
.chatB-header-section,
.chatB-header-section-wrapper,
.chatB-page-header {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.chatB-container {
  align-self: stretch;
  flex-direction: column;
  padding: 0 32px;
}
.chatB-header-section,
.chatB-header-section-wrapper {
  max-width: 100%;
}
.chatB-header-section {
  width: 1078px;
  flex-direction: column;
  z-index: 1;
}
.chatB-header-section-wrapper {
  align-self: stretch;
  flex-direction: row;
}
.chatB-content-divider-child,
.chatB-frame-item {
  position: relative;
  border-radius: 12px;
}
.chatB-content-divider-child {
  align-self: stretch;
  height: 505px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
}
.chatB-frame-item {
  width: 148px;
  height: 148px;
  object-fit: cover;
  z-index: 2;
}
.chatB-rectangle-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 0 0;
}
.chatB-used {
  text-transform: lowercase;
}
.chatB-pre-owned-used-fully-refurbi {
  margin-block-start: 0;
  margin-block-end: 18px;
}
.chatB-ase-antibody-detection {
  margin: 0;
  text-transform: lowercase;
}
.chatB-detail-title {
  align-self: stretch;
  position: relative;
  line-height: 12px;
  font-weight: 600;
  z-index: 2;
}
.chatB-dot-icon3 {
  height: 8px;
  width: 8px;
  position: relative;
  display: none;
}
.chatB-text9 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 51px;
}
.chatB-badge3 {
  width: 69px;
  border-radius: 9999px;
  background-color: #fffdfd;
  border: 1px solid #da2d20;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 8px;
  gap: 6px;
  mix-blend-mode: normal;
  z-index: 2;
}
.chatB-supporting-text {
  position: relative;
  font-size: 16px;
  letter-spacing: -0.17px;
  line-height: 24px;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 83px;
  z-index: 2;
}
.chatB-info-badge {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}
.chatB-marker-icon {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 41px;
}
.chatB-marker-icon-wrapper {
  width: 50px;
  border-radius: 8px;
  background-color: #fdb022;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1px 4px;
  box-sizing: border-box;
  z-index: 2;
}
.chatB-supporting-text1 {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 117px;
  z-index: 2;
}
.chatB-frame-container,
.chatB-location-label {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.chatB-location-label {
  flex-direction: column;
  padding: 1px 0 0;
  color: #475467;
}
.chatB-frame-container {
  flex-direction: row;
  gap: 8px;
  text-align: left;
  font-size: 16px;
  color: #fff;
}
.chatB-text10 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
}
.chatB-badge4,
.chatB-badges {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.chatB-badge4 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  padding: 0 7px;
  white-space: nowrap;
  z-index: 2;
  color: #344054;
}
.chatB-badges {
  flex: 1;
  flex-direction: column;
  gap: 15px;
  min-width: 114px;
  color: #da2d20;
}
.chatB-marker-pin-02-icon {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 2;
}
.chatB-location {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 124px;
  z-index: 2;
}
.chatB-calendar-icon-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 7px;
}
.chatB-work-type {
  width: 61px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 61px;
}
.chatB-badge5 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 7px;
  white-space: nowrap;
  z-index: 2;
}
.chatB-text11 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 60px;
}
.chatB-badge6 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  justify-content: flex-start;
  padding: 0 7px;
  z-index: 2;
}
.chatB-badge6,
.chatB-work-badges,
.chatB-work-badges-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.chatB-work-badges {
  flex: 1;
  justify-content: flex-start;
  gap: 8px;
}
.chatB-work-badges-wrapper {
  align-self: stretch;
  justify-content: flex-end;
  padding: 0 2px 0 0;
  text-align: center;
  font-size: 14px;
  color: #344054;
}
.chatB-frame-div {
  width: 163px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 56px;
  text-align: left;
  font-size: 16px;
}
.chatB-type-of-work {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 109px;
  z-index: 2;
}
.chatB-price-tag,
.chatB-price-tag-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.chatB-price-tag {
  gap: 8px;
}
.chatB-price-tag-wrapper {
  padding: 0 14px;
}
.chatB-cost-label {
  width: 149px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
}
.chatB-badge7,
.chatB-frame-parent1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.chatB-badge7 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  padding: 0 7px;
  white-space: nowrap;
  z-index: 2;
  text-align: center;
  font-size: 14px;
  color: #344054;
}
.chatB-frame-parent1 {
  flex: 1;
  flex-direction: column;
  gap: 56px;
  min-width: 107px;
  text-align: left;
  font-size: 16px;
}
.chatB-status-label {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 77px;
}
.chatB-badge8,
.chatB-badges-parent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.chatB-badge8 {
  width: 93px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  align-items: flex-start;
  padding: 0 7px;
  z-index: 2;
  color: #344054;
}
.chatB-badges-parent {
  width: 615px;
  align-items: flex-end;
  gap: 6.3px;
  max-width: 100%;
  text-align: center;
  font-size: 14px;
  color: #475467;
}
.chatB-content-container-inner,
.chatB-detail-title-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.chatB-detail-title-parent {
  align-self: stretch;
  gap: 4px;
}
.chatB-content-container-inner {
  flex: 1;
  padding: 10px 0 0;
  box-sizing: border-box;
  min-width: 575px;
}
.chatB-filter-label {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 66px;
}
.chatB-badge-wrapper,
.chatB-badge9 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.chatB-badge9 {
  align-self: stretch;
  border-radius: 6px;
  background-color: #416ec6;
  flex-direction: row;
  padding: 2px 8px;
  gap: 6px;
  mix-blend-mode: normal;
  z-index: 2;
}
.chatB-badge-wrapper {
  flex: 1;
  flex-direction: column;
  padding: 14px 0 0;
}
.chatB-dots-vertical-icon {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.chatB-dropdown,
.chatB-filter-dropdown {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.chatB-dropdown {
  flex-direction: column;
  z-index: 2;
}
.chatB-filter-dropdown {
  width: 123px;
  flex-direction: row;
  gap: 19px;
}
.chatB-price {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 32px;
  font-weight: 600;
  white-space: nowrap;
  z-index: 2;
}
.chatB-price-value {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 83px;
  z-index: 3;
}
.chatB-magnifier-1-icon {
  height: 20px;
  width: 20px;
  position: relative;
  object-fit: cover;
  min-height: 20px;
}
.chatB-price-action-icons {
  flex: 1;
  flex-direction: row;
  gap: 8px;
  z-index: 2;
}
.chatB-price-action-icons,
.chatB-price-actions,
.chatB-price-amount {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.chatB-price-actions {
  align-self: stretch;
  flex-direction: row;
  padding: 0 4px 0 3px;
}
.chatB-price-amount {
  flex: 1;
  flex-direction: column;
  gap: 4px;
}
.chatB-price-currency {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 30px;
  font-size: 12px;
  color: #475467;
}
.chatB-frame-wrapper,
.chatB-price-currency,
.chatB-price-parent {
  display: flex;
  align-items: flex-start;
}
.chatB-price-parent {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
}
.chatB-frame-wrapper {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 10px 0 0;
  font-size: 20px;
  color: #6941c6;
}
.chatB-filter-dropdown-parent {
  width: 153px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 8px;
  text-align: center;
  font-size: 14px;
  color: #fff;
}
.chatB-content-container,
.chatB-frame-group {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.chatB-content-container {
  flex: 1;
  gap: 50px;
  min-width: 707px;
}
.chatB-frame-group {
  width: 1260px;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 24px;
}
.chatB-divider-icon2 {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 2;
}
.chatB-heading,
.chatB-number {
  position: relative;
  font-weight: 600;
}
.chatB-heading {
  width: 254px;
  line-height: 24px;
  display: inline-block;
}
.chatB-number {
  align-self: stretch;
  line-height: 38px;
}
.chatB-arrow-up-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 20px;
}
.chatB-change1 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 29px;
}
.chatB-change {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1px;
}
.chatB-text12 {
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  color: #475467;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chatB-change-and-text,
.chatB-number-and-badge {
  display: flex;
  justify-content: flex-start;
}
.chatB-change-and-text {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  text-align: center;
  font-size: 14px;
  color: #079455;
}
.chatB-number-and-badge {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.chatB-chart-mini-icon {
  height: 48px;
  width: 96px;
  position: relative;
  object-fit: contain;
}
.chatB-number-and-chart {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 16px;
  font-size: 30px;
}
.chatB-dropdown1,
.chatB-metric-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.chatB-dropdown1 {
  margin: 0 !important;
  position: absolute;
  top: 16px;
  right: 16px;
}
.chatB-metric-item {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 18px 15px;
  position: relative;
  gap: 20px;
  min-width: 229px;
  max-width: 306px;
  z-index: 2;
}
.chatB-heading1 {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.chatB-change3,
.chatB-change5,
.chatB-change7 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 38px;
}
.chatB-change5,
.chatB-change7 {
  min-width: 28px;
}
.chatB-change7 {
  min-width: 23px;
}
.chatB-metric-item-parent {
  width: 1260px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 12px;
  max-width: 100%;
  font-size: 16px;
}
.chatB-eye-icon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}
.chatB-eye-label {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 34px;
}
.chatB-buttonsbutton {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 6px 12px;
  background-color: #fff;
  width: 90px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  z-index: 2;
}
.chatB-icon1 {
  width: 16.7px;
  height: 16.7px;
  position: relative;
}
.chatB-icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 3.7px 0 0;
}
.chatB-button-label {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 56px;
}
.chatB-buttonsbutton1,
.chatB-buttonsbutton2 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6px 10px 6px 12px;
  min-width: 92px;
  z-index: 2;
}
.chatB-buttonsbutton1 {
  overflow: hidden;
  gap: 5.5px;
}
.chatB-buttonsbutton2 {
  overflow-x: auto;
  gap: 5.4px;
}
.chatB-message-button-label {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 70px;
}
.chatB-notification-badge-background {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: #17b26a;
  width: 17px;
  height: 17px;
  z-index: 3;
}
.chatB-notification-badge-count {
  position: absolute;
  top: 4px;
  left: 6px;
  line-height: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 7px;
  height: 20px;
  min-width: 7px;
  z-index: 4;
}
.chatB-notification-badge-background-parent {
  height: 24px;
  width: 17px;
  position: absolute;
  margin: 0 !important;
  top: -9px;
  right: -2.7px;
  font-size: 10px;
  color: #fff;
}
.chatB-buttonsbutton-parent,
.chatB-buttonsbutton3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.chatB-buttonsbutton3 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  padding: 8px 12px;
  position: relative;
  gap: 6px;
  min-width: 79px;
  z-index: 2;
}
.chatB-buttonsbutton-parent {
  width: 523.3px;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 16px;
  max-width: calc(100% - 44px);
}
.chatB-arrow-square-up-right-icon {
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 2;
}
.chatB-arrow-square-up-right-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 0 0;
}
.chatB-content-divider,
.chatB-frame-parent2 {
  width: 1260px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  max-width: 100%;
  font-size: 14px;
  color: #344054;
}
.chatB-content-divider {
  width: 1306px;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  padding: 12px 0 22px 23px;
  gap: 23.8px;
  z-index: 1;
  font-size: 18px;
  color: #101828;
  align-self: center;
}
.chatB-chat-name-label,
.chatB-text17 {
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  font-family: Inter;
  color: #101828;
  text-align: left;
  display: inline-block;
  /* min-width: 89px; */
}
.chatB-text17 {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #344054;
  text-align: center;
  min-width: 16px;
}
.chatB-badge10,
.chatB-text-and-badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.chatB-badge10 {
  width: 28px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  padding: 0 5px;
}
.chatB-text-and-badge {
  align-self: stretch;
  padding: 0 131px 0 0;
  gap: 8px;
}
.chatB-supporting-text2 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chatB-text-and-supporting-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 12px 0;
  box-sizing: border-box;
  gap: 4px;
  min-width: 166px;
}
.chatB-placeholder-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 20px;
}
.chatB-text18 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #475467;
  text-align: left;
}
.chatB-text-padding {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}
.chatB-button,
.chatB-text-padding,
.chatB-text19 {
  align-self: stretch;
}
.chatB-button {
  border-radius: 8px;
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 14px;
  gap: 4px;
}
.chatB-text19 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #6941c6;
  text-align: left;
}
.chatB-button1,
.chatB-button2 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.chatB-button1 {
  align-self: stretch;
  border: 1px solid #d6bbfb;
  display: none;
  padding: 10px 13px;
  gap: 4px;
}
.chatB-button2 {
  width: 40px;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  padding: 8px 9px;
}
.chatB-button3,
.chatB-text20 {
  align-self: stretch;
}
.chatB-text20 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
}
.chatB-button3 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #7e56d9;
  border: 1px solid #7e56d9;
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 13px;
  gap: 4px;
}
.chatB-content5,
.chatB-dropdown5 {
  align-items: flex-start;
  justify-content: flex-start;
}
.chatB-dropdown5 {
  display: none;
  flex-direction: column;
}
.chatB-content5 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: 20px 36px;
  gap: 16px;
}
.chatB-divider-icon4 {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  display: none;
  z-index: 0;
}
.chatB-card-header {
  align-self: stretch;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  flex-shrink: 0;
}
.chatB-label1,
.chatB-search-placeholder1 {
  font-family: Inter;
  position: relative;
  text-align: left;
}
.chatB-label1 {
  width: 47px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #344054;
  display: none;
}
.chatB-search-placeholder1 {
  width: calc(100% - 20px);
  border: 0;
  outline: 0;
  font-size: 16px;
  background-color: transparent;
  height: 24px;
  flex: 1;
  line-height: 24px;
  color: #667085;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 163px;
  padding: 0;
}
.chatB-input-with-label1,
.chatB-input1 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.chatB-input1 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
}
.chatB-input-with-label1 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}
.chatB-hint-text {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: none;
}
.chatB-input-dropdown,
.chatB-search-wrap {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.chatB-input-dropdown {
  gap: 8px;
  width: 95%;
}
.chatB-search-wrap {
  padding: 0 0 12px 30px;
  flex-shrink: 0;
}
.chatB-dot {
  width: 8px;
  height: 8px;
  position: relative;
  border-radius: 5px;
  background-color: #9e77ed;
  overflow: hidden;
  flex-shrink: 0;
}
.chatB-dot-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 0 0;
}
.chatB-contrast-border2 {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 9999px;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
}
.chatB-avatar-online-indicator,
.chatB-avatar2 {
  border-radius: 9999px;
  box-sizing: border-box;
  position: relative;
}
.chatB-avatar-online-indicator {
  height: 13px;
  width: 13px;
  background-color: #17b26a;
  border: 1.5px solid #fff;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.chatB-avatar2 {
  height: 40px;
  width: 40px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
/* padding: 30px 0 0 27px; */
  background-image: url(../../icons/Gerator/chat_page/avatar@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.chatB-message-sender-name,
.chatB-supporting-text3 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  text-align: left;
  display: inline-block;
}
.chatB-message-sender-name {
  font-weight: 600;
  color: #344054;
  min-width: 97px;
max-width: 102px;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
display: flex;
flex-direction: column;
}
.chatB-supporting-text3 {
  color: #475467;
  min-width: 80px;

}
.chatB-avatar-and-dot,
.chatB-avatar-label-group,
.chatB-text-and-supporting-text1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 10px 0 0;
}
.chatB-avatar-and-dot,
.chatB-avatar-label-group {
  flex-direction: row;
  gap: 12px;
}
.chatB-avatar-and-dot {
  flex: 1;
  min-width: 157px;
}
.chatB-supporting-text4 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 62px;
}
.chatB-avatar-and-time {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
}
.chatB-message-preview {
  flex: 1;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  max-width: 100%;
overflow: hidden; text-overflow: ellipsis; white-space: normal;
display: -webkit-box;
-webkit-Line-clamp: 2; /* Number of lines before ellipsis */
-webkit-box-orient: vertical;
}

.chatB-message,
.chatB-message-preview-wrap {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 16px 16px 0;
}
.chatB-message {
  border-bottom: 1px solid #eaecf0;
  flex-direction: column;
  padding: 16px 16px 14px;
  gap: 16px;
  flex-shrink: 0;
}
.chatB-dot1 {
  width: 8px;
  height: 8px;
  position: relative;
  border-radius: 5px;
  background-color: #9e77ed;
  overflow: hidden;
  flex-shrink: 0;
  opacity: 0;
}
.chatB-supporting-text5,
.chatB-supporting-text6,
.chatB-text21 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  text-align: left;
  display: inline-block;
}
.chatB-text21 {
  font-weight: 600;
  color: #344054;
  min-width: 106px;
  white-space: nowrap;
}
.chatB-supporting-text5,
.chatB-supporting-text6 {
  color: #475467;
  min-width: 50px;
}
.chatB-supporting-text6 {
  min-width: 71px;
}
.chatB-you {
  font-weight: 500;
}
.chatB-sure-thing-ill,
.chatB-you {
  font-family: Inter;
}
.chatB-message-preview1 {
  flex: 1;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  text-align: left;
}
.chatB-message1 {
  align-self: stretch;
  background-color: #fcfcfd;
  border-bottom: 1px solid #eaecf0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 16px 14px;
  gap: 16px;
  flex-shrink: 0;
}
.chatB-supporting-text7,
.chatB-text22 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  text-align: left;
  display: inline-block;
}
.chatB-text22 {
  font-weight: 600;
  color: #344054;
  min-width: 70px;
  white-space: nowrap;
}
.chatB-supporting-text7 {
  color: #475467;
  min-width: 53px;
}
.chatB-avatar-and-dot2 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  min-width: 130px;
}
.chatB-supporting-text8 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 49px;
}
.chatB-avatar-and-time2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}
.chatB-avatar-online-indicator3 {
  height: 13px;
  width: 13px;
  position: relative;
  border-radius: 9999px;
  background-color: #d0d5dd;
  border: 1.5px solid #fff;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.chatB-text23 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 102px;
  white-space: nowrap;
}
.chatB-avatar-and-dot3 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  min-width: 162px;
}
.chatB-supporting-text10 {
  color: #475467;
  min-width: 51px;
}
.chatB-supporting-text10,
.chatB-supporting-text11,
.chatB-text24 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  text-align: left;
  display: inline-block;
}
.chatB-text24 {
  font-weight: 600;
  color: #344054;
  min-width: 105px;
}
.chatB-supporting-text11 {
  color: #475467;
  min-width: 58px;
}
.chatB-avatar-and-dot4 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  min-width: 165px;
}
.chatB-text25 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 99px;
}
.chatB-avatar-and-dot5 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  min-width: 159px;
}
.chatB-supporting-text14 {
  color: #475467;
  min-width: 52px;
}
.chatB-supporting-text14,
.chatB-supporting-text15,
.chatB-text26 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  text-align: left;
  display: inline-block;
}
.chatB-text26 {
  font-weight: 600;
  color: #344054;
  min-width: 63px;
  white-space: nowrap;
}
.chatB-supporting-text15 {
  color: #475467;
  min-width: 38px;
}
.chatB-avatar-and-dot6 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  min-width: 123px;
}
.chatB-message-preview6 {
  align-self: stretch;
  flex: 1;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
}
.chatB-message-preview-wrap6,
.chatB-side-panel {
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.chatB-message-preview-wrap6 {
  align-self: stretch;
  height: 40px;
  flex-direction: row;
  padding: 0 0 0 20px;
}
.chatB-side-panel {
  height: 960px;
  width: 30%;
  border-right: 1px solid #eaecf0;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  padding: 20px;
  /* min-width: 360px;
  max-width: 360px; */
  z-index: 1;
}
.chatB-avatar-icon {
  height: 56px;
  width: 56px;
  position: relative;
  border-radius: 9999px;
}
.chatB-caller-name {
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  font-family: Inter;
  color: #101828;
  text-align: left;
}
.chatB-dot-icon5 {
  height: 8px;
  width: 8px;
  position: relative;
}
.chatB-text27 {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Inter;
  color: #344054;
  text-align: center;
  display: inline-block;
  min-width: 37px;
}
.chatB-badge11,
.chatB-text-and-badge1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.chatB-badge11 {
  width: 61px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  padding: 0 5px;
  gap: 4px;
}
.chatB-text-and-badge1 {
  align-self: stretch;
  padding: 0 217px 0 0;
  gap: 8px;
}
.chatB-supporting-text17 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chatB-avatar-and-text,
.chatB-text-and-supporting-text8 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 275px;
  max-width: 100%;
}
.chatB-avatar-and-text {
  flex-direction: row;
  gap: 16px;
  min-width: 322px;
}
.chatB-text29 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #6941c6;
  text-align: left;
  display: inline-block;
  min-width: 26px;
}
button5.chatB-,
.chatB-text-padding4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}
.chatB-button5 {
  cursor: pointer;
  border: 1px solid #d6bbfb;
  padding: 8px 13px;
  background-color: #fff;
  width: 82px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  gap: 4px;
}

.chatB-button5:hover{
  background-color: #eaecf0;
}
.chatB-call-button-label {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 40px;
}
.chatB-button6 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 13px;
  background-color: #fff;
  width: 96px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.chatB-button6:hover{
  background-color: #eaecf0;
}
.chatB-text30 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 93px;
}
.chatB-actions1,
.chatB-button7,
.chatB-content7 {
  display: flex;
  flex-direction: row;
}
.chatB-button7 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 13px;
  background-color: #7e56d9;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.chatB-button7:hover{
  background-color: #eaecf0;
}
.chatB-actions1,
.chatB-content7 {
  justify-content: flex-start;
}
.chatB-actions1 {
  align-items: center;
  gap: 12px;
  max-width: 100%;
}
.chatB-content7 {
  align-items: flex-start;
  padding: 20px 24px 0;
  box-sizing: border-box;
  gap: 16px;
}
.chatB-card-header1,
.chatB-content7 {
  align-self: stretch;
  max-width: 100%;
}
.chatB-divider-icon5 {
  position: relative;
  overflow: hidden;
  max-height: 100%;
  max-width: 100%;
}
.chatB-card-header1 {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  z-index: 2;
}
.chatB-divider {
  align-self: stretch;
  height: 1px;
  position: relative;
  background-color: #eaecf0;
}
.chatB-divider-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 9.5px 0 0;
  box-sizing: border-box;
  min-width: 266px;
  max-width: 100%;
}
.chatB-divider-label {
  height: 20px;
  width: 64px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #475467;
  text-align: center;
  display: inline-block;
}
.chatB-content-divider1 {
  margin-top: -159px;
  width: 898px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  flex-shrink: 0;
  max-width: 100%;
}
.chatB-sub-text-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chatB-sub-text-wrapper {
  width: 104px;
  flex-direction: column;
  padding: 1px 0 0;
  box-sizing: border-box;
}

/* HERE */
/* Parent container for receiver's message */
.chatB-content8 {
  display: inline-flex; /* Allow the container to shrink or grow with content */
  flex-direction: column; /* Stack username/timestamp and message bubble vertically */
  gap: 6px; /* Add spacing between elements */
  max-width: 80%; /* Prevent the bubble from getting too wide */
  width: fit-content; /* Adjust dynamically to content */
  box-sizing: border-box; /* Include padding in width calculation */
  word-wrap: break-word; /* Wrap long words properly */
  overflow: hidden; /* Prevent content from overflowing */
}

/* Username and timestamp container */
.chatB-name-and-time {
  display: flex; /* Flexbox for layout */
  justify-content: space-between; /* Space out username and timestamp */
  align-items: center; /* Vertically align items */
  width: 100%; /* Match parent width */
  /* padding: 0 13px; Consistent padding with message bubble */
  box-sizing: border-box; /* Include padding in width */
  word-wrap: break-word; /* Wrap long usernames or timestamps */
}

/* Username styling */
.chatB-usernames {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #344054;
  overflow: hidden; /* Prevent overflowing text */
  text-overflow: ellipsis; /* Show ellipsis for long text */
  white-space: nowrap; /* Keep text on one line */
}

/* Timestamp styling */
.chatB-sub-text {
  font-size: 10px;
  /* line-height: 18px; */
  color: #475467;
  text-align: end; /* Align timestamp text to the left */
  padding-left: 12px;
}

/* Message bubble for receiver */
.chatB-input2 {
  display: inline-flex; /* Allow dynamic resizing to content */
  flex-direction: column; /* Stack content inside */
  background-color: #f9fafb; /* Light gray background for received messages */
  border: 1px solid #eaecf0; /* Border for received messages */
  border-radius: 8px 8px 8px 8px; /* Rounded corners for bubble */
  padding: 8px 13px; /* Inner spacing for message content */
  width: fit-content; /* Dynamically adjust to message length */
  max-width: 100%; /* Prevent exceeding parent width */
  box-sizing: border-box; /* Include padding in width calculation */
  word-wrap: break-word; /* Ensure long words wrap properly */
  overflow: hidden; /* Prevent overflow */
}

/* Row for each receiver message */
.chatB-message12 {
  display: flex; /* Flexbox layout */
  flex-direction: row; /* Align avatar and message horizontally */
  justify-content: flex-start; /* Align received messages to the left */
  align-items: flex-start; /* Align items to the top */
  gap: 12px; /* Space between avatar and message bubble */
  width: 100%; /* Take full width of container */
  margin-bottom: 10px; /* Add spacing between messages */
  flex-wrap: nowrap; /* Prevent wrapping unless necessary */
}

/* Avatar adjustments for receiver */
.chatB-avatar2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px; /* Size of avatar */
  height: 40px; /* Make it a square */
  border-radius: 50%; /* Make avatar circular */
  background-color: #dcdcdc; /* Placeholder color for avatar */
}

/* Responsive adjustments for smaller screens */
@media screen and (max-width: 750px) {
  .chatB-content8 {
    max-width: 90%; /* Allow more space for smaller screens */
  }

  .chatB-message12 {
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
  }
}


/* TO HERE */

.chatB-message7 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chatB-message7 {
  width: 100%;
  flex-direction: row;
  gap: 12px;
  max-width: 560px;
}
.chatB-sub-text1 {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 104px;
  white-space: nowrap;
}
.chatB-sub-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1px 0 0;
}
.chatB-file-username,
.chatB-text33 {
  flex: 1;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-family: Inter;
  color: #101828;
  text-align: left;
  display: inline-block;
  max-width: 100%;
}
.chatB-file-username {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #344054;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 82px;
}
.chatB-name-and-time2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  max-width: 100%;
  flex-shrink: 0;
}
.chatB-page-icon {
  position: absolute;
  height: 100%;
  width: 80%;
  top: 0;
  right: 2.5%;
  bottom: 0;
  left: 17.5%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.chatB-file-type {
  position: relative;
  font-size: 10px;
  display: inline-block;
  font-family: Inter;
  color: #fff;
  text-align: center;
  min-width: 20px;
}
.chatB-file-type-wrap {
  position: absolute;
  height: 40%;
  width: 65%;
  top: 45%;
  right: 32.5%;
  bottom: 15%;
  left: 2.5%;
  border-radius: 2px;
  background-color: #da2d20;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 3px;
  box-sizing: border-box;
  z-index: 1;
}
.chatB-file-type-icon {
  height: 40px;
  width: 40px;
  position: relative;
}
.chatB-supporting-text18,
.chatB-text34 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  text-align: left;
}
.chatB-text34 {
  font-weight: 500;
  color: #344054;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chatB-supporting-text18 {
  color: #475467;
}
.chatB-text-and-supporting-text9 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.chatB-content11,
.chatB-content12 {
  flex: 1;
  flex-direction: row;
}
.chatB-content12 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 278px;
  max-width: 100%;
}
.chatB-content11 {
  gap: 12px;
}
.chatB-content10,
.chatB-content11,
.chatB-input4 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.chatB-input4 {
  align-self: stretch;
  border-radius: 0 8px 8px 8px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: row;
  padding: 10px 13px;
  min-height: 62px;
  flex-shrink: 0;
}
.chatB-content10 {
  height: 86px;
  flex: 1;
  flex-direction: column;
  gap: 6px;
  min-width: 330px;
}
.chatB-text35 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-family: Inter;
  color: #fff;
  text-align: left;
}
.chatB-receiever {
  height: 48px;
  flex: 1;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-family: Inter;
  color: black;
  text-align: left;
  display: inline-block;
  max-width: 100%;
}
.chatB-text31 {
  height: 48px;
  flex: 1;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-family: Inter;
  color: white;
  text-align: left;
  display: inline-block;
  max-width: 100%;
}
/* HERE */
/* Shared styles for message elements */
.chatB-single-message-username,
.chatB-sub-text3 {
  position: relative;
  font-family: "Inter", Arial, sans-serif; /* Apply imported font */
  display: inline-block;
  white-space: nowrap;
}

.chatB-single-message-username {
  flex: 1; /* Let "You" take up available space */
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #344054;
  text-align: left; /* Align username to the left */
}

.chatB-sub-text3 {
  flex-shrink: 0; /* Prevent shrinking */
  font-size: 12px;
  /* line-height: 18px; */
  color: #ffff;
  text-align: right; /* Align timestamp to the right */
  padding-left: 12px;
}

/* Parent container for each message */
.chatB-content13 {
  display: inline-flex; /* Allows dynamic resizing based on children */
  flex-direction: column; /* Stack name/time and bubble vertically */
  max-width: 55%; /* Restrict to reasonable width on larger screens */
  width: fit-content; /* Dynamically adjust width to content */
  gap: 4px; /* Space between name/time and bubble */
  word-wrap: break-word; /* Handle long messages */
  box-sizing: border-box; /* Include padding in width calculation */
}

/* Username and timestamp row */
.chatB-name-and-time3 {
  display: flex; /* Use Flexbox for layout */
  justify-content: space-between; /* Space out username and timestamp */
  align-items: center; /* Vertically align content */
  width: 100%; /* Inherit width from parent container (chat-content13) */
  padding: 0 8px; /* Add padding for alignment */
  box-sizing: border-box; /* Include padding in width */
}

/* Message bubble */
.chatB-input5 {
  display: inline-flex; /* Allow dynamic resizing to content */
  flex-direction: column; /* Stack content inside */
  background-color: #7e56d9; /* Purple background for sent messages */
  border-radius: 8px; /* Rounded corners */
  padding: 8px 13px; /* Inner spacing for message content */
  width: fit-content; /* Adjust dynamically to the content size */
  max-width: 100%; /* Prevent exceeding parent width */
  box-sizing: border-box; /* Include padding in width calculation */
  word-wrap: break-word; /* Handle long words properly */
  color: white; /* Text color for contrast */
}

/* Row for each message */
.chatB-row {
  display: flex;
  flex-direction: row; /* Align name/time and message bubble horizontally */
  justify-content: flex-end; /* Align sent messages to the right */
  width: 100%; /* Take full width of the container */
  margin-bottom: 10px; /* Space between rows */
  padding-right: 30px;
}

/* Responsive adjustments for small screens */
@media screen and (max-width: 750px) {
  .chatB-row {
    flex-wrap: wrap; /* Allow wrapping of elements */
    max-width: 100%; /* Ensure full width usage */
  }

  .chatB-name-and-time3,
  .chatB-input5 {
    max-width: 100%; /* Ensure bubbles and content adjust to screen width */
  }
}

/* TO HERE */

/* .chatB-input5 {
  align-self: self-end;
  border-radius: 8px 8px 8px 8px;
  background-color: #7e56d9;
  border: 1px solid #7e56d9;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: column;
  padding: 8px 13px;
  max-width: 100%;
}
.chatB-content13,
.chatB-input5,
.chatB-row {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.chatB-content13{
  flex: 1;
  flex-direction: column;
  gap: 6px;
  min-width: 330px;
  max-width: 100%;
}

.chatB-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 12px;
  max-width: 560px;
}
.chatB-row {
  align-self: stretch;
  max-width: 100%;
  display: flex;
  align-items: flex-start;

  flex-direction: row;
  justify-content: flex-end;

  align-self: stretch;
  max-width: 100%;
}

@media screen and (width <= 750px) {
  .chatB-row {
      flex-wrap: wrap;
      max-width: 100%;
  }
} */
/* .chatB-input5 {
  border-radius: 8px 0 8px 8px;
  background-color: #7e56d9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 14px;
  white-space: nowrap;
}
.chatB-content13,
.chatB-message10,
.chatB-row {
  display: flex;
  align-items: flex-start;
}
.chatB-content13 {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: 6px;
  max-width: 100%;
}
.chatB-message10,
.chatB-row {
  flex-direction: row;
  justify-content: flex-end;
}
.chatB-message10 {
  width: 330px;
  max-width: 330px;
}
.chatB-row {
  align-self: stretch;
  max-width: 100%;
} */
.chatB-avatar-message,
.chatB-divider-frame,
.chatB-message11 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.chatB-message11 {
  flex: 1;
  flex-direction: row;
  gap: 12px;
  max-width: 560px;
}
.chatB-avatar-message,
.chatB-divider-frame {
  box-sizing: border-box;
  max-width: 100%;
}
.chatB-avatar-message {
  width: 560px;
  flex-direction: row;
  padding: 0 0 8px;
}
.chatB-divider-frame {
  flex: 1;
  flex-direction: column;
  padding: 9.5px 0 0;
  min-width: 273px;
}
.chatB-second-divider-label {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #475467;
  text-align: center;
  display: inline-block;
  min-width: 41px;
}
.chatB-content-divider2,
.chatB-second-content-divider {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.chatB-content-divider2 {
  flex: 1;
  gap: 8px;
}
.chatB-second-content-divider {
  align-self: stretch;
  padding: 0 0 8px;
  box-sizing: border-box;
}
.chatB-sub-text5 {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 82px;
}

.chatB-reaction-username,
.chatB-sub-text6 {
  position: relative;
  font-family: Inter;
  text-align: left;
  display: inline-block;
}
.chatB-reaction-username {
  flex: 1;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #344054;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 19px;
  max-width: 100%;
}
.chatB-sub-text6 {
  font-size: 12px;
  line-height: 18px;
  color: #475467;
  min-width: 52px;
}
.chatB-name-and-time6 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  max-width: 100%;
}
.chatB-emoji-icon {
  height: 16px;
  width: 16px;
  position: relative;
  object-fit: cover;
}
.chatB-message-reaction {
  border-radius: 9999px;
  background-color: #f9fafb;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}
.chatB-message13,
.chatB-row2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.chatB-row2 {
  align-items: center;
  padding: 0 0 0 374px;
  gap: 4px;
}
.chatB-message13 {
  width: 442px;
  align-items: flex-start;
  max-width: 442px;
}
.chatB-loading-dot {
  height: 4px;
  width: 4px;
  position: relative;
  border-radius: 50%;
  background-color: #475467;
}
.chatB-loading-dot-frame {
  height: 8px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}
.chatB-loading-dot1 {
  height: 4px;
  width: 4px;
  position: relative;
  border-radius: 50%;
  background-color: #98a2b3;
}
.chatB-loading-dot-frame1 {
  height: 8px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.chatB-input9,
.chatB-messages {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.chatB-input9 {
  width: 40px;
  border-radius: 0 8px 8px 8px;
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow-x: auto;
  flex-direction: row;
  padding: 8px 9px;
  gap: 4px;
}
.chatB-messages {
  flex: 1;
  flex-direction: column;
  gap: 16px;
  min-width: 584px;
  max-width: 100%;
}
.chatB-message-separator {
  width: 8px;
  flex: 1;
  position: relative;
  border-radius: 8px;
  background-color: #dadada;
  z-index: 4;
}
.chatB-separator {
  height: 331px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 151px 0 0;
  box-sizing: border-box;
}
.chatB-container1,
.chatB-messages-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;
  flex-shrink: 0;
  max-width: 100%;
}
.chatB-container1 {
  /* height: 735px; */
  max-height: 735px;
  overflow-y: scroll;
  scrollbar-width: none;
  overflow-y: auto;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 24px 24px;
  box-sizing: border-box;
  gap: 24px;
  z-index: 3;
}
.chatB-label2,
.chatB-message-placeholder {
  font-family: Inter;
  position: relative;
  text-align: left;
}
.chatB-label2 {
  width: 77px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #344054;
  display: none;
}
.chatB-message-placeholder {
  width: 100%;
  border: 0;
  outline: 0;
  font-size: 16px;
  background-color: transparent;
  height: 80px;
  flex: 1;
  line-height: 24px;
  color: #667085;
  display: inline-block;
  min-width: 250px;
  max-width: 100%;
  padding: 0;
}
.chatB-input10 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 13px;
  max-width: 100%;
}
.chatB-hint-text1 {
  width: 320px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: none;
}
.chatB-textarea-input-field {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  max-width: 100%;
}
.chatB-buttonsbutton4,
.chatB-icons {
  display: flex;
  flex-direction: row;
}
.chatB-buttonsbutton4 {
  border-radius: 8px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 8px;
}
.chatB-icons {
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
}
.chatB-send-label {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 35px;
}
.chatB-buttonsbutton6 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 13px;
  background-color: #7e56d9;
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.chatB-actions2,
.chatB-buttonsbutton6,
.chatB-input-actions {
  display: flex;
  flex-direction: row;
}
.chatB-actions2 {
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  z-index: 1;
}
.chatB-input-actions {
  width: 173px;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 12px;
  box-sizing: border-box;
  margin-top: -52px;
}
.chatB-card-header-parent,
.chatB-message-input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
}
.chatB-message-input {
  align-self: stretch;
  height: 128px;
  background-color: #fff;
  align-items: flex-end;
  padding: 0 24px 24px;
  box-sizing: border-box;
  z-index: 4;
}
.chatB-card-header-parent {
  flex: 1;
  align-items: flex-start;
  min-width: 615px;
}
.chatB-buttonsbutton7,
.chatB-chat-content {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
}
.chatB-chat-content {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 20px;
  max-width: 100%;
}
.chatB-buttonsbutton7 {
  width: 36px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 6px 7px;
}
.chatB-details {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 81px;
}
.chatB-chat-panel,
.chatB-pagination {
  align-items: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.chatB-pagination {
  width: 360px;
  border-top: 1px solid #eaecf0;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 16px;
  gap: 20px;
  z-index: 1;
}
.chatB-chat-panel {
  margin: 0;
  align-self: stretch;
  /* padding: 0 0 0 24px; MESSAGES NEW */
  gap: 16px;
}
.chatB-chat-panel,
.chatB-main-content,
.chatB-main-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.chatB-main-content {
  align-self: stretch;
  align-items: flex-end;
  gap: 24px;
  max-width: 100%;
}
.chatB-main-content-wrapper {
  flex: 1;
  align-items: flex-start;
  padding: 32px 0 0;
  box-sizing: border-box;
  max-width: calc(100% - 80px);
}
.chatB-frame-parent,
.chatB-sidebar-navigation-parent {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.chatB-sidebar-navigation-parent {
  flex-direction: row;
  padding: 0 30px 0 0;
  box-sizing: border-box;
  text-align: left;
  font-size: 14px;
  color: #475467;
  font-family: Inter;
}
.chatB-frame-parent {
  flex-direction: column;
  gap: 0.5px;
}
.chatB-footer-text {
  position: relative;
  line-height: 20px;
}
.chatB-footer-text-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.chatB-gerator-10-2-icon {
  height: 38px;
  width: 38px;
  position: relative;
  object-fit: cover;
}
.chatB-logomark1 {
  box-shadow: 0 1.3px 3.75px rgba(16, 24, 40, 0.1),
    0 1.3px 2.5px rgba(16, 24, 40, 0.06);
  align-items: flex-start;
  justify-content: flex-start;
}
.chatB-frame-parent3,
.chatB-logomark,
.chatB-logomark1 {
  display: flex;
  flex-direction: row;
}
.chatB-logomark {
  filter: drop-shadow(0 1.3px 3.75px rgba(16, 24, 40, 0.1))
    drop-shadow(0 1.3px 2.5px rgba(16, 24, 40, 0.06));
  align-items: flex-start;
  justify-content: flex-start;
}
.chatB-frame-parent3 {
  align-items: center;
  justify-content: center;
  gap: 29px;
}
.chatB-content18,
.chatB-logo1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.chatB-content18 {
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: center;
  gap: 20px;
}
.chatB-container2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 32px;
  box-sizing: border-box;
  max-width: 1280px;
}
.chatB-divider-icon6,
.chatB-footer1 {
  align-self: stretch;
  overflow: hidden;
}
.chatB-footer1 {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 48px 80px;
  z-index: 1;
}
.chatB-divider-icon6 {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  z-index: 2;
  margin-top: -120px;
}
.chatB-footer-parent,
.chatB-messaging-with-list-view-car {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.chatB-footer-parent {
  align-self: stretch;
  min-height: 134px;
  text-align: left;
  font-size: 16px;
  color: #475467;
  font-family: Inter;
}
.chatB-messaging-with-list-view-car {
  width: 100%;
  /* position: relative; */
  background-color: #fff;
  overflow: visible;
  padding: 7px 0 0;
  box-sizing: border-box;
  /* gap: 49px; */
  line-height: normal;
  letter-spacing: normal;
  height: 100vh;
}
@media screen and (max-width: 1275px) {
  .chatB-container2 {
    max-width: 100%;
  }
}
@media screen and (max-width: 1100px) {
  .chatB-content-container {
    flex-wrap: wrap;
    min-width: 100%;
  }
  .chatB-side-panel {
    width: 100%;
    padding-bottom: 42px;
    box-sizing: border-box;
  }
  .chatB-content-divider1,
  .chatB-content-divider2,
  .chatB-content7,
  .chatB-messages-parent {
    flex-wrap: wrap;
  }
  .chatB-container1 {
    height: auto;
  }
  .chatB-chat-content {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 750px) {
  .chatB-navbar-parent {
    gap: 19px;
  }
  .chatB-logo-container-parent {
    gap: 35px;
  }
  .chatB-rectangle-parent {
    padding-right: 32px;
    box-sizing: border-box;
  }
  .chatB-badges-parent {
    flex-wrap: wrap;
  }
  .chatB-content-container-inner {
    min-width: 100%;
  }
  .chatB-content-container {
    gap: 25px;
  }
  .chatB-number {
    font-size: 24px;
    line-height: 30px;
  }
  .chatB-side-panel {
    padding-bottom: 27px;
    box-sizing: border-box;
  }
  .chatB-avatar-and-text,
  .chatB-name-and-time,
  .chatB-text-and-badge1 {
    flex-wrap: wrap;
  }
  .chatB-message7 {
    max-width: 100%;
  }
  .chatB-content11,
  .chatB-message7,
  .chatB-name-and-time2 {
    flex-wrap: wrap;
  }
  .chatB-content10 {
    height: auto;
  }
  .chatB-message11{
    flex-wrap: wrap;
    max-width: 100%;
  }
  .chatB-row2 {
    flex-wrap: wrap;
  }
  .chatB-card-header-parent,
  .chatB-messages {
    min-width: 100%;
  }
  .chatB-container2 {
    gap: 16px;
  }
  .chatB-footer1 {
    gap: 32px;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
  }
  .chatB-messaging-with-list-view-car {
    gap: 24px;
  }
}
@media screen and (max-width: 450px) {
  .chatB-logo-container-parent {
    gap: 17px;
  }
  .chatB-price {
    font-size: 16px;
    line-height: 26px;
  }
  .chatB-number {
    font-size: 18px;
    line-height: 23px;
  }
  .chatB-text-and-badge {
    padding-right: 20px;
    box-sizing: border-box;
  }
  .chatB-avatar-and-time,
  .chatB-avatar-and-time2,
  .chatB-content5 {
    flex-wrap: wrap;
  }
  .chatB-side-panel {
    height: auto;
    min-width: 100%;
  }
  .chatB-text-and-badge1 {
    padding-right: 20px;
    box-sizing: border-box;
  }
  .chatB-actions1,
  .chatB-name-and-time3,
  .chatB-name-and-time6 {
    flex-wrap: wrap;
  }
  .chatB-row2 {
    padding-left: 20px;
    box-sizing: border-box;
  }
  .chatB-message13 {
    max-width: 100%;
  }
  .chatB-footer1 {
    gap: 16px;
  }
}


.chat-item {
  cursor: pointer;
  padding: 16px 0px 16px 36px;
  border-bottom: 1px solid #ccc;
}

.chat-item:hover {
  background-color: #f0f0f0;
}

.chatB-message-list{
  width: max-content
}

.chatB-message-stage-badget{
width: 50px;
}

.chatB-load-message-arrow{
  width: 24px;
height: 20px; /* min-height: 24px; */ position: relative;
overflow: hidden; top: 3px;
padding-right: 5px;
}
/* 
time dividers */

.chatB-divider-container {
  display: flex;
  align-items: center;
  margin: 10px 0; /* Adjust spacing around the divider */
}

.chatB-divider-line {
  flex: 1; /* Makes the line stretch to fill the available space */
  height: 1px;
  background-color: black; /* Thin black line */
}

.chatB-divider-label {
  margin: 0 10px; /* Space around the label */
  font-size: 14px; /* Adjust label size */
  color: black; /* Label text color */
  text-align: center;
  white-space: nowrap; /* Prevents label text from wrapping */
}


/*BEACON*/
/* Custom CSS */
/* .chat-beacon-beacon-frame-layout-custom {
  margin-top: 0px !important;
} */

.chat-beacon-main-custom {
  width: 100% !important;
}

.chat-beacon-search-lg-icon-custom {
  cursor: pointer;
}

.chat-beacon-beacon-frame-layout-child {
  position: relative;
  background-color: #fff;
  display: none;
}

/* .chat-beacon-beacon-frame-layout-child {
    align-self: stretch;
    height: 8320px;
    margin-top: 200px;
  } */








.chat-beacon-text {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 30px;
}

.chat-beacon-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}


.chat-beacon-chevron-down-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}


.chat-beacon-badge1,
.chat-beacon-nav-item-base1 {
  background-color: #f9fafb;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}



.chat-beacon-navigation,
.chat-beacon-search-input {
  display: flex;
  justify-content: flex-start;
}

.chat-beacon-search-input {
  flex-direction: column;
  align-items: flex-start;
  padding: 2px 0 0;
}

.chat-beacon-label {
  width: 47px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.chat-beacon-search-lg-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.chat-beacon-search-placeholder {
  flex: 1;
  position: relative;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 8px;
  outline: none;
  border: none;
  font-size: 16px;
}

.chat-beacon-content3,
.chat-beacon-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.chat-beacon-content3 {
  flex: 1;
  gap: 8px;
}








.chat-beacon-divider-icon,
.chat-beacon-home-line-icon {
  position: relative;
  overflow: hidden;
}

.chat-beacon-divider-icon {
  align-self: stretch;
  max-width: 100%;
  max-height: 100%;
  z-index: 1;
}



.chat-beacon-contrast-border1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 9999px;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
}





.chat-beacon-arrow-narrow-left-icon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}













.chat-beacon-content12,
.chat-beacon-navigation4 {
  display: flex;
  justify-content: flex-start;
}

.chat-beacon-content12 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0 142px 0 0;
  gap: 12px;
}

.chat-beacon-content5,
.chat-beacon-navigation4 {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
}

.chat-beacon-content5 {
  padding: 36px 16px 0;
  gap: 16px;
}

.chat-beacon-supporting-text,
.chat-beacon-title {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}

.chat-beacon-title {
  font-weight: 600;
}

.chat-beacon-supporting-text {
  color: #475467;
  white-space: nowrap;
}


.chat-beacon-breadcrumb-button-base {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-beacon-breadcrumb-button-base {
  border-radius: 6px;
  padding: 4px;
}

.chat-beacon-chevron-right-icon {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.chat-beacon-location {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 74px;
}

.chat-beacon-breadcrumb-button-base1 {
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}

.chat-beacon-text20 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 500;
}

.chat-beacon-breadcrumb-button-base2 {
  align-self: stretch;
  border-radius: 6px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 7px;
}

.chat-beacon-chevron-right-icon2 {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.chat-beacon-text21 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  white-space: nowrap;
}

.chat-beacon-breadcrumb-button-base3 {
  align-self: stretch;
  border-radius: 6px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}

.chat-beacon-text22 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 65px;
}

.chat-beacon-breadcrumb-button-base4 {
  border-radius: 6px;
  background-color: #f9fafb;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  color: #344054;
}

.chat-beacon-tabs {
  gap: 8px;
}

.chat-beacon-breadcrumbs,
.chat-beacon-tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.chat-beacon-heading,
.chat-beacon-supporting-text1 {
  align-self: stretch;
  position: relative;
}

.chat-beacon-heading {
  line-height: 38px;
  font-weight: 600;
}

.chat-beacon-supporting-text1 {
  font-size: 16px;
  line-height: 24px;
  color: #475467;
}

.chat-beacon-text-and-supporting-text {
  width: 678px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  min-width: 320px;
  max-width: 100%;
  flex-shrink: 0;
  font-size: 30px;
  color: #101828;
}

.chat-beacon-placeholder-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 20px;
}

.chat-beacon-text-padding {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.chat-beacon-button,
.chat-beacon-button1,
.chat-beacon-button2 {
  height: 40px;
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  justify-content: center;
  gap: 4px;
}

.chat-beacon-button {
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
}

.chat-beacon-button1,
.chat-beacon-button2 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  background-color: #fff;
  padding: 10px 13px;
}

.chat-beacon-button1 {
  border: 1px solid #d6bbfb;
  flex-direction: row;
  align-items: center;
  color: #6941c6;
}

.chat-beacon-button2 {
  border: 1px solid #d0d5dd;
  color: #344054;
}

.chat-beacon-actions,
.chat-beacon-button2,
.chat-beacon-button3 {
  flex-direction: row;
  align-items: center;
}

.chat-beacon-button3 {
  height: 40px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #7e56d9;
  border: 1px solid #7e56d9;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 10px 13px;
  gap: 4px;
  color: #fff;
}

.chat-beacon-actions {
  display: none;
  justify-content: flex-start;
  gap: 12px;
  max-width: 100%;
}

.chat-beacon-hint-text {
  width: 320px;
  position: relative;
  line-height: 20px;
  display: none;
}

.chat-beacon-button4,
.chat-beacon-button5 {
  align-self: stretch;
  border-radius: 8px;
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 14px;
  gap: 4px;
}

.chat-beacon-button5 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  background-color: #fff;
  border: 1px solid #d6bbfb;
  padding: 10px 13px;
  color: #6941c6;
}

.chat-beacon-upload-04-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 20px;
}

.chat-beacon-text29 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 45px;
}

.chat-beacon-button6,
.chat-beacon-text-padding6 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.chat-beacon-button6 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  padding: 8px 13px;
  gap: 4px;
  color: #344054;
}

.chat-beacon-text30 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 73px;
}

.chat-beacon-button7 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #7e56d9;
  border: 1px solid #7e56d9;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 13px;
  gap: 4px;
  color: #fff;
}

.chat-beacon-content13,
.chat-beacon-input-dropdown {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.chat-beacon-input-dropdown {
  width: 320px;
  flex-direction: column;
  gap: 8px;
  min-width: 200px;
  max-width: 320px;
  flex-shrink: 0;
}

.chat-beacon-content13 {
  flex-direction: row;
  padding: 0;
  box-sizing: border-box;
  gap: 70px;
}

.chat-beacon-content13,
.chat-beacon-divider-icon1,
.chat-beacon-page-header {
  align-self: stretch;
  max-width: 100%;
}

.chat-beacon-divider-icon1 {
  position: relative;
  overflow: hidden;
  max-height: 100%;
  display: none;
}

.chat-beacon-page-header {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.chat-beacon-text31 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  /* min-width: 71px; */
}

.chat-beacon-button-group-base {
  background-color: #f9fafb;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  min-height: 40px;
  white-space: nowrap;
}

.chat-beacon-text32 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 55px;
}

.chat-beacon-button-group-base1,
.chat-beacon-button-group-base2 {
  width: 87px;
  background-color: #fff;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  min-height: 40px;
  white-space: nowrap;
}

.chat-beacon-button-group-base2 {
  width: 77px;
}

.chat-beacon-text34 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.chat-beacon-button-group-base3,
.chat-beacon-button-group-base4,
.chat-beacon-button-group-base5,
.chat-beacon-button-group-base6,
.chat-beacon-button-group-base7 {
  align-self: stretch;
  background-color: #fff;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  min-height: 40px;
  z-index: 5;
}

.chat-beacon-button-group-base4,
.chat-beacon-button-group-base5,
.chat-beacon-button-group-base6,
.chat-beacon-button-group-base7 {
  z-index: 4;
}

.chat-beacon-button-group-base5,
.chat-beacon-button-group-base6,
.chat-beacon-button-group-base7 {
  z-index: 3;
}

.chat-beacon-button-group-base6,
.chat-beacon-button-group-base7 {
  z-index: 2;
}

.chat-beacon-button-group-base7 {
  z-index: 1;
}

.chat-beacon-text39 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 61px;
}

.chat-beacon-button-group,
.chat-beacon-button-group-base8 {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}

.chat-beacon-button-group-base8 {
  width: 93px;
  background-color: #fff;
  border-right: 1px solid #d0d5dd;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  min-height: 40px;
  white-space: nowrap;
}

.chat-beacon-button-group {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  overflow-x: auto;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.chat-beacon-filter-date {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 85px;
}

.chat-beacon-buttonsbutton1 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 13px;
  gap: 4px;
  cursor: pointer;
  text-wrap: nowrap;
}

.chat-beacon-date-picker-dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-beacon-filter-options {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 43px;
}

.chat-beacon-actions2,
.chat-beacon-buttonsbutton2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.chat-beacon-buttonsbutton2 {
  width: 99px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
  padding: 8px 13px;
  gap: 4px;
  color: #344054;
}

.chat-beacon-actions2 {
  justify-content: flex-start;
  gap: 12px;
  color: #667085;
}

.chat-beacon-container,
.chat-beacon-header-section,
.chat-beacon-tabs-and-filters {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}

.chat-beacon-tabs-and-filters {
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  color: #344054;
}

.chat-beacon-container,
.chat-beacon-header-section {
  flex-direction: column;
  justify-content: flex-start;
}

.chat-beacon-container {
  padding: 0 32px;
  box-sizing: border-box;
  gap: 24px;
}

.chat-beacon-header-section {
  flex-shrink: 0;
}

.chat-beacon-color {
  width: 8px;
  height: 8px;
  position: relative;
  border-radius: 50%;
  background-color: #7e56d9;
}

.chat-beacon-legend-color {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6px 0 0;
}

.chat-beacon-series-1 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 35px;
}

.chat-beacon-legend-series {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.chat-beacon-color1 {
  width: 8px;
  height: 8px;
  position: relative;
  border-radius: 50%;
  background-color: #b692f6;
}

.chat-beacon-series-2 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 33px;
}

.chat-beacon-color2,
.chat-beacon-color3 {
  width: 8px;
  height: 8px;
  position: relative;
  border-radius: 50%;
  background-color: #6941c6;
}

.chat-beacon-color3 {
  background-color: #17b26a;
}

.chat-beacon-series-4 {
  height: 20px;
  position: relative;
  line-height: 20px;
  display: inline-block;
}

.chat-beacon-legend-series3 {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
}

.chat-beacon-color4 {
  height: 8px;
  width: 8px;
  position: relative;
  border-radius: 50%;
  background-color: #6172f3;
}

.chat-beacon-series-5 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}

.chat-beacon-legend-series4 {
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.chat-beacon-color5 {
  height: 8px;
  width: 8px;
  position: relative;
  border-radius: 50%;
  background-color: #ee46bb;
}

.chat-beacon-legend {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}

.chat-beacon-divider,
.chat-beacon-y-axis-line,
.chat-beacon-y-axis-line1 {
  align-self: stretch;
  box-sizing: border-box;
  max-width: 100%;
}

.chat-beacon-divider {
  flex: 1;
  position: relative;
  border-top: 1px solid #f2f4f7;
}

.chat-beacon-y-axis-line,
.chat-beacon-y-axis-line1 {
  height: 22px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 11px 0 10px;
}

.chat-beacon-y-axis-line1 {
  height: 17px;
  padding: 8.5px 0 7.5px;
}

.chat-beacon-divider6 {
  height: 1px;
  width: 1217px;
  position: relative;
  border-top: 1px solid #f2f4f7;
  box-sizing: border-box;
  max-width: 121%;
  flex-shrink: 0;
}

.chat-beacon-y-axis,
.chat-beacon-y-axis-line6 {
  align-self: stretch;
  justify-content: flex-start;
  max-width: 100%;
}

.chat-beacon-y-axis-line6 {
  height: 17px;
  display: none;
  flex-direction: row;
  align-items: center;
}

.chat-beacon-y-axis {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14.2px;
}

.chat-beacon-jan,
.chat-beacon-jul,
.chat-beacon-jun,
.chat-beacon-mar,
.chat-beacon-may {
  position: relative;
  line-height: 18px;
  display: inline-block;
  min-width: 21px;
}

.chat-beacon-jul,
.chat-beacon-jun,
.chat-beacon-mar,
.chat-beacon-may {
  min-width: 23px;
}

.chat-beacon-jul,
.chat-beacon-jun,
.chat-beacon-may {
  min-width: 25px;
}

.chat-beacon-jul,
.chat-beacon-jun {
  min-width: 22px;
}

.chat-beacon-jul {
  min-width: 17px;
}

.chat-beacon-x-axis {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 24px;
  gap: 20px;
  font-size: 12px;
}

.chat-beacon-chart-data-icon {
  width: 100%;
  height: 170px;
  position: absolute;
  margin: 0 !important;
  top: 20px;
  right: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  z-index: 1;
}

.chat-beacon-content14 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  position: relative;
  max-width: 100%;
}

.chat-beacon-line-and-bar-chart,
.chat-beacon-line-and-bar-chart-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.chat-beacon-line-and-bar-chart {
  flex: 1;
}

.chat-beacon-line-and-bar-chart-wrapper {
  align-self: stretch;
  padding: 0 32px;
  box-sizing: border-box;
  flex-shrink: 0;
}

.chat-beacon-heading1,
.chat-beacon-number {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  font-weight: 600;
}

.chat-beacon-number {
  line-height: 38px;
  white-space: nowrap;
}

.chat-beacon-change1 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 29px;
}

.chat-beacon-change {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.chat-beacon-change-duration {
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  color: #475467;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chat-beacon-change-and-text,
.chat-beacon-number-and-badge {
  display: flex;
  justify-content: flex-start;
}

.chat-beacon-change-and-text {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #079455;
}

.chat-beacon-number-and-badge {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  min-width: 84px;
}

.chat-beacon-chart-mini-icon {
  height: 64px;
  width: 128px;
  position: relative;
  object-fit: contain;
}

.chat-beacon-number-and-chart {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 16px;
  font-size: 30px;
}

.chat-beacon-dots-vertical-icon {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.chat-beacon-dropdown,
.chat-beacon-metric-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-beacon-dropdown {
  margin: 0 !important;
  position: absolute;
  top: 20px;
  right: 20px;
}

.chat-beacon-metric-item {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 20px 20px 24px 24px;
  position: relative;
  gap: 24px;
  min-width: 320px;
  max-width: 100%;
}

.chat-beacon-change2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1px;
}

.chat-beacon-change-and-text1,
.chat-beacon-container1,
.chat-beacon-metric-group,
.chat-beacon-section {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}

.chat-beacon-change-and-text1 {
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #da2d20;
}

.chat-beacon-container1,
.chat-beacon-metric-group,
.chat-beacon-section {
  align-items: flex-start;
  max-width: 100%;
}

.chat-beacon-metric-group {
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 0 4px 0 0;
  box-sizing: border-box;
  gap: 24px 22px;
}

.chat-beacon-container1,
.chat-beacon-section {
  flex-direction: column;
}

.chat-beacon-container1 {
  padding: 0 32px;
  box-sizing: border-box;
}

.chat-beacon-section {
  flex-shrink: 0;
  font-size: 16px;
  color: #101828;
}

.chat-beacon-separator,
.chat-beacon-separator1 {
  width: 1px;
  height: 29px;
  position: relative;
  border-right: 1px solid #000;
  box-sizing: border-box;
  flex-shrink: 0;
}

.chat-beacon-separator1 {
  height: 43px;
}

.chat-beacon-main,
.chat-beacon-main-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}

.chat-beacon-main {
  height: 692px;
  flex: 1;
  background-color: #fff;
  overflow: hidden;
  flex-direction: column;
  padding: 32px 0;
  gap: 32px;
  z-index: 1;
}

.chat-beacon-main-wrapper {
  align-self: stretch;
  flex-direction: column;
  padding: 0 0 4px;
  color: #475467;
}

.chat-beacon-text42 {
  align-self: stretch;
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 500;
}

.chat-beacon-breadcrumb-button-base6,
.chat-beacon-breadcrumb-button-base7 {
  align-self: stretch;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.chat-beacon-breadcrumb-button-base6 {
  flex: 1;
  padding: 4px 8px;
}

.chat-beacon-breadcrumb-button-base7 {
  padding: 4px 7px;
}

.chat-beacon-breadcrumbs1,
.chat-beacon-tabs1 {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.chat-beacon-tabs1 {
  height: 28px;
  flex: 1;
  display: flex;
  gap: 8px;
}

.chat-beacon-breadcrumbs1 {
  width: 278px;
  display: none;
}

.chat-beacon-text46 {
  align-self: stretch;
  position: relative;
  line-height: 38px;
  font-weight: 600;
}

.chat-beacon-supporting-text2 {
  width: 445px;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  display: none;
  max-width: 100%;
}

.chat-beacon-text-and-supporting-text1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  min-width: 320px;
  max-width: 100%;
}

.chat-beacon-text47 {
  align-self: stretch;
  flex: 1;
  position: relative;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chat-beacon-content16 {
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.chat-beacon-content16,
.chat-beacon-input-with-label1,
.chat-beacon-input1 {
  align-self: stretch;
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.chat-beacon-input1 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 10px 13px;
  font-size: 16px;
  color: #667085;
}

.chat-beacon-input-with-label1 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.chat-beacon-hint-text1 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  color: #475467;
  display: none;
}

.chat-beacon-input-dropdown1 {
  height: 44px;
  width: 320px;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  min-width: 200px;
  max-width: 320px;
  font-size: 14px;
  color: #344054;
}

.chat-beacon-content15 {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 20px 16px;
  font-size: 26px;
  color: #101828;
}

.chat-beacon-content15,
.chat-beacon-page-header-wrapper,
.chat-beacon-page-header1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.chat-beacon-page-header1 {
  flex: 1;
  background-color: #fff;
  flex-direction: column;
  gap: 20px;
  z-index: 1;
}

.chat-beacon-page-header-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 32px 4px;
  box-sizing: border-box;
  color: #475467;
}

.chat-beacon-supporting-text3 {
  flex: 1;
  position: relative;
  line-height: 24px;
  display: inline-block;
  max-width: 100%;
  z-index: 1;
}

.chat-beacon-supporting-text-wrapper {
  width: 742px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 32px 20px;
  box-sizing: border-box;
  max-width: 100%;
  font-size: 16px;
  color: #475467;
}

.chat-beacon-tab-name {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 55px;
  white-space: nowrap;
}

.chat-beacon-tab-button-base {
  border-bottom: 2px solid #7e56d9;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 4px 9px;
  color: #6941c6;
}

.chat-beacon-text48 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 48px;
  white-space: nowrap;
}

.chat-beacon-tab-button-base1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 4px 12px;
}

.chat-beacon-text49,
.chat-beacon-text50,
.chat-beacon-text51 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 52px;
  white-space: nowrap;
}

.chat-beacon-text50,
.chat-beacon-text51 {
  min-width: 62px;
}

.chat-beacon-text51 {
  min-width: 34px;
}

.chat-beacon-tab-button-base4 {
  width: 41px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 3px 12px;
  box-sizing: border-box;
}

.chat-beacon-text52 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  white-space: nowrap;
}

.chat-beacon-tab-button-base5 {
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 4px 12px;
}

.chat-beacon-text55 {
  align-self: stretch;
  position: relative;
  line-height: 18px;
  font-weight: 500;
  white-space: nowrap;
}

.chat-beacon-badge10 {
  height: 22px;
  border-radius: 9999px;
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 7px;
  text-align: center;
  font-size: 12px;
  color: #344054;
}

.chat-beacon-tab-button-base7,
.chat-beacon-tab-button-base9 {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.chat-beacon-tab-button-base7 {
  height: 32px;
  padding: 0 4px 12px;
  box-sizing: border-box;
  gap: 8px;
}

.chat-beacon-tab-button-base9 {
  align-self: stretch;
  padding: 0 3px 12px;
}

.chat-beacon-tabs2 {
  overflow-x: auto;
  flex-direction: row;
  padding: 0 20px 0 0;
  gap: 12px;
}

.chat-beacon-horizontal-tabs,
.chat-beacon-horizontal-tabs-wrapper,
.chat-beacon-tabs2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}

.chat-beacon-horizontal-tabs {
  flex: 1;
  background-color: #fff;
  border-bottom: 1px solid #eaecf0;
  flex-direction: column;
  /* z-index: 1; */
}

.chat-beacon-horizontal-tabs-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 24px 32px;
  color: #667085;
}

.chat-beacon-frame-item {
  width: 188px;
  height: 154px;
  position: relative;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
}

.chat-beacon-heading4 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  z-index: 1;
}

.chat-beacon-metric-card-title {
  flex: 1;
  flex-direction: column;
  padding: 4px 0 0;
}

.chat-beacon-dropdown3,
.chat-beacon-metric-card,
.chat-beacon-metric-card-title {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-beacon-dropdown3 {
  flex-direction: column;
  /* z-index: 1; */
}

.chat-beacon-metric-card {
  align-self: stretch;
  flex-direction: row;
}

.chat-beacon-number3 {
  width: 155px;
  position: relative;
  font-size: 36px;
  letter-spacing: -0.02em;
  line-height: 44px;
  font-weight: 600;
  color: #101828;
  display: inline-block;
  z-index: 1;
}

.chat-beacon-metric-card-row {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.chat-beacon-arrow-up-icon2 {
  width: 12px;
  height: 12px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.chat-beacon-options-icon {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4px 0 0;
}

.chat-beacon-text58 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 116px;
}

.chat-beacon-badge11 {
  width: 150px;
  border-radius: 9999px;
  border: 1.5px solid #079455;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 7px 0 8px;
  gap: 4px;
  z-index: 1;
  text-align: center;
  color: #067647;
}

.chat-beacon-frame-inner,
.chat-beacon-rectangle-group {
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
}

.chat-beacon-rectangle-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px 4px 22px 15px;
  gap: 8px;
  min-width: 184px;
  max-width: 188px;
}

.chat-beacon-frame-inner {
  height: 154px;
  width: 188px;
  position: relative;
  display: none;
}

.chat-beacon-heading5,
.chat-beacon-number4 {
  position: relative;
  z-index: 1;
}

.chat-beacon-heading5 {
  width: 147px;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
}

.chat-beacon-number4 {
  align-self: stretch;
  font-size: 36px;
  letter-spacing: -0.02em;
  line-height: 44px;
  font-weight: 600;
  color: #101828;
}

.chat-beacon-change-amount {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 32px;
}

.chat-beacon-badge12 {
  width: 64px;
  border-radius: 9999px;
  border: 1.5px solid #079455;
  box-sizing: border-box;
  flex-direction: row;
  padding: 0 7px 0 8px;
  gap: 2px;
  z-index: 1;
  text-align: center;
  color: #067647;
}

.chat-beacon-badge12,
.chat-beacon-frame-container,
.chat-beacon-heading-parent,
.chat-beacon-rectangle-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-beacon-heading-parent {
  width: 157px;
  flex-direction: column;
  gap: 8px;
}

.chat-beacon-frame-container,
.chat-beacon-rectangle-container {
  box-sizing: border-box;
}

.chat-beacon-frame-container {
  width: 147px;
  flex-direction: column;
  padding: 4px 0 0;
}

.chat-beacon-rectangle-container {
  flex: 1;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  flex-direction: row;
  padding: 20px 4px 22px 15px;
  min-width: 184px;
  max-width: 188px;
}

.chat-beacon-heading-group {
  width: 153px;
  gap: 8px;
}

.chat-beacon-dropdown7,
.chat-beacon-heading-container,
.chat-beacon-heading-group,
.chat-beacon-heading-parent1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-beacon-heading-container {
  width: 163px;
  gap: 8px;
}

.chat-beacon-dropdown7,
.chat-beacon-heading-parent1 {
  flex-shrink: 0;
}

.chat-beacon-heading-parent1 {
  width: 170px;
  gap: 8px;
}

.chat-beacon-dropdown7 {
  z-index: 1;
}

.chat-beacon-frame-group,
.chat-beacon-frame-wrapper,
.chat-beacon-rectangle-parent2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.chat-beacon-rectangle-parent2 {
  flex: 1;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  justify-content: flex-start;
  padding: 20px 2px 22px 16px;
  min-width: 184px;
  max-width: 188px;
}

/* .chat-beacon-frame-group,
  .chat-beacon-frame-wrapper {
    max-width: 100%;
  } */

.chat-beacon-frame-group {
  flex: 1;
  justify-content: flex-start;
  gap: 19px;
  /* z-index: 1; */
}

.chat-beacon-frame-wrapper {
  align-self: stretch;
  justify-content: flex-start;
  padding: 0 30px 24px 32px;
  box-sizing: border-box;
  color: #475467;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.chat-beacon-frame-wrapper::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.chat-beacon-text62,
.chat-beacon-text63 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 51px;
}

.chat-beacon-text63 {
  min-width: 74px;
}

.chat-beacon-buttonsbutton4,
.chat-beacon-text-padding11 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 1px;
}

.chat-beacon-buttonsbutton4 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  padding: 8px 13px;
  gap: 4px;
  min-width: 84px;
  cursor: pointer;
}

.chat-beacon-text64 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 78px;
}

.chat-beacon-buttonsbutton5 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 13px;
  gap: 4px;
  min-width: 87px;
  cursor: pointer;
}

.chat-beacon-text65 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 50px;
}

.chat-beacon-dropdowns,
.chat-beacon-input2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.chat-beacon-dropdowns {
  /* flex: 0.7552; */
  flex: .5;
  align-items: flex-start;
  /* padding: 0 166px 0 0; */
  box-sizing: border-box;
  gap: 12px;
  max-width: 100%;
}

.chat-beacon-input2 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  align-items: center;
  padding: 8px 13px;
  font-size: 16px;
  color: #667085;
  min-width: 208px;
  max-width: 320px;
}

.chat-beacon-input-dropdown2,
.chat-beacon-input-with-label2 {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  /* flex: .85; */
  justify-content: flex-start;
}

.chat-beacon-input-with-label2 {
  align-self: stretch;
  gap: 6px;
  flex: .65;
}

.chat-beacon-input-dropdown2 {
  flex: .5;
  flex-direction: row !important;
  justify-content: flex-end !important;
  gap: 8px;
  /* min-width: 208px;
    max-width: 320px; */
}

.chat-beacon-content17 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.chat-beacon-content17,
.chat-beacon-filters-bar,
.chat-beacon-section1 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.chat-beacon-filters-bar {
  align-self: stretch;
  border-radius: 8px;
  flex-direction: row;
  align-items: flex-start;
}

.chat-beacon-section1 {
  flex: 1;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.chat-beacon-frame-child3,
.chat-beacon-section-wrapper {
  box-sizing: border-box;
  max-width: 100%;
}

.chat-beacon-section-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 24px;
  color: #344054;
  position: relative;
}

.chat-beacon-frame-child3 {
  width: 1016px;
  height: 282px;
  position: relative;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  display: none;
}

.chat-beacon-content-elements-icon {
  height: 148px;
  width: 148px;
  position: relative;
  border-radius: 12px;
  object-fit: cover;
  z-index: 1;
}

.chat-beacon-search-input-icons {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  z-index: 1;
}

.chat-beacon-search-inputs {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 1px;
}

.chat-beacon-dot-icon10 {
  height: 8px;
  width: 8px;
  position: relative;
  display: none;
}

.chat-beacon-badge-number {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 66px;
}

.chat-beacon-badge16 {
  width: 84px;
  border-radius: 9999px;
  background-color: #edfbf5;
  border: 1px solid #41d992;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 8px;
  gap: 6px;
  mix-blend-mode: normal;
  z-index: 1;
  text-align: center;
  font-size: 14px;
  color: #17b26a;
}

.chat-beacon-supporting-text4 {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 83px;
  z-index: 1;
}

.chat-beacon-marker-pin-02-icon {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.chat-beacon-location1 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 124px;
  z-index: 1;
}

.chat-beacon-location-pin-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 7px;
}

.chat-beacon-type-of-work {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 109px;
  z-index: 1;
}

.chat-beacon-location-badge,
.chat-beacon-location-badge-number,
.chat-beacon-type-number {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-beacon-location-badge-number {
  flex: 1;
  gap: 20px;
  max-width: 100%;
}

.chat-beacon-location-badge,
.chat-beacon-type-number {
  box-sizing: border-box;
}

.chat-beacon-location-badge {
  align-self: stretch;
  padding: 0 0 0 1px;
  max-width: 100%;
}

.chat-beacon-type-number {
  width: 50px;
  border-radius: 8px;
  background-color: #fdb022;
  padding: 1px 4px;
  z-index: 1;
}

.chat-beacon-supporting-text5 {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 117px;
  z-index: 1;
}

.chat-beacon-type-support {
  flex-direction: column;
  padding: 1px 0 0;
  color: #475467;
}

.chat-beacon-location-elements,
.chat-beacon-type-badge-elements,
.chat-beacon-type-support {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-beacon-type-badge-elements {
  flex-direction: row;
  gap: 8px;
  color: #fff;
}

.chat-beacon-location-elements {
  align-self: stretch;
  flex-direction: column;
  gap: 15px;
  max-width: 100%;
}

.chat-beacon-card-badge-number {
  position: relative;
  line-height: 20px;
  font-weight: 500;
}

.chat-beacon-badge17 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 7px;
  min-width: 112px;
  white-space: nowrap;
  z-index: 1;
}

.chat-beacon-badge-number-element {
  width: 61px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 61px;
}

.chat-beacon-badge18 {
  width: 77px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 7px;
  white-space: nowrap;
  z-index: 1;
}

.chat-beacon-second-badge-count {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 60px;
}

.chat-beacon-badge19 {
  width: 76px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 7px;
  z-index: 1;
}

.chat-beacon-third-badge-number {
  width: 149px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
}

.chat-beacon-badge20 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 7px;
  min-width: 107px;
  white-space: nowrap;
  z-index: 1;
}

.chat-beacon-badge-elements {
  flex: 1;
  flex-direction: row;
  gap: 8px;
}

.chat-beacon-badge-elements,
.chat-beacon-badge-elements-wrapper,
.chat-beacon-card-badge-elements {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.chat-beacon-badge-elements-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 1px;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.chat-beacon-card-badge-elements {
  flex: 1;
  flex-direction: column;
  gap: 15px;
}

.chat-beacon-location-badge-elements {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 77px;
}

.chat-beacon-badge21 {
  width: 93px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 7px;
  z-index: 1;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.chat-beacon-action-fields,
.chat-beacon-card-elements,
.chat-beacon-input-elements {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.chat-beacon-card-elements {
  width: 616px;
  flex-direction: row;
  align-items: flex-end;
  gap: 7px;
  font-size: 16px;
  color: #475467;
}

.chat-beacon-action-fields,
.chat-beacon-input-elements {
  flex-direction: column;
  align-items: flex-start;
}

.chat-beacon-input-elements {
  align-self: stretch;
  gap: 16px;
}

.chat-beacon-action-fields {
  flex: 1;
  padding: 10px 0 0;
  box-sizing: border-box;
}

.chat-beacon-price {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 32px;
  font-weight: 600;
  white-space: nowrap;
  z-index: 1;
}

.chat-beacon-dropdown-options {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 83px;
  z-index: 2;
}

.chat-beacon-magnifier-1-icon {
  height: 20px;
  width: 20px;
  position: relative;
  object-fit: cover;
  min-height: 20px;
}

.chat-beacon-frame-wrapper3,
.chat-beacon-beacon-1-1-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-beacon-beacon-1-1-parent {
  flex: 1;
  gap: 8px;
  z-index: 1;
}

.chat-beacon-frame-wrapper3 {
  align-self: stretch;
  padding: 0 4px 0 3px;
}

.chat-beacon-dropdown-options-parent {
  flex: 1;
  flex-direction: column;
  gap: 4px;
}

.chat-beacon-dropdown-elements,
.chat-beacon-dropdown-options-parent,
.chat-beacon-price-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-beacon-dropdown-elements {
  align-self: stretch;
  flex-direction: row;
  padding: 0 30px;
  font-size: 12px;
  color: #475467;
}

.chat-beacon-price-parent {
  flex: 1;
  flex-direction: column;
}

.chat-beacon-filter-elements-inner {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 10px 0 0;
}

.chat-beacon-filter-elements {
  width: 153px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 26px;
  text-align: center;
  font-size: 20px;
  color: #6941c6;
}

.chat-beacon-content-actions,
.chat-beacon-content-elements-parent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 100%;
}

.chat-beacon-content-actions {
  flex: 1;
  align-items: flex-start;
  gap: 15px;
  min-width: 525px;
}

.chat-beacon-content-elements-parent {
  align-self: stretch;
  align-items: flex-end;
  gap: 23px;
}

.chat-beacon-location-column-child {
  height: 52px;
  width: 52px;
  position: relative;
  object-fit: cover;
  z-index: 1;
  border-radius: 99px;
}

.chat-beacon-name,
.chat-beacon-role {
  position: relative;
  display: inline-block;
}

.chat-beacon-name {
  line-height: 23px;
  font-weight: 600;
  min-width: 77px;
  z-index: 1;
}

.chat-beacon-role {
  height: 19px;
  font-size: 12px;
  line-height: 24px;
  color: #6941c6;
  flex-shrink: 0;
  min-width: 87px;
  z-index: 2;
}

.chat-beacon-location-column-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 3px 0 0;
}

.chat-beacon-text67 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 34px;
}

.chat-beacon-buttonsbutton7 {
  width: 90px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6px 12px;
  gap: 6px;
  z-index: 1;
}

.chat-beacon-icon1 {
  width: 16.7px;
  height: 16.7px;
  position: relative;
}

.chat-beacon-icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 3.7px 0 0;
}

.chat-beacon-text68 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 56px;
}

.chat-beacon-buttonsbutton8,
.chat-beacon-buttonsbutton9 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6px 11px;
  gap: 5.5px;
  min-width: 86px;
  z-index: 1;
}

.chat-beacon-buttonsbutton9 {
  padding: 6px 10px 6px 12px;
  gap: 5.4px;
}

.chat-beacon-buttonsbutton-parent {
  align-self: stretch;
  flex-direction: row;
  gap: 16px;
}

.chat-beacon-buttonsbutton-parent,
.chat-beacon-primary-table,
.chat-beacon-primary-table-inner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-beacon-primary-table-inner {
  flex: 1;
  flex-direction: column;
  padding: 6px 0 0;
  box-sizing: border-box;
  min-width: 250px;
  max-width: 100%;
  color: #344054;
}

.chat-beacon-primary-table {
  width: 556.4px;
  flex-direction: row;
  gap: 24px;
  max-width: calc(100% - 44px);
}

.chat-beacon-arrow-square-up-right-icon {
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.chat-beacon-arrow-square-up-right-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 14px 0 0;
}

.chat-beacon-primary-table-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 100%;
  gap: 20px;
}

.chat-beacon-content-items,
.chat-beacon-help-avatar,
.chat-beacon-rectangle-parent3 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.chat-beacon-help-avatar {
  width: 968px;
  flex-direction: column;
  gap: 16px;
  font-size: 14px;
}

.chat-beacon-content-items,
.chat-beacon-rectangle-parent3 {
  box-sizing: border-box;
}

.chat-beacon-rectangle-parent3 {
  flex: 1;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  flex-direction: column;
  padding: 12px 13px 15px 23px;
  gap: 24px;
  z-index: 1;
}

.chat-beacon-content-items {
  align-self: stretch;
  flex-direction: row;
  padding: 0 30px 24px 32px;
  font-size: 18px;
}

.chat-beacon-text71 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 55px;
}

.chat-beacon-badge22 {
  width: 73px;
  border-radius: 9999px;
  background-color: #fff9ef;
  border: 1px solid #f5b231;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 8px;
  gap: 6px;
  mix-blend-mode: normal;
  z-index: 1;
}

.chat-beacon-supporting-text6 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 83px;
  z-index: 1;
}

.chat-beacon-badge-parent {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.chat-beacon-badge-parent,
.chat-beacon-badge23,
.chat-beacon-frame-parent4 {
  flex-direction: row;
  justify-content: flex-start;
}

.chat-beacon-frame-parent4 {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  text-align: left;
  font-size: 16px;
  color: #fff;
}

.chat-beacon-badge23 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  padding: 0 7px;
  white-space: nowrap;
  z-index: 1;
  color: #344054;
}

.chat-beacon-badge23,
.chat-beacon-badge24,
.chat-beacon-frame-parent3 {
  display: flex;
  align-items: flex-start;
}

.chat-beacon-frame-parent3 {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;
  min-width: 114px;
  color: #f5b231;
}

.chat-beacon-badge24 {
  flex-direction: row;
  white-space: nowrap;
}

.chat-beacon-badge24,
.chat-beacon-badge25 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  justify-content: flex-start;
  padding: 0 7px;
  z-index: 1;
}

.chat-beacon-badge-elements-copy,
.chat-beacon-badge25,
.chat-beacon-price-badge-elements {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.chat-beacon-badge-elements-copy {
  flex: 1;
  justify-content: flex-start;
  gap: 8px;
}

.chat-beacon-price-badge-elements {
  align-self: stretch;
  justify-content: flex-end;
  padding: 0 6px 0 0;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.chat-beacon-frame-parent5 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 56px;
  min-width: 109px;
  text-align: left;
  font-size: 16px;
}

.chat-beacon-badge26,
.chat-beacon-date-elements {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 10px 0 18px;
}

.chat-beacon-badge26 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  justify-content: flex-start;
  padding: 0 7px;
  white-space: nowrap;
  z-index: 1;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.chat-beacon-badge27,
.chat-beacon-date-elements-parent {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
}

.chat-beacon-date-elements-parent {
  flex: 0.9649;
  flex-direction: column;
  align-items: flex-end;
  padding: 0 6px 0 0;
  gap: 56px;
  min-width: 111px;
  text-align: left;
  font-size: 16px;
}

.chat-beacon-badge27 {
  width: 93px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 7px;
  z-index: 1;
  color: #344054;
}

.chat-beacon-frame-parent2 {
  width: 615px;
  align-items: flex-end;
  padding: 0 2px 0 0;
  box-sizing: border-box;
  gap: 2px;
  text-align: center;
  font-size: 14px;
  color: #475467;
}

.chat-beacon-frame-parent1,
.chat-beacon-frame-parent2,
.chat-beacon-rectangle-parent5 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 100%;
}

.chat-beacon-frame-parent1 {
  flex: 1;
  align-items: flex-start;
  gap: 15px;
  min-width: 524px;
}

.chat-beacon-rectangle-parent5 {
  align-self: stretch;
  align-items: flex-end;
  gap: 24px;
}

.chat-beacon-frame-child5,
.chat-beacon-frame-child6 {
  width: 24px;
  height: 24px;
  position: relative;
  z-index: 1;
}

.chat-beacon-frame-child6 {
  width: 1016px;
  height: 282px;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
  max-width: 100%;
}

.chat-beacon-frame-child7,
.chat-beacon-text78 {
  position: relative;
  z-index: 2;
}

.chat-beacon-frame-child7 {
  height: 148px;
  width: 148px;
  border-radius: 12px;
  object-fit: cover;
}

.chat-beacon-text78 {
  line-height: 28px;
  font-weight: 600;
}

.chat-beacon-badge28 {
  position: absolute;
  top: 80px;
  left: 522px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  width: 93px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 7px;
  z-index: 2;
}

.chat-beacon-marker-pin-02-icon2 {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 2;
}

.chat-beacon-location3,
.chat-beacon-type-of-work2 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 124px;
  z-index: 2;
}

.chat-beacon-type-of-work2 {
  min-width: 109px;
}

.chat-beacon-frame-parent12,
.chat-beacon-frame-wrapper12 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.chat-beacon-frame-parent12 {
  justify-content: flex-start;
  gap: 20px;
}

.chat-beacon-frame-wrapper12 {
  justify-content: flex-end;
  padding: 0 5px;
}

.chat-beacon-badge29,
.chat-beacon-badge30 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  padding: 0 7px;
  z-index: 2;
}

.chat-beacon-badge29 {
  width: 77px;
  flex-direction: row;
  align-items: flex-start;
  white-space: nowrap;
}

.chat-beacon-badge30 {
  width: 76px;
}

.chat-beacon-badge-container,
.chat-beacon-badge30,
.chat-beacon-badge31 {
  flex-direction: row;
  align-items: flex-start;
}

.chat-beacon-badge31 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  display: flex;
  justify-content: flex-start;
  padding: 0 7px;
  white-space: nowrap;
  z-index: 2;
}

.chat-beacon-badge-container {
  gap: 8px;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.chat-beacon-badge-container,
.chat-beacon-badge32,
.chat-beacon-frame-parent11 {
  display: flex;
  justify-content: flex-start;
}

.chat-beacon-frame-parent11 {
  position: absolute;
  top: 0;
  left: 180px;
  flex-direction: column;
  align-items: flex-end;
  gap: 56px;
  text-align: left;
  font-size: 16px;
  color: #475467;
}

.chat-beacon-badge32 {
  flex: 1;
  border-radius: 9999px;
  background-color: #eceef1;
  border: 1px solid #98a2b3;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 8px;
  gap: 6px;
  mix-blend-mode: normal;
  z-index: 2;
}

.chat-beacon-supporting-text8 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 83px;
  z-index: 2;
}

.chat-beacon-badge-parent1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.chat-beacon-supporting-text9 {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 117px;
  z-index: 2;
}

.chat-beacon-badge33,
.chat-beacon-frame-parent15 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-beacon-badge33 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  padding: 0 7px;
  white-space: nowrap;
  z-index: 2;
  color: #344054;
}

.chat-beacon-frame-parent15 {
  position: absolute;
  top: 0;
  left: 0;
  width: 181px;
  flex-direction: column;
  gap: 15px;
  color: #98a2b3;
}

.chat-beacon-badge-group {
  width: 615px;
  height: 104px;
  position: relative;
  max-width: 100%;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.chat-beacon-dropdown10 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 2;
}

.chat-beacon-price2,
.chat-beacon-text83 {
  position: relative;
}

.chat-beacon-price2 {
  align-self: stretch;
  letter-spacing: -0.02em;
  line-height: 32px;
  font-weight: 600;
  white-space: nowrap;
  z-index: 2;
}

.chat-beacon-text83 {
  line-height: 20px;
  display: inline-block;
  min-width: 83px;
  z-index: 3;
}

.chat-beacon-beacon-1-1-container,
.chat-beacon-rectangle-parent7 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.chat-beacon-beacon-1-1-container {
  flex: 1;
  align-items: flex-start;
  gap: 8px;
  z-index: 2;
}

.chat-beacon-rectangle-parent7 {
  align-self: stretch;
  align-items: flex-end;
  padding: 0 0 76px;
  box-sizing: border-box;
  gap: 24px;
  max-width: 100%;
}

.chat-beacon-frame-child8 {
  height: 52px;
  width: 52px;
  position: relative;
  object-fit: cover;
  z-index: 2;
}

.chat-beacon-name2,
.chat-beacon-role2 {
  position: relative;
  display: inline-block;
}

.chat-beacon-name2 {
  line-height: 23px;
  font-weight: 600;
  min-width: 77px;
  z-index: 2;
}

.chat-beacon-role2 {
  height: 19px;
  font-size: 12px;
  line-height: 24px;
  color: #6941c6;
  flex-shrink: 0;
  min-width: 87px;
  z-index: 3;
}

.chat-beacon-buttonsbutton13,
.chat-beacon-buttonsbutton14 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  z-index: 2;
}

.chat-beacon-buttonsbutton13 {
  width: 90px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6px 12px;
  gap: 6px;
}

.chat-beacon-buttonsbutton14 {
  flex: 1;
  overflow-x: auto;
  padding: 6px 11px;
  gap: 5.5px;
  min-width: 86px;
}

.chat-beacon-buttonsbutton14,
.chat-beacon-buttonsbutton15,
.chat-beacon-frame-parent18 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-beacon-buttonsbutton15 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow-x: auto;
  padding: 6px 10px 6px 12px;
  gap: 5.4px;
  min-width: 86px;
  z-index: 2;
}

.chat-beacon-frame-parent18 {
  width: 556.4px;
  gap: 24px;
  max-width: 100%;
}

.chat-beacon-text84 {
  width: 66px;
  position: relative;
  line-height: 18px;
  font-weight: 600;
  display: inline-block;
  min-width: 66px;
}

.chat-beacon-content19 {
  border-radius: 8px;
  background-color: #0c111d;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 12px;
  white-space: nowrap;
}

.chat-beacon-tooltip-icon {
  width: 28px;
  height: 6px;
  position: relative;
}

.chat-beacon-tooltip {
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
  0 4px 6px -2px rgba(16, 24, 40, 0.03);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  z-index: 3;
}

.chat-beacon-arrow-square-up-right-icon1 {
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 2;
}

.chat-beacon-frame-wrapper16,
.chat-beacon-tooltip-parent {
  display: flex;
  flex-direction: column;
}

.chat-beacon-tooltip-parent {
  align-items: flex-end;
  justify-content: flex-start;
  gap: 8px;
}

.chat-beacon-frame-wrapper16 {
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 14px;
  text-align: center;
  font-size: 12px;
  color: #fff;
}

.chat-beacon-divider-icon5,
.chat-beacon-frame-parent17 {
  width: 968px;
  max-width: 100%;
  margin-top: -69px;
}

.chat-beacon-frame-parent17 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  gap: 20px;
  font-size: 14px;
}

.chat-beacon-divider-icon5 {
  position: relative;
  max-height: 100%;
  z-index: 2;
}

.chat-beacon-rectangle-parent6 {
  flex: 1;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 14px 13px 17px 23px;
  min-height: 282px;
  max-width: 100%;
  z-index: 1;
}

.chat-beacon-text86 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 51px;
}

.chat-beacon-badge34,
.chat-beacon-badge36 {
  justify-content: flex-start;
  z-index: 2;
}

.chat-beacon-badge34 {
  width: 69px;
  border-radius: 9999px;
  background-color: #fffdfd;
  border: 1px solid #da2d20;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 8px;
  gap: 6px;
  mix-blend-mode: normal;
}

.chat-beacon-badge36 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  padding: 0 7px;
  white-space: nowrap;
}

.chat-beacon-badge36,
.chat-beacon-badge37,
.chat-beacon-frame-wrapper18 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.chat-beacon-badge37 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  justify-content: flex-start;
  padding: 0 7px;
  z-index: 2;
}

.chat-beacon-frame-wrapper18 {
  align-self: stretch;
  justify-content: flex-end;
  padding: 0 2px 0 0;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.chat-beacon-frame-parent24 {
  width: 163px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 56px;
  text-align: left;
  font-size: 16px;
}

.chat-beacon-frame-wrapper19 {
  flex-direction: row;
  padding: 0 14px;
}

.chat-beacon-badge38,
.chat-beacon-frame-parent26,
.chat-beacon-frame-wrapper19 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-beacon-badge38 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  padding: 0 7px;
  white-space: nowrap;
  z-index: 2;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.chat-beacon-frame-parent26 {
  flex: 1;
  flex-direction: column;
  gap: 56px;
  min-width: 107px;
  text-align: left;
  font-size: 16px;
}

.chat-beacon-badge39,
.chat-beacon-frame-parent21 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.chat-beacon-badge39 {
  width: 93px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  align-items: flex-start;
  padding: 0 7px;
  z-index: 2;
  color: #344054;
}

.chat-beacon-frame-parent21 {
  width: 615px;
  align-items: flex-end;
  gap: 6.3px;
  max-width: 100%;
  text-align: center;
  font-size: 14px;
  color: #475467;
}

.chat-beacon-divider-icon6 {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 2;
}

.chat-beacon-element-details-child,
.chat-beacon-frame-child12 {
  position: relative;
  border-radius: 12px;
  display: none;
}

.chat-beacon-frame-child12 {
  height: 208px;
  width: 1016px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  max-width: 100%;
}

.chat-beacon-element-details-child {
  height: 160px;
  width: 160px;
  background-color: #f5f5f5;
}

.chat-beacon-f4b711e503bc72fe396e5043e0c2-1-icon {
  height: 84px;
  width: 84px;
  position: relative;
  object-fit: cover;
  z-index: 3;
}

.chat-beacon-element-details {
  border-radius: 12px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 38px;
  box-sizing: border-box;
  min-width: 160px;
  z-index: 2;
}

.chat-beacon-supporting-text12 {
  position: relative;
  letter-spacing: -0.06px;
  line-height: 24px;
  z-index: 2;
}

.chat-beacon-end-label {
  width: 50px;
  border-radius: 8px;
  background-color: #fdb022;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1px 4px;
  box-sizing: border-box;
  z-index: 2;
}

.chat-beacon-text99 {
  position: relative;
  line-height: 23px;
  font-weight: 500;
  z-index: 2;
}

.chat-beacon-supporting-text14 {
  position: relative;
  font-size: 12px;
  line-height: 19px;
  color: #475467;
  display: inline-block;
  min-width: 104px;
  z-index: 3;
}

.chat-beacon-date-icon,
.chat-beacon-type-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-beacon-type-label {
  font-size: 14px;
  color: #101828;
}

.chat-beacon-date-icon {
  flex: 1;
  gap: 16px;
  min-width: 114px;
}

.chat-beacon-text100 {
  align-self: stretch;
  position: relative;
  line-height: 23px;
  font-weight: 500;
  z-index: 2;
}

.chat-beacon-supporting-text15 {
  width: 104px;
  position: relative;
  font-size: 12px;
  line-height: 19px;
  color: #475467;
  display: inline-block;
  z-index: 3;
}

.chat-beacon-work-type-icon,
.chat-beacon-work-type-value {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-beacon-work-type-value {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 1px;
  font-size: 14px;
  color: #101828;
}

.chat-beacon-work-type-icon {
  width: 152px;
  flex-direction: column;
  gap: 58px;
  min-width: 152px;
}

.chat-beacon-calendar-icon6 {
  flex: 1;
  gap: 8px;
  min-width: 218px;
  max-width: 100%;
}

.chat-beacon-action-icons,
.chat-beacon-calendar-icon6,
.chat-beacon-work-type-label {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-beacon-work-type-label {
  gap: 8px;
  margin-left: -9px;
}

.chat-beacon-action-icons {
  align-self: stretch;
  max-width: 100%;
  row-gap: 20px;
  font-size: 16px;
  color: #475467;
}

.chat-beacon-element-actions {
  flex: 1;
  flex-direction: column;
  gap: 8px;
  min-width: 302px;
}

.chat-beacon-element-actions,
.chat-beacon-element-details-parent,
.chat-beacon-frame-wrapper25 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.chat-beacon-element-details-parent {
  align-self: stretch;
  flex-direction: row;
  gap: 24px;
}

.chat-beacon-frame-wrapper25 {
  width: 648px;
  flex-direction: column;
  padding: 10px 0 0;
  box-sizing: border-box;
}

.chat-beacon-text101 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 29px;
  z-index: 3;
}

.chat-beacon-text-wrapper10 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 57px;
}

.chat-beacon-price4 {
  align-self: stretch;
  position: relative;
  font-size: 20px;
  letter-spacing: -0.02em;
  line-height: 32px;
  font-weight: 600;
  color: #6941c6;
  white-space: nowrap;
  z-index: 2;
}

.chat-beacon-frame-wrapper27 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 34px 0 0;
}

.chat-beacon-frame-parent32,
.chat-beacon-trend-chart-inner {
  display: flex;
  justify-content: flex-start;
}

.chat-beacon-frame-parent32 {
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  gap: 19px;
}

.chat-beacon-trend-chart-inner {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 0 0 3px;
}

.chat-beacon-dropdown-parent1,
.chat-beacon-frame-parent31,
.chat-beacon-trend-chart {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.chat-beacon-trend-chart {
  width: 113px;
  align-items: flex-start;
  gap: 4px;
}

.chat-beacon-dropdown-parent1,
.chat-beacon-frame-parent31 {
  align-items: flex-end;
}

.chat-beacon-frame-parent31 {
  flex: 1;
}

.chat-beacon-dropdown-parent1 {
  width: 153px;
  gap: 22px;
  text-align: center;
  font-size: 12px;
  color: #475467;
}

.chat-beacon-rectangle-parent10 {
  flex: 1;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 12px 15px 12px 23px;
  max-width: 100%;
  gap: 20px;
  z-index: 1;
}

.chat-beacon-f4b711e503bc72fe396e5043e0c2-1-icon1 {
  height: 84px;
  width: 84px;
  position: relative;
  object-fit: cover;
  z-index: 2;
}

.chat-beacon-rectangle-parent12 {
  border-radius: 12px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 38px;
  z-index: 1;
}

.chat-beacon-text103 {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  min-width: 128px;
  z-index: 1;
}

.chat-beacon-text-wrapper11 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 8px 0 0;
  font-size: 18px;
  color: #101828;
}

.chat-beacon-icon9 {
  width: 12px;
  height: 9.3px;
  position: relative;
  z-index: 1;
}

.chat-beacon-icon-wrapper4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px 0 0;
}

.chat-beacon-span {
  font-weight: 500;
}

.chat-beacon-supporting-text16 {
  height: 19px;
  position: relative;
  line-height: 24px;
  display: inline-block;
  flex-shrink: 0;
  min-width: 63px;
  z-index: 1;
}

.chat-beacon-supporting-text-wrapper1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4px 16px 0 0;
}

.chat-beacon-frame-child15 {
  width: 16px;
  height: 16px;
  position: relative;
  z-index: 1;
}

.chat-beacon-supporting-text17 {
  height: 19px;
  position: relative;
  line-height: 24px;
  display: inline-block;
  flex-shrink: 0;
  min-width: 70.9px;
  z-index: 1;
}

.chat-beacon-frame-parent36,
.chat-beacon-frame-parent37,
.chat-beacon-location-elements-triple {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.chat-beacon-frame-parent37,
.chat-beacon-location-elements-triple {
  gap: 7px;
  font-size: 16px;
}

.chat-beacon-frame-parent37 {
  gap: 8px;
  color: #fff;
}

.chat-beacon-text105,
.chat-beacon-text106,
.chat-beacon-user-avatar {
  position: relative;
  line-height: 23px;
  font-weight: 500;
  z-index: 1;
}

.chat-beacon-text105,
.chat-beacon-text106 {
  display: inline-block;
}

.chat-beacon-text105 {
  min-width: 103px;
}

.chat-beacon-text106 {
  width: 151px;
  flex-shrink: 0;
}

.chat-beacon-icon-button {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 34px;
}

.chat-beacon-supporting-text19 {
  position: relative;
  line-height: 19px;
  display: inline-block;
  min-width: 92.1px;
  z-index: 2;
}

.chat-beacon-sidebar-content1 {
  width: 161.9px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 20px 0 0;
  box-sizing: border-box;
}

.chat-beacon-supporting-text20,
.chat-beacon-supporting-text21 {
  position: relative;
  line-height: 19px;
  display: inline-block;
  flex-shrink: 0;
  z-index: 2;
}

.chat-beacon-supporting-text20 {
  min-width: 70.9px;
}

.chat-beacon-supporting-text21 {
  width: 104px;
}

.chat-beacon-sidebar1 {
  width: 469px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  max-width: 100%;
  font-size: 12px;
  color: #475467;
}

.chat-beacon-frame-parent33,
.chat-beacon-frame-parent34,
.chat-beacon-nav-item-button-icon {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.chat-beacon-nav-item-button-icon {
  align-self: stretch;
  flex-direction: column;
  font-size: 14px;
  color: #101828;
}

.chat-beacon-frame-parent33,
.chat-beacon-frame-parent34 {
  flex: 1;
}

.chat-beacon-frame-parent34 {
  flex-direction: column;
  gap: 16px;
  min-width: 335px;
}

.chat-beacon-frame-parent33 {
  flex-direction: row;
  gap: 24px;
}

.chat-beacon-text107 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 123px;
}

.chat-beacon-buttonsbutton-wrapper,
.chat-beacon-buttonsbutton19 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.chat-beacon-buttonsbutton19 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  justify-content: flex-start;
  padding: 6px 12px;
  gap: 5px;
  z-index: 1;
}

.chat-beacon-buttonsbutton-wrapper {
  align-self: stretch;
  justify-content: flex-end;
  padding: 0 27px 0 0;
}

.chat-beacon-arrow-square-up-right-icon4 {
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 3;
}

.chat-beacon-dropdown-parent2,
.chat-beacon-frame-parent38 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}

.chat-beacon-frame-parent38 {
  flex: 1;
  gap: 27px;
}

.chat-beacon-dropdown-parent2 {
  align-self: stretch;
  gap: 50px;
}

.chat-beacon-frame-wrapper29 {
  width: 215px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 9px;
  box-sizing: border-box;
  font-size: 14px;
  color: #344054;
}

.chat-beacon-rectangle-parent11 {
  flex: 1;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 12px 13px 22px 23px;
  gap: 63px;
  z-index: 1;
}

.chat-beacon-content-items5,
.chat-beacon-frame-child16,
.chat-beacon-rectangle-parent11 {
  box-sizing: border-box;
  max-width: 100%;
}

.chat-beacon-content-items5 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 30px 24px 32px;
  font-size: 12px;
  color: #475467;
}

.chat-beacon-frame-child16 {
  height: 208px;
  width: 1016px;
  position: relative;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  display: none;
  z-index: 1;
}

.chat-beacon-supporting-text-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  font-size: 16px;
  color: #475467;
}

.chat-beacon-search-icon-input {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 41px;
}

.chat-beacon-supporting-text24 {
  position: relative;
  font-size: 12px;
  line-height: 19px;
  color: #475467;
  display: inline-block;
  min-width: 104px;
  z-index: 1;
}

.chat-beacon-frame-parent43 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}

.chat-beacon-supporting-text25 {
  position: relative;
  font-size: 12px;
  line-height: 19px;
  color: #475467;
  display: inline-block;
  min-width: 113px;
  z-index: 1;
}

.chat-beacon-frame-parent42 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 10px;
  min-width: 272px;
  max-width: 100%;
}

.chat-beacon-text111 {
  align-self: stretch;
  position: relative;
  line-height: 23px;
  font-weight: 500;
  z-index: 1;
}

.chat-beacon-supporting-text26 {
  width: 113px;
  position: relative;
  font-size: 12px;
  line-height: 19px;
  color: #475467;
  display: inline-block;
  z-index: 1;
}

.chat-beacon-text-parent8 {
  width: 233px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  color: #101828;
}

.chat-beacon-frame-parent39,
.chat-beacon-frame-parent41 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.chat-beacon-frame-parent41 {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-end;
  gap: 34px;
  font-size: 16px;
  color: #fff;
}

.chat-beacon-frame-parent39 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  min-width: 445px;
}

.chat-beacon-dropdown14,
.chat-beacon-text112 {
  position: absolute;
  z-index: 1;
}

.chat-beacon-dropdown14 {
  top: 0;
  left: 306px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-beacon-text112 {
  top: 19px;
  left: 137px;
  line-height: 20px;
  display: inline-block;
  min-width: 41px;
}

.chat-beacon-price5 {
  flex: 1;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 32px;
  font-weight: 600;
  white-space: nowrap;
  z-index: 2;
}

.chat-beacon-price6,
.chat-beacon-text113 {
  position: relative;
  z-index: 1;
}

.chat-beacon-text113 {
  line-height: 20px;
  display: inline-block;
  min-width: 15px;
}

.chat-beacon-price6 {
  flex: 1;
  letter-spacing: -0.02em;
  line-height: 32px;
  font-weight: 600;
  white-space: nowrap;
}

.chat-beacon-content-table-cards {
  position: absolute;
  top: 39px;
  left: 0;
  width: 316px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 7.5px;
  font-size: 20px;
  color: #6941c6;
}

.chat-beacon-text114 {
  position: absolute;
  top: 70px;
  left: 116px;
  line-height: 20px;
  display: inline-block;
  min-width: 83px;
  z-index: 3;
}

.chat-beacon-beacon-1-1-parent3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  z-index: 1;
}

.chat-beacon-arrow-square-up-right-icon5 {
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 4;
}

.chat-beacon-arrow-square-up-right-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 43px 0 0;
}

.chat-beacon-frame-parent46,
.chat-beacon-frame-wrapper32 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.chat-beacon-frame-parent46 {
  flex: 1;
  justify-content: space-between;
  gap: 20px;
}

.chat-beacon-frame-wrapper32 {
  width: 217px;
  justify-content: flex-end;
  padding: 0 10px;
  box-sizing: border-box;
}

.chat-beacon-frame-parent45,
.chat-beacon-frame-wrapper31 {
  display: flex;
  flex-direction: column;
}

.chat-beacon-frame-parent45 {
  align-self: stretch;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 4px;
}

.chat-beacon-frame-wrapper31 {
  width: 326px;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 9px;
  box-sizing: border-box;
  min-width: 326px;
  max-width: 100%;
  margin-left: -33px;
  text-align: center;
  font-size: 12px;
  color: #475467;
}

.chat-beacon-card-container-child,
.chat-beacon-rectangle-parent13 {
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  max-width: 100%;
}

.chat-beacon-rectangle-parent13 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 12px 13px 22px 23px;
  row-gap: 20px;
  z-index: 1;
}

.chat-beacon-card-container-child {
  width: 1016px;
  height: 301px;
  position: relative;
  display: none;
}

.chat-beacon-supporting-text27 {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 83px;
  z-index: 2;
}

.chat-beacon-title-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  max-width: 100%;
}

.chat-beacon-supporting-text30 {
  position: relative;
  font-size: 12px;
  line-height: 19px;
  color: #475467;
  display: inline-block;
  min-width: 113px;
  z-index: 3;
}

.chat-beacon-detail-title-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 151px;
  font-size: 14px;
  color: #101828;
}

.chat-beacon-supporting-text31 {
  width: 113px;
  position: relative;
  font-size: 12px;
  line-height: 19px;
  color: #475467;
  display: inline-block;
  z-index: 3;
}

.chat-beacon-card-row {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 0 0;
  box-sizing: border-box;
  min-width: 445px;
  max-width: 100%;
}

.chat-beacon-dropdown15,
.chat-beacon-item-title {
  position: absolute;
  z-index: 2;
}

.chat-beacon-dropdown15 {
  top: 0;
  left: 306px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-beacon-item-title {
  top: 19px;
  left: 139px;
  line-height: 20px;
  display: inline-block;
  min-width: 36px;
}

.chat-beacon-price7 {
  flex: 1;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 32px;
  font-weight: 600;
  white-space: nowrap;
  z-index: 3;
}

.chat-beacon-text116 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 15px;
  z-index: 2;
}

.chat-beacon-currency {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5px 0 0;
  font-size: 12px;
  color: #475467;
}

.chat-beacon-item-title1 {
  position: absolute;
  top: 70px;
  left: 116px;
  line-height: 20px;
  display: inline-block;
  min-width: 83px;
  z-index: 4;
}

.chat-beacon-list-item {
  align-self: stretch;
  height: 90px;
  position: relative;
}

.chat-beacon-item-icons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  z-index: 2;
}

.chat-beacon-magnifier-1-icon7 {
  height: 20px;
  width: 20px;
  position: relative;
  object-fit: cover;
  min-height: 20px;
  z-index: 2;
}

.chat-beacon-frame-wrapper33 {
  width: 310px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 20px;
  box-sizing: border-box;
}

.chat-beacon-card-container,
.chat-beacon-card-list,
.chat-beacon-list-item-parent {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.chat-beacon-list-item-parent {
  width: 326px;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
  min-width: 326px;
  margin-left: -33px;
  text-align: center;
  font-size: 12px;
  color: #475467;
}

.chat-beacon-card-container,
.chat-beacon-card-list {
  align-items: flex-start;
}

.chat-beacon-card-list {
  align-self: stretch;
  flex-direction: row;
  row-gap: 20px;
}

.chat-beacon-card-container {
  flex: 1;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  flex-direction: column;
  padding: 12px 13px 22px 23px;
  gap: 24px;
  z-index: 1;
}

.chat-beacon-text-wrapper18 {
  padding: 0 60px 0 57px;
}

.chat-beacon-frame-wrapper37,
.chat-beacon-price-wrapper,
.chat-beacon-text-wrapper18,
.chat-beacon-text-wrapper19 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
}

.chat-beacon-price-wrapper {
  align-self: stretch;
  padding: 0 3px 0 0;
  font-size: 20px;
  color: #6941c6;
}

.chat-beacon-frame-wrapper37,
.chat-beacon-text-wrapper19 {
  padding: 0 33px 4px 30px;
}

.chat-beacon-frame-wrapper37 {
  padding: 0 37px 0 0;
}

.chat-beacon-frame-parent60 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 19px;
}

.chat-beacon-frame-wrapper36 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 7px 0 0;
}

.chat-beacon-text-parent12 {
  justify-content: flex-start;
  gap: 16px;
  font-size: 18px;
  color: #101828;
}

.chat-beacon-buttonsbutton-frame,
.chat-beacon-frame-parent64,
.chat-beacon-text-parent12 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.chat-beacon-frame-parent64 {
  justify-content: flex-start;
  gap: 7.3px;
}

.chat-beacon-buttonsbutton-frame {
  align-self: stretch;
  justify-content: flex-end;
  padding: 0 30px 0 0;
}

.chat-beacon-divider-container-child {
  width: 1016px;
  height: 493px;
  position: relative;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
  max-width: 100%;
}

.chat-beacon-badge-item-parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  min-width: 114px;
  color: #da2d20;
}

.chat-beacon-divider-icon8 {
  width: 968px;
  position: relative;
  max-height: 100%;
  max-width: 100%;
  z-index: 2;
}

.chat-beacon-heading9 {
  width: 254px;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
}

.chat-beacon-change7,
.chat-beacon-text139 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
}

.chat-beacon-change7 {
  display: inline-block;
  min-width: 38px;
}

.chat-beacon-text139 {
  flex: 1;
  color: #475467;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chat-beacon-change-and-text3,
.chat-beacon-number-and-badge3 {
  display: flex;
  justify-content: flex-start;
}

.chat-beacon-change-and-text3 {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  text-align: center;
  font-size: 14px;
  color: #079455;
}

.chat-beacon-number-and-badge3 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  min-width: 105px;
}

.chat-beacon-chart-mini-icon3 {
  height: 48px;
  width: 96px;
  position: relative;
  object-fit: contain;
}

.chat-beacon-dropdown19,
.chat-beacon-metric-item3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-beacon-dropdown19 {
  margin: 0 !important;
  position: absolute;
  top: 16px;
  right: 16px;
}

.chat-beacon-metric-item3 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 16px 15px 20px;
  position: relative;
  gap: 20px;
  min-width: 229px;
  z-index: 2;
}

.chat-beacon-buttonsbutton25,
.chat-beacon-buttonsbutton26,
.chat-beacon-metric-item-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-beacon-metric-item-parent {
  width: 968px;
  gap: 25px;
  max-width: 100%;
  font-size: 16px;
}

.chat-beacon-buttonsbutton25,
.chat-beacon-buttonsbutton26 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;

  padding: 6px 11px;
  gap: 5.5px;
  min-width: 92px;
  z-index: 2;
}

.chat-beacon-buttonsbutton26 {
  padding: 6px 10px 6px 12px;
  gap: 5.4px;
}

.chat-beacon-text145 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 70px;
}

.chat-beacon-ellipse-div {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: #17b26a;
  width: 17px;
  height: 17px;
  z-index: 3;
}

.chat-beacon-div {
  position: absolute;
  top: 4px;
  left: 6px;
  line-height: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 7px;
  height: 20px;
  min-width: 7px;
  z-index: 4;
}

.chat-beacon-ellipse-parent {
  height: 24px;
  width: 17px;
  position: absolute;
  margin: 0 !important;
  top: -9px;
  right: -2.7px;
  font-size: 10px;
  color: #fff;
}

.chat-beacon-buttonsbutton27 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  flex-direction: row;
  padding: 8px 12px;
  position: relative;
  gap: 6px;
  min-width: 79px;
  z-index: 2;
}

.chat-beacon-arrow-square-up-right-wrapper1,
.chat-beacon-buttonsbutton-parent1,
.chat-beacon-buttonsbutton27 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-beacon-buttonsbutton-parent1 {
  width: 523.3px;
  flex-direction: row;
  gap: 16px;
  max-width: calc(100% - 44px);
}

.chat-beacon-arrow-square-up-right-wrapper1 {
  flex-direction: column;
  padding: 8px 0 0;
}

.chat-beacon-divider-container1,
.chat-beacon-frame-parent71 {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}

.chat-beacon-frame-parent71 {
  width: 968px;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  font-size: 14px;
  color: #344054;
}

.chat-beacon-divider-container1 {
  flex: 1;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  padding: 12px 13px 22px 23px;
  gap: 24.3px;
  z-index: 1;
}

.chat-beacon-text146 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 72px;
}

.chat-beacon-buttonsbutton-wrapper1,
.chat-beacon-buttonsbutton28 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.chat-beacon-buttonsbutton28 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  justify-content: flex-start;
  padding: 6px 12px;
  gap: 6px;
  z-index: 1;
  cursor: pointer;
}

.chat-beacon-buttonsbutton-wrapper1 {
  align-self: stretch;
  justify-content: center;
  padding: 0 20px;
}

.chat-beacon-text147 {
  position: relative;
  line-height: 28px;
  font-weight: 600;
}

.chat-beacon-card-header-badge {
  position: relative;
  line-height: 18px;
  font-weight: 500;
}

.chat-beacon-badge46,
.chat-beacon-text-and-badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.chat-beacon-badge46 {
  border-radius: 9999px;
  background-color: #f9f5ff;
  border: 1px solid #e9d7fe;
  padding: 0 7px;
  white-space: nowrap;
  text-align: center;
  font-size: 12px;
  color: #6941c6;
}

.chat-beacon-text-and-badge {
  align-self: stretch;
  padding: 0 242px 0 0;
  gap: 8px;
}

.chat-beacon-supporting-text44 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chat-beacon-text-and-supporting-text2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
  min-width: 424px;
  max-width: 100%;
}

.chat-beacon-text151 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 92px;
}

.chat-beacon-actions3,
.chat-beacon-button11 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.chat-beacon-button11 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #7e56d9;
  border: 1px solid #7e56d9;
  overflow: hidden;
  justify-content: center;
  padding: 8px 13px;
  gap: 4px;
  color: #fff;
}

.chat-beacon-actions3 {
  justify-content: flex-start;
  gap: 12px;
  font-size: 14px;
  color: #475467;
}

.chat-beacon-content20,
.chat-beacon-divider-icon10 {
  align-self: stretch;
  max-width: 100%;
}

.chat-beacon-content20 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 20px 24px 0;
  box-sizing: border-box;
  gap: 16px;
}

.chat-beacon-divider-icon10 {
  position: relative;
  overflow: hidden;
  max-height: 100%;
}

.chat-beacon-card-header,
.chat-beacon-frame-parent72 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.chat-beacon-card-header {
  align-self: stretch;
  background-color: #fff;
  gap: 20px;
  z-index: 1;
  font-size: 26px;
  color: #101828;
}

.chat-beacon-frame-parent72 {
  flex: 1;
  gap: 24px;
}

.chat-beacon-button-group-base9,
.chat-beacon-frame-wrapper44 {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}

.chat-beacon-frame-wrapper44 {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 60px 32px;
  max-width: 100%;
  color: #344054;
  z-index: 0;
}

.chat-beacon-button-group-base9 {
  width: 83px;
  background-color: #f9fafb;
  border-right: 1px solid #d0d5dd;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  min-height: 40px;
}

.chat-beacon-text153 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 105px;
}

.chat-beacon-button-group-base10 {
  display: flex;
  padding: 10px 15px;
  white-space: nowrap;
}

.chat-beacon-button-group-base10,
.chat-beacon-button-group-base11,
.chat-beacon-button-group-base17 {
  background-color: #fff;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 40px;
}

.chat-beacon-button-group-base11 {
  align-self: stretch;
  display: none;
  padding: 8px 16px;
  z-index: 6;
}

.chat-beacon-button-group-base17 {
  width: 93px;
  flex-shrink: 0;
  display: flex;
  padding: 10px 15px;
}

.chat-beacon-button-group1 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-beacon-search-placeholder1 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 82px;

}

.chat-beacon-supporting-text45 {
  align-self: stretch;
  width: 54px;
  position: relative;
  line-height: 24px;
  color: #475467;
  display: none;
}

.chat-beacon-content22,
.chat-beacon-input-with-label3,
.chat-beacon-input3 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.chat-beacon-content22 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0 262px 0 0;
  box-sizing: border-box;
  gap: 8px;
}

.chat-beacon-input-with-label3,
.chat-beacon-input3 {
  align-self: stretch;
}

.chat-beacon-input3 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05),
  0 0 0 4px rgba(158, 119, 237, 0.24);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d6bbfb;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
  font-size: 16px;
  color: #101828;
}

.chat-beacon-input-with-label3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.chat-beacon-hint-text3 {
  width: 320px;
  position: relative;
  line-height: 20px;
  color: #475467;
  display: none;
}

.chat-beacon-input-dropdown3 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  max-width: 400px;
}

.chat-beacon-actions4,
.chat-beacon-button12 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.chat-beacon-button12 {
  width: 99px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
  padding: 10px 13px;
  gap: 4px;
}

.chat-beacon-actions4 {
  width: 511px;
  justify-content: flex-start;
  gap: 12px;
  max-width: 100%;
}

.chat-beacon-content-items9,
.chat-beacon-content21,
.chat-beacon-filters-bar1 {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}

.chat-beacon-content21 {
  align-self: stretch;
  border-radius: 12px;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 16px;
  gap: 20px;
}

.chat-beacon-content-items9,
.chat-beacon-filters-bar1 {
  justify-content: flex-start;
}

.chat-beacon-filters-bar1 {
  flex: 1;
  background-color: #fff;
  border-bottom: 1px solid #eaecf0;
  flex-direction: column;
  z-index: 2;
}

.chat-beacon-content-items9 {
  align-self: stretch;
  flex-direction: row;
  padding: 0 30px 23px 32px;
  color: #344054;
}

.chat-beacon-badge-number1,
.chat-beacon-text162 {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  min-width: 123px;
}

.chat-beacon-badge-number1 {
  line-height: 18px;
  font-weight: 500;
  min-width: 56px;
}

.chat-beacon-badge47,
.chat-beacon-text-and-badge1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.chat-beacon-badge47 {
  width: 72px;
  border-radius: 9999px;
  background-color: #f9f5ff;
  border: 1px solid #e9d7fe;
  box-sizing: border-box;
  padding: 0 7px;
  white-space: nowrap;
  text-align: center;
  font-size: 12px;
  color: #6941c6;
}

.chat-beacon-text-and-badge1 {
  align-self: stretch;
  padding: 0 729px 0 0;
  gap: 8px;
}

.chat-beacon-supporting-text46 {
  width: 697px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  display: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.chat-beacon-text-and-supporting-text3 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 12px 0;
  box-sizing: border-box;
  gap: 4px;
  max-width: 100%;
}

.chat-beacon-actions5,
.chat-beacon-card-header1,
.chat-beacon-content23 {
  justify-content: flex-start;
  max-width: 100%;
}

.chat-beacon-actions5 {
  display: none;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  color: #475467;
}

.chat-beacon-card-header1,
.chat-beacon-content23 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}

.chat-beacon-content23 {
  flex-direction: row;
  padding: 20px 24px 0;
  box-sizing: border-box;
  gap: 16px;
}

.chat-beacon-card-header1 {
  background-color: #fff;
  flex-direction: column;
  gap: 20px;
  flex-shrink: 0;
  font-size: 18px;
}

.chat-beacon-checkbox-base {
  height: 20px;
  width: 20px;
  position: relative;
  border-radius: 6px;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
}

.chat-beacon-checkbox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-beacon-sub-nav-item {
  position: relative;
  line-height: 18px;
  font-weight: 500;
  display: inline-block;
  min-width: 34px;
}

.chat-beacon-arrow-down-icon1 {
  width: 16px;
  height: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.chat-beacon-sub-nav-items {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.chat-beacon-sub-nav-items-wrapper {
  flex-direction: column;
  padding: 1px 0 0;
}

.chat-beacon-checkbox-wrapper,
.chat-beacon-sub-nav-items-wrapper,
.chat-beacon-table-header-cell {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-beacon-table-header-cell {
  width: 441px;
  margin: 0 !important;
  position: absolute;
  top: 0;
  left: -380px;
  background-color: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  flex-direction: row;
  padding: 12px 24px 10px;
  gap: 12px;
  font-size: 12px;
  color: #475467;
}

.chat-beacon-checkbox-wrapper {
  flex-direction: column;
  padding: 10px 0 0;
}

.chat-beacon-avatar-online-indicator {
  height: 13px;
  width: 13px;
  position: relative;
  border-radius: 9999px;
  background-color: #17b26a;
  border: 1.5px solid #fff;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.chat-beacon-avatar2,
.chat-beacon-contrast-border2 {
  height: 40px;
  width: 40px;
  border-radius: 9999px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.chat-beacon-contrast-border2 {
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
  justify-content: flex-end;
  padding: 30px 0 0 26px;
}

.chat-beacon-avatar2 {
  justify-content: flex-start;
  background-image: url(../../icons/Gerator/dashboard/avatar2@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.chat-beacon-account-button,
.chat-beacon-supporting-text47 {
  position: relative;
  line-height: 20px;
  display: inline-block;
}

.chat-beacon-account-button {
  font-weight: 500;
  min-width: 75px;
}

.chat-beacon-supporting-text47 {
  color: #475467;
  min-width: 97px;
}

.chat-beacon-table-cell {
  width: 405px;
  margin: 0 !important;
  position: absolute;
  top: 44px;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow-x: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.chat-beacon-avatar3 {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 9999px;
  flex-shrink: 0;
  background-image: url(../../icons/Gerator/dashboard/avatar3@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.chat-beacon-dropdown-first-date {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 96px;
}

.chat-beacon-table-cell1 {
  width: 467px;
  margin: 0 !important;
  position: absolute;
  top: 116px;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow-x: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.chat-beacon-text167 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 84px;
}

.chat-beacon-table-cell2 {
  width: 428px;
  margin: 0 !important;
  position: absolute;
  top: 188px;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow-x: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.chat-beacon-avatar5 {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 9999px;
  flex-shrink: 0;
  background-image: url(../../icons/Gerator/dashboard/avatar4@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.chat-beacon-card-content-third {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 103px;
  white-space: nowrap;
}

.chat-beacon-contrast-border6,
.chat-beacon-table-cell3 {
  position: absolute;
  margin: 0 !important;
  box-sizing: border-box;
}

.chat-beacon-table-cell3 {
  width: 411px;
  top: 260px;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  overflow-x: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.chat-beacon-contrast-border6 {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 9999px;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  overflow: hidden;
}

.chat-beacon-card-header-actions {
  flex: 1;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  z-index: 1;
}

.chat-beacon-avatar6 {
  height: 40px;
  width: 40px;
  border-radius: 9999px;
  background-color: #f2f4f7;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 0;
  box-sizing: border-box;
  position: relative;
}

.chat-beacon-button-upload-icon {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 82px;
}

.chat-beacon-button-upload,
.chat-beacon-table-cell4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: 14px;
  color: #101828;
}

.chat-beacon-table-cell4 {
  width: 428px;
  margin: 0 !important;
  position: absolute;
  top: 332px;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow-x: auto;
  flex-shrink: 0;
  flex-direction: row;
  padding: 16px 24px 14px;
  gap: 12px;
  text-align: center;
  font-size: 16px;
  color: #667085;
}

.chat-beacon-dropdown-table-input {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 78px;
}

.chat-beacon-table-cell5 {
  width: 428px;
  margin: 0 !important;
  position: absolute;
  bottom: 288px;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow-x: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.chat-beacon-avatar8 {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 9999px;
  flex-shrink: 0;
  background-image: url(../../icons/Gerator/dashboard/avatar5@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.chat-beacon-table-cell6,
.chat-beacon-table-cell7 {
  width: 411px;
  margin: 0 !important;
  position: absolute;
  bottom: 216px;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow-x: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.chat-beacon-table-cell7 {
  width: 428px;
  bottom: 144px;
  text-align: center;
  font-size: 16px;
  color: #667085;
}

.chat-beacon-avatar10 {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 9999px;
  flex-shrink: 0;
  background-image: url(../../icons/Gerator/dashboard/avatar6@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.chat-beacon-title3 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 67px;
}

.chat-beacon-table-cell8 {
  width: 428px;
  margin: 0 !important;
  position: absolute;
  bottom: 72px;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow-x: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.chat-beacon-avatar11 {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 9999px;
  flex-shrink: 0;
  background-image: url(../../icons/Gerator/dashboard/avatar7@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.chat-beacon-title4 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 95px;
}

.chat-beacon-table-cell9 {
  width: 428px;
  margin: 0 !important;
  position: absolute;
  bottom: 0;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow-x: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
  z-index: 1;
}

.chat-beacon-text169 {
  position: relative;
  line-height: 18px;
  font-weight: 500;
  display: inline-block;
  min-width: 50px;
}

.chat-beacon-table-header,
.chat-beacon-table-header-cell1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.chat-beacon-table-header {
  gap: 4px;
}

.chat-beacon-table-header-cell1 {
  width: 255px;
  background-color: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 13px 24px 11px;
  text-align: left;
  color: #475467;
}

.chat-beacon-text170 {
  position: relative;
  line-height: 18px;
  font-weight: 500;
  display: inline-block;
  min-width: 93px;
}

.chat-beacon-table-cell10 {
  width: 255px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 26px 24px 24px;
}

.chat-beacon-text171 {
  position: relative;
  line-height: 18px;
  font-weight: 500;
  display: inline-block;
  min-width: 85px;
}

.chat-beacon-table-cell11 {
  width: 247px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 26px 24px 24px;
}

.chat-beacon-text172 {
  position: relative;
  line-height: 18px;
  font-weight: 500;
  display: inline-block;
  min-width: 91px;
}

.chat-beacon-table-cell15,
.chat-beacon-table-cell16,
.chat-beacon-table-cell18 {
  width: 279px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 26px 24px 24px;
}

.chat-beacon-table-cell16,
.chat-beacon-table-cell18 {
  width: 271px;
}

.chat-beacon-table-cell18 {
  width: 239px;
}

.chat-beacon-column,
.chat-beacon-table-header-cell-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-beacon-column {
  width: 120px;
  flex-direction: column;
  padding: 0;
  box-sizing: border-box;
  z-index: 2;
  text-align: center;
  font-size: 12px;
  color: #344054;
}

.chat-beacon-table-header-cell-parent {
  flex-direction: row;
  position: relative;
}

.chat-beacon-frame-wrapper45 {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
  color: #101828;
}

.chat-beacon-text180 {
  position: relative;
  line-height: 18px;
  font-weight: 500;
  display: inline-block;
  min-width: 40px;
}

.chat-beacon-help-icon {
  height: 16px;
  width: 16px;
  position: relative;
}

.chat-beacon-table-header-cell2 {
  width: 297px;
  background-color: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 13px 24px 11px;
  font-size: 12px;
}

.chat-beacon-text181 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 101px;
}

.chat-beacon-table-cell20 {
  width: 297px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 26px 24px 24px;
}

.chat-beacon-user-cell {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 87px;
}

.chat-beacon-table-cell21 {
  width: 407px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 26px 23px 24px;
  max-width: 232%;
  flex-shrink: 0;
}

.chat-beacon-user-cell1 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 93px;
}

.chat-beacon-table-cell22 {
  width: 407px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 26px 24px 24px;
  max-width: 232%;
  flex-shrink: 0;
}

.chat-beacon-user-cell2,
.chat-beacon-user-cell3,
.chat-beacon-user-cell4 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 64px;
}

.chat-beacon-user-cell3,
.chat-beacon-user-cell4 {
  min-width: 84px;
}

.chat-beacon-user-cell4 {
  min-width: 82px;
}

.chat-beacon-table-cell25 {
  width: 328px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 26px 24px 24px;
  max-width: 187%;
  flex-shrink: 0;
}

.chat-beacon-user-cell5 {
  display: inline-block;
  min-width: 92px;
}

.chat-beacon-user-cell5,
.chat-beacon-user-cell6,
.chat-beacon-user-cell7 {
  position: relative;
  line-height: 20px;
}

.chat-beacon-user-cell6 {
  display: inline-block;
  min-width: 68px;
}

.chat-beacon-user-cell7 {
  white-space: nowrap;
}

.chat-beacon-column1,
.chat-beacon-table-cell29 {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
}

.chat-beacon-table-cell29 {
  width: 287px;
  border-bottom: 1px solid #eaecf0;
  flex-direction: row;
  align-items: center;
  padding: 26px 24px 24px;
}

.chat-beacon-column1 {
  width: 176px;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  z-index: 1;
}

.chat-beacon-user-header {
  position: relative;
  line-height: 18px;
  font-weight: 500;
  display: inline-block;
  min-width: 37px;
}

.chat-beacon-table-cell30,
.chat-beacon-table-header-cell3,
.chat-beacon-table-header2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.chat-beacon-table-header2 {
  flex: 1;
  justify-content: flex-start;
  gap: 4px;
}

.chat-beacon-table-cell30,
.chat-beacon-table-header-cell3 {
  border-bottom: 1px solid #eaecf0;
  justify-content: center;
}

.chat-beacon-table-header-cell3 {
  align-self: stretch;
  background-color: #f9fafb;
  padding: 13px 41px 11px;
}

.chat-beacon-table-cell30 {
  padding: 26px 50px 24px;
}

.chat-beacon-column2,
.chat-beacon-dropdown28 {
  width: 21px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-beacon-column2 {
  width: 120px;
  align-items: flex-end;
  padding: 0;
  box-sizing: border-box;
  z-index: 1;
  font-size: 12px;
}

.chat-beacon-frame-parent73 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 100%;
  gap: 20px;
  color: #475467;
}

.chat-beacon-page-label {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 59px;
}

.chat-beacon-buttonsbutton29 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 11px;
  gap: 4px;
}

.chat-beacon-button-wrap,
.chat-beacon-pagination-controls {
  display: flex;
  justify-content: flex-start;
}

.chat-beacon-button-wrap {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
}

.chat-beacon-pagination-controls {
  width: 326px;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-start;
  padding: 2px 0 0;
  box-sizing: border-box;
}

.chat-beacon-number11 {
  width: 7px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 7px;
}

.chat-beacon-content24,
.chat-beacon-pagination-number-base {
  flex: 1;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-beacon-content24 {
  padding: 10px 16px;
}

.chat-beacon-pagination-number-base {
  background-color: #f9fafb;
  overflow: hidden;
  color: #18222f;
}

.chat-beacon-number12 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 9px;
}

.chat-beacon-content25,
.chat-beacon-pagination-number-base1 {
  flex: 1;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-beacon-content25 {
  padding: 10px 15px;
}

.chat-beacon-pagination-number-base1 {
  overflow: hidden;
}

.chat-beacon-number13 {
  width: 10px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 10px;
}

.chat-beacon-content26,
.chat-beacon-pagination-number-base2 {
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-beacon-content26 {
  padding: 10px 15px;
}

.chat-beacon-pagination-number-base2 {
  overflow: hidden;
}

.chat-beacon-number14 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 13px;
}

.chat-beacon-content27 {
  flex: 1;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 13px;
}

.chat-beacon-number17 {
  width: 16px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 16px;
}

.chat-beacon-content30,
.chat-beacon-pagination-numbers {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-beacon-content30 {
  border-radius: 8px;
  padding: 10px 12px;
}

.chat-beacon-pagination-numbers {
  width: 292px;
  flex-shrink: 0;
  gap: 2px;
  text-align: center;
  color: #475467;
}

.chat-beacon-page-label1 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 32px;
}

.chat-beacon-button-wrap1,
.chat-beacon-buttonsbutton30 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.chat-beacon-buttonsbutton30 {
  width: 84px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
  padding: 6px 11px;
  gap: 4px;
}

.chat-beacon-button-wrap1 {
  align-self: stretch;
  justify-content: flex-end;
}

.chat-beacon-pagination,
.chat-beacon-table {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-beacon-pagination {
  align-self: stretch;
  border-top: 1px solid #eaecf0;
  overflow-x: auto;
  flex-direction: row;
  padding: 12px 24px 16px;
  gap: 12px;
  z-index: 3;
  color: #344054;
}

.chat-beacon-table {
  flex: 1;
  box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: column;
  max-width: 100%;
  z-index: 1;
}

.chat-beacon-content-items10 {
  align-self: stretch;
  padding: 0 30px 24px 32px;
  max-width: 100%;
}

.chat-beacon-content-items10,
.chat-beacon-content-items11,
.chat-beacon-table-cell40,
.chat-beacon-table-cell41,
.chat-beacon-table-cell42,
.chat-beacon-table-header-cell4 {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-beacon-content-items11 {
  align-self: stretch;
  padding: 0 30px 0 32px;
  max-width: 100%;
  font-size: 26px;
}

.chat-beacon-table-cell40,
.chat-beacon-table-cell41,
.chat-beacon-table-cell42,
.chat-beacon-table-header-cell4 {
  position: absolute;
  left: 0;
  border-bottom: 1px solid #eaecf0;
  gap: 12px;
}

.chat-beacon-table-header-cell4 {
  top: 0;
  background-color: #f9fafb;
  width: 100%;
  padding: 12px 24px 10px;
  max-width: 100%;
  font-size: 12px;
  color: #475467;
}

.chat-beacon-table-cell40,
.chat-beacon-table-cell41,
.chat-beacon-table-cell42 {
  top: 44px;
  width: 245px;
  overflow-x: auto;
  padding: 16px 24px 14px;
}

.chat-beacon-table-cell41,
.chat-beacon-table-cell42 {
  top: 116px;
  width: 252px;
}

.chat-beacon-table-cell42 {
  top: 188px;
}

.chat-beacon-table-cell43 {
  top: 260px;
  width: 252px;
}

.chat-beacon-table-cell43,
.chat-beacon-table-cell44,
.chat-beacon-table-cell45 {
  position: absolute;
  left: 0;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.chat-beacon-table-cell44 {
  top: 332px;
  width: 311px;
  text-align: center;
  font-size: 16px;
  color: #667085;
}

.chat-beacon-table-cell45 {
  top: 404px;
  width: 260px;
}

.chat-beacon-table-cell46 {
  top: 476px;
  box-sizing: border-box;
  width: 252px;
}

.chat-beacon-table-cell46,
.chat-beacon-table-cell47,
.chat-beacon-table-cell48,
.chat-beacon-table-cell49 {
  position: absolute;
  left: 0;
  border-bottom: 1px solid #eaecf0;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.chat-beacon-table-cell47 {
  top: 548px;
  box-sizing: border-box;
  width: 242px;
  text-align: center;
  font-size: 16px;
  color: #667085;
}

.chat-beacon-table-cell48,
.chat-beacon-table-cell49 {
  top: 620px;
}

.chat-beacon-table-cell49 {
  top: 692px;
  box-sizing: border-box;
  width: 242px;
  z-index: 1;
}

.chat-beacon-text211 {
  position: relative;
  line-height: 18px;
  font-weight: 500;
  display: inline-block;
  min-width: 55px;
}

.chat-beacon-table-header-cell5 {
  width: 441px;
  background-color: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 13px 24px 11px;
  max-width: 286%;
  flex-shrink: 0;
  font-size: 12px;
  color: #475467;
}

.chat-beacon-background {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 3px;
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
}

.chat-beacon-f4b711e503bc72fe396e5043e0c2-1-icon3 {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 24px;
  height: 24px;
  object-fit: cover;
  z-index: 1;
}

.chat-beacon-background-parent {
  height: 40px;
  width: 40px;
  position: relative;
}

.chat-beacon-supporting-text70 {
  position: relative;
  line-height: 20px;
  color: #475467;
  display: inline-block;
  min-width: 101px;
  white-space: nowrap;
}

.chat-beacon-table-cell50,
.chat-beacon-table-cell51 {
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 228px 14px 24px;
  gap: 12px;
  max-width: 263%;
  flex-shrink: 0;
}

.chat-beacon-table-cell51 {
  padding: 16px 290px 14px 24px;
  max-width: 303%;
}

.chat-beacon-progress-label {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 98px;
}

.chat-beacon-table-cell52 {
  border-bottom: 1px solid #eaecf0;
  flex-direction: row;
  align-items: center;
  padding: 16px 251px 14px 24px;
  gap: 12px;
  max-width: 278%;
  flex-shrink: 0;
}

.chat-beacon-column3,
.chat-beacon-table-cell52,
.chat-beacon-table-cell53 {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
}

.chat-beacon-table-cell53 {
  border-bottom: 1px solid #eaecf0;
  flex-direction: row;
  align-items: center;
  padding: 16px 234px 14px 24px;
  gap: 12px;
  max-width: 267%;
  flex-shrink: 0;
}

.chat-beacon-column3 {
  position: absolute;
  top: 0;
  left: 264.5px;
  width: 154.5px;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  z-index: 1;
}

.chat-beacon-table-header-cell-group {
  height: 764px;
  width: 441px;
  position: relative;
  max-width: 100%;
}

.chat-beacon-marker-pin-02-group,
.chat-beacon-table-cell65 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.chat-beacon-marker-pin-02-group {
  gap: 7px;
}

.chat-beacon-table-cell65 {
  align-self: stretch;
  border-bottom: 1px solid #eaecf0;
  padding: 26px 24px 24px;
}

.chat-beacon-table-header-cell-container {
  width: 279px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-beacon-column4 {
  align-self: stretch;
  display: flex;
  padding: 0;
  z-index: 1;
}

.chat-beacon-column-wrapper,
.chat-beacon-column4,
.chat-beacon-column5 {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-beacon-column-wrapper {
  width: 66px;
  display: flex;
  padding: 0 22px 0 0;
  box-sizing: border-box;
  text-align: center;
  font-size: 12px;
  color: #344054;
}

.chat-beacon-column5 {
  width: 176px;
  padding: 0;
  min-width: 176px;
}

.chat-beacon-column5,
.chat-beacon-column6,
.chat-beacon-table-header-cell8 {
  display: flex;
  box-sizing: border-box;
}

.chat-beacon-table-header-cell8 {
  width: 120px;
  background-color: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 13px 41px 11px;
}

.chat-beacon-column6 {
  width: 79px;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0;
  min-width: 79px;
  font-size: 12px;
}

.chat-beacon-column-parent,
.chat-beacon-frame-parent74,
.chat-beacon-pagination1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.chat-beacon-column-parent {
  width: 333px;
  justify-content: flex-start;
  gap: 78px;
  max-width: 100%;
  z-index: 1;
  color: #475467;
}

.chat-beacon-frame-parent74,
.chat-beacon-pagination1 {
  align-self: stretch;
}

.chat-beacon-frame-parent74 {
  justify-content: space-between;
  max-width: 100%;
  gap: 20px;
}

.chat-beacon-pagination1 {
  border-top: 1px solid #eaecf0;
  overflow-x: auto;
  justify-content: flex-start;
  padding: 12px 24px 16px;
  gap: 12px;
  z-index: 2;
  color: #344054;
}

.chat-beacon-text227 {
  position: relative;
  line-height: 18px;
  font-weight: 500;
  display: inline-block;
  min-width: 73px;
}

.chat-beacon-badge50,
.chat-beacon-text-and-badge4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.chat-beacon-badge50 {
  width: 89px;
  border-radius: 9999px;
  background-color: #f9f5ff;
  border: 1px solid #e9d7fe;
  box-sizing: border-box;
  padding: 0 7px;
  white-space: nowrap;
  text-align: center;
  font-size: 12px;
  color: #6941c6;
}

.chat-beacon-text-and-badge4 {
  align-self: stretch;
  padding: 0 332px 0 0;
  gap: 7px;
}

.chat-beacon-content-items14,
.chat-beacon-text-and-supporting-text16 {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}

.chat-beacon-text-and-supporting-text16 {
  flex: 1;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  min-width: 436px;
}

.chat-beacon-content-items14 {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 29px 0 33px;
  box-sizing: border-box;
  font-size: 26px;
}

.chat-beacon-text232 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 53px;
}

.chat-beacon-button-group-base27,
.chat-beacon-button-group-base28 {
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  min-height: 40px;
}

.chat-beacon-button-group-base27 {
  width: 85px;
  background-color: #f9fafb;
  flex-shrink: 0;
  white-space: nowrap;
}

.chat-beacon-button-group-base28 {
  background-color: #fff;
}

.chat-beacon-text240 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 89px;
}

.chat-beacon-filter-bar-container {
  align-self: stretch;
  flex-direction: row;
  padding: 0 29px 0 33px;
  box-sizing: border-box;
  color: #344054;
}

.chat-beacon-filter-bar-container,
.chat-beacon-frame-parent,
.chat-beacon-all-content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.chat-beacon-frame-parent {
  flex: 1;
  flex-direction: column;
  min-width: 701px;
  font-size: 14px;
}

.chat-beacon-all-content {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  /* padding: 0 0 67px; */
  box-sizing: border-box;
  row-gap: 20px;
}

.chat-beacon-featured-icon {
  width: 48px;
  height: 48px;
  position: relative;
  border-radius: 10px;
}

.chat-beacon-supporting-text82 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
}

.chat-beacon-text-and-supporting-text17 {
  width: 352px;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
  max-width: 352px;
}

.chat-beacon-buttonsbutton33,
.chat-beacon-content45,
.chat-beacon-text-and-supporting-text17 {
  display: flex;
  align-items: center;
}

.chat-beacon-content45 {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 20px;
  gap: 16px;
}

.chat-beacon-buttonsbutton33 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  flex-direction: row;
  justify-content: center;
  padding: 8px 15px;
  gap: 6px;
}

.chat-beacon-text245 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 91px;
}

.chat-beacon-actions11,
.chat-beacon-buttonsbutton34 {
  display: flex;
  flex-direction: row;
}

.chat-beacon-buttonsbutton34 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #7e56d9;
  border: 1px solid #7e56d9;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 8px 15px;
  gap: 6px;
  color: #fff;
}

.chat-beacon-actions11 {
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  text-align: left;
  color: #344054;
}

.chat-beacon-empty-state,
.chat-beacon-empty-state1 {
  flex: 1;
  display: flex;
  align-items: center;
  max-width: 100%;
}

.chat-beacon-empty-state1 {
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
}

.chat-beacon-empty-state {
  flex-direction: row;
  justify-content: center;
  z-index: 1;
}

.chat-beacon-empty-state-wrapper,
.chat-beacon-all {
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
}

.chat-beacon-empty-state-wrapper {
  width: 1074px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 29px;
  text-align: center;
}

.chat-beacon-all {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  /* padding: 0 0 245px; */
}

.chat-beacon-bifocal-optometry-measurement-icon {
  width: 391px;
  position: relative;
  max-height: 100%;
  object-fit: cover;
  max-width: 100%;
}

.chat-beacon-title8 {
  align-self: stretch;
  position: relative;
  line-height: 30px;
  font-weight: 600;
}

.chat-beacon-content46,
.chat-beacon-text-and-supporting-text18 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.chat-beacon-text-and-supporting-text18 {
  width: 352px;
  gap: 8px;
  max-width: 352px;
}

.chat-beacon-content46 {
  align-self: stretch;
  padding: 0 20px;
  box-sizing: border-box;
  gap: 20px;
  max-width: 100%;
}

.chat-beacon-add,
.chat-beacon-learn-more {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 98px;
}

.chat-beacon-learn-more {
  min-width: 88px;
}

.chat-beacon-actions12,
.chat-beacon-empty-state3 {
  display: flex;
  justify-content: flex-start;
}

.chat-beacon-actions12 {
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
  text-align: left;
  font-size: 16px;
  color: #344054;
}

.chat-beacon-empty-state3 {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  max-width: 100%;
}

.chat-beacon-empty-state-container,
.chat-beacon-empty-state2 {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  max-width: 100%;
}

.chat-beacon-empty-state2 {
  flex: 1;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  padding: 41.5px 0;
  z-index: 1;
}

.chat-beacon-empty-state-container {
  width: 1076px;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 30px 124px;
  text-align: center;
  font-size: 20px;
}









.chat-beacon-beacon-frame-layout {

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  background-color: #fff;
  /* overflow: hidden; */
  height: max-content;

  align-self: stretch;
  height: max-content;


  max-width: 1524px;
  margin: 0 auto;
  position: relative;
  align-items: flex-end;
  padding: 7px 0 0;
  box-sizing: border-box;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  font-size: 16px;
  color: #101828;
  font-family: Inter;
}

/* NEW CSS  */

.chat-beacon-buttonsbutton5a {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 15px;
  background-color: #7e56d9;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  gap: 6px;
  position: relative;
}

.chat-beacon-buttonsbutton5a:hover{
  background-color: #6941c6;
}

.chat-beacon-search-lg-icon1 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.chat-beacon-text-padding15 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.chat-beacon-text74 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 91px;
}

.chat-beacon-dropdown-wrapper {
  position: absolute;
  z-index: 3;
  right: 158px;
  top: 227px;
}

@media screen and (max-width: 1275px) {

  .chat-beacon-all,
  .chat-beacon-all-content {
    padding-bottom: 20px;
    box-sizing: border-box;
  }

  .chat-beacon-all {
    padding-bottom: 67px;
  }

  .chat-beacon-container3 {
    max-width: 100%;
  }
}

@media screen and (max-width: 1100px) {
  .chat-beacon-content13 {
    gap: 35px;
    flex-wrap: wrap;
  }

  .chat-beacon-main {
    height: auto;
    padding-top: 21px;
    padding-bottom: 21px;
    box-sizing: border-box;
  }

  .chat-beacon-content-elements-parent,
  .chat-beacon-content17,
  .chat-beacon-frame-group,
  .chat-beacon-rectangle-parent10,
  .chat-beacon-rectangle-parent5,
  .chat-beacon-rectangle-parent7 {
    flex-wrap: wrap;
  }

  .chat-beacon-rectangle-parent11 {
    gap: 31px;
    flex-wrap: wrap;
  }

  .chat-beacon-frame-wrapper31 {
    flex: 1;
  }

  .chat-beacon-rectangle-parent13 {
    flex-wrap: wrap;
  }

  .chat-beacon-list-item-parent {
    flex: 1;
  }

  .chat-beacon-card-list,
  .chat-beacon-content20,
  .chat-beacon-content21,
  .chat-beacon-metric-item-parent {
    flex-wrap: wrap;
  }

  .chat-beacon-text-and-badge1 {
    flex-wrap: wrap;
    padding-right: 364px;
    box-sizing: border-box;
  }

  .chat-beacon-content23,
  .chat-beacon-frame-parent73,
  .chat-beacon-frame-parent74 {
    flex-wrap: wrap;
  }

  .chat-beacon-all {
    padding-bottom: 44px;
    box-sizing: border-box;
  }

  .chat-beacon-empty-state-container {
    padding-bottom: 81px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 750px) {


  .chat-beacon-heading {
    font-size: 24px;
    line-height: 30px;
  }

  .chat-beacon-content13 {
    gap: 17px;
  }

  .chat-beacon-tabs-and-filters {
    flex-wrap: wrap;
  }

  .chat-beacon-jul {
    width: 100%;
    height: 17px;
  }

  .chat-beacon-x-axis {
    flex-wrap: wrap;
  }

  .chat-beacon-number {
    font-size: 24px;
    line-height: 30px;
  }

  .chat-beacon-main {
    gap: 16px;
  }

  .chat-beacon-number3,
  .chat-beacon-number4 {
    font-size: 29px;
    line-height: 35px;
  }

  .chat-beacon-dropdowns {
    padding-right: 83px;
    box-sizing: border-box;
    min-width: 100%;
    flex: 1;
  }

  .chat-beacon-badge-elements,
  .chat-beacon-card-elements,
  .chat-beacon-dropdowns,
  .chat-beacon-location-badge-number {
    flex-wrap: wrap;
  }

  .chat-beacon-action-fields {
    min-width: 100%;
  }

  .chat-beacon-content-actions {
    flex-wrap: wrap;
    min-width: 100%;
  }

  .chat-beacon-frame-parent2,
  .chat-beacon-primary-table {
    flex-wrap: wrap;
  }

  .chat-beacon-frame-parent1 {
    flex-wrap: wrap;
    min-width: 100%;
  }

  .chat-beacon-frame-parent17,
  .chat-beacon-frame-parent18,
  .chat-beacon-frame-parent21 {
    flex-wrap: wrap;
  }

  .chat-beacon-element-details,
  .chat-beacon-f4b711e503bc72fe396e5043e0c2-1-icon {
    flex: 1;
  }

  .chat-beacon-action-icons,
  .chat-beacon-element-details-parent {
    flex-wrap: wrap;
  }

  .chat-beacon-icon-button {
    gap: 17px;
  }

  .chat-beacon-icon-button,
  .chat-beacon-sidebar1 {
    flex-wrap: wrap;
  }

  .chat-beacon-frame-parent33 {
    flex-wrap: wrap;
    min-width: 100%;
  }

  .chat-beacon-rectangle-parent11 {
    gap: 16px;
  }

  .chat-beacon-frame-parent41 {
    gap: 17px;
    flex-wrap: wrap;
  }

  .chat-beacon-frame-parent39 {
    min-width: 100%;
  }

  .chat-beacon-frame-wrapper31 {
    margin-left: 0;
  }

  .chat-beacon-card-row {
    min-width: 100%;
  }

  .chat-beacon-list-item-parent {
    margin-left: 0;
  }

  .chat-beacon-buttonsbutton-parent1 {
    flex-wrap: wrap;
  }

  .chat-beacon-text-and-badge {
    flex-wrap: wrap;
    padding-right: 121px;
    box-sizing: border-box;
  }

  .chat-beacon-text-and-supporting-text2 {
    min-width: 100%;
  }

  .chat-beacon-actions4 {
    flex-wrap: wrap;
  }

  .chat-beacon-text-and-badge1 {
    padding-right: 182px;
    box-sizing: border-box;
  }

  .chat-beacon-text-and-supporting-text3 {
    min-width: 100%;
  }

  .chat-beacon-actions5,
  .chat-beacon-table-cell51,
  .chat-beacon-table-cell52 {
    flex-wrap: wrap;
  }

  .chat-beacon-text-and-badge4 {
    flex-wrap: wrap;
    padding-right: 166px;
    box-sizing: border-box;
  }

  .chat-beacon-frame-parent,
  .chat-beacon-text-and-supporting-text16 {
    min-width: 100%;
  }

  .chat-beacon-empty-state3 {
    gap: 16px;
  }

  .chat-beacon-empty-state2 {
    padding-top: 27px;
    padding-bottom: 27px;
    box-sizing: border-box;
  }

  .chat-beacon-container3 {
    gap: 16px;
  }

}

@media screen and (max-width: 450px) {

  .chat-beacon-account {
    gap: 23px;
    padding-right: 20px;
    box-sizing: border-box;
  }

  .chat-beacon-heading {
    font-size: 18px;
    line-height: 23px;
  }

  .chat-beacon-actions {
    flex-wrap: wrap;
  }

  .chat-beacon-number {
    font-size: 18px;
    line-height: 23px;
  }

  .chat-beacon-number-and-chart {
    flex-wrap: wrap;
  }

  .chat-beacon-main {
    padding-top: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
  }

  .chat-beacon-text46 {
    font-size: 21px;
    line-height: 30px;
  }

  .chat-beacon-number3,
  .chat-beacon-number4 {
    font-size: 22px;
    line-height: 26px;
  }

  .chat-beacon-dropdowns {
    padding-right: 20px;
    box-sizing: border-box;
  }

  .chat-beacon-price {
    font-size: 16px;
    line-height: 26px;
  }

  .chat-beacon-buttonsbutton-parent {
    flex-wrap: wrap;
  }

  .chat-beacon-date-elements-parent {
    flex: 1;
  }

  .chat-beacon-badge-container,
  .chat-beacon-frame-parent12 {
    flex-wrap: wrap;
  }

  .chat-beacon-badge-group {
    height: auto;
    min-height: 104px;
  }

  .chat-beacon-price2 {
    font-size: 16px;
    line-height: 26px;
  }

  .chat-beacon-work-type-icon {
    flex: 1;
  }

  .chat-beacon-calendar-icon6 {
    flex-wrap: wrap;
  }

  .chat-beacon-work-type-label {
    margin-left: 0;
  }

  .chat-beacon-price4 {
    font-size: 16px;
    line-height: 26px;
  }

  .chat-beacon-frame-parent36,
  .chat-beacon-frame-parent42 {
    flex-wrap: wrap;
  }

  .chat-beacon-price5,
  .chat-beacon-price6,
  .chat-beacon-price7 {
    font-size: 16px;
    line-height: 26px;
  }

  .chat-beacon-frame-parent64 {
    flex-wrap: wrap;
  }

  .chat-beacon-text147 {
    font-size: 21px;
    line-height: 22px;
  }

  .chat-beacon-content22,
  .chat-beacon-text-and-badge {
    padding-right: 20px;
    box-sizing: border-box;
  }

  .chat-beacon-input-dropdown3 {
    max-width: 100%;
  }

  .chat-beacon-table-cell50,
  .chat-beacon-table-cell53 {
    flex-wrap: wrap;
  }

  .chat-beacon-table-header-cell-group {
    height: auto;
    min-height: 764px;
  }

  .chat-beacon-column5,
  .chat-beacon-column6 {
    flex: 1;
  }

  .chat-beacon-column-parent {
    gap: 39px;
    flex-wrap: wrap;
  }

  .chat-beacon-text-and-badge4 {
    padding-right: 20px;
    box-sizing: border-box;
  }

  .chat-beacon-all {
    padding-bottom: 29px;
    box-sizing: border-box;
  }

  .chat-beacon-title8 {
    font-size: 16px;
    line-height: 24px;
  }

  .chat-beacon-empty-state-container {
    padding-bottom: 53px;
    box-sizing: border-box;
  }

}