/* Search input component */
.cursor-pointer {
    cursor: pointer;
}

.dashboard-content3 {
    display: flex;
    align-items: center;
    width: 100%;
}

.dashboard-search-placeholder {
    flex-grow: 1;
    margin: 0 8px;
    border: none;
    outline: none;
    background: transparent;
}

/* Ensure the X icon aligns properly */
.dashboard-content3 .lucide-x {
    flex-shrink: 0;
}

.dashboard-input2-custom{
   
    align-self: stretch;
  /* box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05); */
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  align-items: center;
  padding: 8px 13px;
  font-size: 16px;
  color: #667085;
  min-width: 208px;
  transition: box-shadow 0.3s ease, border 0.3s ease;
  
}