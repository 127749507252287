/* Custom CSS */
/* .sales-iq-detail-device-sales-iq-detail-device-frame-layout-custom {
  margin-top: 0px !important;
} */
.sales-iq-detail-page-social-share-popup{
  position: absolute;
  width: 130px;
  right: 35px;
}

.sales-iq-detail-device-buttonsbutton-wrapper1-child {
    outline: none;
    border: none;
    background-color: #fff;
    padding: 0;
  }
  
  
  .sales-iq-detail-device-main-custom {
    width: 100% !important;
  }
  
  .sales-iq-detail-device-search-lg-icon-custom {
    cursor: pointer;
  }
  
  .sales-iq-detail-device-sales-iq-detail-device-frame-layout-child {
    position: relative;
    background-color: #fff;
    display: none;
  }
  
  /* .sales-iq-detail-device-sales-iq-detail-device-frame-layout-child {
    align-self: stretch;
    height: 8320px;
    margin-top: 200px;
  } */
  
  
  
  
  
  
  
  
  .sales-iq-detail-device-text {
    position: relative;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
    min-width: 30px;
  }
  
  .sales-iq-detail-device-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
  }
  
  
  .sales-iq-detail-device-chevron-down-icon {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  
  
  .sales-iq-detail-device-badge1,
  .sales-iq-detail-device-nav-item-base1 {
    background-color: #f9fafb;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  
  
  
  .sales-iq-detail-device-navigation,
  .sales-iq-detail-device-search-input {
    display: flex;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-search-input {
    flex-direction: column;
    align-items: flex-start;
    padding: 2px 0 0;
  }
  
  .sales-iq-detail-device-label {
    width: 47px;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: none;
  }
  
  .sales-iq-detail-device-search-lg-icon {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .sales-iq-detail-device-search-placeholder {
    flex: 1;
    position: relative;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 8px;
    outline: none;
    border: none;
    font-size: 16px;
  }
  
  .sales-iq-detail-device-content3,
  .sales-iq-detail-device-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-content3 {
    flex: 1;
    gap: 8px;
  }
  
  
  
  
  
  
  
  
  .sales-iq-detail-device-divider-icon,
  .sales-iq-detail-device-home-line-icon {
    position: relative;
    overflow: hidden;
  }
  
  .sales-iq-detail-device-divider-icon {
    align-self: stretch;
    max-width: 100%;
    max-height: 100%;
    z-index: 1;
  }
  
  
  
  .sales-iq-detail-device-contrast-border1 {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 9999px;
    border: 0.8px solid rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    overflow: hidden;
  }
  
  
  
  
  
  .sales-iq-detail-device-arrow-narrow-left-icon {
    height: 24px;
    width: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    min-height: 24px;
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  .sales-iq-detail-device-content12,
  .sales-iq-detail-device-navigation4 {
    display: flex;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-content12 {
    flex: 1;
    flex-direction: row;
    align-items: center;
    padding: 0 142px 0 0;
    gap: 12px;
  }
  
  .sales-iq-detail-device-content5,
  .sales-iq-detail-device-navigation4 {
    align-self: stretch;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .sales-iq-detail-device-content5 {
    padding: 36px 16px 0;
    gap: 16px;
  }
  
  .sales-iq-detail-device-supporting-text,
  .sales-iq-detail-device-title {
    align-self: stretch;
    position: relative;
    line-height: 20px;
  }
  
  .sales-iq-detail-device-title {
    font-weight: 600;
  }
  
  .sales-iq-detail-device-supporting-text {
    color: #475467;
    white-space: nowrap;
  }
  
  
  .sales-iq-detail-device-breadcrumb-button-base {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-breadcrumb-button-base {
    border-radius: 6px;
    padding: 4px;
  }
  
  .sales-iq-detail-device-chevron-right-icon {
    height: 16px;
    width: 16px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .sales-iq-detail-device-location {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 74px;
  }
  
  .sales-iq-detail-device-breadcrumb-button-base1 {
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
  }
  
  .sales-iq-detail-device-text20 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    font-weight: 500;
  }
  
  .sales-iq-detail-device-breadcrumb-button-base2 {
    align-self: stretch;
    border-radius: 6px;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 7px;
  }
  
  .sales-iq-detail-device-chevron-right-icon2 {
    height: 16px;
    width: 16px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  
  .sales-iq-detail-device-text21 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    white-space: nowrap;
  }
  
  .sales-iq-detail-device-breadcrumb-button-base3 {
    align-self: stretch;
    border-radius: 6px;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
  }
  
  .sales-iq-detail-device-text22 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 65px;
  }
  
  .sales-iq-detail-device-breadcrumb-button-base4 {
    border-radius: 6px;
    
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    color: #344054;
  }
  
  .sales-iq-detail-device-tabs {
    gap: 8px;
  }
  
  .sales-iq-detail-device-breadcrumbs,
  .sales-iq-detail-device-tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-heading,
  .sales-iq-detail-device-supporting-text1 {
    align-self: stretch;
    position: relative;
  }
  
  .sales-iq-detail-device-heading {
    line-height: 38px;
    font-weight: 600;
  }
  
  .sales-iq-detail-device-supporting-text1 {
    font-size: 16px;
    line-height: 24px;
    color: #475467;
  }
  
  .sales-iq-detail-device-text-and-supporting-text {
    width: 678px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
    min-width: 320px;
    max-width: 100%;
    flex-shrink: 0;
    font-size: 30px;
    color: #101828;
  }
  
  .sales-iq-detail-device-placeholder-icon {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
    min-height: 20px;
  }
  
  .sales-iq-detail-device-text-padding {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
  }
  
  .sales-iq-detail-device-button,
  .sales-iq-detail-device-button1,
  .sales-iq-detail-device-button2 {
    height: 40px;
    border-radius: 8px;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    justify-content: center;
    gap: 4px;
  }
  
  .sales-iq-detail-device-button {
    flex-direction: row;
    align-items: center;
    padding: 10px 14px;
  }
  
  .sales-iq-detail-device-button1,
  .sales-iq-detail-device-button2 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    background-color: #fff;
    padding: 10px 13px;
  }
  
  .sales-iq-detail-device-button1 {
    border: 1px solid #d6bbfb;
    flex-direction: row;
    align-items: center;
    color: #6941c6;
  }
  
  .sales-iq-detail-device-button2 {
    border: 1px solid #d0d5dd;
    color: #344054;
  }
  
  .sales-iq-detail-device-actions,
  .sales-iq-detail-device-button2,
  .sales-iq-detail-device-button3 {
    flex-direction: row;
    align-items: center;
  }
  
  .sales-iq-detail-device-button3 {
    height: 40px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #7e56d9;
    border: 1px solid #7e56d9;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    justify-content: center;
    padding: 10px 13px;
    gap: 4px;
    color: #fff;
  }
  
  .sales-iq-detail-device-actions {
    display: none;
    justify-content: flex-start;
    gap: 12px;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-hint-text {
    width: 320px;
    position: relative;
    line-height: 20px;
    display: none;
  }
  
  .sales-iq-detail-device-button4,
  .sales-iq-detail-device-button5 {
    align-self: stretch;
    border-radius: 8px;
    overflow: hidden;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 14px;
    gap: 4px;
  }
  
  .sales-iq-detail-device-button5 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    background-color: #fff;
    border: 1px solid #d6bbfb;
    padding: 10px 13px;
    color: #6941c6;
  }
  
  .sales-iq-detail-device-upload-04-icon {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    min-height: 20px;
  }
  
  .sales-iq-detail-device-text29 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 45px;
  }
  
  .sales-iq-detail-device-button6,
  .sales-iq-detail-device-text-padding6 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
  }
  
  .sales-iq-detail-device-button6 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    padding: 8px 13px;
    gap: 4px;
    color: #344054;
  }
  
  .sales-iq-detail-device-text30 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 73px;
  }
  
  .sales-iq-detail-device-button7 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #7e56d9;
    border: 1px solid #7e56d9;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 13px;
    gap: 4px;
    color: #fff;
  }
  
  .sales-iq-detail-device-content13,
  .sales-iq-detail-device-input-dropdown {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-input-dropdown {
    width: 320px;
    flex-direction: column;
    gap: 8px;
    min-width: 200px;
    max-width: 320px;
    flex-shrink: 0;
  }
  
  .sales-iq-detail-device-content13 {
    flex-direction: row;
    padding: 0;
    box-sizing: border-box;
    gap: 70px;
  }
  
  .sales-iq-detail-device-content13,
  .sales-iq-detail-device-divider-icon1,
  .sales-iq-detail-device-page-header {
    align-self: stretch;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-divider-icon1 {
    position: relative;
    overflow: hidden;
    max-height: 100%;
    display: none;
  }
  
  .sales-iq-detail-device-page-header {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
  }
  
  .sales-iq-detail-device-text31 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 71px;
  }
  
  .sales-iq-detail-device-button-group-base {
    background-color: #f9fafb;
    border-right: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    min-height: 40px;
    white-space: nowrap;
  }
  
  .sales-iq-detail-device-text32 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 55px;
  }
  
  .sales-iq-detail-device-button-group-base1,
  .sales-iq-detail-device-button-group-base2 {
    width: 87px;
    background-color: #fff;
    border-right: 1px solid #d0d5dd;
    box-sizing: border-box;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    min-height: 40px;
    white-space: nowrap;
  }
  
  .sales-iq-detail-device-button-group-base2 {
    width: 77px;
  }
  
  .sales-iq-detail-device-text34 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
  }
  
  .sales-iq-detail-device-button-group-base3,
  .sales-iq-detail-device-button-group-base4,
  .sales-iq-detail-device-button-group-base5,
  .sales-iq-detail-device-button-group-base6,
  .sales-iq-detail-device-button-group-base7 {
    align-self: stretch;
    background-color: #fff;
    border-right: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    min-height: 40px;
    z-index: 5;
  }
  
  .sales-iq-detail-device-button-group-base4,
  .sales-iq-detail-device-button-group-base5,
  .sales-iq-detail-device-button-group-base6,
  .sales-iq-detail-device-button-group-base7 {
    z-index: 4;
  }
  
  .sales-iq-detail-device-button-group-base5,
  .sales-iq-detail-device-button-group-base6,
  .sales-iq-detail-device-button-group-base7 {
    z-index: 3;
  }
  
  .sales-iq-detail-device-button-group-base6,
  .sales-iq-detail-device-button-group-base7 {
    z-index: 2;
  }
  
  .sales-iq-detail-device-button-group-base7 {
    z-index: 1;
  }
  
  .sales-iq-detail-device-text39 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 61px;
  }
  
  .sales-iq-detail-device-button-group,
  .sales-iq-detail-device-button-group-base8 {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
  }
  
  .sales-iq-detail-device-button-group-base8 {
    width: 93px;
    background-color: #fff;
    border-right: 1px solid #d0d5dd;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    min-height: 40px;
    white-space: nowrap;
  }
  
  .sales-iq-detail-device-button-group {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    overflow-x: auto;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-filter-date {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 85px;
  }
  
  .sales-iq-detail-device-buttonsbutton1 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 13px;
    gap: 4px;
    cursor: pointer;
    text-wrap: nowrap;
  }
  
  .sales-iq-detail-device-date-picker-dropdown {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-filter-options {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 43px;
  }
  
  .sales-iq-detail-device-actions2,
  .sales-iq-detail-device-buttonsbutton2 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .sales-iq-detail-device-buttonsbutton2 {
    width: 99px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
    justify-content: center;
    padding: 8px 13px;
    gap: 4px;
    color: #344054;
  }
  
  .sales-iq-detail-device-actions2 {
    justify-content: flex-start;
    gap: 12px;
    color: #667085;
  }
  
  .sales-iq-detail-device-container,
  .sales-iq-detail-device-header-section,
  .sales-iq-detail-device-tabs-and-filters {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-tabs-and-filters {
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    color: #344054;
  }
  
  .sales-iq-detail-device-container,
  .sales-iq-detail-device-header-section {
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-container {
    padding: 0 32px;
    box-sizing: border-box;
    gap: 24px;
  }
  
  .sales-iq-detail-device-header-section {
    flex-shrink: 0;
  }
  
  .sales-iq-detail-device-color {
    width: 8px;
    height: 8px;
    position: relative;
    border-radius: 50%;
    background-color: #7e56d9;
  }
  
  .sales-iq-detail-device-legend-color {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px 0 0;
  }
  
  .sales-iq-detail-device-series-1 {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 35px;
  }
  
  .sales-iq-detail-device-legend-series {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }
  
  .sales-iq-detail-device-color1 {
    width: 8px;
    height: 8px;
    position: relative;
    border-radius: 50%;
    background-color: #b692f6;
  }
  
  .sales-iq-detail-device-series-2 {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 33px;
  }
  
  .sales-iq-detail-device-color2,
  .sales-iq-detail-device-color3 {
    width: 8px;
    height: 8px;
    position: relative;
    border-radius: 50%;
    background-color: #6941c6;
  }
  
  .sales-iq-detail-device-color3 {
    background-color: #17b26a;
  }
  
  .sales-iq-detail-device-series-4 {
    height: 20px;
    position: relative;
    line-height: 20px;
    display: inline-block;
  }
  
  .sales-iq-detail-device-legend-series3 {
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 7px;
  }
  
  .sales-iq-detail-device-color4 {
    height: 8px;
    width: 8px;
    position: relative;
    border-radius: 50%;
    background-color: #6172f3;
  }
  
  .sales-iq-detail-device-series-5 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
  }
  
  .sales-iq-detail-device-legend-series4 {
    align-self: stretch;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }
  
  .sales-iq-detail-device-color5 {
    height: 8px;
    width: 8px;
    position: relative;
    border-radius: 50%;
    background-color: #ee46bb;
  }
  
  .sales-iq-detail-device-legend {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
  }
  
  .sales-iq-detail-device-divider,
  .sales-iq-detail-device-y-axis-line,
  .sales-iq-detail-device-y-axis-line1 {
    align-self: stretch;
    box-sizing: border-box;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-divider {
    flex: 1;
    position: relative;
    border-top: 1px solid #f2f4f7;
  }
  
  .sales-iq-detail-device-y-axis-line,
  .sales-iq-detail-device-y-axis-line1 {
    height: 22px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 11px 0 10px;
  }
  
  .sales-iq-detail-device-y-axis-line1 {
    height: 17px;
    padding: 8.5px 0 7.5px;
  }
  
  .sales-iq-detail-device-divider6 {
    height: 1px;
    width: 1217px;
    position: relative;
    border-top: 1px solid #f2f4f7;
    box-sizing: border-box;
    max-width: 121%;
    flex-shrink: 0;
  }
  
  .sales-iq-detail-device-y-axis,
  .sales-iq-detail-device-y-axis-line6 {
    align-self: stretch;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-y-axis-line6 {
    height: 17px;
    display: none;
    flex-direction: row;
    align-items: center;
  }
  
  .sales-iq-detail-device-y-axis {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 14.2px;
  }
  
  .sales-iq-detail-device-jan,
  .sales-iq-detail-device-jul,
  .sales-iq-detail-device-jun,
  .sales-iq-detail-device-mar,
  .sales-iq-detail-device-may {
    position: relative;
    line-height: 18px;
    display: inline-block;
    min-width: 21px;
  }
  
  .sales-iq-detail-device-jul,
  .sales-iq-detail-device-jun,
  .sales-iq-detail-device-mar,
  .sales-iq-detail-device-may {
    min-width: 23px;
  }
  
  .sales-iq-detail-device-jul,
  .sales-iq-detail-device-jun,
  .sales-iq-detail-device-may {
    min-width: 25px;
  }
  
  .sales-iq-detail-device-jul,
  .sales-iq-detail-device-jun {
    min-width: 22px;
  }
  
  .sales-iq-detail-device-jul {
    min-width: 17px;
  }
  
  .sales-iq-detail-device-x-axis {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 24px;
    gap: 20px;
    font-size: 12px;
  }
  
  .sales-iq-detail-device-chart-data-icon {
    width: 100%;
    height: 170px;
    position: absolute;
    margin: 0 !important;
    top: 20px;
    right: 0;
    left: 0;
    max-width: 100%;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: cover;
    z-index: 1;
  }
  
  .sales-iq-detail-device-content14 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    position: relative;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-line-and-bar-chart,
  .sales-iq-detail-device-line-and-bar-chart-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-line-and-bar-chart {
    flex: 1;
  }
  
  .sales-iq-detail-device-line-and-bar-chart-wrapper {
    align-self: stretch;
    padding: 0 32px;
    box-sizing: border-box;
    flex-shrink: 0;
  }
  
  .sales-iq-detail-device-heading1,
  .sales-iq-detail-device-number {
    align-self: stretch;
    position: relative;
    line-height: 24px;
    font-weight: 600;
  }
  
  .sales-iq-detail-device-number {
    line-height: 38px;
    white-space: nowrap;
  }
  
  .sales-iq-detail-device-change1 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 29px;
  }
  
  .sales-iq-detail-device-change {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .sales-iq-detail-device-change-duration {
    flex: 1;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    color: #475467;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .sales-iq-detail-device-change-and-text,
  .sales-iq-detail-device-number-and-badge {
    display: flex;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-change-and-text {
    align-self: stretch;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: #079455;
  }
  
  .sales-iq-detail-device-number-and-badge {
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    min-width: 84px;
  }
  
  .sales-iq-detail-device-chart-mini-icon {
    height: 64px;
    width: 128px;
    position: relative;
    object-fit: contain;
  }
  
  .sales-iq-detail-device-number-and-chart {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 16px;
    font-size: 30px;
  }
  
  .sales-iq-detail-device-dots-vertical-icon {
    width: 20px;
    height: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .sales-iq-detail-device-dropdown,
  .sales-iq-detail-device-metric-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-dropdown {
    margin: 0 !important;
    position: absolute;
    top: 20px;
    right: 20px;
  }
  
  .sales-iq-detail-device-metric-item {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    padding: 20px 20px 24px 24px;
    position: relative;
    gap: 24px;
    min-width: 320px;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-change2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1px;
  }
  
  .sales-iq-detail-device-change-and-text1,
  .sales-iq-detail-device-container1,
  .sales-iq-detail-device-metric-group,
  .sales-iq-detail-device-section {
    align-self: stretch;
    display: flex;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-change-and-text1 {
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: #da2d20;
  }
  
  .sales-iq-detail-device-container1,
  .sales-iq-detail-device-metric-group,
  .sales-iq-detail-device-section {
    align-items: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-metric-group {
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 0 4px 0 0;
    box-sizing: border-box;
    gap: 24px 22px;
  }
  
  .sales-iq-detail-device-container1,
  .sales-iq-detail-device-section {
    flex-direction: column;
  }
  
  .sales-iq-detail-device-container1 {
    padding: 0 32px;
    box-sizing: border-box;
  }
  
  .sales-iq-detail-device-section {
    flex-shrink: 0;
    font-size: 16px;
    color: #101828;
  }
  
  .sales-iq-detail-device-separator,
  .sales-iq-detail-device-separator1 {
    width: 1px;
    height: 29px;
    position: relative;
    border-right: 1px solid #000;
    box-sizing: border-box;
    flex-shrink: 0;
  }
  
  .sales-iq-detail-device-separator1 {
    height: 43px;
  }
  
  .sales-iq-detail-device-main,
  .sales-iq-detail-device-main-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-main {
    height: 692px;
    flex: 1;
    background-color: #fff;
    overflow: hidden;
    flex-direction: column;
    padding: 32px 0;
    gap: 32px;
    z-index: 1;
  }
  
  .sales-iq-detail-device-main-wrapper {
    align-self: stretch;
    flex-direction: column;
    padding: 0 0 4px;
    color: #475467;
  }
  
  .sales-iq-detail-device-text42 {
    align-self: stretch;
    flex: 1;
    position: relative;
    line-height: 20px;
    font-weight: 500;
  }
  
  .sales-iq-detail-device-breadcrumb-button-base6,
  .sales-iq-detail-device-breadcrumb-button-base7 {
    align-self: stretch;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .sales-iq-detail-device-breadcrumb-button-base6 {
    flex: 1;
    padding: 4px 8px;
  }
  
  .sales-iq-detail-device-breadcrumb-button-base7 {
    padding: 4px 7px;
  }
  
  .sales-iq-detail-device-breadcrumbs1,
  .sales-iq-detail-device-tabs1 {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-tabs1 {
    height: 28px;
    flex: 1;
    display: flex;
    gap: 8px;
  }
  
  .sales-iq-detail-device-breadcrumbs1 {
    width: 278px;
    display: none;
  }
  
  .sales-iq-detail-device-text46 {
    align-self: stretch;
    position: relative;
    line-height: 38px;
    font-weight: 600;
  }
  
  .sales-iq-detail-device-supporting-text2 {
    width: 445px;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    color: #475467;
    display: none;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-text-and-supporting-text1 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
    min-width: 320px;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-text47 {
    align-self: stretch;
    flex: 1;
    position: relative;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .sales-iq-detail-device-content16 {
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  
  .sales-iq-detail-device-content16,
  .sales-iq-detail-device-input-with-label1,
  .sales-iq-detail-device-input1 {
    align-self: stretch;
    flex: 1;
    display: flex;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-input1 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    flex-direction: row;
    align-items: center;
    padding: 10px 13px;
    font-size: 16px;
    color: #667085;
  }
  
  .sales-iq-detail-device-input-with-label1 {
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
  }
  
  .sales-iq-detail-device-hint-text1 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    color: #475467;
    display: none;
  }
  
  .sales-iq-detail-device-input-dropdown1 {
    height: 44px;
    width: 320px;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    min-width: 200px;
    max-width: 320px;
    font-size: 14px;
    color: #344054;
  }
  
  .sales-iq-detail-device-content15 {
    align-self: stretch;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 20px 16px;
    font-size: 26px;
    color: #101828;
  }
  
  .sales-iq-detail-device-content15,
  .sales-iq-detail-device-page-header-wrapper,
  .sales-iq-detail-device-page-header1 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-page-header1 {
    flex: 1;
    background-color: #fff;
    flex-direction: column;
    gap: 20px;
    z-index: 1;
  }
  
  .sales-iq-detail-device-page-header-wrapper {
    align-self: stretch;
    flex-direction: row;
    padding: 0 32px 4px;
    box-sizing: border-box;
    color: #475467;
  }
  
  .sales-iq-detail-device-supporting-text3 {
    flex: 1;
    position: relative;
    line-height: 24px;
    display: inline-block;
    max-width: 100%;
    z-index: 1;
  }
  
  .sales-iq-detail-device-supporting-text-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 32px 20px;
    box-sizing: border-box;
    max-width: 100%;
    font-size: 16px;
    color: #475467;
  }
  
  .sales-iq-detail-device-tab-name {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 55px;
    white-space: nowrap;
  }
  
  .sales-iq-detail-device-tab-button-base {
    border-bottom: 2px solid #7e56d9;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 4px 9px;
    color: #6941c6;
  }
  
  .sales-iq-detail-device-text48 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 48px;
    white-space: nowrap;
  }
  
  .sales-iq-detail-device-tab-button-base1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 4px 12px;
  }
  
  .sales-iq-detail-device-text49,
  .sales-iq-detail-device-text50,
  .sales-iq-detail-device-text51 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 52px;
    white-space: nowrap;
  }
  
  .sales-iq-detail-device-text50,
  .sales-iq-detail-device-text51 {
    min-width: 62px;
  }
  
  .sales-iq-detail-device-text51 {
    min-width: 34px;
  }
  
  .sales-iq-detail-device-tab-button-base4 {
    width: 41px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 3px 12px;
    box-sizing: border-box;
  }
  
  .sales-iq-detail-device-text52 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    font-weight: 600;
    white-space: nowrap;
  }
  
  .sales-iq-detail-device-tab-button-base5 {
    align-self: stretch;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 4px 12px;
  }
  
  .sales-iq-detail-device-text55 {
    align-self: stretch;
    position: relative;
    line-height: 18px;
    font-weight: 500;
    white-space: nowrap;
  }
  
  .sales-iq-detail-device-badge10 {
    height: 22px;
    border-radius: 9999px;
    background-color: #f9fafb;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 2px 7px;
    text-align: center;
    font-size: 12px;
    color: #344054;
  }
  
  .sales-iq-detail-device-tab-button-base7,
  .sales-iq-detail-device-tab-button-base9 {
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .sales-iq-detail-device-tab-button-base7 {
    height: 32px;
    padding: 0 4px 12px;
    box-sizing: border-box;
    gap: 8px;
  }
  
  .sales-iq-detail-device-tab-button-base9 {
    align-self: stretch;
    padding: 0 3px 12px;
  }
  
  .sales-iq-detail-device-tabs2 {
    overflow-x: auto;
    flex-direction: row;
    padding: 0 20px 0 0;
    gap: 12px;
  }
  
  .sales-iq-detail-device-horizontal-tabs,
  .sales-iq-detail-device-horizontal-tabs-wrapper,
  .sales-iq-detail-device-tabs2 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-horizontal-tabs {
    flex: 1;
    background-color: #fff;
    border-bottom: 1px solid #eaecf0;
    flex-direction: column;
    z-index: 1;
  }
  
  .sales-iq-detail-device-horizontal-tabs-wrapper {
    align-self: stretch;
    flex-direction: row;
    padding: 0 0 24px 32px;
    color: #667085;
  }
  
  .sales-iq-detail-device-frame-item {
    width: 188px;
    height: 154px;
    position: relative;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    display: none;
  }
  
  .sales-iq-detail-device-heading4 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    z-index: 1;
  }
  
  .sales-iq-detail-device-metric-card-title {
    flex: 1;
    flex-direction: column;
    padding: 4px 0 0;
  }
  
  .sales-iq-detail-device-dropdown3,
  .sales-iq-detail-device-metric-card,
  .sales-iq-detail-device-metric-card-title {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-dropdown3 {
    flex-direction: column;
    z-index: 1;
  }
  
  .sales-iq-detail-device-metric-card {
    align-self: stretch;
    flex-direction: row;
  }
  
  .sales-iq-detail-device-number3 {
    width: 155px;
    position: relative;
    font-size: 36px;
    letter-spacing: -0.02em;
    line-height: 44px;
    font-weight: 600;
    color: #101828;
    display: inline-block;
    z-index: 1;
  }
  
  .sales-iq-detail-device-metric-card-row {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }
  
  .sales-iq-detail-device-arrow-up-icon2 {
    width: 12px;
    height: 12px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .sales-iq-detail-device-options-icon {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 4px 0 0;
  }
  
  .sales-iq-detail-device-text58 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 116px;
  }
  
  .sales-iq-detail-device-badge11 {
    width: 150px;
    border-radius: 9999px;
    border: 1.5px solid #079455;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 7px 0 8px;
    gap: 4px;
    z-index: 1;
    text-align: center;
    color: #067647;
  }
  
  .sales-iq-detail-device-frame-inner,
  .sales-iq-detail-device-rectangle-group {
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
  }
  
  .sales-iq-detail-device-rectangle-group {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px 4px 22px 15px;
    gap: 8px;
    min-width: 184px;
    max-width: 188px;
  }
  
  .sales-iq-detail-device-frame-inner {
    height: 154px;
    width: 188px;
    position: relative;
    display: none;
  }
  
  .sales-iq-detail-device-heading5,
  .sales-iq-detail-device-number4 {
    position: relative;
    z-index: 1;
  }
  
  .sales-iq-detail-device-heading5 {
    width: 147px;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
  }
  
  .sales-iq-detail-device-number4 {
    align-self: stretch;
    font-size: 36px;
    letter-spacing: -0.02em;
    line-height: 44px;
    font-weight: 600;
    color: #101828;
  }
  
  .sales-iq-detail-device-change-amount {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 32px;
  }
  
  .sales-iq-detail-device-badge12 {
    width: 64px;
    border-radius: 9999px;
    border: 1.5px solid #079455;
    box-sizing: border-box;
    flex-direction: row;
    padding: 0 7px 0 8px;
    gap: 2px;
    z-index: 1;
    text-align: center;
    color: #067647;
  }
  
  .sales-iq-detail-device-badge12,
  .sales-iq-detail-device-frame-container,
  .sales-iq-detail-device-heading-parent,
  .sales-iq-detail-device-rectangle-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-heading-parent {
    width: 157px;
    flex-direction: column;
    gap: 8px;
  }
  
  .sales-iq-detail-device-frame-container,
  .sales-iq-detail-device-rectangle-container {
    box-sizing: border-box;
  }
  
  .sales-iq-detail-device-frame-container {
    width: 147px;
    flex-direction: column;
    padding: 4px 0 0;
  }
  
  .sales-iq-detail-device-rectangle-container {
    flex: 1;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    flex-direction: row;
    padding: 20px 4px 22px 15px;
    min-width: 184px;
    max-width: 188px;
  }
  
  .sales-iq-detail-device-heading-group {
    width: 153px;
    gap: 8px;
  }
  
  .sales-iq-detail-device-dropdown7,
  .sales-iq-detail-device-heading-container,
  .sales-iq-detail-device-heading-group,
  .sales-iq-detail-device-heading-parent1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-heading-container {
    width: 163px;
    gap: 8px;
  }
  
  .sales-iq-detail-device-dropdown7,
  .sales-iq-detail-device-heading-parent1 {
    flex-shrink: 0;
  }
  
  .sales-iq-detail-device-heading-parent1 {
    width: 170px;
    gap: 8px;
  }
  
  .sales-iq-detail-device-dropdown7 {
    z-index: 1;
  }
  
  .sales-iq-detail-device-frame-group,
  .sales-iq-detail-device-frame-wrapper,
  .sales-iq-detail-device-rectangle-parent2 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .sales-iq-detail-device-rectangle-parent2 {
    flex: 1;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    justify-content: flex-start;
    padding: 20px 2px 22px 16px;
    min-width: 184px;
    max-width: 188px;
  }
  
  .sales-iq-detail-device-frame-group,
  .sales-iq-detail-device-frame-wrapper {
    max-width: 100%;
  }
  
  .sales-iq-detail-device-frame-group {
    flex: 1;
    justify-content: flex-start;
    gap: 19px;
    z-index: 1;
  }
  
  .sales-iq-detail-device-frame-wrapper {
    align-self: stretch;
    justify-content: flex-start;
    padding: 0 30px 24px 30px;
    box-sizing: border-box;
    color: #475467;
  }
  
  .sales-iq-detail-device-text62,
  .sales-iq-detail-device-text63 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 51px;
  }
  
  .sales-iq-detail-device-text63 {
    min-width: 74px;
  }
  
  .sales-iq-detail-device-buttonsbutton4,
  .sales-iq-detail-device-text-padding11 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 1px;
  }
  
  .sales-iq-detail-device-buttonsbutton4 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    overflow: hidden;
    padding: 8px 13px;
    gap: 4px;
    min-width: 84px;
    cursor: pointer;
  }
  
  .sales-iq-detail-device-text64 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 78px;
  }
  
  .sales-iq-detail-device-buttonsbutton5 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 13px;
    gap: 4px;
    min-width: 87px;
    cursor: pointer;
  }
  
  .sales-iq-detail-device-text65 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 50px;
  }
  
  .sales-iq-detail-device-dropdowns,
  .sales-iq-detail-device-input2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-dropdowns {
    /* flex: 0.7552; */
    flex: .5;
    align-items: flex-start;
    /* padding: 0 166px 0 0; */
    box-sizing: border-box;
    gap: 12px;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-input2 {
    align-self: stretch;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    align-items: center;
    padding: 8px 13px;
    font-size: 16px;
    color: #667085;
    min-width: 208px;
    max-width: 320px;
  }
  
  .sales-iq-detail-device-input-dropdown2,
  .sales-iq-detail-device-input-with-label2 {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    /* flex: .85; */
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-input-with-label2 {
    align-self: stretch;
    gap: 6px;
    flex: .65;
  }
  
  .sales-iq-detail-device-input-dropdown2 {
    flex: .5;
    flex-direction: row !important;
    justify-content: flex-end !important;
    gap: 8px;
    /* min-width: 208px;
    max-width: 320px; */
  }
  
  .sales-iq-detail-device-content17 {
    flex: 1;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }
  
  .sales-iq-detail-device-content17,
  .sales-iq-detail-device-filters-bar,
  .sales-iq-detail-device-section1 {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-filters-bar {
    align-self: stretch;
    border-radius: 8px;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .sales-iq-detail-device-section1 {
    flex: 1;
    flex-direction: column;
    align-items: center;
    z-index: 1;
  }
  
  .sales-iq-detail-device-frame-child3,
  .sales-iq-detail-device-section-wrapper {
    box-sizing: border-box;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-section-wrapper {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 0 24px;
    color: #344054;
    position: relative;
  }
  
  .sales-iq-detail-device-frame-child3 {
    width: 1016px;
    height: 282px;
    position: relative;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    display: none;
  }
  
  .sales-iq-detail-device-content-elements-icon {
    height: 148px;
    width: 148px;
    position: relative;
    border-radius: 12px;
    object-fit: cover;
    z-index: 1;
  }
  
  .sales-iq-detail-device-search-input-icons {
    position: relative;
    line-height: 28px;
    font-weight: 600;
    z-index: 1;
  }
  
  .sales-iq-detail-device-search-inputs {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 0 0 1px;
  }
  
  .sales-iq-detail-device-dot-icon10 {
    height: 8px;
    width: 8px;
    position: relative;
    display: none;
  }
  
  .sales-iq-detail-device-badge-number {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 66px;
  }
  
  .sales-iq-detail-device-badge16 {
    width: 84px;
    border-radius: 9999px;
    background-color: #edfbf5;
    border: 1px solid #41d992;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 8px;
    gap: 6px;
    mix-blend-mode: normal;
    z-index: 1;
    text-align: center;
    font-size: 14px;
    color: #17b26a;
  }
  
  .sales-iq-detail-device-supporting-text4 {
    position: relative;
    line-height: 24px;
    display: inline-block;
    min-width: 83px;
    z-index: 1;
  }
  
  .sales-iq-detail-device-marker-pin-02-icon {
    width: 20px;
    height: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 1;
  }
  
  .sales-iq-detail-device-location1 {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 124px;
    z-index: 1;
  }
  
  .sales-iq-detail-device-location-pin-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 7px;
  }
  
  .sales-iq-detail-device-type-of-work {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 109px;
    z-index: 1;
  }
  
  .sales-iq-detail-device-location-badge,
  .sales-iq-detail-device-location-badge-number,
  .sales-iq-detail-device-type-number {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-location-badge-number {
    flex: 1;
    gap: 20px;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-location-badge,
  .sales-iq-detail-device-type-number {
    box-sizing: border-box;
  }
  
  .sales-iq-detail-device-location-badge {
    align-self: stretch;
    padding: 0 0 0 1px;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-type-number {
    width: 50px;
    border-radius: 8px;
    background-color: #fdb022;
    padding: 1px 4px;
    z-index: 1;
  }
  
  .sales-iq-detail-device-supporting-text5 {
    position: relative;
    line-height: 24px;
    display: inline-block;
    min-width: 117px;
    z-index: 1;
  }
  
  .sales-iq-detail-device-type-support {
    flex-direction: column;
    padding: 1px 0 0;
    color: #475467;
  }
  
  .sales-iq-detail-device-location-elements,
  .sales-iq-detail-device-type-badge-elements,
  .sales-iq-detail-device-type-support {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-type-badge-elements {
    flex-direction: row;
    gap: 8px;
    color: #fff;
  }
  
  .sales-iq-detail-device-location-elements {
    align-self: stretch;
    flex-direction: column;
    gap: 15px;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-card-badge-number {
    position: relative;
    line-height: 20px;
    font-weight: 500;
  }
  
  .sales-iq-detail-device-badge17 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 7px;
    min-width: 112px;
    white-space: nowrap;
    z-index: 1;
  }
  
  .sales-iq-detail-device-badge-number-element {
    width: 61px;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 61px;
  }
  
  .sales-iq-detail-device-badge18 {
    width: 77px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 7px;
    white-space: nowrap;
    z-index: 1;
  }
  
  .sales-iq-detail-device-second-badge-count {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 60px;
  }
  
  .sales-iq-detail-device-badge19 {
    width: 76px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 7px;
    z-index: 1;
  }
  
  .sales-iq-detail-device-third-badge-number {
    width: 149px;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
  }
  
  .sales-iq-detail-device-badge20 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 7px;
    min-width: 107px;
    white-space: nowrap;
    z-index: 1;
  }
  
  .sales-iq-detail-device-badge-elements {
    flex: 1;
    flex-direction: row;
    gap: 8px;
  }
  
  .sales-iq-detail-device-badge-elements,
  .sales-iq-detail-device-badge-elements-wrapper,
  .sales-iq-detail-device-card-badge-elements {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-badge-elements-wrapper {
    align-self: stretch;
    flex-direction: row;
    padding: 0 1px;
    box-sizing: border-box;
    text-align: center;
    font-size: 14px;
    color: #344054;
  }
  
  .sales-iq-detail-device-card-badge-elements {
    flex: 1;
    flex-direction: column;
    gap: 15px;
  }
  
  .sales-iq-detail-device-location-badge-elements {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 77px;
  }
  
  .sales-iq-detail-device-badge21 {
    width: 93px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 7px;
    z-index: 1;
    text-align: center;
    font-size: 14px;
    color: #344054;
  }
  
  .sales-iq-detail-device-action-fields,
  .sales-iq-detail-device-card-elements,
  .sales-iq-detail-device-input-elements {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-card-elements {
    width: 616px;
    flex-direction: row;
    align-items: flex-end;
    gap: 7px;
    font-size: 16px;
    color: #475467;
  }
  
  .sales-iq-detail-device-action-fields,
  .sales-iq-detail-device-input-elements {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .sales-iq-detail-device-input-elements {
    align-self: stretch;
    gap: 16px;
  }
  
  .sales-iq-detail-device-action-fields {
    flex: 1;
    padding: 10px 0 0;
    box-sizing: border-box;
  }
  
  .sales-iq-detail-device-price {
    align-self: stretch;
    position: relative;
    letter-spacing: -0.02em;
    line-height: 32px;
    font-weight: 600;
    white-space: nowrap;
    z-index: 1;
  }
  
  .sales-iq-detail-device-dropdown-options {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 83px;
    z-index: 2;
  }
  
  .sales-iq-detail-device-magnifier-1-icon {
    height: 20px;
    width: 20px;
    position: relative;
    object-fit: cover;
    min-height: 20px;
  }
  
  .sales-iq-detail-device-frame-wrapper3,
  .sales-iq-detail-device-radar-1-1-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-radar-1-1-parent {
    flex: 1;
    gap: 8px;
    z-index: 1;
  }
  
  .sales-iq-detail-device-frame-wrapper3 {
    align-self: stretch;
    padding: 0 4px 0 3px;
  }
  
  .sales-iq-detail-device-dropdown-options-parent {
    flex: 1;
    flex-direction: column;
    gap: 4px;
  }
  
  .sales-iq-detail-device-dropdown-elements,
  .sales-iq-detail-device-dropdown-options-parent,
  .sales-iq-detail-device-price-parent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-dropdown-elements {
    align-self: stretch;
    flex-direction: row;
    padding: 0 30px;
    font-size: 12px;
    color: #475467;
  }
  
  .sales-iq-detail-device-price-parent {
    flex: 1;
    flex-direction: column;
  }
  
  .sales-iq-detail-device-filter-elements-inner {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 10px 0 0;
  }
  
  .sales-iq-detail-device-filter-elements {
    width: 153px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 26px;
    text-align: center;
    font-size: 20px;
    color: #6941c6;
  }
  
  .sales-iq-detail-device-content-actions,
  .sales-iq-detail-device-content-elements-parent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-content-actions {
    flex: 1;
    align-items: flex-start;
    gap: 15px;
    min-width: 525px;
  }
  
  .sales-iq-detail-device-content-elements-parent {
    align-self: stretch;
    align-items: flex-end;
    gap: 23px;
  }
  
  .sales-iq-detail-device-location-column-child {
    height: 52px;
    width: 52px;
    position: relative;
    object-fit: cover;
    z-index: 1;
  }
  
  .sales-iq-detail-device-name,
  .sales-iq-detail-device-role {
    position: relative;
    display: inline-block;
  }
  
  .sales-iq-detail-device-name {
    line-height: 23px;
    font-weight: 600;
    min-width: 77px;
    z-index: 1;
  }
  
  .sales-iq-detail-device-role {
    height: 19px;
    font-size: 12px;
    line-height: 24px;
    color: #6941c6;
    flex-shrink: 0;
    min-width: 87px;
    z-index: 2;
  }
  
  .sales-iq-detail-device-location-column-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 3px 0 0;
  }
  
  .sales-iq-detail-device-text67 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 34px;
  }
  
  .sales-iq-detail-device-buttonsbutton7 {
    width: 90px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px 12px;
    gap: 6px;
    z-index: 1;
  }
  
  .sales-iq-detail-device-icon1 {
    width: 16.7px;
    height: 16.7px;
    position: relative;
  }
  
  .sales-iq-detail-device-icon-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 3.7px 0 0;
  }
  
  .sales-iq-detail-device-text68 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 56px;
  }
  
  .sales-iq-detail-device-buttonsbutton8,
  .sales-iq-detail-device-buttonsbutton9 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px 11px;
    gap: 5.5px;
    min-width: 86px;
    z-index: 1;
  }
  
  .sales-iq-detail-device-buttonsbutton9 {
    padding: 6px 10px 6px 12px;
    gap: 5.4px;
  }
  
  .sales-iq-detail-device-buttonsbutton-parent {
    align-self: stretch;
    flex-direction: row;
    gap: 16px;
  }
  
  .sales-iq-detail-device-buttonsbutton-parent,
  .sales-iq-detail-device-primary-table,
  .sales-iq-detail-device-primary-table-inner {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-primary-table-inner {
    flex: 1;
    flex-direction: column;
    padding: 6px 0 0;
    box-sizing: border-box;
    min-width: 250px;
    max-width: 100%;
    color: #344054;
  }
  
  .sales-iq-detail-device-primary-table {
    width: 556.4px;
    flex-direction: row;
    gap: 24px;
    max-width: calc(100% - 44px);
  }
  
  .sales-iq-detail-device-arrow-square-up-right-icon {
    width: 24px;
    height: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 1;
  }
  
  .sales-iq-detail-device-arrow-square-up-right-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 14px 0 0;
  }
  
  .sales-iq-detail-device-primary-table-parent {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 100%;
    gap: 20px;
  }
  
  .sales-iq-detail-device-content-items,
  .sales-iq-detail-device-help-avatar,
  .sales-iq-detail-device-rectangle-parent3 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-help-avatar {
    width: 968px;
    flex-direction: column;
    gap: 16px;
    font-size: 14px;
  }
  
  .sales-iq-detail-device-content-items,
  .sales-iq-detail-device-rectangle-parent3 {
    box-sizing: border-box;
  }
  
  .sales-iq-detail-device-rectangle-parent3 {
    flex: 1;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    flex-direction: column;
    padding: 12px 13px 15px 23px;
    gap: 24px;
    z-index: 1;
  }
  
  .sales-iq-detail-device-content-items {
    align-self: stretch;
    flex-direction: row;
    padding: 0 30px 24px 32px;
    font-size: 18px;
  }
  
  .sales-iq-detail-device-text71 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 55px;
  }
  
  .sales-iq-detail-device-badge22 {
    width: 73px;
    border-radius: 9999px;
    background-color: #fff9ef;
    border: 1px solid #f5b231;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 8px;
    gap: 6px;
    mix-blend-mode: normal;
    z-index: 1;
  }
  
  .sales-iq-detail-device-supporting-text6 {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    color: #475467;
    text-align: left;
    display: inline-block;
    min-width: 83px;
    z-index: 1;
  }
  
  .sales-iq-detail-device-badge-parent {
    display: flex;
    align-items: flex-start;
    gap: 20px;
  }
  
  .sales-iq-detail-device-badge-parent,
  .sales-iq-detail-device-badge23,
  .sales-iq-detail-device-frame-parent4 {
    flex-direction: row;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-frame-parent4 {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    text-align: left;
    font-size: 16px;
    color: #fff;
  }
  
  .sales-iq-detail-device-badge23 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    padding: 0 7px;
    white-space: nowrap;
    z-index: 1;
    color: #344054;
  }
  
  .sales-iq-detail-device-badge23,
  .sales-iq-detail-device-badge24,
  .sales-iq-detail-device-frame-parent3 {
    display: flex;
    align-items: flex-start;
  }
  
  .sales-iq-detail-device-frame-parent3 {
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    gap: 15px;
    min-width: 114px;
    color: #f5b231;
  }
  
  .sales-iq-detail-device-badge24 {
    flex-direction: row;
    white-space: nowrap;
  }
  
  .sales-iq-detail-device-badge24,
  .sales-iq-detail-device-badge25 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    justify-content: flex-start;
    padding: 0 7px;
    z-index: 1;
  }
  
  .sales-iq-detail-device-badge-elements-copy,
  .sales-iq-detail-device-badge25,
  .sales-iq-detail-device-price-badge-elements {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .sales-iq-detail-device-badge-elements-copy {
    flex: 1;
    justify-content: flex-start;
    gap: 8px;
  }
  
  .sales-iq-detail-device-price-badge-elements {
    align-self: stretch;
    justify-content: flex-end;
    padding: 0 6px 0 0;
    text-align: center;
    font-size: 14px;
    color: #344054;
  }
  
  .sales-iq-detail-device-frame-parent5 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 56px;
    min-width: 109px;
    text-align: left;
    font-size: 16px;
  }
  
  .sales-iq-detail-device-badge26,
  .sales-iq-detail-device-date-elements {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 10px 0 18px;
  }
  
  .sales-iq-detail-device-badge26 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    justify-content: flex-start;
    padding: 0 7px;
    white-space: nowrap;
    z-index: 1;
    text-align: center;
    font-size: 14px;
    color: #344054;
  }
  
  .sales-iq-detail-device-badge27,
  .sales-iq-detail-device-date-elements-parent {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-date-elements-parent {
    flex: 0.9649;
    flex-direction: column;
    align-items: flex-end;
    padding: 0 6px 0 0;
    gap: 56px;
    min-width: 111px;
    text-align: left;
    font-size: 16px;
  }
  
  .sales-iq-detail-device-badge27 {
    width: 93px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 7px;
    z-index: 1;
    color: #344054;
  }
  
  .sales-iq-detail-device-frame-parent2 {
    width: 615px;
    align-items: flex-end;
    padding: 0 2px 0 0;
    box-sizing: border-box;
    gap: 2px;
    text-align: center;
    font-size: 14px;
    color: #475467;
  }
  
  .sales-iq-detail-device-frame-parent1,
  .sales-iq-detail-device-frame-parent2,
  .sales-iq-detail-device-rectangle-parent5 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-frame-parent1 {
    flex: 1;
    align-items: flex-start;
    gap: 15px;
    min-width: 524px;
  }
  
  .sales-iq-detail-device-rectangle-parent5 {
    align-self: stretch;
    align-items: flex-end;
    gap: 24px;
  }
  
  .sales-iq-detail-device-frame-child5,
  .sales-iq-detail-device-frame-child6 {
    width: 24px;
    height: 24px;
    position: relative;
    z-index: 1;
  }
  
  .sales-iq-detail-device-frame-child6 {
    width: 1016px;
    height: 282px;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    display: none;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-frame-child7,
  .sales-iq-detail-device-text78 {
    position: relative;
    z-index: 2;
  }
  
  .sales-iq-detail-device-frame-child7 {
    height: 148px;
    width: 148px;
    border-radius: 12px;
    object-fit: cover;
  }
  
  .sales-iq-detail-device-text78 {
    line-height: 28px;
    font-weight: 600;
  }
  
  .sales-iq-detail-device-badge28 {
    position: absolute;
    top: 80px;
    left: 522px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    width: 93px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 7px;
    z-index: 2;
  }
  
  .sales-iq-detail-device-marker-pin-02-icon2 {
    width: 20px;
    height: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 2;
  }
  
  .sales-iq-detail-device-location3,
  .sales-iq-detail-device-type-of-work2 {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 124px;
    z-index: 2;
  }
  
  .sales-iq-detail-device-type-of-work2 {
    min-width: 109px;
  }
  
  .sales-iq-detail-device-frame-parent12,
  .sales-iq-detail-device-frame-wrapper12 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .sales-iq-detail-device-frame-parent12 {
    justify-content: flex-start;
    gap: 20px;
  }
  
  .sales-iq-detail-device-frame-wrapper12 {
    justify-content: flex-end;
    padding: 0 5px;
  }
  
  .sales-iq-detail-device-badge29,
  .sales-iq-detail-device-badge30 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    padding: 0 7px;
    z-index: 2;
  }
  
  .sales-iq-detail-device-badge29 {
    width: 77px;
    flex-direction: row;
    align-items: flex-start;
    white-space: nowrap;
  }
  
  .sales-iq-detail-device-badge30 {
    width: 76px;
  }
  
  .sales-iq-detail-device-badge-container,
  .sales-iq-detail-device-badge30,
  .sales-iq-detail-device-badge31 {
    flex-direction: row;
    align-items: flex-start;
  }
  
  .sales-iq-detail-device-badge31 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    display: flex;
    justify-content: flex-start;
    padding: 0 7px;
    white-space: nowrap;
    z-index: 2;
  }
  
  .sales-iq-detail-device-badge-container {
    gap: 8px;
    text-align: center;
    font-size: 14px;
    color: #344054;
  }
  
  .sales-iq-detail-device-badge-container,
  .sales-iq-detail-device-badge32,
  .sales-iq-detail-device-frame-parent11 {
    display: flex;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-frame-parent11 {
    position: absolute;
    top: 0;
    left: 180px;
    flex-direction: column;
    align-items: flex-end;
    gap: 56px;
    text-align: left;
    font-size: 16px;
    color: #475467;
  }
  
  .sales-iq-detail-device-badge32 {
    flex: 1;
    border-radius: 9999px;
    background-color: #eceef1;
    border: 1px solid #98a2b3;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 8px;
    gap: 6px;
    mix-blend-mode: normal;
    z-index: 2;
  }
  
  .sales-iq-detail-device-supporting-text8 {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    color: #475467;
    text-align: left;
    display: inline-block;
    min-width: 83px;
    z-index: 2;
  }
  
  .sales-iq-detail-device-badge-parent1 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
  }
  
  .sales-iq-detail-device-supporting-text9 {
    position: relative;
    line-height: 24px;
    display: inline-block;
    min-width: 117px;
    z-index: 2;
  }
  
  .sales-iq-detail-device-badge33,
  .sales-iq-detail-device-frame-parent15 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-badge33 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    flex-direction: row;
    padding: 0 7px;
    white-space: nowrap;
    z-index: 2;
    color: #344054;
  }
  
  .sales-iq-detail-device-frame-parent15 {
    position: absolute;
    top: 0;
    left: 0;
    width: 181px;
    flex-direction: column;
    gap: 15px;
    color: #98a2b3;
  }
  
  .sales-iq-detail-device-badge-group {
    width: 615px;
    height: 104px;
    position: relative;
    max-width: 100%;
    text-align: center;
    font-size: 14px;
    color: #344054;
  }
  
  .sales-iq-detail-device-dropdown10 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 2;
  }
  
  .sales-iq-detail-device-price2,
  .sales-iq-detail-device-text83 {
    position: relative;
  }
  
  .sales-iq-detail-device-price2 {
    align-self: stretch;
    letter-spacing: -0.02em;
    line-height: 32px;
    font-weight: 600;
    white-space: nowrap;
    z-index: 2;
  }
  
  .sales-iq-detail-device-text83 {
    line-height: 20px;
    display: inline-block;
    min-width: 83px;
    z-index: 3;
  }
  
  .sales-iq-detail-device-radar-1-1-container,
  .sales-iq-detail-device-rectangle-parent7 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-radar-1-1-container {
    flex: 1;
    align-items: flex-start;
    gap: 8px;
    z-index: 2;
  }
  
  .sales-iq-detail-device-rectangle-parent7 {
    align-self: stretch;
    align-items: flex-end;
    padding: 0 0 76px;
    box-sizing: border-box;
    gap: 24px;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-frame-child8 {
    height: 52px;
    width: 52px;
    position: relative;
    object-fit: cover;
    z-index: 2;
  }
  
  .sales-iq-detail-device-name2,
  .sales-iq-detail-device-role2 {
    position: relative;
    display: inline-block;
  }
  
  .sales-iq-detail-device-name2 {
    line-height: 23px;
    font-weight: 600;
    min-width: 77px;
    z-index: 2;
  }
  
  .sales-iq-detail-device-role2 {
    height: 19px;
    font-size: 12px;
    line-height: 24px;
    color: #6941c6;
    flex-shrink: 0;
    min-width: 87px;
    z-index: 3;
  }
  
  .sales-iq-detail-device-buttonsbutton13,
  .sales-iq-detail-device-buttonsbutton14 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    z-index: 2;
  }
  
  .sales-iq-detail-device-buttonsbutton13 {
    width: 90px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px 12px;
    gap: 6px;
  }
  
  .sales-iq-detail-device-buttonsbutton14 {
    flex: 1;
    overflow-x: auto;
    padding: 6px 11px;
    gap: 5.5px;
    min-width: 86px;
  }
  
  .sales-iq-detail-device-buttonsbutton14,
  .sales-iq-detail-device-buttonsbutton15,
  .sales-iq-detail-device-frame-parent18 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-buttonsbutton15 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    overflow-x: auto;
    padding: 6px 10px 6px 12px;
    gap: 5.4px;
    min-width: 86px;
    z-index: 2;
  }
  
  .sales-iq-detail-device-frame-parent18 {
    width: 556.4px;
    gap: 24px;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-text84 {
    width: 66px;
    position: relative;
    line-height: 18px;
    font-weight: 600;
    display: inline-block;
    min-width: 66px;
  }
  
  .sales-iq-detail-device-content19 {
    border-radius: 8px;
    background-color: #0c111d;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 8px 12px;
    white-space: nowrap;
  }
  
  .sales-iq-detail-device-tooltip-icon {
    width: 28px;
    height: 6px;
    position: relative;
  }
  
  .sales-iq-detail-device-tooltip {
    box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
      0 4px 6px -2px rgba(16, 24, 40, 0.03);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    z-index: 3;
  }
  
  .sales-iq-detail-device-arrow-square-up-right-icon1 {
    width: 24px;
    height: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 2;
  }
  
  .sales-iq-detail-device-frame-wrapper16,
  .sales-iq-detail-device-tooltip-parent {
    display: flex;
    flex-direction: column;
  }
  
  .sales-iq-detail-device-tooltip-parent {
    align-items: flex-end;
    justify-content: flex-start;
    gap: 8px;
  }
  
  .sales-iq-detail-device-frame-wrapper16 {
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 0 14px;
    text-align: center;
    font-size: 12px;
    color: #fff;
  }
  
  .sales-iq-detail-device-divider-icon5,
  .sales-iq-detail-device-frame-parent17 {
    width: 968px;
    max-width: 100%;
    margin-top: -69px;
  }
  
  .sales-iq-detail-device-frame-parent17 {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    gap: 20px;
    font-size: 14px;
  }
  
  .sales-iq-detail-device-divider-icon5 {
    position: relative;
    max-height: 100%;
    z-index: 2;
  }
  
  .sales-iq-detail-device-rectangle-parent6 {
    flex: 1;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 14px 13px 17px 23px;
    min-height: 282px;
    max-width: 100%;
    z-index: 1;
  }
  
  .sales-iq-detail-device-text86 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 51px;
  }
  
  .sales-iq-detail-device-badge34,
  .sales-iq-detail-device-badge36 {
    justify-content: flex-start;
    z-index: 2;
  }
  
  .sales-iq-detail-device-badge34 {
    width: 69px;
    border-radius: 9999px;
    background-color: #fffdfd;
    border: 1px solid #da2d20;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 8px;
    gap: 6px;
    mix-blend-mode: normal;
  }
  
  .sales-iq-detail-device-badge36 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    padding: 0 7px;
    white-space: nowrap;
  }
  
  .sales-iq-detail-device-badge36,
  .sales-iq-detail-device-badge37,
  .sales-iq-detail-device-frame-wrapper18 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .sales-iq-detail-device-badge37 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    justify-content: flex-start;
    padding: 0 7px;
    z-index: 2;
  }
  
  .sales-iq-detail-device-frame-wrapper18 {
    align-self: stretch;
    justify-content: flex-end;
    padding: 0 2px 0 0;
    text-align: center;
    font-size: 14px;
    color: #344054;
  }
  
  .sales-iq-detail-device-frame-parent24 {
    width: 163px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 56px;
    text-align: left;
    font-size: 16px;
  }
  
  .sales-iq-detail-device-frame-wrapper19 {
    flex-direction: row;
    padding: 0 14px;
  }
  
  .sales-iq-detail-device-badge38,
  .sales-iq-detail-device-frame-parent26,
  .sales-iq-detail-device-frame-wrapper19 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-badge38 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    flex-direction: row;
    padding: 0 7px;
    white-space: nowrap;
    z-index: 2;
    text-align: center;
    font-size: 14px;
    color: #344054;
  }
  
  .sales-iq-detail-device-frame-parent26 {
    flex: 1;
    flex-direction: column;
    gap: 56px;
    min-width: 107px;
    text-align: left;
    font-size: 16px;
  }
  
  .sales-iq-detail-device-badge39,
  .sales-iq-detail-device-frame-parent21 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-badge39 {
    width: 93px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    align-items: flex-start;
    padding: 0 7px;
    z-index: 2;
    color: #344054;
  }
  
  .sales-iq-detail-device-frame-parent21 {
    width: 615px;
    align-items: flex-end;
    gap: 6.3px;
    max-width: 100%;
    text-align: center;
    font-size: 14px;
    color: #475467;
  }
  
  .sales-iq-detail-device-divider-icon6 {
    align-self: stretch;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    z-index: 2;
  }
  
  .sales-iq-detail-device-element-details-child,
  .sales-iq-detail-device-frame-child12 {
    position: relative;
    border-radius: 12px;
    display: none;
  }
  
  .sales-iq-detail-device-frame-child12 {
    height: 208px;
    width: 1016px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-element-details-child {
    height: 160px;
    width: 160px;
    background-color: #f5f5f5;
  }
  
  .sales-iq-detail-device-f4b711e503bc72fe396e5043e0c2-1-icon {
    height: 84px;
    width: 84px;
    position: relative;
    object-fit: cover;
    z-index: 3;
  }
  
  .sales-iq-detail-device-element-details {
    border-radius: 12px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 38px;
    box-sizing: border-box;
    min-width: 160px;
    z-index: 2;
  }
  
  .sales-iq-detail-device-supporting-text12 {
    position: relative;
    letter-spacing: -0.06px;
    line-height: 24px;
    z-index: 2;
  }
  
  .sales-iq-detail-device-end-label {
    width: 50px;
    border-radius: 8px;
    background-color: #fdb022;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1px 4px;
    box-sizing: border-box;
    z-index: 2;
  }
  
  .sales-iq-detail-device-text99 {
    position: relative;
    line-height: 23px;
    font-weight: 500;
    z-index: 2;
  }
  
  .sales-iq-detail-device-supporting-text14 {
    position: relative;
    font-size: 12px;
    line-height: 19px;
    color: #475467;
    display: inline-block;
    min-width: 104px;
    z-index: 3;
  }
  
  .sales-iq-detail-device-date-icon,
  .sales-iq-detail-device-type-label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-type-label {
    font-size: 14px;
    color: #101828;
  }
  
  .sales-iq-detail-device-date-icon {
    flex: 1;
    gap: 16px;
    min-width: 114px;
  }
  
  .sales-iq-detail-device-text100 {
    align-self: stretch;
    position: relative;
    line-height: 23px;
    font-weight: 500;
    z-index: 2;
  }
  
  .sales-iq-detail-device-supporting-text15 {
    width: 104px;
    position: relative;
    font-size: 12px;
    line-height: 19px;
    color: #475467;
    display: inline-block;
    z-index: 3;
  }
  
  .sales-iq-detail-device-work-type-icon,
  .sales-iq-detail-device-work-type-value {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-work-type-value {
    align-self: stretch;
    flex-direction: row;
    padding: 0 0 0 1px;
    font-size: 14px;
    color: #101828;
  }
  
  .sales-iq-detail-device-work-type-icon {
    width: 152px;
    flex-direction: column;
    gap: 58px;
    min-width: 152px;
  }
  
  .sales-iq-detail-device-calendar-icon6 {
    flex: 1;
    gap: 8px;
    min-width: 218px;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-action-icons,
  .sales-iq-detail-device-calendar-icon6,
  .sales-iq-detail-device-work-type-label {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-work-type-label {
    gap: 8px;
    margin-left: -9px;
  }
  
  .sales-iq-detail-device-action-icons {
    align-self: stretch;
    max-width: 100%;
    row-gap: 20px;
    font-size: 16px;
    color: #475467;
  }
  
  .sales-iq-detail-device-element-actions {
    flex: 1;
    flex-direction: column;
    gap: 8px;
    min-width: 302px;
  }
  
  .sales-iq-detail-device-element-actions,
  .sales-iq-detail-device-element-details-parent,
  .sales-iq-detail-device-frame-wrapper25 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-element-details-parent {
    align-self: stretch;
    flex-direction: row;
    gap: 24px;
  }
  
  .sales-iq-detail-device-frame-wrapper25 {
    width: 648px;
    flex-direction: column;
    padding: 10px 0 0;
    box-sizing: border-box;
  }
  
  .sales-iq-detail-device-text101 {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 29px;
    z-index: 3;
  }
  
  .sales-iq-detail-device-text-wrapper10 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 57px;
  }
  
  .sales-iq-detail-device-price4 {
    align-self: stretch;
    position: relative;
    font-size: 20px;
    letter-spacing: -0.02em;
    line-height: 32px;
    font-weight: 600;
    color: #6941c6;
    white-space: nowrap;
    z-index: 2;
  }
  
  .sales-iq-detail-device-frame-wrapper27 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 34px 0 0;
  }
  
  .sales-iq-detail-device-frame-parent32,
  .sales-iq-detail-device-trend-chart-inner {
    display: flex;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-frame-parent32 {
    flex: 1;
    flex-direction: column;
    align-items: flex-end;
    gap: 19px;
  }
  
  .sales-iq-detail-device-trend-chart-inner {
    align-self: stretch;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 0 0 3px;
  }
  
  .sales-iq-detail-device-dropdown-parent1,
  .sales-iq-detail-device-frame-parent31,
  .sales-iq-detail-device-trend-chart {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-trend-chart {
    width: 113px;
    align-items: flex-start;
    gap: 4px;
  }
  
  .sales-iq-detail-device-dropdown-parent1,
  .sales-iq-detail-device-frame-parent31 {
    align-items: flex-end;
  }
  
  .sales-iq-detail-device-frame-parent31 {
    flex: 1;
  }
  
  .sales-iq-detail-device-dropdown-parent1 {
    width: 153px;
    gap: 22px;
    text-align: center;
    font-size: 12px;
    color: #475467;
  }
  
  .sales-iq-detail-device-rectangle-parent10 {
    flex: 1;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 12px 15px 12px 23px;
    max-width: 100%;
    gap: 20px;
    z-index: 1;
  }
  
  .sales-iq-detail-device-f4b711e503bc72fe396e5043e0c2-1-icon1 {
    height: 84px;
    width: 84px;
    position: relative;
    object-fit: cover;
    z-index: 2;
  }
  
  .sales-iq-detail-device-rectangle-parent12 {
    border-radius: 12px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 38px;
    z-index: 1;
  }
  
  .sales-iq-detail-device-text103 {
    position: relative;
    line-height: 28px;
    font-weight: 600;
    display: inline-block;
    min-width: 128px;
    z-index: 1;
  }
  
  .sales-iq-detail-device-text-wrapper11 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 8px 0 0;
    font-size: 18px;
    color: #101828;
  }
  
  .sales-iq-detail-device-icon9 {
    width: 12px;
    height: 9.3px;
    position: relative;
    z-index: 1;
  }
  
  .sales-iq-detail-device-icon-wrapper4 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px 0 0;
  }
  
  .sales-iq-detail-device-span {
    font-weight: 500;
  }
  
  .sales-iq-detail-device-supporting-text16 {
    height: 19px;
    position: relative;
    line-height: 24px;
    display: inline-block;
    flex-shrink: 0;
    min-width: 63px;
    z-index: 1;
  }
  
  .sales-iq-detail-device-supporting-text-wrapper1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 4px 16px 0 0;
  }
  
  .sales-iq-detail-device-frame-child15 {
    width: 16px;
    height: 16px;
    position: relative;
    z-index: 1;
  }
  
  .sales-iq-detail-device-supporting-text17 {
    height: 19px;
    position: relative;
    line-height: 24px;
    display: inline-block;
    flex-shrink: 0;
    min-width: 70.9px;
    z-index: 1;
  }
  
  .sales-iq-detail-device-frame-parent36,
  .sales-iq-detail-device-frame-parent37,
  .sales-iq-detail-device-location-elements-triple {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }
  
  .sales-iq-detail-device-frame-parent37,
  .sales-iq-detail-device-location-elements-triple {
    gap: 7px;
    font-size: 16px;
  }
  
  .sales-iq-detail-device-frame-parent37 {
    gap: 8px;
    color: #fff;
  }
  
  .sales-iq-detail-device-text105,
  .sales-iq-detail-device-text106,
  .sales-iq-detail-device-user-avatar {
    position: relative;
    line-height: 23px;
    font-weight: 500;
    z-index: 1;
  }
  
  .sales-iq-detail-device-text105,
  .sales-iq-detail-device-text106 {
    display: inline-block;
  }
  
  .sales-iq-detail-device-text105 {
    min-width: 103px;
  }
  
  .sales-iq-detail-device-text106 {
    width: 151px;
    flex-shrink: 0;
  }
  
  .sales-iq-detail-device-icon-button {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 34px;
  }
  
  .sales-iq-detail-device-supporting-text19 {
    position: relative;
    line-height: 19px;
    display: inline-block;
    min-width: 92.1px;
    z-index: 2;
  }
  
  .sales-iq-detail-device-sidebar-content1 {
    width: 161.9px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 20px 0 0;
    box-sizing: border-box;
  }
  
  .sales-iq-detail-device-supporting-text20,
  .sales-iq-detail-device-supporting-text21 {
    position: relative;
    line-height: 19px;
    display: inline-block;
    flex-shrink: 0;
    z-index: 2;
  }
  
  .sales-iq-detail-device-supporting-text20 {
    min-width: 70.9px;
  }
  
  .sales-iq-detail-device-supporting-text21 {
    width: 104px;
  }
  
  .sales-iq-detail-device-sidebar1 {
    width: 469px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    max-width: 100%;
    font-size: 12px;
    color: #475467;
  }
  
  .sales-iq-detail-device-frame-parent33,
  .sales-iq-detail-device-frame-parent34,
  .sales-iq-detail-device-nav-item-button-icon {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-nav-item-button-icon {
    align-self: stretch;
    flex-direction: column;
    font-size: 14px;
    color: #101828;
  }
  
  .sales-iq-detail-device-frame-parent33,
  .sales-iq-detail-device-frame-parent34 {
    flex: 1;
  }
  
  .sales-iq-detail-device-frame-parent34 {
    flex-direction: column;
    gap: 16px;
    min-width: 335px;
  }
  
  .sales-iq-detail-device-frame-parent33 {
    flex-direction: row;
    gap: 24px;
  }
  
  .sales-iq-detail-device-text107 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 123px;
  }
  
  .sales-iq-detail-device-buttonsbutton-wrapper,
  .sales-iq-detail-device-buttonsbutton19 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .sales-iq-detail-device-buttonsbutton19 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    justify-content: flex-start;
    padding: 6px 12px;
    gap: 5px;
    z-index: 1;
  }
  
  .sales-iq-detail-device-buttonsbutton-wrapper {
    align-self: stretch;
    justify-content: flex-end;
    padding: 0 27px 0 0;
  }
  
  .sales-iq-detail-device-arrow-square-up-right-icon4 {
    width: 24px;
    height: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 3;
  }
  
  .sales-iq-detail-device-dropdown-parent2,
  .sales-iq-detail-device-frame-parent38 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-frame-parent38 {
    flex: 1;
    gap: 27px;
  }
  
  .sales-iq-detail-device-dropdown-parent2 {
    align-self: stretch;
    gap: 50px;
  }
  
  .sales-iq-detail-device-frame-wrapper29 {
    width: 215px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 0 9px;
    box-sizing: border-box;
    font-size: 14px;
    color: #344054;
  }
  
  .sales-iq-detail-device-rectangle-parent11 {
    flex: 1;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 12px 13px 22px 23px;
    gap: 63px;
    z-index: 1;
  }
  
  .sales-iq-detail-device-content-items5,
  .sales-iq-detail-device-frame-child16,
  .sales-iq-detail-device-rectangle-parent11 {
    box-sizing: border-box;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-content-items5 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 30px 24px 32px;
    font-size: 12px;
    color: #475467;
  }
  
  .sales-iq-detail-device-frame-child16 {
    height: 208px;
    width: 1016px;
    position: relative;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    display: none;
    z-index: 1;
  }
  
  .sales-iq-detail-device-supporting-text-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    font-size: 16px;
    color: #475467;
  }
  
  .sales-iq-detail-device-search-icon-input {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 41px;
  }
  
  .sales-iq-detail-device-supporting-text24 {
    position: relative;
    font-size: 12px;
    line-height: 19px;
    color: #475467;
    display: inline-block;
    min-width: 104px;
    z-index: 1;
  }
  
  .sales-iq-detail-device-frame-parent43 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
  }
  
  .sales-iq-detail-device-supporting-text25 {
    position: relative;
    font-size: 12px;
    line-height: 19px;
    color: #475467;
    display: inline-block;
    min-width: 113px;
    z-index: 1;
  }
  
  .sales-iq-detail-device-frame-parent42 {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 10px;
    min-width: 272px;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-text111 {
    align-self: stretch;
    position: relative;
    line-height: 23px;
    font-weight: 500;
    z-index: 1;
  }
  
  .sales-iq-detail-device-supporting-text26 {
    width: 113px;
    position: relative;
    font-size: 12px;
    line-height: 19px;
    color: #475467;
    display: inline-block;
    z-index: 1;
  }
  
  .sales-iq-detail-device-text-parent8 {
    width: 233px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 14px;
    color: #101828;
  }
  
  .sales-iq-detail-device-frame-parent39,
  .sales-iq-detail-device-frame-parent41 {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-frame-parent41 {
    align-self: stretch;
    flex-direction: row;
    align-items: flex-end;
    gap: 34px;
    font-size: 16px;
    color: #fff;
  }
  
  .sales-iq-detail-device-frame-parent39 {
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    min-width: 445px;
  }
  
  .sales-iq-detail-device-dropdown14,
  .sales-iq-detail-device-text112 {
    position: absolute;
    z-index: 1;
  }
  
  .sales-iq-detail-device-dropdown14 {
    top: 0;
    left: 306px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-text112 {
    top: 19px;
    left: 137px;
    line-height: 20px;
    display: inline-block;
    min-width: 41px;
  }
  
  .sales-iq-detail-device-price5 {
    flex: 1;
    position: relative;
    letter-spacing: -0.02em;
    line-height: 32px;
    font-weight: 600;
    white-space: nowrap;
    z-index: 2;
  }
  
  .sales-iq-detail-device-price6,
  .sales-iq-detail-device-text113 {
    position: relative;
    z-index: 1;
  }
  
  .sales-iq-detail-device-text113 {
    line-height: 20px;
    display: inline-block;
    min-width: 15px;
  }
  
  .sales-iq-detail-device-price6 {
    flex: 1;
    letter-spacing: -0.02em;
    line-height: 32px;
    font-weight: 600;
    white-space: nowrap;
  }
  
  .sales-iq-detail-device-content-table-cards {
    position: absolute;
    top: 39px;
    left: 0;
    width: 316px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 7.5px;
    font-size: 20px;
    color: #6941c6;
  }
  
  .sales-iq-detail-device-text114 {
    position: absolute;
    top: 70px;
    left: 116px;
    line-height: 20px;
    display: inline-block;
    min-width: 83px;
    z-index: 3;
  }
  
  .sales-iq-detail-device-radar-1-1-parent3 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    z-index: 1;
  }
  
  .sales-iq-detail-device-arrow-square-up-right-icon5 {
    width: 24px;
    height: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 4;
  }
  
  .sales-iq-detail-device-arrow-square-up-right-frame {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 43px 0 0;
  }
  
  .sales-iq-detail-device-frame-parent46,
  .sales-iq-detail-device-frame-wrapper32 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .sales-iq-detail-device-frame-parent46 {
    flex: 1;
    justify-content: space-between;
    gap: 20px;
  }
  
  .sales-iq-detail-device-frame-wrapper32 {
    width: 217px;
    justify-content: flex-end;
    padding: 0 10px;
    box-sizing: border-box;
  }
  
  .sales-iq-detail-device-frame-parent45,
  .sales-iq-detail-device-frame-wrapper31 {
    display: flex;
    flex-direction: column;
  }
  
  .sales-iq-detail-device-frame-parent45 {
    align-self: stretch;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 4px;
  }
  
  .sales-iq-detail-device-frame-wrapper31 {
    width: 326px;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 0 9px;
    box-sizing: border-box;
    min-width: 326px;
    max-width: 100%;
    margin-left: -33px;
    text-align: center;
    font-size: 12px;
    color: #475467;
  }
  
  .sales-iq-detail-device-card-container-child,
  .sales-iq-detail-device-rectangle-parent13 {
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-rectangle-parent13 {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 12px 13px 22px 23px;
    row-gap: 20px;
    z-index: 1;
  }
  
  .sales-iq-detail-device-card-container-child {
    width: 1016px;
    height: 301px;
    position: relative;
    display: none;
  }
  
  .sales-iq-detail-device-supporting-text27 {
    position: relative;
    line-height: 24px;
    display: inline-block;
    min-width: 83px;
    z-index: 2;
  }
  
  .sales-iq-detail-device-title-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-supporting-text30 {
    position: relative;
    font-size: 12px;
    line-height: 19px;
    color: #475467;
    display: inline-block;
    min-width: 113px;
    z-index: 3;
  }
  
  .sales-iq-detail-device-detail-title-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-width: 151px;
    font-size: 14px;
    color: #101828;
  }
  
  .sales-iq-detail-device-supporting-text31 {
    width: 113px;
    position: relative;
    font-size: 12px;
    line-height: 19px;
    color: #475467;
    display: inline-block;
    z-index: 3;
  }
  
  .sales-iq-detail-device-card-row {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px 0 0;
    box-sizing: border-box;
    min-width: 445px;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-dropdown15,
  .sales-iq-detail-device-item-title {
    position: absolute;
    z-index: 2;
  }
  
  .sales-iq-detail-device-dropdown15 {
    top: 0;
    left: 306px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-item-title {
    top: 19px;
    left: 139px;
    line-height: 20px;
    display: inline-block;
    min-width: 36px;
  }
  
  .sales-iq-detail-device-price7 {
    flex: 1;
    position: relative;
    letter-spacing: -0.02em;
    line-height: 32px;
    font-weight: 600;
    white-space: nowrap;
    z-index: 3;
  }
  
  .sales-iq-detail-device-text116 {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 15px;
    z-index: 2;
  }
  
  .sales-iq-detail-device-currency {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 5px 0 0;
    font-size: 12px;
    color: #475467;
  }
  
  .sales-iq-detail-device-item-title1 {
    position: absolute;
    top: 70px;
    left: 116px;
    line-height: 20px;
    display: inline-block;
    min-width: 83px;
    z-index: 4;
  }
  
  .sales-iq-detail-device-list-item {
    align-self: stretch;
    height: 90px;
    position: relative;
  }
  
  .sales-iq-detail-device-item-icons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    z-index: 2;
  }
  
  .sales-iq-detail-device-magnifier-1-icon7 {
    height: 20px;
    width: 20px;
    position: relative;
    object-fit: cover;
    min-height: 20px;
    z-index: 2;
  }
  
  .sales-iq-detail-device-frame-wrapper33 {
    width: 310px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 0 20px;
    box-sizing: border-box;
  }
  
  .sales-iq-detail-device-card-container,
  .sales-iq-detail-device-card-list,
  .sales-iq-detail-device-list-item-parent {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-list-item-parent {
    width: 326px;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;
    min-width: 326px;
    margin-left: -33px;
    text-align: center;
    font-size: 12px;
    color: #475467;
  }
  
  .sales-iq-detail-device-card-container,
  .sales-iq-detail-device-card-list {
    align-items: flex-start;
  }
  
  .sales-iq-detail-device-card-list {
    align-self: stretch;
    flex-direction: row;
    row-gap: 20px;
  }
  
  .sales-iq-detail-device-card-container {
    flex: 1;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    flex-direction: column;
    padding: 12px 13px 22px 23px;
    gap: 24px;
    z-index: 1;
  }
  
  .sales-iq-detail-device-text-wrapper18 {
    padding: 0 60px 0 57px;
  }
  
  .sales-iq-detail-device-frame-wrapper37,
  .sales-iq-detail-device-price-wrapper,
  .sales-iq-detail-device-text-wrapper18,
  .sales-iq-detail-device-text-wrapper19 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
  }
  
  .sales-iq-detail-device-price-wrapper {
    align-self: stretch;
    padding: 0 3px 0 0;
    font-size: 20px;
    color: #6941c6;
  }
  
  .sales-iq-detail-device-frame-wrapper37,
  .sales-iq-detail-device-text-wrapper19 {
    padding: 0 33px 4px 30px;
  }
  
  .sales-iq-detail-device-frame-wrapper37 {
    padding: 0 37px 0 0;
  }
  
  .sales-iq-detail-device-frame-parent60 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 19px;
  }
  
  .sales-iq-detail-device-frame-wrapper36 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 7px 0 0;
  }
  
  .sales-iq-detail-device-text-parent12 {
    justify-content: flex-start;
    gap: 16px;
    font-size: 18px;
    color: #101828;
  }
  
  .sales-iq-detail-device-buttonsbutton-frame,
  .sales-iq-detail-device-frame-parent64,
  .sales-iq-detail-device-text-parent12 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .sales-iq-detail-device-frame-parent64 {
    justify-content: flex-start;
    gap: 7.3px;
  }
  
  .sales-iq-detail-device-buttonsbutton-frame {
    align-self: stretch;
    justify-content: flex-end;
    padding: 0 30px 0 0;
  }
  
  .sales-iq-detail-device-divider-container-child {
    width: 1016px;
    height: 493px;
    position: relative;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    display: none;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-badge-item-parent {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
    min-width: 114px;
    color: #da2d20;
  }
  
  .sales-iq-detail-device-divider-icon8 {
    width: 968px;
    position: relative;
    max-height: 100%;
    max-width: 100%;
    z-index: 2;
  }
  
  .sales-iq-detail-device-heading9 {
    width: 254px;
    position: relative;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
  }
  
  .sales-iq-detail-device-change7,
  .sales-iq-detail-device-text139 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
  }
  
  .sales-iq-detail-device-change7 {
    display: inline-block;
    min-width: 38px;
  }
  
  .sales-iq-detail-device-text139 {
    flex: 1;
    color: #475467;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .sales-iq-detail-device-change-and-text3,
  .sales-iq-detail-device-number-and-badge3 {
    display: flex;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-change-and-text3 {
    align-self: stretch;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    text-align: center;
    font-size: 14px;
    color: #079455;
  }
  
  .sales-iq-detail-device-number-and-badge3 {
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    min-width: 105px;
  }
  
  .sales-iq-detail-device-chart-mini-icon3 {
    height: 48px;
    width: 96px;
    position: relative;
    object-fit: contain;
  }
  
  .sales-iq-detail-device-dropdown19,
  .sales-iq-detail-device-metric-item3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-dropdown19 {
    margin: 0 !important;
    position: absolute;
    top: 16px;
    right: 16px;
  }
  
  .sales-iq-detail-device-metric-item3 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    padding: 16px 15px 20px;
    position: relative;
    gap: 20px;
    min-width: 229px;
    z-index: 2;
  }
  
  .sales-iq-detail-device-buttonsbutton25,
  .sales-iq-detail-device-buttonsbutton26,
  .sales-iq-detail-device-metric-item-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-metric-item-parent {
    width: 968px;
    gap: 25px;
    max-width: 100%;
    font-size: 16px;
  }
  
  .sales-iq-detail-device-buttonsbutton25,
  .sales-iq-detail-device-buttonsbutton26 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
  
    padding: 6px 11px;
    gap: 5.5px;
    min-width: 92px;
    z-index: 2;
  }
  
  .sales-iq-detail-device-buttonsbutton26 {
    padding: 6px 10px 6px 12px;
    gap: 5.4px;
  }
  
  .sales-iq-detail-device-text145 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 70px;
  }
  
  .sales-iq-detail-device-ellipse-div {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    background-color: #17b26a;
    width: 17px;
    height: 17px;
    z-index: 3;
  }
  
  .sales-iq-detail-device-div {
    position: absolute;
    top: 4px;
    left: 6px;
    line-height: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    width: 7px;
    height: 20px;
    min-width: 7px;
    z-index: 4;
  }
  
  .sales-iq-detail-device-ellipse-parent {
    height: 24px;
    width: 17px;
    position: absolute;
    margin: 0 !important;
    top: -9px;
    right: -2.7px;
    font-size: 10px;
    color: #fff;
  }
  
  .sales-iq-detail-device-buttonsbutton27 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    flex-direction: row;
    padding: 8px 12px;
    position: relative;
    gap: 6px;
    min-width: 79px;
    z-index: 2;
  }
  
  .sales-iq-detail-device-arrow-square-up-right-wrapper1,
  .sales-iq-detail-device-buttonsbutton-parent1,
  .sales-iq-detail-device-buttonsbutton27 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-buttonsbutton-parent1 {
    width: 523.3px;
    flex-direction: row;
    gap: 16px;
    max-width: calc(100% - 44px);
  }
  
  .sales-iq-detail-device-arrow-square-up-right-wrapper1 {
    flex-direction: column;
    padding: 8px 0 0;
  }
  
  .sales-iq-detail-device-divider-container1,
  .sales-iq-detail-device-frame-parent71 {
    display: flex;
    align-items: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-frame-parent71 {
    width: 968px;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    font-size: 14px;
    color: #344054;
  }
  
  .sales-iq-detail-device-divider-container1 {
    flex: 1;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: flex-start;
    padding: 12px 13px 22px 23px;
    gap: 24.3px;
    z-index: 1;
  }
  
  .sales-iq-detail-device-text146 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 72px;
  }
  
  .sales-iq-detail-device-buttonsbutton-wrapper1,
  .sales-iq-detail-device-buttonsbutton28 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .sales-iq-detail-device-buttonsbutton28 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    justify-content: flex-start;
    padding: 6px 12px;
    gap: 6px;
    z-index: 1;
    cursor: pointer;
  }
  
  .sales-iq-detail-device-buttonsbutton-wrapper1 {
    align-self: stretch;
    justify-content: center;
    padding: 0 20px;
  }
  
  .sales-iq-detail-device-text147 {
    position: relative;
    line-height: 28px;
    font-weight: 600;
  }
  
  .sales-iq-detail-device-card-header-badge {
    position: relative;
    line-height: 18px;
    font-weight: 500;
  }
  
  .sales-iq-detail-device-badge46,
  .sales-iq-detail-device-text-and-badge {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-badge46 {
    border-radius: 9999px;
    background-color: #f9f5ff;
    border: 1px solid #e9d7fe;
    padding: 0 7px;
    white-space: nowrap;
    text-align: center;
    font-size: 12px;
    color: #6941c6;
  }
  
  .sales-iq-detail-device-text-and-badge {
    align-self: stretch;
    padding: 0 242px 0 0;
    gap: 8px;
  }
  
  .sales-iq-detail-device-supporting-text44 {
    align-self: stretch;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    color: #475467;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .sales-iq-detail-device-text-and-supporting-text2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;
    min-width: 424px;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-text151 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 92px;
  }
  
  .sales-iq-detail-device-actions3,
  .sales-iq-detail-device-button11 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .sales-iq-detail-device-button11 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #7e56d9;
    border: 1px solid #7e56d9;
    overflow: hidden;
    justify-content: center;
    padding: 8px 13px;
    gap: 4px;
    color: #fff;
  }
  
  .sales-iq-detail-device-actions3 {
    justify-content: flex-start;
    gap: 12px;
    font-size: 14px;
    color: #475467;
  }
  
  .sales-iq-detail-device-content20,
  .sales-iq-detail-device-divider-icon10 {
    align-self: stretch;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-content20 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 20px 24px 0;
    box-sizing: border-box;
    gap: 16px;
  }
  
  .sales-iq-detail-device-divider-icon10 {
    position: relative;
    overflow: hidden;
    max-height: 100%;
  }
  
  .sales-iq-detail-device-card-header,
  .sales-iq-detail-device-frame-parent72 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-card-header {
    align-self: stretch;
    background-color: #fff;
    gap: 20px;
    z-index: 1;
    font-size: 26px;
    color: #101828;
  }
  
  .sales-iq-detail-device-frame-parent72 {
    flex: 1;
    gap: 24px;
  }
  
  .sales-iq-detail-device-button-group-base9,
  .sales-iq-detail-device-frame-wrapper44 {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
  }
  
  .sales-iq-detail-device-frame-wrapper44 {
    align-self: stretch;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 60px 32px;
    max-width: 100%;
    color: #344054;
    z-index: 0;
  }
  
  .sales-iq-detail-device-button-group-base9 {
    width: 83px;
    background-color: #f9fafb;
    border-right: 1px solid #d0d5dd;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    min-height: 40px;
  }
  
  .sales-iq-detail-device-text153 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 105px;
  }
  
  .sales-iq-detail-device-button-group-base10 {
    display: flex;
    padding: 10px 15px;
    white-space: nowrap;
  }
  
  .sales-iq-detail-device-button-group-base10,
  .sales-iq-detail-device-button-group-base11,
  .sales-iq-detail-device-button-group-base17 {
    background-color: #fff;
    border-right: 1px solid #d0d5dd;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 40px;
  }
  
  .sales-iq-detail-device-button-group-base11 {
    align-self: stretch;
    display: none;
    padding: 8px 16px;
    z-index: 6;
  }
  
  .sales-iq-detail-device-button-group-base17 {
    width: 93px;
    flex-shrink: 0;
    display: flex;
    padding: 10px 15px;
  }
  
  .sales-iq-detail-device-button-group1 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-search-placeholder1 {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 82px;
  
  }
  
  .sales-iq-detail-device-supporting-text45 {
    align-self: stretch;
    width: 54px;
    position: relative;
    line-height: 24px;
    color: #475467;
    display: none;
  }
  
  .sales-iq-detail-device-content22,
  .sales-iq-detail-device-input-with-label3,
  .sales-iq-detail-device-input3 {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-content22 {
    flex: 1;
    flex-direction: row;
    align-items: center;
    padding: 0 262px 0 0;
    box-sizing: border-box;
    gap: 8px;
  }
  
  .sales-iq-detail-device-input-with-label3,
  .sales-iq-detail-device-input3 {
    align-self: stretch;
  }
  
  .sales-iq-detail-device-input3 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05),
      0 0 0 4px rgba(158, 119, 237, 0.24);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d6bbfb;
    box-sizing: border-box;
    overflow: hidden;
    flex-direction: row;
    align-items: center;
    padding: 8px 13px;
    font-size: 16px;
    color: #101828;
  }
  
  .sales-iq-detail-device-input-with-label3 {
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
  }
  
  .sales-iq-detail-device-hint-text3 {
    width: 320px;
    position: relative;
    line-height: 20px;
    color: #475467;
    display: none;
  }
  
  .sales-iq-detail-device-input-dropdown3 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    max-width: 400px;
  }
  
  .sales-iq-detail-device-actions4,
  .sales-iq-detail-device-button12 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .sales-iq-detail-device-button12 {
    width: 99px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
    justify-content: center;
    padding: 10px 13px;
    gap: 4px;
  }
  
  .sales-iq-detail-device-actions4 {
    width: 511px;
    justify-content: flex-start;
    gap: 12px;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-content-items9,
  .sales-iq-detail-device-content21,
  .sales-iq-detail-device-filters-bar1 {
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-content21 {
    align-self: stretch;
    border-radius: 12px;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 16px;
    gap: 20px;
  }
  
  .sales-iq-detail-device-content-items9,
  .sales-iq-detail-device-filters-bar1 {
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-filters-bar1 {
    flex: 1;
    background-color: #fff;
    border-bottom: 1px solid #eaecf0;
    flex-direction: column;
    z-index: 2;
  }
  
  .sales-iq-detail-device-content-items9 {
    align-self: stretch;
    flex-direction: row;
    padding: 0 30px 23px 32px;
    color: #344054;
  }
  
  .sales-iq-detail-device-badge-number1,
  .sales-iq-detail-device-text162 {
    position: relative;
    line-height: 28px;
    font-weight: 600;
    display: inline-block;
    min-width: 123px;
  }
  
  .sales-iq-detail-device-badge-number1 {
    line-height: 18px;
    font-weight: 500;
    min-width: 56px;
  }
  
  .sales-iq-detail-device-badge47,
  .sales-iq-detail-device-text-and-badge1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-badge47 {
    width: 72px;
    border-radius: 9999px;
    background-color: #f9f5ff;
    border: 1px solid #e9d7fe;
    box-sizing: border-box;
    padding: 0 7px;
    white-space: nowrap;
    text-align: center;
    font-size: 12px;
    color: #6941c6;
  }
  
  .sales-iq-detail-device-text-and-badge1 {
    align-self: stretch;
    padding: 0 729px 0 0;
    gap: 8px;
  }
  
  .sales-iq-detail-device-supporting-text46 {
    width: 697px;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    color: #475467;
    display: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-text-and-supporting-text3 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 12px 0;
    box-sizing: border-box;
    gap: 4px;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-actions5,
  .sales-iq-detail-device-card-header1,
  .sales-iq-detail-device-content23 {
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-actions5 {
    display: none;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    font-size: 14px;
    color: #475467;
  }
  
  .sales-iq-detail-device-card-header1,
  .sales-iq-detail-device-content23 {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
  }
  
  .sales-iq-detail-device-content23 {
    flex-direction: row;
    padding: 20px 24px 0;
    box-sizing: border-box;
    gap: 16px;
  }
  
  .sales-iq-detail-device-card-header1 {
    background-color: #fff;
    flex-direction: column;
    gap: 20px;
    flex-shrink: 0;
    font-size: 18px;
  }
  
  .sales-iq-detail-device-checkbox-base {
    height: 20px;
    width: 20px;
    position: relative;
    border-radius: 6px;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .sales-iq-detail-device-checkbox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-sub-nav-item {
    position: relative;
    line-height: 18px;
    font-weight: 500;
    display: inline-block;
    min-width: 34px;
  }
  
  .sales-iq-detail-device-arrow-down-icon1 {
    width: 16px;
    height: 16px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .sales-iq-detail-device-sub-nav-items {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
  }
  
  .sales-iq-detail-device-sub-nav-items-wrapper {
    flex-direction: column;
    padding: 1px 0 0;
  }
  
  .sales-iq-detail-device-checkbox-wrapper,
  .sales-iq-detail-device-sub-nav-items-wrapper,
  .sales-iq-detail-device-table-header-cell {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-table-header-cell {
    width: 441px;
    margin: 0 !important;
    position: absolute;
    top: 0;
    left: -380px;
    background-color: #f9fafb;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    flex-direction: row;
    padding: 12px 24px 10px;
    gap: 12px;
    font-size: 12px;
    color: #475467;
  }
  
  .sales-iq-detail-device-checkbox-wrapper {
    flex-direction: column;
    padding: 10px 0 0;
  }
  
  .sales-iq-detail-device-avatar-online-indicator {
    height: 13px;
    width: 13px;
    position: relative;
    border-radius: 9999px;
    background-color: #17b26a;
    border: 1.5px solid #fff;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 1;
  }
  
  .sales-iq-detail-device-avatar2,
  .sales-iq-detail-device-contrast-border2 {
    height: 40px;
    width: 40px;
    border-radius: 9999px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .sales-iq-detail-device-contrast-border2 {
    border: 0.8px solid rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    overflow: hidden;
    justify-content: flex-end;
    padding: 30px 0 0 26px;
  }
  
  .sales-iq-detail-device-avatar2 {
    justify-content: flex-start;
    background-image: url(../../../icons/Gerator/sales_iq/avatar2@3x.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
  
  .sales-iq-detail-device-account-button,
  .sales-iq-detail-device-supporting-text47 {
    position: relative;
    line-height: 20px;
    display: inline-block;
  }
  
  .sales-iq-detail-device-account-button {
    font-weight: 500;
    min-width: 75px;
  }
  
  .sales-iq-detail-device-supporting-text47 {
    color: #475467;
    min-width: 97px;
  }
  
  .sales-iq-detail-device-table-cell {
    width: 405px;
    margin: 0 !important;
    position: absolute;
    top: 44px;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    overflow-x: auto;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
  }
  
  .sales-iq-detail-device-avatar3 {
    height: 40px;
    width: 40px;
    position: relative;
    border-radius: 9999px;
    flex-shrink: 0;
    background-image: url(../../../icons/Gerator/sales_iq/avatar3@3x.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
  
  .sales-iq-detail-device-dropdown-first-date {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 96px;
  }
  
  .sales-iq-detail-device-table-cell1 {
    width: 467px;
    margin: 0 !important;
    position: absolute;
    top: 116px;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    overflow-x: auto;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
  }
  
  .sales-iq-detail-device-text167 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 84px;
  }
  
  .sales-iq-detail-device-table-cell2 {
    width: 428px;
    margin: 0 !important;
    position: absolute;
    top: 188px;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    overflow-x: auto;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
  }
  
  .sales-iq-detail-device-avatar5 {
    height: 40px;
    width: 40px;
    position: relative;
    border-radius: 9999px;
    flex-shrink: 0;
    background-image: url(../../../icons/Gerator/sales_iq/avatar4@3x.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
  
  .sales-iq-detail-device-card-content-third {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 103px;
    white-space: nowrap;
  }
  
  .sales-iq-detail-device-contrast-border6,
  .sales-iq-detail-device-table-cell3 {
    position: absolute;
    margin: 0 !important;
    box-sizing: border-box;
  }
  
  /* .sales-iq-detail-device-table-cell3 {
    width: 411px;
    top: 260px;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    overflow-x: auto;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
  } */
  
  .sales-iq-detail-device-contrast-border6 {
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 9999px;
    border: 0.8px solid rgba(0, 0, 0, 0.08);
    overflow: hidden;
  }
  
  .sales-iq-detail-device-card-header-actions {
    flex: 1;
    position: relative;
    line-height: 24px;
    font-weight: 600;
    z-index: 1;
  }
  
  .sales-iq-detail-device-avatar6 {
    height: 40px;
    width: 40px;
    border-radius: 9999px;
    background-color: #f2f4f7;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 8px 0;
    box-sizing: border-box;
    position: relative;
  }
  
  .sales-iq-detail-device-button-upload-icon {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 82px;
  }
  
  .sales-iq-detail-device-button-upload,
  .sales-iq-detail-device-table-cell4 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: 14px;
    color: #101828;
  }
  
  .sales-iq-detail-device-table-cell4 {
    width: 428px;
    margin: 0 !important;
    position: absolute;
    top: 332px;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    overflow-x: auto;
    flex-shrink: 0;
    flex-direction: row;
    padding: 16px 24px 14px;
    gap: 12px;
    text-align: center;
    font-size: 16px;
    color: #667085;
  }
  
  .sales-iq-detail-device-dropdown-table-input {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 78px;
  }
  
  .sales-iq-detail-device-table-cell5 {
    width: 428px;
    margin: 0 !important;
    position: absolute;
    bottom: 288px;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    overflow-x: auto;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
  }
  
  .sales-iq-detail-device-avatar8 {
    height: 40px;
    width: 40px;
    position: relative;
    border-radius: 9999px;
    flex-shrink: 0;
    background-image: url(../../../icons/Gerator/sales_iq/avatar5@3x.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
  
  .sales-iq-detail-device-table-cell6,
  .sales-iq-detail-device-table-cell7 {
    width: 411px;
    margin: 0 !important;
    position: absolute;
    bottom: 216px;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    overflow-x: auto;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
  }
  
  .sales-iq-detail-device-table-cell7 {
    width: 428px;
    bottom: 144px;
    text-align: center;
    font-size: 16px;
    color: #667085;
  }
  
  .sales-iq-detail-device-avatar10 {
    height: 40px;
    width: 40px;
    position: relative;
    border-radius: 9999px;
    flex-shrink: 0;
    background-image: url(../../../icons/Gerator/sales_iq/avatar6@3x.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
  
  .sales-iq-detail-device-title3 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 67px;
  }
  
  .sales-iq-detail-device-table-cell8 {
    width: 428px;
    margin: 0 !important;
    position: absolute;
    bottom: 72px;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    overflow-x: auto;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
  }
  
  .sales-iq-detail-device-avatar11 {
    height: 40px;
    width: 40px;
    position: relative;
    border-radius: 9999px;
    flex-shrink: 0;
    background-image: url(../../../icons/Gerator/sales_iq/avatar7@3x.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
  
  .sales-iq-detail-device-title4 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 95px;
  }
  
  .sales-iq-detail-device-table-cell9 {
    width: 428px;
    margin: 0 !important;
    position: absolute;
    bottom: 0;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    overflow-x: auto;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
    z-index: 1;
  }
  
  .sales-iq-detail-device-text169 {
    position: relative;
    line-height: 18px;
    font-weight: 500;
    display: inline-block;
    min-width: 50px;
  }
  
  .sales-iq-detail-device-table-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-table-header {
    gap: 4px;
  }
  
  
  
  .sales-iq-detail-device-text170 {
    position: relative;
    line-height: 18px;
    font-weight: 500;
    display: inline-block;
    min-width: 93px;
  }
  
  /* .sales-iq-detail-device-table-cell10 {
    width: 255px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 26px 24px 24px;
  } */
  
  .sales-iq-detail-device-text171 {
    position: relative;
    line-height: 18px;
    font-weight: 500;
    display: inline-block;
    min-width: 85px;
  }
  
  .sales-iq-detail-device-table-cell11 {
    width: 247px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 26px 24px 24px;
  }
  
  .sales-iq-detail-device-text172 {
    position: relative;
    line-height: 18px;
    font-weight: 500;
    display: inline-block;
    min-width: 91px;
  }
  
  .sales-iq-detail-device-table-cell15,
  .sales-iq-detail-device-table-cell16,
  .sales-iq-detail-device-table-cell18 {
    width: 279px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 26px 24px 24px;
  }
  
  .sales-iq-detail-device-table-cell16,
  .sales-iq-detail-device-table-cell18 {
    width: 271px;
  }
  
  .sales-iq-detail-device-table-cell18 {
    width: 239px;
  }
  
  .sales-iq-detail-device-column,
  .sales-iq-detail-device-table-header-cell-parent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-column {
    width: 120px;
    flex-direction: column;
    padding: 0;
    box-sizing: border-box;
    z-index: 2;
    text-align: center;
    font-size: 12px;
    color: #344054;
  }
  
  .sales-iq-detail-device-table-header-cell-parent {
    flex-direction: row;
    position: relative;
  }
  
  .sales-iq-detail-device-frame-wrapper45 {
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    max-width: 100%;
    color: #101828;
  }
  
  .sales-iq-detail-device-text180 {
    position: relative;
    line-height: 18px;
    font-weight: 500;
    display: inline-block;
    min-width: 40px;
  }
  
  .sales-iq-detail-device-help-icon {
    height: 16px;
    width: 16px;
    position: relative;
  }
  
  /* .sales-iq-detail-device-table-header-cell2 {
    width: 297px;
    background-color: #f9fafb;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 13px 24px 11px;
    font-size: 12px;
  } */
  
  .sales-iq-detail-device-text181 {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 101px;
  }
  
 
  
  .sales-iq-detail-device-user-cell {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 87px;
  }
  
  .sales-iq-detail-device-table-cell21 {
    width: 407px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 26px 23px 24px;
    max-width: 232%;
    flex-shrink: 0;
  }
  
  .sales-iq-detail-device-user-cell1 {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 93px;
  }
  
  .sales-iq-detail-device-table-cell22 {
    width: 407px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 26px 24px 24px;
    max-width: 232%;
    flex-shrink: 0;
  }
  
  .sales-iq-detail-device-user-cell2,
  .sales-iq-detail-device-user-cell3,
  .sales-iq-detail-device-user-cell4 {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 64px;
  }
  
  .sales-iq-detail-device-user-cell3,
  .sales-iq-detail-device-user-cell4 {
    min-width: 84px;
  }
  
  .sales-iq-detail-device-user-cell4 {
    min-width: 82px;
  }
  
  .sales-iq-detail-device-table-cell25 {
    width: 328px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 26px 24px 24px;
    max-width: 187%;
    flex-shrink: 0;
  }
  
  .sales-iq-detail-device-user-cell5 {
    display: inline-block;
    min-width: 92px;
  }
  
  .sales-iq-detail-device-user-cell5,
  .sales-iq-detail-device-user-cell6,
  .sales-iq-detail-device-user-cell7 {
    position: relative;
    line-height: 20px;
  }
  
  .sales-iq-detail-device-user-cell6 {
    display: inline-block;
    min-width: 68px;
  }
  
  .sales-iq-detail-device-user-cell7 {
    white-space: nowrap;
  }
  
  .sales-iq-detail-device-column1,
  .sales-iq-detail-device-table-cell29 {
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;
  }
  
  .sales-iq-detail-device-table-cell29 {
    width: 287px;
    border-bottom: 1px solid #eaecf0;
    flex-direction: row;
    align-items: center;
    padding: 26px 24px 24px;
  }
  
  .sales-iq-detail-device-column1 {
    width: 176px;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    z-index: 1;
  }
  
  .sales-iq-detail-device-user-header {
    position: relative;
    line-height: 18px;
    font-weight: 500;
    display: inline-block;
    min-width: 37px;
  }
  
  
  .sales-iq-detail-device-table-header2 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .sales-iq-detail-device-table-header2 {
    flex: 1;
    justify-content: flex-start;
    gap: 4px;
  }
  
  /* .sales-iq-detail-device-table-cell30 {
    border-bottom: 1px solid #eaecf0;
    justify-content: center;
  } */
  
 
/*   
  .sales-iq-detail-device-table-cell30 {
    padding: 26px 50px 24px;
  } */
  
  .sales-iq-detail-device-column2,
  .sales-iq-detail-device-dropdown28 {
    width: 21px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-column2 {
    width: 120px;
    align-items: flex-end;
    padding: 0;
    box-sizing: border-box;
    z-index: 1;
    font-size: 12px;
  }
  
  .sales-iq-detail-device-frame-parent73 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 100%;
    gap: 20px;
    color: #475467;
  }
  
  .sales-iq-detail-device-page-label {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 59px;
  }
  
  .sales-iq-detail-device-buttonsbutton29 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 11px;
    gap: 4px;
  }
  
  .sales-iq-detail-device-button-wrap,
  .sales-iq-detail-device-pagination-controls {
    display: flex;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-button-wrap {
    align-self: stretch;
    flex-direction: row;
    align-items: center;
  }
  
  .sales-iq-detail-device-pagination-controls {
    width: 326px;
    flex-shrink: 0;
    flex-direction: column;
    align-items: flex-start;
    padding: 2px 0 0;
    box-sizing: border-box;
  }
  
  .sales-iq-detail-device-number11 {
    width: 7px;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 7px;
  }
  
  .sales-iq-detail-device-content24,
  .sales-iq-detail-device-pagination-number-base {
    flex: 1;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-content24 {
    padding: 10px 16px;
  }
  
  .sales-iq-detail-device-pagination-number-base {
    background-color: #f9fafb;
    overflow: hidden;
    color: #18222f;
  }
  
  .sales-iq-detail-device-number12 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 9px;
  }
  
  .sales-iq-detail-device-content25,
  .sales-iq-detail-device-pagination-number-base1 {
    flex: 1;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-content25 {
    padding: 10px 15px;
  }
  
  .sales-iq-detail-device-pagination-number-base1 {
    overflow: hidden;
  }
  
  .sales-iq-detail-device-number13 {
    width: 10px;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 10px;
  }
  
  .sales-iq-detail-device-content26,
  .sales-iq-detail-device-pagination-number-base2 {
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-content26 {
    padding: 10px 15px;
  }
  
  .sales-iq-detail-device-pagination-number-base2 {
    overflow: hidden;
  }
  
  .sales-iq-detail-device-number14 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 13px;
  }
  
  .sales-iq-detail-device-content27 {
    flex: 1;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px 13px;
  }
  
  .sales-iq-detail-device-number17 {
    width: 16px;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 16px;
  }
  
  .sales-iq-detail-device-content30,
  .sales-iq-detail-device-pagination-numbers {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-content30 {
    border-radius: 8px;
    padding: 10px 12px;
  }
  
  .sales-iq-detail-device-pagination-numbers {
    width: 292px;
    flex-shrink: 0;
    gap: 2px;
    text-align: center;
    color: #475467;
  }
  
  .sales-iq-detail-device-page-label1 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 32px;
  }
  
  .sales-iq-detail-device-button-wrap1,
  .sales-iq-detail-device-buttonsbutton30 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .sales-iq-detail-device-buttonsbutton30 {
    width: 84px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
    justify-content: center;
    padding: 6px 11px;
    gap: 4px;
  }
  
  .sales-iq-detail-device-button-wrap1 {
    align-self: stretch;
    justify-content: flex-end;
  }
  
  .sales-iq-detail-device-pagination,
  .sales-iq-detail-device-table {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-pagination {
    align-self: stretch;
    border-top: 1px solid #eaecf0;
    overflow-x: auto;
    flex-direction: row;
    padding: 12px 24px 16px;
    gap: 12px;
    z-index: 3;
    color: #344054;
  }
  
  .sales-iq-detail-device-table {
    flex: 1;
    box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    overflow: hidden;
    flex-direction: column;
    max-width: 100%;
    z-index: 1;
  }
  
  .sales-iq-detail-device-content-items10 {
    align-self: stretch;
    padding: 0 30px 24px 32px;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-content-items10,
  .sales-iq-detail-device-content-items11,
  .sales-iq-detail-device-table-cell41,
  .sales-iq-detail-device-table-cell42 {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-content-items11 {
    align-self: stretch;
    padding: 0 30px 0 32px;
    max-width: 100%;
    font-size: 26px;
  }
  
  /* .sales-iq-detail-device-table-cell40,
  .sales-iq-detail-device-table-cell41,
  .sales-iq-detail-device-table-cell42 {
    position: absolute;
    left: 0;
    border-bottom: 1px solid #eaecf0;
    gap: 12px;
  }
   */

  
  /* .sales-iq-detail-device-table-cell40,
  .sales-iq-detail-device-table-cell41,
  .sales-iq-detail-device-table-cell42 {
    top: 44px;
    width: 245px;
    overflow-x: auto;
    padding: 16px 24px 14px;
  }
  
  .sales-iq-detail-device-table-cell41,
  .sales-iq-detail-device-table-cell42 {
    top: 116px;
    width: 252px;
  }
  
  .sales-iq-detail-device-table-cell42 {
    top: 188px;
  }
  
  .sales-iq-detail-device-table-cell43 {
    top: 260px;
    width: 252px;
  }
  
  .sales-iq-detail-device-table-cell43,
  .sales-iq-detail-device-table-cell44,
  .sales-iq-detail-device-table-cell45 {
    position: absolute;
    left: 0;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
  }
  
  .sales-iq-detail-device-table-cell44 {
    top: 332px;
    width: 311px;
    text-align: center;
    font-size: 16px;
    color: #667085;
  }
  
  .sales-iq-detail-device-table-cell45 {
    top: 404px;
    width: 260px;
  }
  
  .sales-iq-detail-device-table-cell46 {
    top: 476px;
    box-sizing: border-box;
    width: 252px;
  }
  
  .sales-iq-detail-device-table-cell46,
  .sales-iq-detail-device-table-cell47,
  .sales-iq-detail-device-table-cell48,
  .sales-iq-detail-device-table-cell49 {
    position: absolute;
    left: 0;
    border-bottom: 1px solid #eaecf0;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
  }
  
  .sales-iq-detail-device-table-cell47 {
    top: 548px;
    box-sizing: border-box;
    width: 242px;
    text-align: center;
    font-size: 16px;
    color: #667085;
  }
  
  .sales-iq-detail-device-table-cell48,
  .sales-iq-detail-device-table-cell49 {
    top: 620px;
  }
  
  .sales-iq-detail-device-table-cell49 {
    top: 692px;
    box-sizing: border-box;
    width: 242px;
    z-index: 1;
  } */
  
  .sales-iq-detail-device-text211 {
    position: relative;
    line-height: 18px;
    font-weight: 500;
    display: inline-block;
    min-width: 55px;
  }
  
  .sales-iq-detail-device-table-header-cell5 {
    width: 441px;
    background-color: #f9fafb;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 13px 24px 11px;
    max-width: 286%;
    flex-shrink: 0;
    font-size: 12px;
    color: #475467;
  }
  
  .sales-iq-detail-device-background {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 3px;
    background-color: #f5f5f5;
    width: 100%;
    height: 100%;
  }
  
  .sales-iq-detail-device-f4b711e503bc72fe396e5043e0c2-1-icon3 {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 24px;
    height: 24px;
    object-fit: cover;
    z-index: 1;
  }
  
  .sales-iq-detail-device-background-parent {
    height: 40px;
    width: 40px;
    position: relative;
  }
  
  .sales-iq-detail-device-supporting-text70 {
    position: relative;
    line-height: 20px;
    color: #475467;
    display: inline-block;
    min-width: 101px;
    white-space: nowrap;
  }
  
  .sales-iq-detail-device-table-cell50,
  .sales-iq-detail-device-table-cell51 {
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 16px 228px 14px 24px;
    gap: 12px;
    max-width: 263%;
    flex-shrink: 0;
  }
  
  .sales-iq-detail-device-table-cell51 {
    padding: 16px 290px 14px 24px;
    max-width: 303%;
  }
  
  .sales-iq-detail-device-progress-label {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 98px;
  }
  
  .sales-iq-detail-device-table-cell52 {
    border-bottom: 1px solid #eaecf0;
    flex-direction: row;
    align-items: center;
    padding: 16px 251px 14px 24px;
    gap: 12px;
    max-width: 278%;
    flex-shrink: 0;
  }
  
  .sales-iq-detail-device-column3,
  .sales-iq-detail-device-table-cell52,
  .sales-iq-detail-device-table-cell53 {
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;
  }
  
  .sales-iq-detail-device-table-cell53 {
    border-bottom: 1px solid #eaecf0;
    flex-direction: row;
    align-items: center;
    padding: 16px 234px 14px 24px;
    gap: 12px;
    max-width: 267%;
    flex-shrink: 0;
  }
  
  .sales-iq-detail-device-column3 {
    position: absolute;
    top: 0;
    left: 264.5px;
    width: 154.5px;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    z-index: 1;
  }
  
  .sales-iq-detail-device-table-header-cell-group {
    height: 764px;
    width: 441px;
    position: relative;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-marker-pin-02-group,
  .sales-iq-detail-device-table-cell65 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-marker-pin-02-group {
    gap: 7px;
  }
  
  .sales-iq-detail-device-table-cell65 {
    align-self: stretch;
    border-bottom: 1px solid #eaecf0;
    padding: 26px 24px 24px;
  }
  
  .sales-iq-detail-device-table-header-cell-container {
    width: 279px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-column4 {
    align-self: stretch;
    display: flex;
    padding: 0;
    z-index: 1;
  }
  
  .sales-iq-detail-device-column-wrapper,
  .sales-iq-detail-device-column4,
  .sales-iq-detail-device-column5 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-column-wrapper {
    width: 66px;
    display: flex;
    padding: 0 22px 0 0;
    box-sizing: border-box;
    text-align: center;
    font-size: 12px;
    color: #344054;
  }
  
  .sales-iq-detail-device-column5 {
    width: 176px;
    padding: 0;
    min-width: 176px;
  }
  
  .sales-iq-detail-device-column5,
  .sales-iq-detail-device-column6,
  .sales-iq-detail-device-table-header-cell8 {
    display: flex;
    box-sizing: border-box;
  }
  
  .sales-iq-detail-device-table-header-cell8 {
    width: 120px;
    background-color: #f9fafb;
    border-bottom: 1px solid #eaecf0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 13px 41px 11px;
  }
  
  .sales-iq-detail-device-column6 {
    width: 79px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 0;
    min-width: 79px;
    font-size: 12px;
  }
  
  .sales-iq-detail-device-column-parent,
  .sales-iq-detail-device-frame-parent74,
  .sales-iq-detail-device-pagination1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .sales-iq-detail-device-column-parent {
    width: 333px;
    justify-content: flex-start;
    gap: 78px;
    max-width: 100%;
    z-index: 1;
    color: #475467;
  }
  
  .sales-iq-detail-device-frame-parent74,
  .sales-iq-detail-device-pagination1 {
    align-self: stretch;
  }
  
  .sales-iq-detail-device-frame-parent74 {
    justify-content: space-between;
    max-width: 100%;
    gap: 20px;
  }
  
  .sales-iq-detail-device-pagination1 {
    border-top: 1px solid #eaecf0;
    overflow-x: auto;
    justify-content: flex-start;
    padding: 12px 24px 16px;
    gap: 12px;
    z-index: 2;
    color: #344054;
  }
  
  .sales-iq-detail-device-text227 {
    position: relative;
    line-height: 18px;
    font-weight: 500;
    display: inline-block;
    min-width: 73px;
  }
  
  .sales-iq-detail-device-badge50,
  .sales-iq-detail-device-text-and-badge4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-badge50 {
    width: 89px;
    border-radius: 9999px;
    background-color: #f9f5ff;
    border: 1px solid #e9d7fe;
    box-sizing: border-box;
    padding: 0 7px;
    white-space: nowrap;
    text-align: center;
    font-size: 12px;
    color: #6941c6;
  }
  
  .sales-iq-detail-device-text-and-badge4 {
    align-self: stretch;
    padding: 0 332px 0 0;
    gap: 7px;
  }
  
  .sales-iq-detail-device-content-items14,
  .sales-iq-detail-device-text-and-supporting-text16 {
    display: flex;
    align-items: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-text-and-supporting-text16 {
    flex: 1;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    min-width: 436px;
  }
  
  .sales-iq-detail-device-content-items14 {
    align-self: stretch;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0 29px 0 33px;
    box-sizing: border-box;
    font-size: 26px;
  }
  
  .sales-iq-detail-device-text232 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 53px;
  }
  
  .sales-iq-detail-device-button-group-base27,
  .sales-iq-detail-device-button-group-base28 {
    border-right: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    min-height: 40px;
  }
  
  .sales-iq-detail-device-button-group-base27 {
    width: 85px;
    background-color: #f9fafb;
    flex-shrink: 0;
    white-space: nowrap;
  }
  
  .sales-iq-detail-device-button-group-base28 {
    background-color: #fff;
  }
  
  .sales-iq-detail-device-text240 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 89px;
  }
  
  .sales-iq-detail-device-filter-bar-container {
    align-self: stretch;
    flex-direction: row;
    padding: 0 29px 0 33px;
    box-sizing: border-box;
    color: #344054;
  }
  
  .sales-iq-detail-device-filter-bar-container,
  .sales-iq-detail-device-frame-parent,
  .sales-iq-detail-device-all-content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-frame-parent {
    flex: 1;
    flex-direction: column;
    min-width: 701px;
    font-size: 14px;
  }
  
  .sales-iq-detail-device-all-content {
    align-self: stretch;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    /* padding: 0 0 67px; */
    box-sizing: border-box;
    row-gap: 20px;
  }
  
  .sales-iq-detail-device-featured-icon {
    width: 48px;
    height: 48px;
    position: relative;
    border-radius: 10px;
  }
  
  .sales-iq-detail-device-supporting-text82 {
    align-self: stretch;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    color: #475467;
  }
  
  .sales-iq-detail-device-text-and-supporting-text17 {
    width: 352px;
    flex-direction: column;
    justify-content: flex-start;
    gap: 4px;
    max-width: 352px;
  }
  
  .sales-iq-detail-device-buttonsbutton33,
  .sales-iq-detail-device-content45,
  .sales-iq-detail-device-text-and-supporting-text17 {
    display: flex;
    align-items: center;
  }
  
  .sales-iq-detail-device-content45 {
    align-self: stretch;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 20px;
    gap: 16px;
  }
  
  .sales-iq-detail-device-buttonsbutton33 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    flex-direction: row;
    justify-content: center;
    padding: 8px 15px;
    gap: 6px;
  }
  
  .sales-iq-detail-device-text245 {
    position: relative;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
    min-width: 91px;
  }
  
  .sales-iq-detail-device-actions11,
  .sales-iq-detail-device-buttonsbutton34 {
    display: flex;
    flex-direction: row;
  }
  
  .sales-iq-detail-device-buttonsbutton34 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #7e56d9;
    border: 1px solid #7e56d9;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    padding: 8px 15px;
    gap: 6px;
    color: #fff;
  }
  
  .sales-iq-detail-device-actions11 {
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    text-align: left;
    color: #344054;
  }
  
  .sales-iq-detail-device-empty-state,
  .sales-iq-detail-device-empty-state1 {
    flex: 1;
    display: flex;
    align-items: center;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-empty-state1 {
    flex-direction: column;
    justify-content: flex-start;
    gap: 24px;
  }
  
  .sales-iq-detail-device-empty-state {
    flex-direction: row;
    justify-content: center;
    z-index: 1;
  }
  
  .sales-iq-detail-device-empty-state-wrapper,
  .sales-iq-detail-device-all {
    display: flex;
    box-sizing: border-box;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-empty-state-wrapper {
    width: 1074px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 29px;
    text-align: center;
  }
  
  .sales-iq-detail-device-all {
    align-self: stretch;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    /* padding: 0 0 245px; */
  }
  
  .sales-iq-detail-device-bifocal-optometry-measurement-icon {
    width: 391px;
    position: relative;
    max-height: 100%;
    object-fit: cover;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-title8 {
    align-self: stretch;
    position: relative;
    line-height: 30px;
    font-weight: 600;
  }
  
  .sales-iq-detail-device-content46,
  .sales-iq-detail-device-text-and-supporting-text18 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-text-and-supporting-text18 {
    width: 352px;
    gap: 8px;
    max-width: 352px;
  }
  
  .sales-iq-detail-device-content46 {
    align-self: stretch;
    padding: 0 20px;
    box-sizing: border-box;
    gap: 20px;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-add,
  .sales-iq-detail-device-learn-more {
    position: relative;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
    min-width: 98px;
  }
  
  .sales-iq-detail-device-learn-more {
    min-width: 88px;
  }
  
  .sales-iq-detail-device-actions12,
  .sales-iq-detail-device-empty-state3 {
    display: flex;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-actions12 {
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
    text-align: left;
    font-size: 16px;
    color: #344054;
  }
  
  .sales-iq-detail-device-empty-state3 {
    flex: 1;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-empty-state-container,
  .sales-iq-detail-device-empty-state2 {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-empty-state2 {
    flex: 1;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    padding: 41.5px 0;
    z-index: 1;
  }
  
  .sales-iq-detail-device-empty-state-container {
    width: 1076px;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 30px 124px;
    text-align: center;
    font-size: 20px;
  }
  
  
  
  
  
  
  
  
  
  .sales-iq-detail-device-sales-iq-detail-device-frame-layout {
    max-width: 1524px;
    margin: 0 auto;
    position: relative;
    align-items: flex-end;
    padding: 7px 0 0;
    box-sizing: border-box;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    font-size: 16px;
    color: #101828;
    font-family: Inter;
  
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  
    background-color: #fff;
    
  
    align-self: stretch;
    height: max-content;
  }
  
  .sales-iq-detail-device-buttonsbutton5a {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    border: 1px solid #7e56d9;
    padding: 8px 15px;
    background-color: #7e56d9;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    gap: 6px;
    position: relative;
  }
  
  .sales-iq-detail-device-buttonsbutton5a:hover{
    background-color: #6941c6;
  }
  
  .sales-iq-detail-device-search-lg-icon1 {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .sales-iq-detail-device-text-padding15 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
  }
  
  .sales-iq-detail-device-text74 {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-family: Inter;
    color: #fff;
    text-align: left;
    display: inline-block;
    min-width: 91px;
  }
  
  .sales-iq-detail-device-dropdown-wrapper {
    position: absolute; 
    z-index: 3;
    right: 35px;
    top: 800px;
  }


  /* Table Css */

  .sales-iq-detail-device-display-form-content-wrapper {
    align-self: stretch;
    flex-direction: row;
    padding: 0 0 0 58px;
    box-sizing: border-box;
    color: #344054;
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    width: max-content;
  }

  .sales-iq-detail-device-display-form-content{
    display: flex;
  justify-content: flex-start;
  max-width: 100%;
  align-items: flex-start;
  flex: 1;
  flex-direction: column;
  gap: 20px;

  }

  .sales-iq-detail-device-display-toggle-header-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  
    gap: 12px;
    max-width: 100%;
    font-size: 14px;
    color: #344054;
  
    align-self: stretch;
    gap: 16px;
    font-size: 18px;
    color: #101828;
    
  }

  .sales-iq-detail-device-display-toggle-header {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
    min-width: 320px;
    max-width: 100%;
  }

  .sales-iq-detail-device-display-toggle-title {
    align-self: stretch;
    position: relative;
 
    line-height: 28px;
    font-weight: 600;
  }


  .sales-iq-detail-device-display-divider-container {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 0 4px;
    box-sizing: border-box;
    max-width: 100%;
  }

  .sales-iq-detail-device-display-divider-icon3 {
    flex: 1;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }
  
.sales-iq-detail-device-table {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #fff;
    /* max-width: 100%; */
    width: max-content;
    min-height: 500px;
    overflow: hidden;
    flex-direction: column;
    box-sizing: border-box;
    margin: 0;
    align-self: stretch;
    box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 12px;
    border: 1px solid #eaecf0;
  }
  
  .sales-iq-detail-device-card-header1 {
    justify-content: flex-start;
    max-width: 100%;
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    background-color: #fff;
    flex-direction: column;
    gap: 20px;
    flex-shrink: 0;
  }
  
  .sales-iq-detail-device-content4 {
    justify-content: flex-start;
    max-width: 100%;
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 20px 24px 0;
    box-sizing: border-box;
    gap: 16px;
  }
  
  .sales-iq-detail-device-text-and-supporting-text2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 12px 0;
    box-sizing: border-box;
    gap: 4px;
    max-width: 100%;
  }
  
  .sales-iq-detail-device-text-and-badge1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    padding: 0 729px 0 0;
    gap: 8px;
  }
  
  .sales-iq-detail-device-table-header-title {
    font-family: Inter;
    color: #101828;
    text-align: left;
    position: relative;
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    display: inline-block;
    min-width: 123px;
  }
  
  .sales-iq-detail-device-dropdown {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-detail-device-dots-vertical-icon {
    width: 20px;
    height: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .sales-iq-detail-device-divider-icon {
    align-self: stretch;
    max-width: 100%;
  
    position: relative;
    overflow: hidden;
    max-height: 100%;
  }
  
  .sales-iq-detail-device-table-content {
    display: flex;
    box-sizing: border-box;
    width: max-content;
    /* height: 607px; */
    overflow-y: auto;
    scrollbar-width: none;
    flex-direction: column;
    align-items: flex-start;
    /* justify-content: space-between; */
    padding: 0 1px 0 0;
    /* gap: 20px; */
    max-width: 100%;
  }
  
  .sales-iq-detail-device-table-row {
  
    display: flex;
    justify-content: start;
    flex-shrink: 0;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
  }
  
  .sales-iq-detail-device-table-header-cell1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    width: 240px;
    background-color: #f9fafb;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    padding: 13px 24px 11px;  

    
    text-align: left;
    color: #475467;
  }

  .sales-iq-detail-device-table-cell10 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  
    width: 240px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    padding: 6px 20px 0px;
    gap: 12px;
  }
  
  
  .sales-iq-detail-device-checkbox-wrapper {
    margin: 0;
    height: 20px;
    width: 28px;
  }
  
  .sales-iq-detail-device-table-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  
    gap: 4px;
  }
  
  .sales-iq-detail-device-column-title {
    text-decoration: none;
    position: relative;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    font-family: Inter;
    color: #475467;
    text-align: left;
    display: inline-block;
    min-width: 50px;
  }
  
  .sales-iq-detail-device-arrow-down-icon1 {
    height: 16px;
    width: 16px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .sales-iq-detail-device-table-header-cell2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  
    width: 230px;
    background-color: #f9fafb;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    padding: 13px 27px 11px;
  }

  .sales-iq-detail-device-table-cell20 {
    width: 230px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 13px 27px 11px;
  }
  
  .sales-iq-detail-device-help-icon {
    height: 16px;
    width: 16px;
    position: relative;
  }
  
  .sales-iq-detail-device-table-header-cell3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 200px;
    background-color: #f9fafb;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    padding: 13px 27px 11px;
    align-self: stretch;
    
   
  
  }

  .sales-iq-detail-device-table-cell30 {
    width: 200px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 13px 27px 11px;
  }
  
  .sales-iq-detail-device-table-header-cell4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  
    width: 144px;
    background-color: #f9fafb;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    padding: 13px 27px 11px;
  }
  
  .sales-iq-detail-device-table-cell40 {
    width: 144px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding:13px 50px 4.5px 11px;
  }

  .sales-iq-detail-device-table-header2 {
    display: flex;
    flex-direction: row;
    align-items: center;
  
    flex: 1;
    justify-content: center;
    gap: 4px;
  }
  
  .sales-iq-detail-device-action-column-title {
    position: relative;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    font-family: Inter;
    color: #475467;
    text-align: left;
    display: inline-block;
    min-width: 37px;
  }
  
  .sales-iq-detail-device-arrow-down-icon2 {
    height: 16px;
    width: 16px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  
  .sales-iq-detail-device-table-header-cell-parent {
    display: flex;
    justify-content: start;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    /* padding: 0 0 0 20px; */
    position: relative;

  }

  .sales-iq-detail-device-table-header-cell-parent:hover{
    background-color: #F9FAFB;
    cursor: pointer;
  }
  

  .sales-iq-detail-device-cell-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /* padding: 10px 0 0; */
  }
  
    /*Viewed Table Css */

    .sales-iq-detail-device-display-form-content-wrapper-Viewed {
        align-self: stretch;
        flex-direction: row;
        padding: 0 0 0 58px;
        box-sizing: border-box;
        color: #344054;
        align-items: flex-start;
        display: flex;
        justify-content: flex-start;
        width: max-content;
      }
    
      .sales-iq-detail-device-display-form-content-Viewed{
        display: flex;
      justify-content: flex-start;
      max-width: 100%;
      align-items: flex-start;
      flex: 1;
      flex-direction: column;
      gap: 20px;
    
      }
    
      .sales-iq-detail-device-display-toggle-header-parent-Viewed {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
      
        gap: 12px;
        max-width: 100%;
        font-size: 14px;
        color: #344054;
      
        align-self: stretch;
        gap: 16px;
        font-size: 18px;
        color: #101828;
        
      }
    
      .sales-iq-detail-device-display-toggle-header-Viewed {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 4px;
        min-width: 320px;
        max-width: 100%;
      }
    
      .sales-iq-detail-device-display-toggle-title-Viewed {
        align-self: stretch;
        position: relative;
     
        line-height: 28px;
        font-weight: 600;
      }
    
    
      .sales-iq-detail-device-display-divider-container-Viewed {
        align-self: stretch;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0 0 4px;
        box-sizing: border-box;
        max-width: 100%;
      }
    
      .sales-iq-detail-device-display-divider-icon3-Viewed {
        flex: 1;
        position: relative;
        max-width: 100%;
        overflow: hidden;
        max-height: 100%;
      }
      
    .sales-iq-detail-device-table-Viewed {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        background-color: #fff;
        /* max-width: 100%; */
        width: max-content;
        min-height: 500px;
        overflow: hidden;
        flex-direction: column;
        box-sizing: border-box;
        margin: 0;
        align-self: stretch;
        box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
        border-radius: 12px;
        border: 1px solid #eaecf0;
      }
      
      .sales-iq-detail-device-card-header1-Viewed {
        justify-content: flex-start;
        max-width: 100%;
        align-self: stretch;
        display: flex;
        align-items: flex-start;
        background-color: #fff;
        flex-direction: column;
        gap: 20px;
        flex-shrink: 0;
      }
      
      .sales-iq-detail-device-content4-Viewed {
        justify-content: flex-start;
        max-width: 100%;
        align-self: stretch;
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        padding: 20px 24px 0;
        box-sizing: border-box;
        gap: 16px;
      }
      
      .sales-iq-detail-device-text-and-supporting-text2-Viewed {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 12px 0;
        box-sizing: border-box;
        gap: 4px;
        max-width: 100%;
      }
      
      .sales-iq-detail-device-text-and-badge1-Viewed {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        padding: 0 729px 0 0;
        gap: 8px;
      }
      
      .sales-iq-detail-device-table-header-title-Viewed {
        font-family: Inter;
        color: #101828;
        text-align: left;
        position: relative;
        font-size: 18px;
        line-height: 28px;
        font-weight: 600;
        display: inline-block;
        min-width: 123px;
      }
      
      .sales-iq-detail-device-dropdown-Viewed {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
      
      .sales-iq-detail-device-dots-vertical-icon-Viewed {
        width: 20px;
        height: 20px;
        position: relative;
        overflow: hidden;
        flex-shrink: 0;
      }
      
      .sales-iq-detail-device-divider-icon-Viewed {
        align-self: stretch;
        max-width: 100%;
      
        position: relative;
        overflow: hidden;
        max-height: 100%;
      }
      
      .sales-iq-detail-device-table-content-Viewed {
        display: flex;
        box-sizing: border-box;
        width: max-content;
        /* height: 607px; */
        overflow-y: auto;
        scrollbar-width: none;
        flex-direction: column;
        align-items: flex-start;
        /* justify-content: space-between; */
        padding: 0 1px 0 0;
        /* gap: 20px; */
        max-width: 100%;
      }
      
      .sales-iq-detail-device-table-row-Viewed {
      
        display: flex;
        justify-content: start;
        flex-shrink: 0;
        flex-direction: row;
        align-items: flex-end;
        width: 100%;
      }
      
      .sales-iq-detail-device-table-header-cell1-Viewed {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      
        width: 190px;
        background-color: #f9fafb;
        border-bottom: 1px solid #eaecf0;
        box-sizing: border-box;
        padding: 13px 24px 11px;
      }
      
      
      .sales-iq-detail-device-table-cell10-Viewed {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      
        width: 190px;
        border-bottom: 1px solid #eaecf0;
        box-sizing: border-box;
        padding: 13px 24px 11px;
      }

      .sales-iq-detail-device-checkbox-wrapper-Viewed {
        margin: 0;
        height: 20px;
        width: 28px;
      }
      
      .sales-iq-detail-device-table-header-Viewed {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      
        gap: 4px;
      }
      
      .sales-iq-detail-device-column-title-Viewed {
        text-decoration: none;
        position: relative;
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        font-family: Inter;
        color: #475467;
        text-align: left;
        display: inline-block;
        min-width: 50px;
      }
      
      .sales-iq-detail-device-arrow-down-icon1-Viewed {
        height: 16px;
        width: 16px;
        position: relative;
        overflow: hidden;
        flex-shrink: 0;
      }
      
      .sales-iq-detail-device-table-header-cell2-Viewed {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      
        width: 230px;
        background-color: #f9fafb;
        border-bottom: 1px solid #eaecf0;
        box-sizing: border-box;
        padding: 13px 27px 11px;
      }

      
  .sales-iq-detail-device-table-cell20-Viewed {
    width: 230px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 13px 27px 11px;
  }
  
      
      .sales-iq-detail-device-help-icon-Viewed {
        height: 16px;
        width: 16px;
        position: relative;
      }
      
      .sales-iq-detail-device-table-header-cell3-Viewed {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 200px;
        background-color: #f9fafb;
        border-bottom: 1px solid #eaecf0;
        box-sizing: border-box;
        padding: 13px 27px 11px;
      
      }

      .sales-iq-detail-device-table-cell30-Viewed {
        width: 200px;
        border-bottom: 1px solid #eaecf0;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 13px 27px 11px;
      }
      
      .sales-iq-detail-device-table-header-cell4-Viewed {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      
        width: 144px;
        background-color: #f9fafb;
        border-bottom: 1px solid #eaecf0;
        box-sizing: border-box;
        padding: 13px 27px 11px;
      }
      
      .sales-iq-detail-device-table-cell40-Viewed {
        width: 144px;
        border-bottom: 1px solid #eaecf0;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding:13px 50px 5px 11px;;
      }

      .sales-iq-detail-device-table-header2-Viewed {
        display: flex;
        flex-direction: row;
        align-items: center;
      
        flex: 1;
        justify-content: flex-start;
        gap: 4px;
      }
      
      .sales-iq-detail-device-action-column-title-Viewed {
        position: relative;
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        font-family: Inter;
        color: #475467;
        text-align: left;
        display: inline-block;
        min-width: 37px;
      }
      
      .sales-iq-detail-device-arrow-down-icon2-Viewed {
        height: 16px;
        width: 16px;
        position: relative;
        overflow: hidden;
        flex-shrink: 0;
        display: none;
      }
      
      .sales-iq-detail-device-table-header-cell-parent-Viewed {
        display: flex;
        justify-content: start;
        width: 100%;
        flex-direction: row;
        align-items: flex-start;
        /* padding: 0 0 0 20px; */
        position: relative;
      }
      
      /* .sales-iq-detail-device-table-cell10-Viewed {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      
        width: 180px;
        border-bottom: 1px solid #eaecf0;
        box-sizing: border-box;
        padding: 26px 24px 22px 19px;
      } */
      
      .sales-iq-detail-device-cell-content-Viewed {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        /* padding: 10px 0 0; */
      }


/*Added to radar Table Css */

    .sales-iq-detail-device-display-form-content-wrapper-Added-to-Radar {
        align-self: stretch;
        flex-direction: row;
        padding: 0 0 0 58px;
        box-sizing: border-box;
        color: #344054;
        align-items: flex-start;
        display: flex;
        justify-content: flex-start;
        width: max-content;
      }
    
      .sales-iq-detail-device-display-form-content-Added-to-Radar{
        display: flex;
      justify-content: flex-start;
      max-width: 100%;
      align-items: flex-start;
      flex: 1;
      flex-direction: column;
      gap: 20px;
    
      }
    
      .sales-iq-detail-device-display-toggle-header-parent-Added-to-Radar {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
      
        gap: 12px;
        max-width: 100%;
        font-size: 14px;
        color: #344054;
      
        align-self: stretch;
        gap: 16px;
        font-size: 18px;
        color: #101828;
        
      }
    
      .sales-iq-detail-device-display-toggle-header-Added-to-Radar {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 4px;
        min-width: 320px;
        max-width: 100%;
      }
    
      .sales-iq-detail-device-display-toggle-title-Added-to-Radar {
        align-self: stretch;
        position: relative;
     
        line-height: 28px;
        font-weight: 600;
      }
    
    
      .sales-iq-detail-device-display-divider-container-Added-to-Radar {
        align-self: stretch;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0 0 4px;
        box-sizing: border-box;
        max-width: 100%;
      }
    
      .sales-iq-detail-device-display-divider-icon3-Added-to-Radar {
        flex: 1;
        position: relative;
        max-width: 100%;
        overflow: hidden;
        max-height: 100%;
      }
      
    .sales-iq-detail-device-table-Added-to-Radar {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        background-color: #fff;
        /* max-width: 100%; */
        width: max-content;
        min-height: 500px;
        overflow: hidden;
        flex-direction: column;
        box-sizing: border-box;
        margin: 0;
        align-self: stretch;
        box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
        border-radius: 12px;
        border: 1px solid #eaecf0;
      }
      
      .sales-iq-detail-device-card-header1-Added-to-Radar {
        justify-content: flex-start;
        max-width: 100%;
        align-self: stretch;
        display: flex;
        align-items: flex-start;
        background-color: #fff;
        flex-direction: column;
        gap: 20px;
        flex-shrink: 0;
      }
      
      .sales-iq-detail-device-content4-Added-to-Radar {
        justify-content: flex-start;
        max-width: 100%;
        align-self: stretch;
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        padding: 20px 24px 0;
        box-sizing: border-box;
        gap: 16px;
      }
      
      .sales-iq-detail-device-text-and-supporting-text2-Added-to-Radar {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 12px 0;
        box-sizing: border-box;
        gap: 4px;
        max-width: 100%;
      }
      
      .sales-iq-detail-device-text-and-badge1-Added-to-Radar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        padding: 0 729px 0 0;
        gap: 8px;
      }
      
      .sales-iq-detail-device-table-header-title-Added-to-Radar {
        font-family: Inter;
        color: #101828;
        text-align: left;
        position: relative;
        font-size: 18px;
        line-height: 28px;
        font-weight: 600;
        display: inline-block;
        min-width: 123px;
      }
      
      .sales-iq-detail-device-dropdown-Added-to-Radar {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
      
      .sales-iq-detail-device-dots-vertical-icon-Added-to-Radar {
        width: 20px;
        height: 20px;
        position: relative;
        overflow: hidden;
        flex-shrink: 0;
      }
      
      .sales-iq-detail-device-divider-icon-Added-to-Radar {
        align-self: stretch;
        max-width: 100%;
      
        position: relative;
        overflow: hidden;
        max-height: 100%;
      }
      
      .sales-iq-detail-device-table-content-Added-to-Radar {
        display: flex;
        box-sizing: border-box;
        width: max-content;
        /* height: 607px; */
        overflow-y: auto;
        scrollbar-width: none;
        flex-direction: column;
        align-items: flex-start;
        /* justify-content: space-between; */
        padding: 0 1px 0 0;
        /* gap: 20px; */
        max-width: 100%;
      }
      
      .sales-iq-detail-device-table-row-Added-to-Radar {
      
        display: flex;
        justify-content: start;
        flex-shrink: 0;
        flex-direction: row;
        align-items: flex-end;
        width: 100%;
      }
      
      .sales-iq-detail-device-table-header-cell1-Added-to-Radar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      
        width: 190px;
        background-color: #f9fafb;
        border-bottom: 1px solid #eaecf0;
        box-sizing: border-box;
        padding: 13px 24px 11px;
      }

      .sales-iq-detail-device-table-cell10-Added-to-Radar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      
        width: 190px;
        border-bottom: 1px solid #eaecf0;
        box-sizing: border-box;
        padding: 13px 24px 11px;
      }
      
      .sales-iq-detail-device-checkbox-wrapper-Added-to-Radar {
        margin: 0;
        height: 20px;
        width: 28px;
      }
      
      .sales-iq-detail-device-table-header-Added-to-Radar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      
        gap: 4px;
      }
      
      .sales-iq-detail-device-column-title-Added-to-Radar {
        text-decoration: none;
        position: relative;
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        font-family: Inter;
        color: #475467;
        text-align: left;
        display: inline-block;
        min-width: 50px;
      }
      
      .sales-iq-detail-device-arrow-down-icon1-Added-to-Radar {
        height: 16px;
        width: 16px;
        position: relative;
        overflow: hidden;
        flex-shrink: 0;
      }
      
      .sales-iq-detail-device-table-header-cell2-Added-to-Radar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      
        width: 230px;
        background-color: #f9fafb;
        border-bottom: 1px solid #eaecf0;
        box-sizing: border-box;
        padding: 13px 27px 11px;
      }

      .sales-iq-detail-device-table-cell20-Added-to-Radar {
        width: 230px;
        border-bottom: 1px solid #eaecf0;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 13px 27px 11px;
      }
      
      .sales-iq-detail-device-help-icon-Added-to-Radar {
        height: 16px;
        width: 16px;
        position: relative;
      }
      
      .sales-iq-detail-device-table-header-cell3-Added-to-Radar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 200px;
        background-color: #f9fafb;
        border-bottom: 1px solid #eaecf0;
        box-sizing: border-box;
        padding: 13px 27px 11px;
      
      }

      .sales-iq-detail-device-table-cell30-Added-to-Radar {
        width: 200px;
        border-bottom: 1px solid #eaecf0;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 13px 27px 11px;
      }
      
      .sales-iq-detail-device-table-header-cell4-Added-to-Radar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      
        width: 144px;
        background-color: #f9fafb;
        border-bottom: 1px solid #eaecf0;
        box-sizing: border-box;
        padding: 13px 27px 11px;
      }

      .sales-iq-detail-device-table-cell40-Added-to-Radar {
        width: 144px;
        border-bottom: 1px solid #eaecf0;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding:13px 50px 5px 11px;;
      }
      
      .sales-iq-detail-device-table-header2-Added-to-Radar {
        display: flex;
        flex-direction: row;
        align-items: center;
      
        flex: 1;
        justify-content: flex-start;
        gap: 4px;
      }
      
      .sales-iq-detail-device-action-column-title-Added-to-Radar {
        position: relative;
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        font-family: Inter;
        color: #475467;
        text-align: left;
        display: inline-block;
        min-width: 37px;
      }
      
      .sales-iq-detail-device-arrow-down-icon2-Added-to-Radar {
        height: 16px;
        width: 16px;
        position: relative;
        overflow: hidden;
        flex-shrink: 0;
        display: none;
      }
      
      .sales-iq-detail-device-table-header-cell-parent-Added-to-Radar {
        display: flex;
        justify-content: start;
        width: 100%;
        flex-direction: row;
        align-items: flex-start;
        /* padding: 0 0 0 20px; */
        position: relative;
      }
      
    
      
      .sales-iq-detail-device-cell-content-Added-to-Radar {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        /* padding: 10px 0 0; */
      }

         /*Searched Table Css */

    .sales-iq-detail-device-display-form-content-wrapper-Searched {
        align-self: stretch;
        flex-direction: row;
        padding: 0 0 0 58px;
        box-sizing: border-box;
        color: #344054;
        align-items: flex-start;
        display: flex;
        justify-content: flex-start;
        width: max-content;
      }
    
      .sales-iq-detail-device-display-form-content-Searched{
        display: flex;
      justify-content: flex-start;
      max-width: 100%;
      align-items: flex-start;
      flex: 1;
      flex-direction: column;
      gap: 20px;
    
      }
    
      .sales-iq-detail-device-display-toggle-header-parent-Searched {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
      
        gap: 12px;
        max-width: 100%;
        font-size: 14px;
        color: #344054;
      
        align-self: stretch;
        gap: 16px;
        font-size: 18px;
        color: #101828;
        
      }
    
      .sales-iq-detail-device-display-toggle-header-Searched {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 4px;
        min-width: 320px;
        max-width: 100%;
      }
    
      .sales-iq-detail-device-display-toggle-title-Searched {
        align-self: stretch;
        position: relative;
     
        line-height: 28px;
        font-weight: 600;
      }
    
    
      .sales-iq-detail-device-display-divider-container-Searched {
        align-self: stretch;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0 0 4px;
        box-sizing: border-box;
        max-width: 100%;
      }
    
      .sales-iq-detail-device-display-divider-icon3-Searched {
        flex: 1;
        position: relative;
        max-width: 100%;
        overflow: hidden;
        max-height: 100%;
      }
      
    .sales-iq-detail-device-table-Searched {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        background-color: #fff;
        /* max-width: 100%; */
        width: max-content;
        min-height: 500px;
        overflow: hidden;
        flex-direction: column;
        box-sizing: border-box;
        margin: 0;
        align-self: stretch;
        box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
        border-radius: 12px;
        border: 1px solid #eaecf0;
      }
      
      .sales-iq-detail-device-card-header1-Searched {
        justify-content: flex-start;
        max-width: 100%;
        align-self: stretch;
        display: flex;
        align-items: flex-start;
        background-color: #fff;
        flex-direction: column;
        gap: 20px;
        flex-shrink: 0;
      }
      
      .sales-iq-detail-device-content4-Searched {
        justify-content: flex-start;
        max-width: 100%;
        align-self: stretch;
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        padding: 20px 24px 0;
        box-sizing: border-box;
        gap: 16px;
      }
      
      .sales-iq-detail-device-text-and-supporting-text2-Searched {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 12px 0;
        box-sizing: border-box;
        gap: 4px;
        max-width: 100%;
      }
      
      .sales-iq-detail-device-text-and-badge1-Searched {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        padding: 0 729px 0 0;
        gap: 8px;
      }
      
      .sales-iq-detail-device-table-header-title-Searched {
        font-family: Inter;
        color: #101828;
        text-align: left;
        position: relative;
        font-size: 18px;
        line-height: 28px;
        font-weight: 600;
        display: inline-block;
        min-width: 123px;
      }
      
      .sales-iq-detail-device-dropdown-Searched {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
      
      .sales-iq-detail-device-dots-vertical-icon-Searched {
        width: 20px;
        height: 20px;
        position: relative;
        overflow: hidden;
        flex-shrink: 0;
      }
      
      .sales-iq-detail-device-divider-icon-Searched {
        align-self: stretch;
        max-width: 100%;
      
        position: relative;
        overflow: hidden;
        max-height: 100%;
      }
      
      .sales-iq-detail-device-table-content-Searched {
        display: flex;
        box-sizing: border-box;
        width: max-content;
        /* height: 607px; */
        overflow-y: auto;
        scrollbar-width: none;
        flex-direction: column;
        align-items: flex-start;
        /* justify-content: space-between; */
        padding: 0 1px 0 0;
        /* gap: 20px; */
        max-width: 100%;
      }
      
      .sales-iq-detail-device-table-row-Searched {
      
        display: flex;
        justify-content: start;
        flex-shrink: 0;
        flex-direction: row;
        align-items: flex-end;
        width: 100%;
      }
      
      .sales-iq-detail-device-table-header-cell1-Searched {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      
        width: 190px;
        background-color: #f9fafb;
        border-bottom: 1px solid #eaecf0;
        box-sizing: border-box;
        padding: 13px 24px 11px;
      }
      
      .sales-iq-detail-device-table-cell10-Searched {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      
        width: 190px;
        border-bottom: 1px solid #eaecf0;
        box-sizing: border-box;
        padding: 13px 24px 11px;
      }

      .sales-iq-detail-device-checkbox-wrapper-Searched {
        margin: 0;
        height: 20px;
        width: 28px;
      }
      
      .sales-iq-detail-device-table-header-Searched {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      
        gap: 4px;
      }
      
      .sales-iq-detail-device-column-title-Searched {
        text-decoration: none;
        position: relative;
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        font-family: Inter;
        color: #475467;
        text-align: left;
        display: inline-block;
        min-width: 50px;
      }
      
      .sales-iq-detail-device-arrow-down-icon1-Searched {
        height: 16px;
        width: 16px;
        position: relative;
        overflow: hidden;
        flex-shrink: 0;
      }
      
      .sales-iq-detail-device-table-header-cell2-Searched {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      
        width: 230px;
        background-color: #f9fafb;
        border-bottom: 1px solid #eaecf0;
        box-sizing: border-box;
        padding: 13px 27px 11px;
      }

      .sales-iq-detail-device-table-cell20-Searched {
        width: 230px;
        border-bottom: 1px solid #eaecf0;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 13px 27px 11px;
      }
      
      
      .sales-iq-detail-device-help-icon-Searched {
        height: 16px;
        width: 16px;
        position: relative;
      }
      
      .sales-iq-detail-device-table-header-cell3-Searched {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 200px;
        background-color: #f9fafb;
        border-bottom: 1px solid #eaecf0;
        box-sizing: border-box;
        padding: 13px 27px 11px;
      
      }

      .sales-iq-detail-device-table-cell30-Searched{
        width: 200px;
        border-bottom: 1px solid #eaecf0;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 13px 27px 11px;
      }
      
      .sales-iq-detail-device-table-header-cell4-Searched {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      
        width: 144px;
        background-color: #f9fafb;
        border-bottom: 1px solid #eaecf0;
        box-sizing: border-box;
        padding: 13px 27px 11px;
      }

      .sales-iq-detail-device-table-cell40-Searched{
        width: 144px;
        border-bottom: 1px solid #eaecf0;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding:13px 50px 5px 11px;;
      }
      
      .sales-iq-detail-device-table-header2-Searched {
        display: flex;
        flex-direction: row;
        align-items: center;
      
        flex: 1;
        justify-content: flex-start;
        gap: 4px;
      }
      
      .sales-iq-detail-device-action-column-title-Searched {
        position: relative;
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        font-family: Inter;
        color: #475467;
        text-align: left;
        display: inline-block;
        min-width: 37px;
      }
      
      .sales-iq-detail-device-arrow-down-icon2-Searched {
        height: 16px;
        width: 16px;
        position: relative;
        overflow: hidden;
        flex-shrink: 0;
        display: none;
      }
      
      .sales-iq-detail-device-table-header-cell-parent-Searched {
        display: flex;
        justify-content: start;
        width: 100%;
        flex-direction: row;
        align-items: flex-start;
        /* padding: 0 0 0 20px; */
        position: relative;
      }
      
    
      
      .sales-iq-detail-device-cell-content-Searched {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        /* padding: 10px 0 0; */
      }

      .sales-iq-detail-device-table-data-custom-main-container{
        display: flex;
        flex-direction: column;
        max-height: 420px;
        overflow-y: scroll;
        scrollbar-width: none;
      }
  

      /* New Css  */
      .sales-iq-detail-device-table-avatar{
        height: 35px;
        width: 35px;
        border-radius: 9999px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    
        justify-content: flex-start;
        /* background-image: url("../../icons/Gerator/contacts/avatar@3x.png"); */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top;
      }
      /* Load More css  */

      .sales-iq-detail-device-frame-wrapper44 {
        align-self: stretch;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 10px 32px;
        max-width: 100%;
        color: #344054;
        z-index: 0;
  
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
      }
  
      .sales-iq-detail-device-frame-parent72 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        max-width: 100%;
  
        flex: 1;
        gap: 24px;
      }
  
      .sales-iq-detail-device-buttonsbutton-wrapper1 {
        align-self: stretch;
        justify-content: center;
        padding: 0 20px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      }
      
      .sales-iq-detail-device-buttonsbutton-wrapper1-child {
        outline: none;
        border: none;
        background-color: #fff;
        padding: 0;
      }
  
      .sales-iq-detail-device-buttonsbutton28 {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        background-color: #fff;
        border: 1px solid #d0d5dd;
        overflow: hidden;
        justify-content: flex-start;
        padding: 6px 12px;
        gap: 6px;
        z-index: 1;
        cursor: pointer;
      }
  
      .sales-iq-detail-device-arrow-narrow-left-icon {
        height: 24px;
        width: 24px;
        position: relative;
        overflow: hidden;
        flex-shrink: 0;
        min-height: 24px;
      }
  
      .sales-iq-detail-device-search-input {
        display: flex;
        justify-content: flex-start;
   
        flex-direction: column;
        align-items: flex-start;
        padding: 2px 0 0;
      }
  
      .sales-iq-detail-device-text146 {
        position: relative;
        line-height: 20px;
        font-weight: 600;
        display: inline-block;
        min-width: 72px;
      }
  
/* .sales-iq-detail-device-location-content{
  position: relative;
} */

  @media screen and (max-width: 1275px) {
  
    .sales-iq-detail-device-all,
    .sales-iq-detail-device-all-content {
      padding-bottom: 20px;
      box-sizing: border-box;
    }
  
    .sales-iq-detail-device-all {
      padding-bottom: 67px;
    }
  
    .sales-iq-detail-device-container3 {
      max-width: 100%;
    }
  }
  
  @media screen and (max-width: 1100px) {
    .sales-iq-detail-device-content13 {
      gap: 35px;
      flex-wrap: wrap;
    }
  
    .sales-iq-detail-device-main {
      height: auto;
      padding-top: 21px;
      padding-bottom: 21px;
      box-sizing: border-box;
    }
  
    .sales-iq-detail-device-content-elements-parent,
    .sales-iq-detail-device-content17,
    .sales-iq-detail-device-frame-group,
    .sales-iq-detail-device-rectangle-parent10,
    .sales-iq-detail-device-rectangle-parent5,
    .sales-iq-detail-device-rectangle-parent7 {
      flex-wrap: wrap;
    }
  
    .sales-iq-detail-device-rectangle-parent11 {
      gap: 31px;
      flex-wrap: wrap;
    }
  
    .sales-iq-detail-device-frame-wrapper31 {
      flex: 1;
    }
  
    .sales-iq-detail-device-rectangle-parent13 {
      flex-wrap: wrap;
    }
  
    .sales-iq-detail-device-list-item-parent {
      flex: 1;
    }
  
    .sales-iq-detail-device-card-list,
    .sales-iq-detail-device-content20,
    .sales-iq-detail-device-content21,
    .sales-iq-detail-device-metric-item-parent {
      flex-wrap: wrap;
    }
  
    .sales-iq-detail-device-text-and-badge1 {
      flex-wrap: wrap;
      padding-right: 364px;
      box-sizing: border-box;
    }
  
    .sales-iq-detail-device-content23,
    .sales-iq-detail-device-frame-parent73,
    .sales-iq-detail-device-frame-parent74 {
      flex-wrap: wrap;
    }
  
    .sales-iq-detail-device-all {
      padding-bottom: 44px;
      box-sizing: border-box;
    }
  
    .sales-iq-detail-device-empty-state-container {
      padding-bottom: 81px;
      box-sizing: border-box;
    }
  }
  
  @media screen and (max-width: 750px) {
  
  
    .sales-iq-detail-device-heading {
      font-size: 24px;
      line-height: 30px;
    }
  
    .sales-iq-detail-device-content13 {
      gap: 17px;
    }
  
    .sales-iq-detail-device-tabs-and-filters {
      flex-wrap: wrap;
    }
  
    .sales-iq-detail-device-jul {
      width: 100%;
      height: 17px;
    }
  
    .sales-iq-detail-device-x-axis {
      flex-wrap: wrap;
    }
  
    .sales-iq-detail-device-number {
      font-size: 24px;
      line-height: 30px;
    }
  
    .sales-iq-detail-device-main {
      gap: 16px;
    }
  
    .sales-iq-detail-device-number3,
    .sales-iq-detail-device-number4 {
      font-size: 29px;
      line-height: 35px;
    }
  
    .sales-iq-detail-device-dropdowns {
      padding-right: 83px;
      box-sizing: border-box;
      min-width: 100%;
      flex: 1;
    }
  
    .sales-iq-detail-device-badge-elements,
    .sales-iq-detail-device-card-elements,
    .sales-iq-detail-device-dropdowns,
    .sales-iq-detail-device-location-badge-number {
      flex-wrap: wrap;
    }
  
    .sales-iq-detail-device-action-fields {
      min-width: 100%;
    }
  
    .sales-iq-detail-device-content-actions {
      flex-wrap: wrap;
      min-width: 100%;
    }
  
    .sales-iq-detail-device-frame-parent2,
    .sales-iq-detail-device-primary-table {
      flex-wrap: wrap;
    }
  
    .sales-iq-detail-device-frame-parent1 {
      flex-wrap: wrap;
      min-width: 100%;
    }
  
    .sales-iq-detail-device-frame-parent17,
    .sales-iq-detail-device-frame-parent18,
    .sales-iq-detail-device-frame-parent21 {
      flex-wrap: wrap;
    }
  
    .sales-iq-detail-device-element-details,
    .sales-iq-detail-device-f4b711e503bc72fe396e5043e0c2-1-icon {
      flex: 1;
    }
  
    .sales-iq-detail-device-action-icons,
    .sales-iq-detail-device-element-details-parent {
      flex-wrap: wrap;
    }
  
    .sales-iq-detail-device-icon-button {
      gap: 17px;
    }
  
    .sales-iq-detail-device-icon-button,
    .sales-iq-detail-device-sidebar1 {
      flex-wrap: wrap;
    }
  
    .sales-iq-detail-device-frame-parent33 {
      flex-wrap: wrap;
      min-width: 100%;
    }
  
    .sales-iq-detail-device-rectangle-parent11 {
      gap: 16px;
    }
  
    .sales-iq-detail-device-frame-parent41 {
      gap: 17px;
      flex-wrap: wrap;
    }
  
    .sales-iq-detail-device-frame-parent39 {
      min-width: 100%;
    }
  
    .sales-iq-detail-device-frame-wrapper31 {
      margin-left: 0;
    }
  
    .sales-iq-detail-device-card-row {
      min-width: 100%;
    }
  
    .sales-iq-detail-device-list-item-parent {
      margin-left: 0;
    }
  
    .sales-iq-detail-device-buttonsbutton-parent1 {
      flex-wrap: wrap;
    }
  
    .sales-iq-detail-device-text-and-badge {
      flex-wrap: wrap;
      padding-right: 121px;
      box-sizing: border-box;
    }
  
    .sales-iq-detail-device-text-and-supporting-text2 {
      min-width: 100%;
    }
  
    .sales-iq-detail-device-actions4 {
      flex-wrap: wrap;
    }
  
    .sales-iq-detail-device-text-and-badge1 {
      padding-right: 182px;
      box-sizing: border-box;
    }
  
    .sales-iq-detail-device-text-and-supporting-text3 {
      min-width: 100%;
    }
  
    .sales-iq-detail-device-actions5,
    .sales-iq-detail-device-table-cell51,
    .sales-iq-detail-device-table-cell52 {
      flex-wrap: wrap;
    }
  
    .sales-iq-detail-device-text-and-badge4 {
      flex-wrap: wrap;
      padding-right: 166px;
      box-sizing: border-box;
    }
  
    .sales-iq-detail-device-frame-parent,
    .sales-iq-detail-device-text-and-supporting-text16 {
      min-width: 100%;
    }
  
    .sales-iq-detail-device-empty-state3 {
      gap: 16px;
    }
  
    .sales-iq-detail-device-empty-state2 {
      padding-top: 27px;
      padding-bottom: 27px;
      box-sizing: border-box;
    }
  
    .sales-iq-detail-device-container3 {
      gap: 16px;
    }
  
  }
  
  @media screen and (max-width: 450px) {
  
    .sales-iq-detail-device-account {
      gap: 23px;
      padding-right: 20px;
      box-sizing: border-box;
    }
  
    .sales-iq-detail-device-heading {
      font-size: 18px;
      line-height: 23px;
    }
  
    .sales-iq-detail-device-actions {
      flex-wrap: wrap;
    }
  
    .sales-iq-detail-device-number {
      font-size: 18px;
      line-height: 23px;
    }
  
    .sales-iq-detail-device-number-and-chart {
      flex-wrap: wrap;
    }
  
    .sales-iq-detail-device-main {
      padding-top: 20px;
      padding-bottom: 20px;
      box-sizing: border-box;
    }
  
    .sales-iq-detail-device-text46 {
      font-size: 21px;
      line-height: 30px;
    }
  
    .sales-iq-detail-device-number3,
    .sales-iq-detail-device-number4 {
      font-size: 22px;
      line-height: 26px;
    }
  
    .sales-iq-detail-device-dropdowns {
      padding-right: 20px;
      box-sizing: border-box;
    }
  
    .sales-iq-detail-device-price {
      font-size: 16px;
      line-height: 26px;
    }
  
    .sales-iq-detail-device-buttonsbutton-parent {
      flex-wrap: wrap;
    }
  
    .sales-iq-detail-device-date-elements-parent {
      flex: 1;
    }
  
    .sales-iq-detail-device-badge-container,
    .sales-iq-detail-device-frame-parent12 {
      flex-wrap: wrap;
    }
  
    .sales-iq-detail-device-badge-group {
      height: auto;
      min-height: 104;
    }
  
    .sales-iq-detail-device-price2 {
      font-size: 16px;
      line-height: 26px;
    }
  
    .sales-iq-detail-device-work-type-icon {
      flex: 1;
    }
  
    .sales-iq-detail-device-calendar-icon6 {
      flex-wrap: wrap;
    }
  
    .sales-iq-detail-device-work-type-label {
      margin-left: 0;
    }
  
    .sales-iq-detail-device-price4 {
      font-size: 16px;
      line-height: 26px;
    }
  
    .sales-iq-detail-device-frame-parent36,
    .sales-iq-detail-device-frame-parent42 {
      flex-wrap: wrap;
    }
  
    .sales-iq-detail-device-price5,
    .sales-iq-detail-device-price6,
    .sales-iq-detail-device-price7 {
      font-size: 16px;
      line-height: 26px;
    }
  
    .sales-iq-detail-device-frame-parent64 {
      flex-wrap: wrap;
    }
  
    .sales-iq-detail-device-text147 {
      font-size: 21px;
      line-height: 22px;
    }
  
    .sales-iq-detail-device-content22,
    .sales-iq-detail-device-text-and-badge {
      padding-right: 20px;
      box-sizing: border-box;
    }
  
    .sales-iq-detail-device-input-dropdown3 {
      max-width: 100%;
    }
  
    .sales-iq-detail-device-table-cell50,
    .sales-iq-detail-device-table-cell53 {
      flex-wrap: wrap;
    }
  
    .sales-iq-detail-device-table-header-cell-group {
      height: auto;
      min-height: 764;
    }
  
    .sales-iq-detail-device-column5,
    .sales-iq-detail-device-column6 {
      flex: 1;
    }
  
    .sales-iq-detail-device-column-parent {
      gap: 39px;
      flex-wrap: wrap;
    }
  
    .sales-iq-detail-device-text-and-badge4 {
      padding-right: 20px;
      box-sizing: border-box;
    }
  
    .sales-iq-detail-device-all {
      padding-bottom: 29px;
      box-sizing: border-box;
    }
  
    .sales-iq-detail-device-title8 {
      font-size: 16px;
      line-height: 24px;
    }
  
    .sales-iq-detail-device-empty-state-container {
      padding-bottom: 53px;
      box-sizing: border-box;
    }
  
  }