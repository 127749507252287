@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

.address-form-profile-content-custom-23 {
  box-sizing: border-box;
  background-color: #fff;
  border: none;
  outline: none;
  flex-direction: row;
  flex: 1;
  align-items: center;
}


.address-profile-form-text63 {
  color: #667085;
  text-align: left;
  min-width: 126px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: inline-block;
  position: relative;
}


.address-form-type-container {
  width: 100%;
}


.address-form-dashboard-frame-layout-setti-child,
.address-form-frame-child {
  position: relative;
  background-color: #fff;
  display: none;
}

.address-form-dashboard-frame-layout-setti-child {
  align-self: stretch;
  height: 2583px;
  z-index: 1;
}

.address-form-frame-child {
  height: 72px;
  width: 1440px;
  max-width: 100%;
}

.address-form-gerator-04-1-icon {
  height: 32px;
  width: 121px;
  position: relative;
  object-fit: cover;
  display: none;
}

.address-form-logo,
.address-form-logo-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.address-form-logo {
  align-self: stretch;
  height: 32px;
  flex-direction: row;
  background-image: url("../../../icons/Gerator/profile_settings/gerator04-1@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;
}

.address-form-logo-wrapper {
  width: 121px;
  flex-direction: column;
  padding: 6px 0 0;
  box-sizing: border-box;
}

.address-form-bar-chart-01-icon,
.address-form-dot-icon {
  height: 10px;
  width: 10px;
  position: relative;
  display: none;
}

.address-form-bar-chart-01-icon {
  height: 24px;
  width: 24px;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.address-form-text {
  text-decoration: none;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 30px;
}

.address-form-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.address-form-text1 {
  align-self: stretch;
  position: relative;
  line-height: 18px;
  font-weight: 500;
}

.address-form-badge {
  height: 22px;
  border-radius: 9999px;
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 7px;
  text-align: center;
  font-size: 12px;
}

.address-form-chevron-down-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.address-form-nav-item-base {
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 12px;
  gap: 8px;
}

.address-form-text2,
.address-form-text3 {
  position: relative;
  font-family: Inter;
}

.address-form-text2 {
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #18222f;
  text-align: left;
  display: inline-block;
  min-width: 29px;
}

.address-form-text3 {
  align-self: stretch;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #344054;
  text-align: center;
}

.address-form-badge1,
.address-form-nav-item-base1 {
  background-color: #f9fafb;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.address-form-badge1 {
  height: 22px;
  border-radius: 9999px;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
  padding: 2px 7px;
}

.address-form-nav-item-base1 {
  cursor: pointer;
  border: 0;
  padding: 8px 12px;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  gap: 8px;
}

.address-form-text4 {
  text-decoration: none;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 38px;
}

.address-form-navigation,
.address-form-navigation-wrapper {
  display: flex;
  justify-content: flex-start;
}

.address-form-navigation {
  flex-direction: row;
  align-items: center;
  gap: 4px;
  z-index: 1;
}

.address-form-navigation-wrapper {
  flex-direction: column;
  align-items: flex-start;
  padding: 2px 0 0;
}

.address-form-label {
  width: 47px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.address-form-search-lg-icon,
.address-form-text6 {
  position: relative;
  overflow: hidden;
}

.address-form-search-lg-icon {
  height: 20px;
  width: 20px;
  flex-shrink: 0;
}

.address-form-text6 {
  text-decoration: none;
  flex: 1;
  line-height: 24px;
  color: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.address-form-content3,
.address-form-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.address-form-content3 {
  flex: 1;
  gap: 8px;
}

.address-form-input {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  padding: 8px 13px;
  font-size: 16px;
  color: #667085;
}

.address-form-frame-group,
.address-form-frame-parent,
.address-form-input-with-label {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.address-form-input-with-label {
  flex: 1;
  flex-direction: column;
  gap: 6px;
  z-index: 1;
  font-size: 14px;
}

.address-form-frame-group,
.address-form-frame-parent {
  flex-direction: row;
  max-width: 100%;
}

.address-form-frame-group {
  flex: 1;
  gap: 38px;
}

.address-form-frame-parent {
  width: 754px;
  gap: 70px;
}

.address-form-icon {
  height: 19px;
  width: 19px;
  position: relative;
}

.address-form-nav-item-button {
  width: 40px;
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  box-sizing: border-box;
  z-index: 1;
}

.address-form-nav-item-button-child {
  height: 18.3px;
  width: 14.6px;
  position: relative;
}

.address-form-avatar-button1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 3px 0 0;
}

.address-form-contrast-border {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 9999px;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
  display: none;
}

.address-form-avatar {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 9999px;
  background-image: url("./../../../icons/Gerator/profile_settings/avatar@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;
}

.address-form-avatar-button-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
}

.address-form-dashboard-frame-layout-setti-inner,
.address-form-rectangle-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}

.address-form-rectangle-parent {
  flex: 1;
  background-color: #fff;
  justify-content: space-between;
  padding: 14px 64px 14px 31px;
  top: 0;
  z-index: 99;
  position: sticky;
  gap: 20px;
  text-align: left;
  font-size: 16px;
  color: #344054;
  font-family: Inter;
}

.address-form-dashboard-frame-layout-setti-inner {
  align-self: stretch;
  justify-content: flex-start;
  padding: 0 0 135px;
}

.address-form-divider-icon {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 1;
}

.address-form-divider-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 134px;
  box-sizing: border-box;
  max-width: 100%;
  /* margin-top: -134px; */
}

.address-form-home-line-icon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.address-form-nav-item-button2,
.address-form-nav-item-button3 {
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px;
}

.address-form-nav-item-button3 {
  background-color: #f9fafb;
}

.address-form-navigation2 {
  padding: 0 16px;
  gap: 8px;
}

.address-form-navigation1,
.address-form-navigation2,
.address-form-navigation3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.address-form-navigation1 {
  padding: 32px 0 0;
}

.address-form-navigation3 {
  gap: 8px;
}

.address-form-contrast-border1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 9999px;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
}

.address-form-avatar1 {
  width: 48px;
  height: 48px;
  position: relative;
  border-radius: 9999px;
  background-image: url("./../../../icons/Gerator/profile_settings/avatar1@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.address-form-content4,
.address-form-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.address-form-footer {
  justify-content: flex-start;
  padding: 0 16px 24px;
  gap: 24px;
}

.address-form-content4 {
  align-self: stretch;
  border-right: 1px solid #eaecf0;
  justify-content: space-between;
}

.address-form-sub-nav-title {
  text-decoration: none;
  flex: 1;
  position: relative;
  line-height: 24px;
  font-weight: 500;
  color: inherit;
}

.address-form-arrow-narrow-left-icon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.address-form-sub-nav-header {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 13px 0 0;
  gap: 5px;
}

.address-form-text7 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 46px;
}

.address-form-badge3,
.address-form-content6,
.address-form-nav-item-base3 {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.address-form-content6 {
  flex: 1;
  display: flex;
  padding: 0 144px 0 0;
  gap: 12px;
}

.address-form-badge3,
.address-form-nav-item-base3 {
  background-color: #f9fafb;
}

.address-form-badge3 {
  height: 22px;
  border-radius: 9999px;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
  padding: 2px 7px;
  text-align: center;
  font-size: 12px;
  color: #344054;
}

.address-form-nav-item-base3 {
  align-self: stretch;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  padding: 8px 12px;
  gap: 8px;
  color: #18222f;
}

.address-form-text9 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 49px;
}

.address-form-content7 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 103px 0 0;
  gap: 12px;
}

.address-form-text10 {
  width: 14px;
  position: relative;
  line-height: 18px;
  font-weight: 500;
  display: inline-block;
  min-width: 14px;
}

.address-form-badge4 {
  width: 30px;
  border-radius: 9999px;
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 0 7px;
  text-align: center;
  font-size: 12px;
}

.address-form-badge4,
.address-form-content8,
.address-form-nav-item-base4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.address-form-nav-item-base4 {
  align-self: stretch;
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  padding: 8px 12px;
  gap: 8px;
}

.address-form-content8 {
  flex: 1;
  padding: 0 141px 0 0;
  gap: 12px;
}

.address-form-text19 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 48px;
}

.address-form-content12,
.address-form-content5,
.address-form-navigation4 {
  display: flex;
  justify-content: flex-start;
}

.address-form-content12 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0 142px 0 0;
  gap: 12px;
}

.address-form-content5,
.address-form-navigation4 {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
}

.address-form-navigation4 {
  gap: 4px;
  color: #344054;
}

.address-form-content5 {
  padding: 36px 16px 0;
  gap: 16px;
}

.address-form-supporting-text,
.address-form-title {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}

.address-form-title {
  font-weight: 600;
}

.address-form-supporting-text {
  color: #475467;
  white-space: nowrap;
}

.address-form-text21 {
  width: 214px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.address-form-buttonsbutton {
  margin: 0 !important;
  position: absolute;
  top: -8px;
  right: 12px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.address-form-account,
.address-form-sub-nav {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}

.address-form-account {
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 20px 24px;
  position: relative;
  gap: 47px;
  font-size: 14px;
}

.address-form-sub-nav {
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}

.address-form-breadcrumb-button-base,
.address-form-sidebar-navigation {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.address-form-sidebar-navigation {
  height: 1480px;
  width: 362px;
  background-color: #fff;
  border-right: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  max-width: 100%;
  z-index: 1;
}

.address-form-breadcrumb-button-base {
  border-radius: 6px;
  padding: 4px;
}

.address-form-chevron-right-icon {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.address-form-text22 {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  /* min-width: 74px; */
}

.address-form-breadcrumb-button-base1 {
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}

.address-form-text23 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 500;
}

.address-form-breadcrumb-button-base2 {
  align-self: stretch;
  border-radius: 6px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 7px;
}

.address-form-chevron-right-icon2 {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.address-form-text24 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  white-space: nowrap;
}

.address-form-breadcrumb-button-base3 {
  align-self: stretch;
  border-radius: 6px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}

.address-form-text25 {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 57px;
}

.address-form-breadcrumb-button-base4 {
  border-radius: 6px;
  background-color: #f9fafb;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  color: #344054;
}

.address-form-tabs {
  gap: 8px;
}

.address-form-breadcrumbs,
.address-form-tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.address-form-divider-icon1 {
  width: 255px;
  height: 1px;
  position: relative;
  display: none;
}

.address-form-page-header {
  align-self: stretch;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.address-form-container {
  padding: 0 32px;
}

.address-form-container,
.address-form-header-section {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.address-form-main-child {
  width: 1px;
  height: 29px;
  position: relative;
  border-right: 1px solid #000;
}

.address-form-main,
.address-form-main-child,
.address-form-main-item {
  flex-shrink: 0;
  box-sizing: border-box;
}

.address-form-main-item {
  width: 1px;
  height: 43px;
  position: relative;
  border-right: 1px solid #000;
}

.address-form-main {
  align-self: stretch;
  height: 60px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px 0 0;
  gap: 32px;
  z-index: 1;
}

.address-form-text26 {
  height: 20px;
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
}

.address-form-breadcrumb-button-base6,
.address-form-breadcrumb-button-base7 {
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.address-form-breadcrumb-button-base6 {
  flex: 1;
  padding: 4px 8px;
  min-width: 47px;
}

.address-form-breadcrumb-button-base7 {
  height: 28px;
  padding: 4px 7px;
}

.address-form-text29 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.address-form-breadcrumbs1,
.address-form-tabs1 {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.address-form-tabs1 {
  flex: 1;
  display: flex;
  gap: 8px;
}

.address-form-breadcrumbs1 {
  width: 278px;
  display: none;
}

.address-form-page-title {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 38px;
  font-weight: 600;
  font-family: inherit;
}

.address-form-supporting-text1 {
  width: 445px;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  display: block;
  max-width: 100%;
}

.address-form-text-and-supporting-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  min-width: 320px;
  max-width: 100%;
  font-size: 30px;
}

.address-form-placeholder-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 20px;
}

.address-form-text-padding {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.address-form-button,
.address-form-button1,
.address-form-button2 {
  height: 40px;
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  justify-content: center;
  gap: 4px;
}

.address-form-button {
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
}

.address-form-button1,
.address-form-button2 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  background-color: #fff;
  padding: 10px 13px;
}

.address-form-button1 {
  border: 1px solid #d6bbfb;
  flex-direction: row;
  align-items: center;
  color: #6941c6;
}

.address-form-button2 {
  border: 1px solid #d0d5dd;
  color: #344054;
}

.address-form-actions,
.address-form-button2,
.address-form-button3 {
  flex-direction: row;
  align-items: center;
}

.address-form-button3 {
  height: 40px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #7e56d9;
  border: 1px solid #7e56d9;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 10px 13px;
  gap: 4px;
  color: #fff;
}

.address-form-actions {
  display: none;
  justify-content: flex-start;
  gap: 12px;
  max-width: 100%;
  color: #475467;
}

.address-form-text34 {
  width: calc(100% - 20px);
  border: 0;
  outline: 0;
  font-family: Inter;
  font-size: 16px;
  background-color: transparent;
  height: 24px;
  flex: 1;
  position: relative;
  line-height: 24px;
  color: #667085;
  text-align: left;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 158px;
  padding: 0;
}

.address-form-input-with-label1,
.address-form-input1 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}

.address-form-input1 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
}

.address-form-input-with-label1 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.address-form-hint-text {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  color: #475467;
  display: none;
}

.address-form-content13,
.address-form-input-dropdown {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.address-form-input-dropdown {
  flex: 1;
  flex-direction: column;
  gap: 8px;
  min-width: 200px;
  max-width: 320px;
  color: #344054;
}

.address-form-content13 {
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 0 2px 0 0;
  box-sizing: border-box;
  gap: 16px 14px;
  color: #101828;
}

.address-form-content13,
.address-form-divider-icon2,
.address-form-page-header1 {
  align-self: stretch;
  max-width: 100%;
}

.address-form-divider-icon2 {
  position: relative;
  overflow: hidden;
  max-height: 100%;
  display: none;
}

.address-form-page-header1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.address-form-text35 {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 44px;
}

.address-form-tab-button-base {
  border-bottom: 2px solid #7e56d9;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 4px 9px;
  color: #6941c6;
}

.address-form-text36 {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 62px;
}

.address-form-tab-button-base1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 4px 12px;
}

.address-form-badge-count,
.address-form-text38 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 38px;
}

.address-form-badge-count {
  line-height: 18px;
  font-weight: 500;
  min-width: 8px;
}

.address-form-badge10,
.address-form-tab-button-base3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
}

.address-form-badge10 {
  width: 24px;
  border-radius: 9999px;
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  justify-content: flex-start;
  padding: 0 7px;
  text-align: center;
  font-size: 12px;
  color: #344054;
}

.address-form-tab-button-base3 {
  height: 32px;
  justify-content: center;
  padding: 0 4px 11px;
  gap: 8px;
}

.address-form-text39,
.address-form-text40,
.address-form-text43 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 30px;
}

.address-form-text40,
.address-form-text43 {
  text-decoration: none;
  color: inherit;
  min-width: 42px;
}

.address-form-text43 {
  min-width: 83px;
}

.address-form-tab-button-base7 {
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 4px 11px;
  box-sizing: border-box;
  gap: 7px;
}

.address-form-text45,
.address-form-text46 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 82px;
}

.address-form-text46 {
  min-width: 24px;
}

.address-form-tab-button-base9,
.address-form-tabs2 {
  display: flex;
  flex-direction: row;
}

.address-form-tab-button-base9 {
  width: 31px;
  align-items: center;
  justify-content: center;
  padding: 0 3px 12px;
  box-sizing: border-box;
}

.address-form-tabs2 {
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  max-width: 100%;
}

.address-form-container1,
.address-form-horizontal-tabs {
  align-self: stretch;
  flex-direction: column;
  box-sizing: border-box;
}

.address-form-horizontal-tabs {
  border-bottom: 1px solid #eaecf0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  color: #667085;
}

.address-form-container1 {
  padding: 0 32px;
  gap: 24px;
}

.address-form-container1,
.address-form-header-content,
.address-form-header-section1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.address-form-header-section1 {
  flex: 1;
  flex-direction: column;
}

.address-form-header-content {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 12px;
  box-sizing: border-box;
}

.address-form-text47 {
  align-self: stretch;
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.address-form-button4,
.address-form-button5,
.address-form-text-padding4 {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.address-form-text-padding4 {
  align-self: stretch;
  flex: 1;
  display: flex;
  padding: 0 2px;
}

.address-form-button4,
.address-form-button5 {
  width: 86px;
  border-radius: 8px;
  overflow: hidden;
  display: none;
  padding: 10px 14px;
  box-sizing: border-box;
  gap: 4px;
}

.address-form-button5 {
  width: 106px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  background-color: #fff;
  border: 1px solid #d6bbfb;
  padding: 10px 13px;
  color: #6941c6;
}

.address-form-dots-vertical-icon {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.address-form-dropdown {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.address-form-supporting-text2,
.address-form-toggle-title {
  align-self: stretch;
  position: relative;
}

.address-form-toggle-title {
  line-height: 28px;
  font-weight: 600;
}

.address-form-supporting-text2 {
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.address-form-toggle-header {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  min-width: 320px;
  max-width: 100%;
}

.address-form-frame-item {
  height: 40px;
  width: 156px;
  position: relative;
  background-color: #fff;
  display: none;
}

.address-form-button6,
.address-form-toggle-base {
  border-radius: 9999px;
  overflow: hidden;
  flex-shrink: 0;
}

.address-form-button6 {
  height: 16px;
  width: 16px;
  position: relative;
  box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
  background-color: #fff;
}

.address-form-toggle-base {
  height: 20px;
  width: 36px;
  background-color: #f2f4f7;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 2px;
  box-sizing: border-box;
  z-index: 1;
}

.address-form-supporting-text3,
.address-form-switch-label {
  position: relative;
  line-height: 20px;
}

.address-form-switch-label {
  letter-spacing: -0.02px;
  font-weight: 500;
  display: inline-block;
  min-width: 97px;
  z-index: 1;
}

.address-form-supporting-text3 {
  align-self: stretch;
  width: 300px;
  color: #475467;
  display: none;
}

.address-form-rectangle-group {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 0 10px 15px;
  gap: 8px;
}

.address-form-button-labels {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 63px;
}

.address-form-button7 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 16px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.address-form-text49 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 110px;
}

.address-form-button8,
.address-form-toggle-controls,
.address-form-toggle-header-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.address-form-button8 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 16px;
  background-color: #7e56d9;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
}

.address-form-toggle-controls,
.address-form-toggle-header-parent {
  gap: 12px;
  max-width: 100%;
  font-size: 14px;
  color: #344054;
}

.address-form-toggle-header-parent {
  align-self: stretch;
  gap: 16px;
  font-size: 18px;
  color: #101828;
}

.address-form-divider-icon3 {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.address-form-divider-container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 4px;
  box-sizing: border-box;
  max-width: 100%;
}

.address-form-text50 {
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.address-form-section-label {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 200px;
  max-width: 280px;
}

.address-form-label2 {
  width: 36px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.address-form-content16 {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 24px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: Inter;
  font-size: 16px;
  color: #101828;
  min-width: 130px;
}

.address-form-help-icon {
  height: 16px;
  width: 16px;
  position: relative;
  display: none;
}

.address-form-input-field,
.address-form-input2 {
  display: flex;
  justify-content: flex-start;
}

.address-form-input2 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
  gap: 8px;
}

.address-form-input-field {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  min-width: 159px;
}

.address-form-label3 {
  width: 96px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.address-form-content17 {
  width: calc(100% - 46px);
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 24px;
  flex: 1;
  align-items: center;
  font-family: Inter;
  font-size: 16px;
  color: #101828;
  min-width: 113px;
}

.address-form-content15,
.address-form-content17,
.address-form-input-fields,
.address-form-input3 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.address-form-input3 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  align-items: center;
  padding: 8px 13px;
  gap: 8px;
}

.address-form-content15,
.address-form-input-fields {
  align-items: flex-start;
}

.address-form-input-fields {
  flex: 1;
  gap: 24px;
  min-width: 480px;
  max-width: 512px;
}

.address-form-content15 {
  align-self: stretch;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 16px 32px;
  max-width: 100%;
}

.address-form-divider {
  align-self: stretch;
  height: 1px;
  position: relative;
  background-color: #eaecf0;
}

.address-form-section-label1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 200px;
  max-width: 280px;
}

.address-form-extra-input-info {
  width: calc(100% - 40px);
  border: 0;
  outline: 0;
  font-family: Inter;
  font-size: 16px;
  background-color: transparent;
  height: 24px;
  flex: 1;
  position: relative;
  line-height: 24px;
  color: #101828;
  text-align: left;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 250px;
  max-width: calc(100% - 28px);
  padding: 0;
}

.address-form-content19,
.address-form-input4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.address-form-content19 {
  flex: 1;
  max-width: calc(100% - 28px);
}

.address-form-input4 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  padding: 8px 13px;
  max-width: 100%;
}

.address-form-hint-text2 {
  width: 320px;
  position: relative;
  line-height: 20px;
  /* color: #475467; */
  /* display: none; */
}

.address-form-hint-text2a {
  width: 320px;
  position: relative;
  line-height: 20px;
  margin-top:-38px ;
  color: #475467;
  /* display: none; */
}

.address-form-file-button-labels{
 left: 5px;
 position: relative;
 top:1px
}

.address-form-content18,
.address-form-input-field1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.address-form-input-field1 {
  flex: 1;
  flex-direction: column;
  gap: 6px;
  min-width: 480px;
  max-width: 512px;
}

.address-form-content18 {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 0 102px 0 0;
  box-sizing: border-box;
  gap: 16px 32px;
  max-width: 100%;
}

.address-form-upload-title {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 75px;
}

.address-form-help-icon3 {
  width: 16px;
  height: 16px;
  position: relative;
}

.address-form-label-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
}

.address-form-supporting-text4 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  color: #475467;
}

.address-form-avatar2 {
  height: 64px;
  width: 64px;
  position: relative;
  border-radius: 9999px;
  background-image: url("./../../../icons/Gerator/profile_settings/avatar2@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.address-form-featured-icon {
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 8px;
}

.address-form-text54 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 101px;
}

.address-form-buttonsbutton1 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.address-form-text55 {
  position: relative;
  line-height: 20px;
  color: #475467;
  display: inline-block;
  min-width: 112px;
}

.address-form-action {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 20px;
  gap: 4px;
}

.address-form-supporting-text5 {
  align-self: stretch;
  position: relative;
  font-size: 12px;
  line-height: 18px;
  color: #475467;
  text-align: center;
}

.address-form-text-and-supporting-text1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}

.address-form-page-icon {
  position: absolute;
  height: 100%;
  width: 80%;
  top: 0;
  right: 2.5%;
  bottom: 0;
  left: 17.5%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.address-form-file-type {
  position: relative;
  display: inline-block;
  min-width: 20px;
}

.address-form-file-type-wrap {
  position: absolute;
  height: 40%;
  width: 65%;
  top: 45%;
  right: 32.5%;
  bottom: 15%;
  left: 2.5%;
  border-radius: 2px;
  background-color: #7e56d9;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 3px;
  box-sizing: border-box;
  z-index: 1;
}

.address-form-cursor-icon,
.address-form-file-type-icon {
  position: absolute;
  margin: 0 !important;
}

.address-form-file-type-icon {
  width: 40px;
  height: 40px;
  right: 0;
  bottom: 4px;
  filter: drop-shadow(0 24px 48px rgba(16, 24, 40, 0.18));
  z-index: 1;
  text-align: center;
  font-size: 10px;
  color: #fff;
}

.address-form-cursor-icon {
  width: 20px;
  height: 20px;
  right: -8px;
  bottom: -4px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  z-index: 2;
}

.address-form-content23,
.address-form-file-upload-base {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.address-form-content23 {
  align-self: stretch;
  align-items: center;
  position: relative;
  gap: 12px;
}

.address-form-file-upload-base {
  flex: 1;
  border-radius: 12px;
  background-color: #fff;
  border: 2px solid #7e56d9;
  box-sizing: border-box;
  align-items: flex-start;
  padding: 13px 24px;
  min-width: 278px;
  max-width: 100%;
}

.address-form-avatar-and-file-upload,
.address-form-content25 {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.address-form-avatar-and-file-upload {
  align-items: flex-start;
  gap: 20px;
  min-width: 480px;
  max-width: 512px;
  color: #6941c6;
}

.address-form-content25 {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 24px;
  align-items: center;
  font-family: Inter;
  font-size: 16px;
  color: #101828;
  min-width: 250px;
  max-width: 100%;
}

.address-form-supporting-text6,
.address-form-text59 {
  position: relative;
  line-height: 24px;
}

.address-form-text59 {
  font-weight: 500;
  display: inline-block;
  min-width: 208px;
}

.address-form-supporting-text6 {
  align-self: stretch;
  width: 54px;
  color: #475467;
  display: none;
}

.address-form-content29 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  /* padding: 0 360px 0 0; */
  box-sizing: border-box;
  /* max-width: calc(100% - 28px); */
}

.address-form-content29,
.address-form-input-dropdown1,
.address-form-input8 {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}

.address-form-input8 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
  max-width: 100%;
  font-size: 16px;
  color: #101828;
}

.address-form-input-dropdown1 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  min-width: 480px;
  max-width: 512px;
}

.address-form-text61 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 126px;
}

.address-form-content31 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  max-width: 100%;
}

.address-form-input9 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  padding: 8px 13px;
  font-size: 16px;
  color: #101828;
}

.address-form-content33,
.address-form-input10,
.address-form-input9 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}

.address-form-content33 {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 24px;
  flex: 1;
  font-family: Inter;
  font-weight: 500;
  font-size: 16px;
  color: #101828;
  min-width: 250px;
}

.address-form-input10 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  padding: 8px 13px;
}

.address-form-label-title {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 67px;
}

.address-form-help-icon6 {
  height: 16px;
  width: 16px;
  position: relative;
}

.address-form-heading-and-help-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
}

.address-form-supporting-text8,
.address-form-text66 {
  position: relative;
  line-height: 24px;
}

.address-form-text66 {
  font-weight: 500;
}

.address-form-supporting-text8 {
  color: #475467;
  display: inline-block;
  min-width: 89px;
  white-space: nowrap;
}

.address-form-content41 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0 116px 0 0;
  box-sizing: border-box;
  gap: 8px;
}

.address-form-content41,
.address-form-form-content,
.address-form-form-content-wrapper,
.address-form-input14 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.address-form-input14 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
  gap: 8px;
  font-size: 16px;
  color: #101828;
}

.address-form-form-content,
.address-form-form-content-wrapper {
  align-items: flex-start;
}

.address-form-form-content {
  flex: 1;
  flex-direction: column;
  gap: 20px;
}

.address-form-form-content-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 32px;
  box-sizing: border-box;
  color: #344054;
}

.address-form-write-a-short {
  margin-block-start: 0;
  margin-block-end: 14px;
}

.address-form-this-is-also {
  margin: 0;
}

.address-form-dropdown-placeholder {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 59px;
}

.address-form-content43 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  min-width: 59px;
}

.address-form-content43,
.address-form-input-dropdown8,
.address-form-input15 {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}

.address-form-input15 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
  font-size: 16px;
  color: #101828;
}

.address-form-input-dropdown8 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  min-width: 156px;
  max-width: 240px;
}

.address-form-wysiwyg-editor-icon,
.address-form-wysiwyg-editor-icon2 {
  height: 32px;
  width: 32px;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 32px;
}

.address-form-wysiwyg-editor-icon2 {
  display: none;
}

.address-form-icons,
.address-form-wysiwyg-toolbar {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.address-form-wysiwyg-toolbar {
  align-self: stretch;
  align-items: center;
  padding: 0 84px 0 0;
  gap: 12px;
}

.address-form-label16 {
  width: 77px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.address-form-text68 {
  height: 130px;
  flex: 1;
  position: relative;
  line-height: 24px;
  display: inline-block;
  max-width: 100%;
}

.address-form-input16,
.address-form-textarea-input-field {
  align-self: stretch;
  align-items: flex-start;
  max-width: 100%;
}

.address-form-input16 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px 13px;
  font-size: 16px;
  color: #101828;
}

.address-form-textarea-input-field {
  gap: 6px;
}

.address-form-content45,
.address-form-file-upload-base1,
.address-form-textarea-input-field,
.address-form-toolbar-and-text-input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.address-form-toolbar-and-text-input {
  flex: 1;
  align-items: flex-start;
  gap: 12px;
  min-width: 480px;
  max-width: 512px;
}

.address-form-content45,
.address-form-file-upload-base1 {
  align-self: stretch;
  align-items: center;
}

.address-form-content45 {
  gap: 12px;
}

.address-form-file-upload-base1 {
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  padding: 14px 23px;
}

.address-form-page-icon1 {
  height: 40px;
  width: 32px;
  position: absolute;
  margin: 0 !important;
  top: -18px;
  right: -12px;
}

.address-form-file-type-wrap1 {
  border-radius: 2px;
  background-color: #da2d20;
  flex-direction: row;
  padding: 2px 3px;
  z-index: 1;
}

.address-form-file-type-wrap1,
.address-form-item-container,
.address-form-item-info-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.address-form-item-info-container {
  flex-direction: row;
  position: relative;
}

.address-form-item-container {
  flex-direction: column;
  padding: 18px 0 0;
}

.address-form-supporting-text13,
.address-form-uploaded-file-name {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.address-form-uploaded-file-name {
  font-weight: 500;
}

.address-form-supporting-text13 {
  color: #475467;
}

.address-form-background,
.address-form-progress {
  align-self: stretch;
  width: 379px;
  position: relative;
  border-radius: 9999px;
  background-color: #eaecf0;
  display: none;
  max-width: 100%;
}

.address-form-progress {
  background-color: #7e56d9;
}

.address-form-item-progress-info,
.address-form-progress-bar {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.address-form-progress-bar {
  width: 379px;
  height: 8px;
  border-radius: 9999px;
  background-color: #7e56d9;
  flex-direction: row;
  z-index: 1;
}

.address-form-item-progress-info {
  flex: 1;
  flex-direction: column;
  gap: 10px;
  min-width: 278px;
}

.address-form-checkbox-base-icon {
  height: 16px;
  width: 16px;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  flex-shrink: 0;
}

.address-form-select-file-checkbox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 21px;
}

.address-form-percentage {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 37px;
}

.address-form-item-status {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 28px;
  margin-left: -37px;
}

.address-form-file-upload-item-base,
.address-form-item-progress-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}

.address-form-item-progress-container {
  flex: 1;
  justify-content: center;
  min-width: 278px;
  row-gap: 20px;
  text-align: left;
  font-size: 14px;
  color: #344054;
}

.address-form-file-upload-item-base {
  align-self: stretch;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  justify-content: flex-start;
  padding: 14px 15px 14px 16px;
  gap: 25px;
}

.address-form-page-icon2 {
  height: 40px;
  width: 32px;
  position: absolute;
  margin: 0 !important;
  top: -18px;
  right: -9px;
}

.address-form-file-type2 {
  position: relative;
  display: inline-block;
  min-width: 23px;
}

.address-form-file-type-wrap2 {
  border-radius: 2px;
  background-color: #155eef;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 3px;
  z-index: 1;
}

.address-form-text71 {
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.address-form-buttonsbutton3 {
  margin: 0 !important;
  position: absolute;
  top: calc(50% - 18px);
  right: -8px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.address-form-progress-bar-container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  max-width: 100%;
}

.address-form-background1,
.address-form-progress1 {
  position: relative;
  border-radius: 9999px;
}

.address-form-background1 {
  align-self: stretch;
  width: 386px;
  background-color: #eaecf0;
  display: none;
  max-width: 100%;
}

.address-form-progress1 {
  height: 8px;
  width: 155.5px;
  background-color: #7e56d9;
  z-index: 1;
}

.address-form-progress-bar-wrapper,
.address-form-progress-bar1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.address-form-progress-bar1 {
  align-self: stretch;
  border-radius: 9999px;
  background-color: #eaecf0;
  flex-direction: row;
}

.address-form-progress-bar-wrapper {
  flex: 1;
  flex-direction: column;
  padding: 6px 0 0;
  box-sizing: border-box;
  min-width: 251px;
}

.address-form-percentage1 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 33px;
}

.address-form-progress-bar-container1 {
  align-self: stretch;
  flex-direction: row;
  gap: 9px;
}

.address-form-file-upload-item-base1,
.address-form-progress-bar-container-parent,
.address-form-progress-bar-container1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.address-form-progress-bar-container-parent {
  flex: 1;
  flex-direction: column;
  gap: 2px;
  min-width: 278px;
  text-align: left;
  font-size: 14px;
  color: #344054;
}

.address-form-file-upload-item-base1 {
  align-self: stretch;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  flex-direction: row;
  padding: 14px 15px 14px 16px;
  gap: 22px;
}

.address-form-page-icon3 {
  height: 40px;
  width: 32px;
  position: absolute;
  margin: 0 !important;
  top: -18px;
  right: -15px;
}

.address-form-file-type3 {
  position: relative;
  display: inline-block;
  min-width: 17px;
}

.address-form-file-type-wrap3,
.address-form-progress2 {
  background-color: #7e56d9;
  z-index: 1;
}

.address-form-file-type-wrap3 {
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 3px;
}

.address-form-progress2 {
  height: 8px;
  width: 311.1px;
  position: relative;
  border-radius: 9999px;
}

.address-form-percentage2 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 32px;
}

.address-form-file-upload-item-base2,
.address-form-frame-div {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  max-width: 100%;
}

.address-form-file-upload-item-base2 {
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 14px 15px 14px 16px;
  gap: 28px;
}

.address-form-file-queue {
  align-self: stretch;
  flex-direction: column;
  gap: 12px;
  max-width: 100%;
  text-align: center;
  font-size: 10px;
  color: #fff;
}

.address-form-content-wrapper,
.address-form-content44,
.address-form-file-queue,
.address-form-file-upload {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.address-form-file-upload {
  flex: 1;
  flex-direction: column;
  gap: 16px;
  min-width: 480px;
  max-width: 512px;
  color: #6941c6;
}

.address-form-content-wrapper,
.address-form-content44 {
  flex-direction: row;
  max-width: 100%;
}

.address-form-content44 {
  flex: 1;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 16px 32px;
}

.address-form-content-wrapper {
  align-self: stretch;
  padding: 0 32px 4px;
  box-sizing: border-box;
  color: #344054;
}

.address-form-divider-icon4,
.address-form-text73 {
  align-self: stretch;
  position: relative;
}

.address-form-divider-icon4 {
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.address-form-text73 {
  line-height: 20px;
  font-weight: 600;
  white-space: nowrap;
}

.address-form-button9,
.address-form-buttonsbutton5 {
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.address-form-buttonsbutton5 {
  height: 20px;
  gap: 6px;
}

.address-form-button9 {
  align-self: stretch;
  border-radius: 8px;
  padding: 10px 14px;
  gap: 4px;
}

.address-form-text75 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 48px;
}

.address-form-button10,
.address-form-text-padding7 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.address-form-button10 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 13px;
  background-color: #fff;
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  gap: 4px;
  min-width: 52px;
}

.address-form-text76 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;

}

.address-form-text-padding8 {
  width: 37px;
  justify-content: center;
  padding: 0 1px;
  box-sizing: border-box;
}

.address-form-actions1,
.address-form-button11,
.address-form-content46,
.address-form-text-padding8 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.address-form-button11 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 13px;
  background-color: #7e56d9;
  width: 100px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
  gap: 4px;
}

.address-form-button11:hover{
  background-color: #6941c6;
}

.address-form-actions1,
.address-form-content46 {
  justify-content: flex-end;
  max-width: 100%;
}

.address-form-actions1 {
  flex: 1;
  padding: 0 0 0 769px;
  box-sizing: border-box;
  gap: 12px;
}

.address-form-content46 {
  align-self: stretch;
  gap: 20px;
}

.address-form-main1,
.address-form-section-footer,
.address-form-upload-footer {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.address-form-section-footer {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.address-form-main1,
.address-form-upload-footer {
  align-items: flex-start;
  box-sizing: border-box;
}

.address-form-upload-footer {
  align-self: stretch;
  flex-direction: row;
  padding: 0 32px;
}

.address-form-main1 {
  flex: 1;
  background-color: #fff;
  overflow: hidden;
  flex-direction: column;
  padding: 32px 0 48px;
  gap: 20px;
  z-index: 1;
  align-items: flex-start;
  box-sizing: border-box;
}

.address-form-main2{
  flex: 1;
  background-color: #fff;
  overflow: hidden;
  flex-direction: column;
  padding: 0 0 108px 0;
  gap: 20px;
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
  align-items: flex-start;
  box-sizing: border-box;
}

.address-form-main-wrapper {
  width: 1038px;
  flex-direction: row;
  padding: 0 24px;
  box-sizing: border-box;
}

.address-form-main-parent,
.address-form-main-wrapper,
.address-form-sidebar-navigation-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.address-form-main-parent {
  flex: 1;
  flex-direction: column;
  min-width: 701px;
  font-size: 14px;
  color: #475467;
}

.address-form-sidebar-navigation-parent {
  min-height: 1300px;
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  /* padding: 0 0 137px; */
  box-sizing: border-box;
  row-gap: 20px;
  margin-top: -134px;
  text-align: left;
  font-size: 16px;
  color: #101828;
  /* font-family: Inter; */
}

.address-form-footer-text {
  position: relative;
  line-height: 20px;
}

.address-form-logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.address-form-gerator-10-2-icon {
  height: 38px;
  width: 38px;
  position: relative;
  object-fit: cover;
}

.address-form-logomark1 {
  box-shadow: 0 1.3px 3.75px rgba(16, 24, 40, 0.1),
    0 1.3px 2.5px rgba(16, 24, 40, 0.06);
  align-items: flex-start;
  justify-content: flex-start;
}

.address-form-logo-wrapper1,
.address-form-logomark,
.address-form-logomark1 {
  display: flex;
  flex-direction: row;
}

.address-form-logomark {
  filter: drop-shadow(0 1.3px 3.75px rgba(16, 24, 40, 0.1)) drop-shadow(0 1.3px 2.5px rgba(16, 24, 40, 0.06));
  align-items: flex-start;
  justify-content: flex-start;
}

.address-form-logo-wrapper1 {
  align-items: center;
  justify-content: center;
  gap: 29px;
}

.address-form-content47,
.address-form-logo1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.address-form-content47 {
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: center;
  gap: 20px;
}

.address-form-container2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 32px;
  box-sizing: border-box;
  max-width: 1280px;
}

.address-form-divider-icon5,
.address-form-footer1 {
  align-self: stretch;
  overflow: hidden;
  margin-top: -134px;
}

.address-form-footer1 {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 48px 80px;
  z-index: 1;
  text-align: left;
  font-size: 16px;
  color: #475467;
  font-family: Inter;
}

.address-form-divider-icon5 {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  z-index: 2;
}

.address-form-dashboard-frame-layout-setti,
.address-form-dashboard-frame-layout-setti1 {
  background-color: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.address-form-dashboard-frame-layout-setti1 {
  flex: 1;
  flex-direction: column;
  /* padding: 7px 0 0; */
  box-sizing: border-box;
  /* min-height: 2590px; */
  max-width: 100%;
}

.address-form-dashboard-frame-layout-setti {
  width: 100%;
  position: relative;
  font-family: Inter;
  flex-direction: row;
  line-height: normal;
  letter-spacing: normal;
}

.address-form-span {
  color: #f04438;
}

.address-form-tagged-inputs {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;

  align-items: flex-start;

  flex: 1;
  flex-direction: column;
  gap: 6px;

  align-self: stretch;
  flex-direction: row;
  z-index: 1;
}

.address-form-input-field12 {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
  flex: 1;
  flex-direction: column;
  gap: 6px;
}

.address-form-input-with-label19 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 20px 0 0;

}

.address-form-label21 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  text-align: left;
  display: inline-block;
  min-width: 50px;
}

.address-form-transaction-type {
  color: #344054;
}

.address-form-file-button-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
}

.address-form-buttonsbutton4 {
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  gap: 6px;
}

.address-form-search-lg-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.address-form-text-padding6 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}




@media screen and (max-width: 1275px) {
  .address-form-main1 {
    padding-top: 154px;
    padding-bottom: 20px;
    box-sizing: border-box;
  }

  .address-form-sidebar-navigation-parent {
    padding-bottom: 58px;
    box-sizing: border-box;
  }

  .address-form-container2 {
    max-width: 100%;
  }
}

@media screen and (max-width: 1100px) {

  .address-form-tabs2,
  .address-form-toggle-header-parent {
    flex-wrap: wrap;
  }

  .address-form-content18 {
    padding-right: 51px;
    box-sizing: border-box;
  }

  .address-form-actions1 {
    flex-wrap: wrap;
    padding-left: 384px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 750px) {
  .address-form-frame-group {
    gap: 19px;
  }

  .address-form-frame-parent {
    gap: 35px;
  }

  .address-form-rectangle-parent {
    padding-right: 32px;
    box-sizing: border-box;
  }

  .address-form-main {
    gap: 16px;
  }

  .address-form-page-title {
    font-size: 24px;
    line-height: 30px;
  }

  .address-form-actions {
    flex-wrap: wrap;
  }

  .address-form-input-dropdown {
    max-width: 100%;
  }

  .address-form-input-fields,
  .address-form-toggle-controls {
    flex-wrap: wrap;
  }

  .address-form-input-field1,
  .address-form-input-fields {
    max-width: 100%;
    min-width: 100%;
  }

  .address-form-avatar-and-file-upload {
    flex-wrap: wrap;
  }

  .address-form-avatar-and-file-upload,
  .address-form-input-dropdown1 {
    max-width: 100%;
    min-width: 100%;
  }

  .address-form-content41,
  .address-form-input14 {
    flex-wrap: wrap;
  }

  .address-form-wysiwyg-toolbar {
    flex-wrap: wrap;
    padding-right: 42px;
    box-sizing: border-box;
  }

  .address-form-toolbar-and-text-input {
    max-width: 100%;
    min-width: 100%;
  }

  .address-form-file-upload-item-base,
  .address-form-file-upload-item-base1,
  .address-form-file-upload-item-base2,
  .address-form-frame-div,
  .address-form-item-progress-container,
  .address-form-progress-bar-container1 {
    flex-wrap: wrap;
  }

  .address-form-file-upload {
    max-width: 100%;
  }

  .address-form-file-upload,
  .address-form-main-parent {
    min-width: 100%;
  }

  .address-form-sidebar-navigation-parent {
    padding-bottom: 38px;
    box-sizing: border-box;
  }

  .address-form-container2 {
    gap: 16px;
  }

  .address-form-footer1 {
    gap: 32px;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 450px) {
  .address-form-frame-parent {
    gap: 17px;
  }

  .address-form-account {
    gap: 23px;
  }

  .address-form-tabs1 {
    flex-wrap: wrap;
  }

  .address-form-page-title {
    font-size: 18px;
    line-height: 23px;
  }

  .address-form-content15 {
    gap: 16px;
  }

  .address-form-content18 {
    gap: 16px;
    padding-right: 25px;
    box-sizing: border-box;
  }

  .address-form-action {
    flex-wrap: wrap;
  }

  .address-form-content29,
  .address-form-content41 {
    padding-right: 180px;
    box-sizing: border-box;
  }

  .address-form-content41 {
    padding-right: 58px;
  }

  .address-form-input15 {
    flex-wrap: wrap;
  }

  .address-form-wysiwyg-toolbar {
    padding-right: 20px;
    box-sizing: border-box;
  }

  .address-form-item-status {
    margin-left: 0;
  }

  .address-form-content44 {
    gap: 16px;
  }

  .address-form-actions1 {
    padding-left: 192px;
    box-sizing: border-box;
  }

  .address-form-footer1 {
    gap: 16px;
  }
}