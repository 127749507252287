@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");

/* NEW CSS */
#countryArchiveSelect,
#deviceCategoryArchiveSelect,
#oemArchiveSelect,
#yearOfManufactureArchiveSelect,
#clinicalApplicationsArchiveSelect,
#purposeUseArchiveSelect,
#physicalLocationArchiveSelect {
  position: relative;
}

#countryArchiveSelect ul,
#deviceCategoryArchiveSelect ul,
#oemArchiveSelect ul,
#yearOfManufactureArchiveSelect ul,
#clinicalApplicationsArchiveSelect ul,
#purposeUseArchiveSelect ul,
#physicalLocationArchiveSelect ul {
  width: 305px;
  max-height: 316px;
  overflow-y: scroll;
  position: absolute;
  top: 5px;
  left: 0px;
  z-index: 2;
  background-color: white;
  padding: 0px 0px;
  border: 1px solid #583c97;
  border-radius: 5px;
}

#countryArchiveSelect li,
#deviceCategoryArchiveSelect li,
#oemArchiveSelect li,
#yearOfManufactureArchiveSelect li,
#clinicalApplicationsArchiveSelect li,
#purposeUseArchiveSelect li,
#physicalLocationArchiveSelect li {
  padding: 5px 5px;
}

#countryArchiveSelect li:hover,
#deviceCategoryArchiveSelect li:hover,
#oemArchiveSelect li:hover,
#yearOfManufactureArchiveSelect li:hover,
#clinicalApplicationsArchiveSelect li:hover,
#purposeUseArchiveSelect li:hover,
#physicalLocationArchiveSelect li:hover {
  background-color: #583c97;
  color: white;
  border-radius: 4px;
}

.archive-service-error-text {
  color: red;
  align-self: stretch;
  line-height: 20px;
  z-index: 2;
  position: relative;
}

.archive-service-frame-parent-31 {
  align-self: stretch;
  /* gap: 32px; */
}


.archive-service-frame-parent-28 {
  align-items: flex-start;
  padding: 0 1px 0 0;
  box-sizing: border-box;
}

.archive-service-buttons-button7 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  justify-content: flex-start;
  /* padding: 8px 13px 8px 15px; */
  gap: 6px;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 5px;
}

.archive-service-buttonsbutton17-custom {
  right: 325px !important;
}

.archive-service1-buttonsbutton18-custom {
  right: 195px !important;
}

.archive-service-listing-container-custom {
  justify-content: initial !important;
  flex-direction: column !important;
}

.archive-service-filter-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5px 32px;
  /* left: 35px;
  top: 55px;
  position: relative; */
}

.archive-service-input-fields2-custom {
  flex-direction: column !important;
  align-items: initial !important;
}

.archive-service-second-badge-divider-custom,
.archive-service-section-wrapper-custom {
  z-index: 1;
}

/* End of new CSS */

.archive-service-text47 {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 51px;
}

.archive-service-buttonsbutton7 {
  /* margin: 0 !important;
    position: absolute;
    top: 173px;
    left: 45px; */
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  /* display: flex;
    flex-direction: row;
    align-items: flex-start; */
  justify-content: flex-start;
  padding: 8px 13px 8px 15px;
  gap: 6px;
  z-index: 1;
  display: flex;
  align-items: center;

  padding: 5px 10px;
  border-radius: 5px;
}





.archive-service-sliders-02-icon {
  cursor: pointer;
}

.custom-datepicker {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
  gap: 8px;
  font-size: 16px;
  color: #101828;
  width: 70%;

}


.react-datepicker {
  background-color: white !important;
  color: black !important;
  left: 23px;

}


.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #6941c6 !important;
  color: white !important;
}

.react-datepicker__day:hover {
  background-color: #7e56d9 !important;
  color: white !important;
}


.react-datepicker__header {
  background-color: white !important;


}


.archive-service1-text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #344054;
  overflow: hidden;
}


.archive-service1-filter-selected-div {
  width: 100%;
  height: max-content;
  /* position: relative; */
}

.archive-service1-listing-container {
  width: 100%;
  gap: 20px;
  /* max-height: 85vh; */
  /* margin: 260px auto 0; */
  padding: 10px;
  display: grid;
  overflow-y: auto;
}

.archive-service1-mid-container {
  width: 100%;
  height: max-content;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  /* padding: 81px 0px 0px 0px; */
  box-sizing: border-box;
  /* gap: 999px; */
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  font-size: 18px;
  color: #101828;
  font-family: Inter;
  /* min-height: 1624px; */
  /* margin: 0 auto; */
}

.archive-service1-divider-icon7-cstm {
  position: initial !important;
}


.archive-service1-listings-search-results-list-child {
  align-self: stretch;
  height: 5871px;
  position: relative;
  background-color: #fff;
  display: none;
  z-index: 0;
}

.archive-service1-frame-child {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
}

.archive-service1-gerator-04-1-icon {
  height: 32px;
  width: 121px;
  position: relative;
  object-fit: cover;
}

.archive-service1-logo,
.archive-service1-logo-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-logo {
  flex-direction: row;
  z-index: 1;
}

.archive-service1-logo-wrapper {
  flex-direction: column;
  padding: 6px 0 0;
}

.archive-service1-bar-chart-01-icon,
.archive-service1-dot-icon {
  height: 10px;
  width: 10px;
  position: relative;
  display: none;
}

.archive-service1-bar-chart-01-icon {
  height: 24px;
  width: 24px;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.archive-service1-text {
  text-decoration: none;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 30px;
  white-space: nowrap;
}

.archive-service1-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.archive-service1-text1 {
  align-self: stretch;
  position: relative;
  line-height: 18px;
  font-weight: 500;
}

.archive-service1-badge {
  height: 22px;
  border-radius: 9999px;
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 7px;
  text-align: center;
  font-size: 12px;
}

.archive-service1-chevron-down-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.archive-service1-nav-item-base {
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 12px;
  gap: 8px;
}

.archive-service1-text2,
.archive-service1-text3 {
  position: relative;
  font-family: Inter;
}

.archive-service1-text2 {
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #18222f;
  text-align: left;
  display: inline-block;
  min-width: 29px;
  white-space: nowrap;
}

.archive-service1-text3 {
  align-self: stretch;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #344054;
  text-align: center;
}

.archive-service1-badge1,
.archive-service1-nav-item-base1 {
  background-color: #f9fafb;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.archive-service1-badge1 {
  height: 22px;
  border-radius: 9999px;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
  padding: 2px 7px;
}

.archive-service1-nav-item-base1 {
  cursor: pointer;
  border: 0;
  padding: 8px 12px;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  gap: 8px;
}

.archive-service1-text4 {
  text-decoration: none;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 38px;
  white-space: nowrap;
}

.archive-service1-navigation,
.archive-service1-navigation1 {
  display: flex;
  justify-content: flex-start;
}

.archive-service1-navigation1 {
  flex-direction: row;
  align-items: center;
  gap: 4px;
  z-index: 1;
}

.archive-service1-navigation {
  flex-direction: column;
  align-items: flex-start;
  padding: 2px 0 0;
}

.archive-service1-label {
  width: 47px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
  white-space: nowrap;
}

.archive-service1-search-lg-icon,
.archive-service1-text6 {
  position: relative;
  overflow: hidden;
}

.archive-service1-search-lg-icon {
  height: 20px;
  width: 20px;
  flex-shrink: 0;
}

.archive-service1-text6 {
  width: calc(100% - 20px);
  border: 0;
  outline: 0;
  font-family: Inter;
  font-size: 16px;
  background-color: transparent;
  height: 24px;
  flex: 1;
  line-height: 24px;
  color: #667085;
  text-align: left;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 158px;
  padding: 0;
}

.archive-service1-content3,
.archive-service1-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.archive-service1-content3 {
  flex: 1;
  gap: 8px;
}

.archive-service1-input {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  padding: 8px 13px;
}

.archive-service1-frame-parent,
.archive-service1-input-with-label,
.archive-service1-navigation-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-input-with-label {
  flex: 1;
  flex-direction: column;
  gap: 6px;
  z-index: 1;
  font-size: 14px;
}

.archive-service1-frame-parent,
.archive-service1-navigation-parent {
  flex-direction: row;
  max-width: 100%;
}

.archive-service1-navigation-parent {
  flex: 1;
  gap: 38px;
}

.archive-service1-frame-parent {
  width: 754px;
  gap: 70px;
}

.archive-service1-icon {
  height: 19px;
  width: 19px;
  position: relative;
}

.archive-service1-nav-item-button {
  width: 40px;
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  box-sizing: border-box;
  z-index: 1;
}

.archive-service1-nav-item-button-child {
  height: 18.3px;
  width: 14.6px;
  position: relative;
}

.archive-service1-contrast-border {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 9999px;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
}

.archive-service1-avatar {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 9999px;
  background-image: url("../../../icons/Gerator/archive_page/avatar@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;
}

.archive-service1-user-buttons {
  justify-content: flex-start;
  gap: 15px;
}

.archive-service1-breadcrumb-button-base,
.archive-service1-rectangle-parent,
.archive-service1-user-buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.archive-service1-rectangle-parent {
  width: 100%;
  margin: 0 !important;
  position: absolute;
  top: 7px;
  right: 0;
  left: 0;
  justify-content: space-between;
  padding: 14px 64px 14px 31px;
  box-sizing: border-box;
  gap: 20px;
  max-width: 100%;
  z-index: 1;
  text-align: left;
  font-size: 16px;
  color: #344054;
  font-family: Inter;
}

.archive-service1-breadcrumb-button-base {
  border-radius: 6px;
  justify-content: flex-start;
  padding: 4px;
}

.archive-service1-chevron-right-icon {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.archive-service1-text7 {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  min-width: 74px;
}

.archive-service1-breadcrumb-button-base1 {
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}

.archive-service1-text8 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 500;
}

.archive-service1-breadcrumb-button-base2 {
  align-self: stretch;
  border-radius: 6px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 7px;
}

.archive-service1-chevron-right-icon2 {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.archive-service1-text9 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  white-space: nowrap;
}

.archive-service1-breadcrumb-button-base3 {
  align-self: stretch;
  border-radius: 6px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}

.archive-service1-text10 {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 64px;
}

.archive-service1-breadcrumb-button-base4 {
  border-radius: 6px;
  background-color: #f9fafb;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  color: #344054;
}

.archive-service1-tabs {
  gap: 8px;
}

.archive-service1-breadcrumbs,
.archive-service1-tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.archive-service1-divider-icon {
  width: 262px;
  height: 1px;
  position: relative;
  display: none;
}

.archive-service1-page-header {
  align-self: stretch;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.archive-service1-container {
  padding: 0 32px;
}

.archive-service1-container,
.archive-service1-header-section {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-main-child,
.archive-service1-main-item {
  width: 1px;
  height: 29px;
  position: relative;
  border-right: 1px solid #000;
  box-sizing: border-box;
  flex-shrink: 0;
}

.archive-service1-main-item {
  height: 43px;
}

.archive-service1-main,
.archive-service1-text11 {
  align-self: stretch;
}

.archive-service1-main {
  height: 80px;
  background-color: #fff;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px 0 0;
  box-sizing: border-box;
  gap: 32px;
  z-index: 1;
  color: #475467;
}

.archive-service1-text11 {
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 500;
}

.archive-service1-breadcrumb-button-base6,
.archive-service1-breadcrumb-button-base7 {
  align-self: stretch;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.archive-service1-breadcrumb-button-base6 {
  flex: 1;
  padding: 4px 8px;
}

.archive-service1-breadcrumb-button-base7 {
  padding: 4px 7px;
}

.archive-service1-breadcrumbs1,
.archive-service1-tabs1 {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.archive-service1-tabs1 {
  height: 28px;
  flex: 1;
  display: flex;
  gap: 8px;
}

.archive-service1-breadcrumbs1 {
  width: 278px;
  display: none;
}

.archive-service1-page-title {
  align-self: stretch;
  position: relative;
  line-height: 38px;
  font-weight: 600;
}

.archive-service1-supporting-text {
  width: 445px;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  display: none;
  max-width: 100%;
}

.archive-service1-text-and-supporting-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  min-width: 320px;
  max-width: 100%;
}

.archive-service1-label1,
.archive-service1-text15 {
  position: relative;
}

.archive-service1-label1 {
  width: 47px;
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.archive-service1-text15 {
  align-self: stretch;
  flex: 1;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.archive-service1-content5 {
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.archive-service1-content5,
.archive-service1-input-with-label1,
.archive-service1-input1 {
  align-self: stretch;
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.archive-service1-input1 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 10px 13px;
  font-size: 16px;
  color: #667085;
}

.archive-service1-input-with-label1 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.archive-service1-hint-text {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  color: #475467;
  display: none;
}

.archive-service1-input-dropdown {
  height: 44px;
  width: 320px;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  min-width: 200px;
  max-width: 320px;
  font-size: 14px;
  color: #344054;
}

.archive-service1-content4,
.archive-service1-divider-icon1 {
  align-self: stretch;
  max-width: 100%;
}

.archive-service1-content4 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 20px 16px;
  font-size: 26px;
  color: #101828;
}

.archive-service1-divider-icon1 {
  position: relative;
  overflow: hidden;
  max-height: 100%;
  display: none;
}

.archive-service1-page-header1,
.archive-service1-secondary-content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-page-header1 {
  flex: 1;
  background-color: #fff;
  flex-direction: column;
  gap: 20px;
  z-index: 1;
}

.archive-service1-secondary-content {
  width: 100%;
  flex-direction: row;
  padding: 0 32px;
  box-sizing: border-box;
  color: #475467;
}

.archive-service1-text16 {
  text-decoration: none;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 53px;
}

.archive-service1-button-group-base {
  cursor: pointer;
  border: 0;
  padding: 10px 15px 10px 16px;
  background-color: #f9fafb;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 40px;
  white-space: nowrap;
}

.archive-service1-button-group-base:hover {
  background-color: #e0e0e0;
}

.archive-service1-text17 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 70px;
}

.archive-service1-button-group-base1 {
  background-color: #fff;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 15px 10px 16px;
  min-height: 40px;
}

.archive-service1-button-group-base2,
.archive-service1-button-group-base3,
.archive-service1-button-group-base4,
.archive-service1-button-group-base5,
.archive-service1-button-group-base6,
.archive-service1-button-group-base7 {
  align-self: stretch;
  background-color: #fff;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  min-height: 40px;
  z-index: 7;
}

.archive-service1-button-group-base3,
.archive-service1-button-group-base4,
.archive-service1-button-group-base5,
.archive-service1-button-group-base6,
.archive-service1-button-group-base7 {
  z-index: 6;
}

.archive-service1-button-group-base4,
.archive-service1-button-group-base5,
.archive-service1-button-group-base6,
.archive-service1-button-group-base7 {
  z-index: 5;
}

.archive-service1-button-group-base5,
.archive-service1-button-group-base6,
.archive-service1-button-group-base7 {
  z-index: 4;
}

.archive-service1-button-group-base6,
.archive-service1-button-group-base7 {
  z-index: 3;
}

.archive-service1-button-group-base7 {
  z-index: 2;
}

.archive-service1-text24 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 89px;
}

.archive-service1-chevron-right {
  margin: 0;
  width: 16px;
  height: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.archive-service1-breadcrumb-button-base11,
.archive-service1-breadcrumb-button-base12 {
  align-self: stretch;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-breadcrumb-button-base11 {
  flex: 1;
  padding: 4px 8px;
}

.archive-service1-breadcrumb-button-base12 {
  width: 28px;
  padding: 4px 7px;
  box-sizing: border-box;
}

.archive-service1-text28 {
  align-self: stretch;
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.archive-service1-breadcrumb-button-base14 {
  width: 54px;
  border-radius: 6px;
  background-color: #f9fafb;
  padding: 4px 8px;
  box-sizing: border-box;
  color: #344054;
}

.archive-service1-breadcrumb-button-base14,
.archive-service1-breadcrumbs2,
.archive-service1-button-group,
.archive-service1-tabs2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-tabs2 {
  align-self: stretch;
  flex: 1;
  gap: 8px;
}

.archive-service1-breadcrumbs2 {
  margin: 0 !important;
  position: absolute;
}

.archive-service1-breadcrumbs2 {
  height: 28px;
  width: 278px;
  right: -2px;
  bottom: -20px;
  color: #475467;
}

.archive-service1-button-group {
  /* margin: 0 !important;
  position: absolute;
  top: 304px;
  left: 52px; */
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  z-index: 1;
}

.archive-service1-hint-text1 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  display: none;
}

.archive-service1-sliders-02-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 20px;
}

.archive-service1-menu-label {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.archive-service1-text-padding {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.archive-service1-placeholder-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 20px;
}

.archive-service1-buttonsbutton {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 13px;
  gap: 4px;
  color: #344054;
}

.archive-service1-input-dropdown1 {
  /* margin: 0 !important;
  position: absolute;
  top: 304px;
  right: 29px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  max-width: 320px;
  z-index: 1;
  color: #475467;
}

/* .archive-service1-divider-icon2,
.archive-service1-divider-icon3 {
  width: 1077px;
  height: 1px;
  right: 0;
  z-index: 1;
} */

.archive-service1-divider-icon2 {
 
  margin: 0 !important;
  /* position: absolute;
  right: 0;
  top: 356px; */
  width: 100%;
  height: 1px;
 
  z-index: 1;
}

.archive-service1-divider-icon3 {
  /* top: 291px;
  position: absolute;
  right: 0; */
  margin: 0 !important;
  width: 100%;
  height: 1px;

  z-index: 1;
 
}


.archive-service1-filter-items{
  position: absolute;
  margin: 0 !important;
}

.archive-service1-supporting-text1 {
  width: 678px;

  margin: 0 !important;
  /* position: absolute;
  top: 122px;
  left: 52px; */
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  display: inline-block;
  z-index: 1;
}

.archive-service1-filter-items {
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
}

.archive-service1-close-icons-child {
  height: 148px;
  width: 148px;
  position: relative;
  border-radius: 12px;
  object-fit: cover;
  z-index: 1;
}

.archive-service1-text29 {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  z-index: 1;
}

.archive-service1-dots {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 1px;
}

.archive-service1-dot-icon3 {
  height: 8px;
  width: 8px;
  position: relative;
  display: none;
}

.archive-service1-text30 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 66px;
}

.archive-service1-badge3 {
  width: 84px;
  border-radius: 9999px;
  background-color: #edfbf5;
  border: 1px solid #41d992;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 8px;
  gap: 6px;
  mix-blend-mode: normal;
  z-index: 1;
  text-align: center;
  font-size: 14px;
  color: #17b26a;
}

.archive-service1-supporting-text2 {
  position: relative;
  letter-spacing: -0.17px;
  line-height: 24px;
  display: inline-block;
  min-width: 83px;
  z-index: 1;
}

.archive-service1-marker-pin-02-icon {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.archive-service1-location {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 124px;
  z-index: 1;
}

.archive-service1-dot-item-location-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 7px;
}

.archive-service1-type-of-work {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 109px;
  z-index: 1;
}

.archive-service1-dot-item-type-parent {
  gap: 8px;
}

.archive-service1-completed,
.archive-service1-dot-item-content,
.archive-service1-dot-item-innermost,
.archive-service1-dot-item-type-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-dot-item-content {
  flex: 1;
  gap: 20px;
  max-width: 100%;
}

.archive-service1-completed,
.archive-service1-dot-item-innermost {
  box-sizing: border-box;
}

.archive-service1-dot-item-innermost {
  align-self: stretch;
  padding: 0 0 0 1px;
  max-width: 100%;
}

.archive-service1-completed {
  width: 50px;
  border-radius: 8px;
  background-color: #fdb022;
  padding: 1px 4px;
  z-index: 1;
}

.archive-service1-supporting-text3 {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 117px;
  z-index: 1;
}

.archive-service1-supporting-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1px 0 0;
  color: #475467;
}

.archive-service1-completed-parent {
  flex-direction: row;
  gap: 8px;
  color: #fff;
}

.archive-service1-badge4,
.archive-service1-completed-parent,
.archive-service1-dot-item-inner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-dot-item-inner {
  align-self: stretch;
  flex-direction: column;
  gap: 15px;
  max-width: 100%;
}

.archive-service1-badge4 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  flex-direction: row;
  padding: 0 7px;
  min-width: 112px;
  white-space: nowrap;
  z-index: 1;
}

.archive-service1-text33 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 61px;
}

.archive-service1-badge5,
.archive-service1-badge6 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 7px;
  z-index: 1;
}

.archive-service1-badge5 {
  width: 77px;
  white-space: nowrap;
}

.archive-service1-badge6 {
  width: 76px;
}

.archive-service1-badge7,
.archive-service1-badges {
  flex: 1;
  flex-direction: row;
}

.archive-service1-badge7 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 7px;
  min-width: 107px;
  white-space: nowrap;
  z-index: 1;
}

.archive-service1-badges {
  gap: 8px;
}

.archive-service1-badges,
.archive-service1-badges-wrapper,
.archive-service1-dot-item {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-badges-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 1px;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.archive-service1-dot-item {
  flex: 1;
  flex-direction: column;
  gap: 15px;
}

.archive-service1-text36 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 77px;
}

.archive-service1-badge8 {
  width: 93px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 7px;
  z-index: 1;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.archive-service1-dot-item-parent,
.archive-service1-dots-parent,
.archive-service1-dots-vertical {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-dot-item-parent {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-end;
  padding: 0 23px 0 0;
  box-sizing: border-box;
  gap: 7px;
  font-size: 16px;
  color: #475467;
}

.archive-service1-dots-parent,
.archive-service1-dots-vertical {
  flex-direction: column;
  align-items: flex-start;
}

.archive-service1-dots-parent {
  align-self: stretch;
  gap: 16px;
}

.archive-service1-dots-vertical {
  flex: 1;
  padding: 10px 0 0;
  box-sizing: border-box;
}

.archive-service1-price {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 32px;
  font-weight: 600;
  white-space: nowrap;
  z-index: 1;
}

.archive-service1-deal-title {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 83px;
  z-index: 2;
}

.archive-service1-magnifier-1-icon {
  height: 20px;
  width: 20px;
  position: relative;
  object-fit: cover;
  min-height: 20px;
  cursor: pointer;
}

.archive-service1-deal-inner,
.archive-service1-radar-1-1-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.archive-service1-radar-1-1-parent {
  flex: 1;
  justify-content: flex-start;
  gap: 8px;
  z-index: 1;
}

.archive-service1-deal-inner {
  align-self: stretch;
  padding: 0 4px 0 3px;
}

.archive-service1-card-deal,
.archive-service1-deal-details,
.archive-service1-deal-inner {
  justify-content: flex-start;
}

.archive-service1-deal-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.archive-service1-card-deal {
  align-self: stretch;
  flex-direction: row;
  padding: 0 30px;
  font-size: 12px;
  color: #475467;
}

.archive-service1-card-deal,
.archive-service1-frame-wrapper,
.archive-service1-price-parent {
  display: flex;
  align-items: flex-start;
}

.archive-service1-price-parent {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
}

.archive-service1-frame-wrapper {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 10px 0 0;
}

.archive-service1-dots-vertical-group {
  width: 153px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 26px;
  text-align: center;
  font-size: 20px;
  color: #6941c6;
}

.archive-service1-dots-vertical-parent {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  min-width: 525px;
  max-width: 100%;
}

.archive-service1-close-icons,
.archive-service1-divider-icon4 {
  align-self: stretch;
  max-width: 100%;
}

.archive-service1-close-icons {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-end;
  gap: 23px;
}

.archive-service1-divider-icon4 {
  position: relative;
  overflow: hidden;
  max-height: 100%;
  z-index: 1;
}

.archive-service1-user-content-child {
  height: 52px;
  width: 52px;
  position: relative;
  object-fit: cover;
  z-index: 1;
}

.archive-service1-name,
.archive-service1-role {
  position: relative;
  display: inline-block;
}

.archive-service1-name {
  line-height: 23px;
  font-weight: 600;
  min-width: 77px;
  z-index: 1;
}

.archive-service1-role {
  height: 19px;
  font-size: 12px;
  line-height: 24px;
  color: #6941c6;
  flex-shrink: 0;
  min-width: 94px;
  z-index: 2;
}

.archive-service1-inner-info {
  flex-direction: column;
}

.archive-service1-inner-info,
.archive-service1-inner-info-wrapper,
.archive-service1-user-content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-inner-info-wrapper {
  flex-direction: column;
  padding: 3px 0 0;
}

.archive-service1-user-content {
  flex: 1;
  flex-direction: row;
  gap: 8px;
  min-width: 100px;
  color: #101828;
}

.archive-service1-eye-icon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.archive-service1-view-label {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 34px;
}

.archive-service1-buttonsbutton1 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 6px 12px;
  background-color: #fff;
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  z-index: 1;
}

.archive-service1-icon1 {
  width: 16.7px;
  height: 16.7px;
  position: relative;
}

.archive-service1-icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 3.7px 0 0;
}

.archive-service1-lock-label {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 56px;
}

.archive-service1-buttonsbutton2 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow-x: auto;
  padding: 6px 11px;
  gap: 5.5px;
  z-index: 1;
}

.archive-service1-buttonsbutton2,
.archive-service1-buttonsbutton3,
.archive-service1-user-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-buttonsbutton3 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow-x: auto;
  padding: 6px 10px 6px 12px;
  gap: 5.4px;
  z-index: 1;
}

.archive-service1-user-item {
  width: 556.4px;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 16.3px;
  max-width: calc(100% - 44px);
}

.archive-service1-arrow-square-up-right-icon {
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.archive-service1-arrow-square-up-right-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 14px 0 0;
}

.archive-service1-arrow-square-up-right-wrapper1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 28px 0 0;
}

.archive-service1-user-listings {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 100%;
  gap: 20px;
}

.archive-service1-filter-items-parent,
.archive-service1-listing-separator {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}

.archive-service1-listing-separator {
  align-self: stretch;
  padding: 0 10px 0 0;
  gap: 16px;
  font-size: 14px;
  color: #344054;
}

.archive-service1-filter-items-parent {
  width: 1016px;
  margin: 0 !important;
  position: absolute;
  right: 30px;
  bottom: 291px;
  padding: 14px 14px 17px 24px;
  gap: 24px;
  z-index: 1;
  font-size: 18px;
  color: #101828;
}

.archive-service1-frame-inner {
  height: 148px;
  width: 148px;
  position: relative;
  border-radius: 12px;
  object-fit: cover;
  min-height: 148px;
  z-index: 1;
}

.archive-service1-dots-vertical-icon1 {
  height: 20px;
  width: 20px;
  position: absolute;
  margin: 0 !important;
  top: -10px;
  right: -10px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.archive-service1-price-value,
.archive-service1-text37 {
  position: relative;
  display: inline-block;
}

.archive-service1-text37 {
  line-height: 28px;
  font-weight: 600;
  max-width: 100%;
  z-index: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #344054;
  overflow: hidden;
}

.archive-service1-price-value {
  line-height: 20px;
  font-weight: 500;
  min-width: 55px;
}

.archive-service1-badge9 {
  width: 73px;
  border-radius: 9999px;
  background-color: #fff9ef;
  border: 1px solid #f5b231;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 8px;
  gap: 6px;
  mix-blend-mode: normal;
  z-index: 1;
}

.archive-service1-supporting-text4 {
  position: relative;
  font-size: 16px;
  letter-spacing: -0.17px;
  line-height: 24px;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 83px;
  z-index: 1;
}

.archive-service1-badge-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.archive-service1-deal-type-parent {
  flex-direction: row;
  gap: 8px;
  text-align: left;
  font-size: 16px;
  color: #fff;
}

.archive-service1-badge10,
.archive-service1-badge11,
.archive-service1-deal-type-parent,
.archive-service1-frame-parent1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-badge10 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  padding: 0 7px;
  white-space: nowrap;
  z-index: 1;
  color: #344054;
}

.archive-service1-badge11,
.archive-service1-frame-parent1 {
  flex: 1;
}

.archive-service1-frame-parent1 {
  flex-direction: column;
  gap: 15px;
  min-width: 114px;
  color: #f5b231;
}

.archive-service1-badge11 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  padding: 0 7px;
  white-space: nowrap;
  z-index: 1;
}

.archive-service1-sub-badge {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 60px;
}

.archive-service1-badge12 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  justify-content: flex-start;
  padding: 0 7px;
  z-index: 1;
}

.archive-service1-badge-group,
.archive-service1-badge12,
.archive-service1-frame-wrapper1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.archive-service1-badge-group {
  flex: 1;
  justify-content: flex-start;
  gap: 8px;
}

.archive-service1-frame-wrapper1 {
  align-self: stretch;
  justify-content: flex-end;
  padding: 0 6px 0 0;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.archive-service1-deal-location-parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 56px;
  min-width: 109px;
  text-align: left;
  font-size: 16px;
}

.archive-service1-badge13,
.archive-service1-inner-work {
  flex-direction: row;
  align-items: flex-start;
}

.archive-service1-inner-work {
  display: flex;
  justify-content: flex-end;
  padding: 0 10px 0 18px;
}

.archive-service1-badge13 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  padding: 0 7px;
  white-space: nowrap;
  z-index: 1;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.archive-service1-badge13,
.archive-service1-badge14,
.archive-service1-card-work,
.archive-service1-frame-div {
  display: flex;
  justify-content: flex-start;
}

.archive-service1-card-work {
  flex: 0.9649;
  flex-direction: column;
  align-items: flex-end;
  padding: 0 6px 0 0;
  box-sizing: border-box;
  gap: 56px;
  min-width: 111px;
  text-align: left;
  font-size: 16px;
}

.archive-service1-badge14,
.archive-service1-frame-div {
  flex-direction: row;
}

.archive-service1-badge14 {
  width: 93px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  align-items: flex-start;
  padding: 0 7px;
  z-index: 1;
  color: #344054;
}

.archive-service1-frame-div {
  align-self: stretch;
  align-items: flex-end;
  padding: 0 2px 0 0;
  gap: 2px;
}

.archive-service1-frame-container,
.archive-service1-price-value1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-frame-container {
  flex: 1;
  flex-direction: column;
  padding: 8px 0 0;
  box-sizing: border-box;
  max-width: 80%;
}

.archive-service1-price-value1 {
  flex-direction: row;
  padding: 0 30px;
  font-size: 12px;
  color: #475467;
}

.archive-service1-inner-icons {
  flex-direction: row;
  gap: 8px;
  z-index: 1;
}

.archive-service1-inner-icons,
.archive-service1-inner-icons-wrapper,
.archive-service1-second-price {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-inner-icons-wrapper {
  flex-direction: row;
  padding: 0 34px 0 33px;
}

.archive-service1-second-price {
  width: 20%;
  flex-direction: column;
  gap: 2px;
  font-size: 20px;
  color: #6941c6;
}

.archive-service1-frame-group {
  align-self: stretch;
  flex-direction: row;
  /* gap: 38px; */
  text-align: center;
  font-size: 14px;
  color: #475467;
}

.archive-service1-dots-vertical-container,
.archive-service1-frame-group,
.archive-service1-rectangle-container,
.archive-service1-text-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-text-parent {
  flex: 1;
  flex-direction: column;
  gap: 8px;
}

.archive-service1-dots-vertical-container,
.archive-service1-rectangle-container {
  flex-direction: row;
}

.archive-service1-dots-vertical-container {
  flex: 1;
  position: relative;
  min-width: 517px;
}

.archive-service1-rectangle-container {
  align-self: stretch;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 24px;
}

.archive-service1-role1 {
  height: 19px;
  position: relative;
  font-size: 12px;
  line-height: 24px;
  color: #6941c6;
  display: inline-block;
  flex-shrink: 0;
  min-width: 96px;
  z-index: 2;
}

.archive-service1-frame-parent4 {
  flex: 1;
  flex-direction: row;
  gap: 8px;
  min-width: 101px;
  color: #101828;
}

.archive-service1-buttonsbutton-wrapper,
.archive-service1-frame-parent3,
.archive-service1-frame-parent4 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-buttonsbutton-wrapper {
  width: 90px;
  flex-direction: column;
  padding: 6px 0 0;
  box-sizing: border-box;
}

.archive-service1-frame-parent3 {
  width: 556.4px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 15.7px;
  max-width: calc(100% - 44px);
}

.archive-service1-frame-child1 {
  width: 24px;
  height: 24px;
  position: relative;
  z-index: 1;
}

.archive-service1-divider-parent,
.archive-service1-rectangle-group,
.archive-service1-rectangle-group1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-divider-parent {
  align-self: stretch;
  gap: 16px;
  font-size: 14px;
  color: #344054;
}

.archive-service1-rectangle-group {
  width: 100%;
  /* margin: 0 !important; */
  /* position: absolute; */
  /* right: 30px; */
  /* bottom: -15px; */
  padding: 24px 24px 17px;
  box-sizing: border-box;
  gap: 24px;
  /* z-index: 1; */
  font-size: 18px;
  color: #101828;
  /* top: 400px; */
  height: max-content;
  border: 1px solid #eaecf0;
  border-radius: 12px;

}

.archive-service1-rectangle-group1 {
  width: 1016px;
  margin: 0 !important;
  position: absolute;
  right: 30px;
  bottom: -15px;
  padding: 24px 24px 17px;
  box-sizing: border-box;
  gap: 24px;
  z-index: 1;
  font-size: 18px;
  color: #101828;
  top: 700px;
  height: max-content;
}

.archive-service1-text47 {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 51px;
}

.archive-service1-buttonsbutton7 {
  margin: 0 !important;
  position: absolute;
  top: 173px;
  left: 45px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 13px 8px 15px;
  gap: 6px;
  z-index: 1;
}

.archive-service1-text48 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
}

.archive-service1-buttonsbutton8 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 13px 8px 15px;
  background-color: #fff;
  margin: 0 !important;
  position: absolute;
  top: 173px;
  left: 164px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  z-index: 1;
}

.archive-service1-text49 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 74px;
}

.archive-service1-buttonsbutton9 {
  margin: 0 !important;
  position: absolute;
  top: 235px;
  left: 45px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 13px 8px 15px;
  gap: 5px;
  z-index: 1;
}

.archive-service1-text50 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 90px;
}

.archive-service1-buttonsbutton10 {
  left: 370px;
}

.archive-service1-buttonsbutton10,
.archive-service1-buttonsbutton11,
.archive-service1-buttonsbutton12 {
  margin: 0 !important;
  position: absolute;
  top: 173px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 13px 8px 15px;
  gap: 5px;
  z-index: 1;
}

.archive-service1-buttonsbutton11 {
  cursor: pointer;
  right: 377px;
}

.archive-service1-buttonsbutton12 {
  right: 262px;
}

.archive-service1-text53 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 63px;
}

.archive-service1-buttonsbutton13 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 13px 8px 15px;
  background-color: #fff;
  margin: 0 !important;
  position: absolute;
  top: 235px;
  left: 186px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  z-index: 1;
}

.archive-service1-text54 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 93px;
}

.archive-service1-buttonsbutton14 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 13px 8px 15px;
  background-color: #fff;
  margin: 0 !important;
  position: absolute;
  top: 235px;
  left: 317px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  z-index: 1;
}

.archive-service1-text55 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 106px;
}

.archive-service1-buttonsbutton15 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 13px 8px 15px;
  background-color: #fff;
  margin: 0 !important;
  position: absolute;
  top: 235px;
  left: 477px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  z-index: 1;
}

.archive-service1-text56 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 77px;
}

.archive-service1-buttonsbutton16 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 13px 8px 15px;
  background-color: #fff;
  margin: 0 !important;
  position: absolute;
  top: 173px;
  right: 116px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  z-index: 1;
}

.archive-service1-text57 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 78px;
}

.archive-service1-buttonsbutton17 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 14px;
  background-color: #fff;
  margin: 0 !important;
  /* position: absolute;
  top: 235px;
  right: 198px; */
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  z-index: 1;
}



.archive-service1-text58 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #eaecf0;
  text-align: left;
  display: inline-block;
  min-width: 75px;
}

.archive-service1-buttonsbutton18 {
  cursor: pointer;
  border: 0;
  padding: 10px 14px;
  background-color: #7e56d9;
  margin: 0 !important;
  /* position: absolute;
  top: 235px;
  right: 57px; */
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  z-index: 1;
}

.archive-service1-text59 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #475467;
  text-align: left;
}

.archive-service1-button,
.archive-service1-text-padding1 {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.archive-service1-text-padding1 {
  align-self: stretch;
  display: flex;
  padding: 0 2px;
}

.archive-service1-button {
  border-radius: 8px;
  overflow: hidden;
  display: none;
  padding: 10px 14px;
  gap: 4px;
}

.archive-service1-button,
.archive-service1-button1,
.archive-service1-text60 {
  align-self: stretch;
}

.archive-service1-text60 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #6941c6;
  text-align: left;
}

.archive-service1-button1 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d6bbfb;
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 13px;
  gap: 4px;
}

.archive-service1-filter-label {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 72px;
}



.archive-service1-button2 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  justify-content: center;
  padding: 8px 13px;
  gap: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.archive-service1-actions {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  margin: 0 !important;
  /* position: absolute;
  top: 80px;
  right: 26px; */
  justify-content: flex-start;
  gap: 12px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.archive-service1-divider-icon6,
.archive-service1-filters {
  max-width: 100%;
  flex-shrink: 0;
}

.archive-service1-filters {
  width: 75%;
  /* display: flex; */
  height: max-content;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* padding: 0 0 1518px 0px; */
  box-sizing: border-box;

  /* position: relative; */
  font-size: 14px;
  color: #344054;
}

.archive-service1-divider-icon6 {
  height: 1px;
  top: 70px;
  overflow: hidden;
  z-index: 1;
}

.archive-service1-divider-icon6,
.archive-service1-divider-icon7,
.archive-service1-listing-border {
  width: 100%;
  position: absolute;
  margin: 0 !important;
  right: 0;
  left: 0;
}

.archive-service1-divider-icon7 {
  height: 1px;
  bottom: 127px;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.archive-service1-listing-border {
  height: 100%;
  top: 0;
  bottom: 0;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
}

.archive-service1-text-group,
.archive-service1-work-type {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-work-type {
  flex-direction: row;
  gap: 20px;
  font-size: 16px;
  color: #475467;
}

.archive-service1-text-group {
  flex-direction: column;
  gap: 8px;
  max-width: 100%;
}

.archive-service1-supporting-text8 {
  position: relative;
  font-size: 12px;
  line-height: 19px;
  color: #475467;
  display: inline-block;
  min-width: 104px;
  z-index: 1;
}

.archive-service1-last-active,
.archive-service1-name-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-last-active {
  font-size: 14px;
  color: #101828;
}

.archive-service1-name-container {
  gap: 16px;
}

.archive-service1-supporting-text9 {
  position: relative;
  font-size: 12px;
  line-height: 19px;
  color: #475467;
  display: inline-block;
  min-width: 113px;
  z-index: 1;
}

.archive-service1-applications,
.archive-service1-provider-name {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.archive-service1-applications {
  flex-direction: column;
  align-items: flex-start;
  min-width: 151px;
  font-size: 14px;
  color: #101828;
}

.archive-service1-provider-name {
  flex-direction: row;
  align-items: flex-end;
  gap: 10px;
  min-width: 272px;
  max-width: 100%;
}

.archive-service1-text64 {
  align-self: stretch;
  position: relative;
  line-height: 23px;
  font-weight: 500;
  z-index: 1;
}

.archive-service1-supporting-text10 {
  width: 113px;
  position: relative;
  font-size: 12px;
  line-height: 19px;
  color: #475467;
  display: inline-block;
  z-index: 1;
}

.archive-service1-invites {
  width: 233px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  color: #101828;
}

.archive-service1-card-actions,
.archive-service1-provider-details {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-provider-details {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
  align-content: flex-end;
  gap: 34px;
  font-size: 16px;
  color: #fff;
}

.archive-service1-card-actions {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.archive-service1-dots-vertical-icon2 {
  height: 20px;
  width: 20px;
  position: absolute;
  margin: 0 !important;
  top: -19px;
  right: -10px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.archive-service1-text65 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 41px;
  z-index: 1;
}

.archive-service1-financial-details {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 21px 0 20px;
}

.archive-service1-price2,
.archive-service1-rate {
  position: relative;
  display: inline-block;
}

.archive-service1-price2 {
  flex: 1;
  letter-spacing: -0.02em;
  line-height: 32px;
  font-weight: 600;
  min-width: 93px;
  white-space: nowrap;
  z-index: 2;
}

.archive-service1-rate {
  line-height: 20px;
  min-width: 15px;
  z-index: 1;
}

.archive-service1-daily-rate-amount {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5px 0 0;
  font-size: 12px;
  color: #475467;
}

.archive-service1-price3 {
  flex: 1;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 32px;
  font-weight: 600;
  display: inline-block;
  min-width: 93px;
  white-space: nowrap;
  z-index: 1;
}

.archive-service1-price-group {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 7.5px;
  font-size: 20px;
  color: #6941c6;
}

.archive-service1-text66 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 83px;
  z-index: 3;
}

.archive-service1-deal-icons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 4px 0 3px;
}

.archive-service1-deal-container {
  flex-direction: column;
  gap: 4px;
}

.archive-service1-card-actions1,
.archive-service1-deal-container,
.archive-service1-listing-border-parent,
.archive-service1-listing-card-actions {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-card-actions1 {
  align-self: stretch;
  flex-direction: row;
  position: relative;
}

.archive-service1-listing-border-parent,
.archive-service1-listing-card-actions {
  box-sizing: border-box;
}

.archive-service1-listing-card-actions {
  width: 316px;
  flex-direction: column;
  padding: 9px 0 0;
  margin-left: -33px;
  text-align: center;
  font-size: 12px;
  color: #475467;
}

.archive-service1-listing-border-parent {
  width: 1016px;
  margin: 0 !important;
  position: absolute;
  right: -24px;
  bottom: 33px;
  flex-direction: row;
  padding: 24px;
  z-index: 1;
}

.archive-service1-collapsed-image {
  height: 160px;
  width: 160px;
  position: absolute;
  margin: 0 !important;
  bottom: 0;
  left: 0;
  border-radius: 12px;
  background-color: #f5f5f5;
  z-index: 2;
}

.archive-service1-f4b711e503bc72fe396e5043e0c2-1-icon {
  height: 84px;
  width: 84px;
  position: absolute;
  margin: 0 !important;
  bottom: 38px;
  left: 38px;
  object-fit: cover;
  z-index: 3;
}

.archive-service1-collapsed-names-child {
  height: 52px;
  width: 52px;
  position: relative;
  object-fit: cover;
  z-index: 2;
}

.archive-service1-name3,
.archive-service1-role2 {
  position: relative;
  display: inline-block;
}

.archive-service1-name3 {
  line-height: 23px;
  font-weight: 600;
  min-width: 77px;
  z-index: 2;
}

.archive-service1-role2 {
  align-self: stretch;
  height: 19px;
  font-size: 12px;
  line-height: 24px;
  color: #6941c6;
  flex-shrink: 0;
  z-index: 3;
}

.archive-service1-collapsed-name-details,
.archive-service1-collapsed-names {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-collapsed-name-details {
  flex: 1;
  flex-direction: column;
  padding: 3px 0 0;
}

.archive-service1-collapsed-names {
  align-self: stretch;
  flex-direction: row;
  gap: 8px;
}

.archive-service1-collapsed-name-role,
.archive-service1-collapsed-names-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-collapsed-names-parent {
  align-self: stretch;
  gap: 254px;
}

.archive-service1-collapsed-name-role {
  width: 166px;
  padding: 153px 0 0;
  box-sizing: border-box;
}

.archive-service1-detail-header,
.archive-service1-details-image {
  position: absolute;
  margin: 0 !important;
}

.archive-service1-detail-header {
  top: 262px;
  right: -124px;
  line-height: 28px;
  font-weight: 600;
  z-index: 2;
}

.archive-service1-details-image {
  height: 208px;
  width: 1016px;
  right: -306px;
  bottom: -24px;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  z-index: 1;
}

.archive-service1-detail-header1,
.archive-service1-divider-icon8 {
  position: absolute;
  margin: 0 !important;
  z-index: 2;
}

.archive-service1-detail-header1 {
  bottom: 132px;
  left: 13px;
  line-height: 28px;
  font-weight: 600;
}

.archive-service1-divider-icon8 {
  height: 1px;
  width: 968px;
  right: -282px;
  bottom: 70px;
}

.archive-service1-badge15 {
  flex: 1;
  border-radius: 9999px;
  background-color: #eceef1;
  border: 1px solid #98a2b3;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 8px;
  gap: 6px;
  mix-blend-mode: normal;
  z-index: 2;
}

.archive-service1-supporting-text11 {
  position: relative;
  font-size: 16px;
  letter-spacing: -0.17px;
  line-height: 24px;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 83px;
  z-index: 2;
}

.archive-service1-badge-value {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.archive-service1-second-value {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 41px;
}

.archive-service1-second-badge-count {
  width: 50px;
  border-radius: 8px;
  background-color: #fdb022;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1px 4px;
  box-sizing: border-box;
  z-index: 2;
}

.archive-service1-supporting-text12 {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 117px;
  z-index: 2;
}

.archive-service1-third-badge-count {
  position: relative;
  line-height: 20px;
  font-weight: 500;
}

.archive-service1-badge16,
.archive-service1-first-badge {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-badge16 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  padding: 0 7px;
  white-space: nowrap;
  z-index: 2;
  color: #344054;
}

.archive-service1-first-badge {
  width: 181px;
  flex-direction: column;
  gap: 15px;
  min-width: 181px;
}

.archive-service1-marker-pin-02-icon2 {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 2;
}

.archive-service1-location2,
.archive-service1-type-of-work3 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 124px;
  z-index: 2;
}

.archive-service1-type-of-work3 {
  min-width: 109px;
}

.archive-service1-location-content,
.archive-service1-location-details {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.archive-service1-location-content {
  justify-content: flex-start;
  gap: 20px;
}

.archive-service1-location-details {
  justify-content: flex-end;
  padding: 0 5px;
}

.archive-service1-badge-count {
  width: 61px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 61px;
}

.archive-service1-badge17,
.archive-service1-badge18 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  padding: 0 7px;
  z-index: 2;
}

.archive-service1-badge17 {
  width: 77px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  white-space: nowrap;
}

.archive-service1-badge18 {
  width: 76px;
}

.archive-service1-badge-container,
.archive-service1-badge18,
.archive-service1-badge19 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-badge19 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  padding: 0 7px;
  white-space: nowrap;
  z-index: 2;
}

.archive-service1-badge-container {
  align-self: stretch;
  gap: 8px;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.archive-service1-location-details-parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 56px;
  min-width: 217px;
  max-width: 100%;
  margin-left: -1px;
  text-align: left;
  font-size: 16px;
  color: #475467;
}

.archive-service1-badge-name,
.archive-service1-buttonsbutton19,
.archive-service1-buttonsbutton20,
.archive-service1-buttonsbutton21 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-badge-name {
  flex: 1;
  max-width: 100%;
  row-gap: 20px;
}

.archive-service1-buttonsbutton19,
.archive-service1-buttonsbutton20,
.archive-service1-buttonsbutton21 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  z-index: 2;
}

.archive-service1-buttonsbutton19 {
  cursor: pointer;
  padding: 6px 12px;
  width: 90px;
  overflow: hidden;
  flex-shrink: 0;
  gap: 6px;
}

.archive-service1-buttonsbutton20,
.archive-service1-buttonsbutton21 {
  flex: 1;
  overflow-x: auto;
  padding: 6px 11px;
  gap: 5.5px;
  min-width: 86px;
}

.archive-service1-buttonsbutton21 {
  padding: 6px 10px 6px 12px;
  gap: 5.4px;
}

.archive-service1-buttonsbutton-parent {
  width: 385.4px;
  flex-direction: row;
  gap: 16px;
  text-align: left;
  color: #344054;
}

.archive-service1-badge-details,
.archive-service1-buttonsbutton-parent,
.archive-service1-divider-group {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-badge-details {
  flex: 1;
  flex-direction: column;
  padding: 0 0 8px;
  box-sizing: border-box;
  gap: 47px;
}

.archive-service1-divider-group {
  align-self: stretch;
  flex-direction: row;
  position: relative;
  text-align: center;
  font-size: 14px;
  color: #98a2b3;
}

.archive-service1-divider-icon9 {
  height: 1px;
  width: 968px;
  position: absolute;
  margin: 0 !important;
  right: -282px;
  bottom: 62px;
  z-index: 2;
}

.archive-service1-name-value {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 51px;
}

.archive-service1-badge20 {
  width: 69px;
  border-radius: 9999px;
  background-color: #fffdfd;
  border: 1px solid #da2d20;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 8px;
  gap: 6px;
  mix-blend-mode: normal;
  z-index: 2;
}

.archive-service1-badge22,
.archive-service1-first-badge-content {
  flex: 1;
  justify-content: flex-start;
}

.archive-service1-first-badge-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  min-width: 114px;
  text-align: center;
  font-size: 14px;
  color: #da2d20;
}

.archive-service1-badge22 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  padding: 0 7px;
  white-space: nowrap;
  z-index: 2;
}

.archive-service1-badge-list-item,
.archive-service1-badge22,
.archive-service1-badge23 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.archive-service1-badge23 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  justify-content: flex-start;
  padding: 0 7px;
  z-index: 2;
}

.archive-service1-badge-list-item {
  align-self: stretch;
  justify-content: flex-end;
  padding: 0 2px 0 0;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.archive-service1-location-item,
.archive-service1-work-calendar {
  display: flex;
  justify-content: flex-start;
}

.archive-service1-location-item {
  width: 163px;
  flex-direction: column;
  align-items: flex-end;
  gap: 56px;
}

.archive-service1-work-calendar {
  flex-direction: row;
  align-items: flex-start;
  padding: 0 14px;
}

.archive-service1-work-type-value {
  width: 149px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
}

.archive-service1-badge24,
.archive-service1-buttonsbutton23,
.archive-service1-first-badge-details,
.archive-service1-work-item {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-badge24 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  padding: 0 7px;
  white-space: nowrap;
  z-index: 2;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.archive-service1-buttonsbutton23,
.archive-service1-first-badge-details,
.archive-service1-work-item {
  flex: 1;
}

.archive-service1-work-item {
  flex-direction: column;
  gap: 56px;
  min-width: 107px;
}

.archive-service1-buttonsbutton23,
.archive-service1-first-badge-details {
  flex-direction: row;
  gap: 5.5px;
}

.archive-service1-first-badge-details {
  max-width: 100%;
}

.archive-service1-buttonsbutton23 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  padding: 6px 11px;
  min-width: 86px;
  z-index: 2;
}

.archive-service1-buttonsbutton-group {
  width: 385.4px;
  flex-direction: row;
  gap: 16px;
  font-size: 14px;
  color: #344054;
}

.archive-service1-buttonsbutton-group,
.archive-service1-second-badge-divider,
.archive-service1-second-badge-list-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-second-badge-list-parent {
  flex: 1;
  flex-direction: column;
  gap: 47px;
}

.archive-service1-second-badge-divider {
  align-self: stretch;
  flex-direction: row;
  position: relative;
}

.archive-service1-supporting-text15 {
  position: relative;
  letter-spacing: -0.06px;
  line-height: 24px;
  z-index: 2;
}

.archive-service1-supporting-text17 {
  position: relative;
  font-size: 12px;
  line-height: 19px;
  color: #475467;
  display: inline-block;
  min-width: 104px;
  z-index: 3;
}

.archive-service1-summary-content-parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  min-width: 114px;
}

.archive-service1-price-details-title {
  align-self: stretch;
  position: relative;
  line-height: 23px;
  font-weight: 500;
  z-index: 2;
}

.archive-service1-supporting-text18 {
  width: 104px;
  position: relative;
  font-size: 12px;
  line-height: 19px;
  color: #475467;
  display: inline-block;
  z-index: 3;
}

.archive-service1-summary-location-name {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 1px;
  font-size: 14px;
  color: #101828;
}

.archive-service1-location-summary,
.archive-service1-summary-details,
.archive-service1-summary-location-name {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-location-summary {
  width: 152px;
  flex-direction: column;
  gap: 58px;
  min-width: 152px;
}

.archive-service1-summary-details {
  flex: 1;
  flex-direction: row;
  gap: 8px;
  min-width: 218px;
  max-width: 100%;
}

.archive-service1-third-badge-details {
  width: 490px;
  flex-direction: row;
  padding: 0 13px;
  box-sizing: border-box;
  max-width: 100%;
}

.archive-service1-badge25,
.archive-service1-frame-parent5,
.archive-service1-third-badge-details {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-frame-parent5 {
  flex: 1;
  flex-direction: column;
  gap: 107px;
  max-width: 100%;
  font-size: 16px;
  color: #475467;
}

.archive-service1-badge25 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  padding: 0 7px;
  z-index: 2;
}

.archive-service1-first-badges {
  align-self: stretch;
  flex-direction: column;
  gap: 282px;
}

.archive-service1-expanded-content,
.archive-service1-expanded-listing,
.archive-service1-first-badges,
.archive-service1-work-type-badge-list {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-work-type-badge-list {
  width: 93px;
  flex-direction: column;
  padding: 80px 0 0;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.archive-service1-expanded-content,
.archive-service1-expanded-listing {
  max-width: 100%;
}

.archive-service1-expanded-content {
  align-self: stretch;
  flex-direction: row;
  gap: 8px;
}

.archive-service1-expanded-listing {
  flex: 1;
  flex-direction: column;
  padding: 8px 33px 0 0;
  box-sizing: border-box;
  min-width: 422px;
  font-size: 18px;
}

.archive-service1-arrow-square-up-right-icon1 {
  height: 24px;
  width: 24px;
  top: 167px;
  right: 0;
}

.archive-service1-arrow-square-up-right-icon1,
.archive-service1-arrow-square-up-right-icon2,
.archive-service1-dots-vertical-icon3,
.archive-service1-dots-vertical-icon4 {
  position: absolute;
  margin: 0 !important;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 2;
}

.archive-service1-arrow-square-up-right-icon2 {
  height: 24px;
  width: 24px;
  right: 0;
  bottom: 206px;
}

.archive-service1-dots-vertical-icon3,
.archive-service1-dots-vertical-icon4 {
  height: 20px;
  width: 20px;
  right: -10px;
}

.archive-service1-dots-vertical-icon3 {
  top: 260px;
}

.archive-service1-dots-vertical-icon4 {
  bottom: 117px;
}

.archive-service1-price4 {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 32px;
  font-weight: 600;
  white-space: nowrap;
  z-index: 2;
}

.archive-service1-radar-1-1-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  z-index: 2;
  cursor: pointer;
}

.archive-service1-text81 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 29px;
  z-index: 3;
}

.archive-service1-text-wrapper3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 57px;
}

.archive-service1-price6 {
  align-self: stretch;
  position: relative;
  font-size: 20px;
  letter-spacing: -0.02em;
  line-height: 32px;
  font-weight: 600;
  color: #6941c6;
  white-space: nowrap;
  z-index: 2;
}

.archive-service1-frame-parent7 {
  align-self: stretch;
  flex-direction: column;
  font-size: 12px;
  color: #475467;
}

.archive-service1-arrow-square-up-right-parent,
.archive-service1-frame-parent6,
.archive-service1-frame-parent7 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-frame-parent6 {
  flex: 1;
  flex-direction: column;
  gap: 229px;
}

.archive-service1-arrow-square-up-right-parent {
  width: 143px;
  flex-direction: row;
  position: relative;
  text-align: center;
  font-size: 20px;
  color: #6941c6;
}

.archive-service1-collapsed-details {
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 5px;
}

.archive-service1-collapsed-content,
.archive-service1-collapsed-details,
.archive-service1-collapsed-listing {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-collapsed-content {
  position: relative;
}

.archive-service1-collapsed-listing {
  padding: 0 0 521px 24px;
  box-sizing: border-box;
  font-size: 14px;
}

.archive-service1-dots-vertical-icon5 {
  height: 20px;
  width: 20px;
  position: absolute;
  margin: 0 !important;
  top: 14px;
  right: 14px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.archive-service1-f4b711e503bc72fe396e5043e0c2-1-icon1 {
  height: 84px;
  width: 84px;
  position: absolute;
  margin: 0 !important;
  top: calc(50% - 42px);
  left: 62px;
  object-fit: cover;
  z-index: 2;
}

.archive-service1-business-image,
.archive-service1-text83 {
  position: relative;
  z-index: 1;
}

.archive-service1-business-image {
  height: 160px;
  width: 160px;
  border-radius: 12px;
  background-color: #f5f5f5;
}

.archive-service1-text83 {
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  min-width: 128px;
}

.archive-service1-text-wrapper4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 8px 0 0;
  font-size: 18px;
  color: #101828;
}

.archive-service1-icon9 {
  width: 12px;
  height: 9.3px;
  position: relative;
  z-index: 1;
}

.archive-service1-business-verified {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px 0 0;
}

.archive-service1-span {
  font-weight: 500;
}

.archive-service1-supporting-text19 {
  height: 19px;
  position: relative;
  line-height: 24px;
  display: inline-block;
  flex-shrink: 0;
  min-width: 63px;
  z-index: 1;
}

.archive-service1-business-role {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4px 16px 0 0;
}

.archive-service1-rating-child {
  width: 16px;
  height: 16px;
  position: relative;
  z-index: 1;
}

.archive-service1-supporting-text20 {
  height: 19px;
  position: relative;
  line-height: 24px;
  display: inline-block;
  flex-shrink: 0;
  min-width: 70.9px;
  z-index: 1;
}

.archive-service1-reviews {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4px 0 0;
}

.archive-service1-application-count,
.archive-service1-business-location,
.archive-service1-business-name-role {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.archive-service1-application-count,
.archive-service1-business-location {
  gap: 7px;
  font-size: 16px;
}

.archive-service1-application-count {
  gap: 8px;
  color: #fff;
}

.archive-service1-badge-one,
.archive-service1-badge-three,
.archive-service1-badge-two {
  position: relative;
  line-height: 23px;
  font-weight: 500;
  z-index: 1;
}

.archive-service1-badge-three,
.archive-service1-badge-two {
  display: inline-block;
}

.archive-service1-badge-two {
  min-width: 103px;
}

.archive-service1-badge-three {
  width: 151px;
  flex-shrink: 0;
}

.archive-service1-badges1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 34px;
}

.archive-service1-supporting-text22 {
  position: relative;
  line-height: 19px;
  display: inline-block;
  min-width: 92.1px;
  z-index: 2;
}

.archive-service1-badge-support {
  width: 161.9px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 20px 0 0;
  box-sizing: border-box;
}

.archive-service1-supporting-text23,
.archive-service1-supporting-text24 {
  position: relative;
  line-height: 19px;
  display: inline-block;
  flex-shrink: 0;
  z-index: 2;
}

.archive-service1-supporting-text23 {
  min-width: 70.9px;
}

.archive-service1-supporting-text24 {
  width: 104px;
}

.archive-service1-badge-support-parent {
  width: 469px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  max-width: 100%;
  font-size: 12px;
  color: #475467;
}

.archive-service1-badges-parent {
  align-self: stretch;
  flex-direction: column;
  font-size: 14px;
  color: #101828;
}

.archive-service1-badges-parent,
.archive-service1-business-details,
.archive-service1-business-listing {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-business-details {
  flex: 1;
  flex-direction: column;
  gap: 16px;
  min-width: 335px;
}

.archive-service1-business-listing {
  width: 700px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 24px;
}

.archive-service1-text84 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 55px;
}

.archive-service1-buttonsbutton25 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 6px 12px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  z-index: 1;
}

.archive-service1-business-actions,
.archive-service1-listing-card-my-radar-bus {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 60px 0 0;
}

.archive-service1-listing-card-my-radar-bus {
  width: 1016px;
  margin: 0 !important;
  position: absolute;
  right: -24px;
  bottom: 265px;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 40px 24px 24px;
  box-sizing: border-box;
  gap: 20px;
  z-index: 1;
  font-size: 12px;
  color: #475467;
}

.archive-service1-text85 {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: center;
  display: inline-block;
  min-width: 66px;
}

.archive-service1-content6 {
  cursor: pointer;
  border: 0;
  padding: 8px 12px;
  background-color: #0c111d;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  white-space: nowrap;
}

.archive-service1-content6:hover {
  background-color: #40454f;
}

.archive-service1-tooltip-icon {
  width: 28px;
  height: 6px;
  position: relative;
}

.archive-service1-tooltip {
  margin: 0 !important;
  position: absolute;
  top: 119px;
  right: 0;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0 4px 6px -2px rgba(16, 24, 40, 0.03);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  z-index: 3;
}

.archive-service1-arrow-square-up-right-icon3,
.archive-service1-arrow-square-up-right-icon4,
.archive-service1-arrow-square-up-right-icon5 {
  height: 24px;
  width: 24px;
  position: absolute;
  margin: 0 !important;
  right: 0;
  bottom: 511px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 2;
}

.archive-service1-arrow-square-up-right-icon4,
.archive-service1-arrow-square-up-right-icon5 {
  bottom: 298px;
  z-index: 3;
}

.archive-service1-arrow-square-up-right-icon5 {
  bottom: 66px;
  z-index: 4;
}

.archive-service1-list-header {
  width: 992px;
  position: relative;
  max-width: 100%;
}

.archive-service1-frame-parent11,
.archive-service1-group-div,
.archive-service1-list-header,
.archive-service1-text-parent4 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-text-parent4 {
  gap: 16px;
  font-size: 18px;
  color: #101828;
}

.archive-service1-frame-parent11,
.archive-service1-group-div {
  gap: 7.3px;
}

.archive-service1-group-div {
  width: 1016px;
  margin: 0 !important;
  position: absolute;
  bottom: 64px;
  left: calc(50% - 508px);
  justify-content: space-between;
  padding: 24px 40px 24px 24px;
  box-sizing: border-box;
  gap: 20px;
  z-index: 1;
}

.archive-service1-divider-icon10 {
  height: 1px;
  width: 100%;
  top: 175px;
  right: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
}

.archive-service1-divider-icon10,
.archive-service1-dots-vertical-icon7,
.archive-service1-text92 {
  position: absolute;
  margin: 0 !important;
  z-index: 2;
}

.archive-service1-text92 {
  top: -9px;
  left: 0;
  line-height: 28px;
  font-weight: 600;
}

.archive-service1-dots-vertical-icon7 {
  height: 20px;
  width: 20px;
  top: -19px;
  right: -10px;
  overflow: hidden;
  flex-shrink: 0;
}

.archive-service1-text93 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 36px;
  z-index: 2;
}

.archive-service1-dots-vertical-parent1,
.archive-service1-text-wrapper7 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-text-wrapper7 {
  flex: 1;
}

.archive-service1-dots-vertical-parent1 {
  width: 177px;
  position: relative;
}

.archive-service1-rectangle-textarea,
.archive-service1-text94 {
  position: absolute;
  margin: 0 !important;
}

.archive-service1-text94 {
  bottom: 132px;
  left: 184px;
  line-height: 28px;
  font-weight: 600;
  z-index: 2;
}

.archive-service1-rectangle-textarea {
  border: 1px solid #eaecf0;
  background-color: #fff;
  height: 301px;
  width: 1016px;
  outline: 0;
  top: -60px;
  right: -307px;
  border-radius: 12px;
  box-sizing: border-box;
  z-index: 1;
}

.archive-service1-supporting-text31 {
  position: relative;
  letter-spacing: -0.17px;
  line-height: 24px;
  display: inline-block;
  min-width: 83px;
  z-index: 2;
}

.archive-service1-price-label {
  position: relative;
  line-height: 23px;
  font-weight: 500;
  z-index: 2;
}

.archive-service1-supporting-text34 {
  position: relative;
  font-size: 12px;
  line-height: 19px;
  color: #475467;
  display: inline-block;
  min-width: 113px;
  z-index: 3;
}

.archive-service1-contact-details,
.archive-service1-detail-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.archive-service1-detail-item {
  align-items: flex-end;
  gap: 10px;
  max-width: 100%;
}

.archive-service1-contact-details {
  flex: 1;
  align-items: flex-start;
  gap: 8px;
  min-width: 104px;
}

.archive-service1-text95 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 70px;
}

.archive-service1-buttonsbutton27,
.archive-service1-buttonsbutton28,
.archive-service1-contact-actions {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-buttonsbutton27 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 6px 12px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  flex-direction: row;
  gap: 6px;
  z-index: 2;
}

.archive-service1-buttonsbutton28,
.archive-service1-contact-actions {
  flex-direction: column;
  padding: 6px 5px 0 0;
}

.archive-service1-buttonsbutton28 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow-x: auto;
  flex-direction: row;
  padding: 6px 11px;
  gap: 5.5px;
  z-index: 2;
}

.archive-service1-lock-action1 {
  flex-direction: column;
  padding: 6px 0 0;
  color: #344054;
}

.archive-service1-contact-details-parent,
.archive-service1-detail-item-parent,
.archive-service1-lock-action1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-contact-details-parent {
  align-self: stretch;
  flex-direction: row;
  gap: 11px;
  font-size: 14px;
  color: #101828;
}

.archive-service1-detail-item-parent {
  flex: 1;
  flex-direction: column;
  gap: 41px;
  min-width: 289px;
  max-width: 100%;
}

.archive-service1-supporting-text35 {
  width: 113px;
  position: relative;
  font-size: 12px;
  line-height: 19px;
  color: #475467;
  display: inline-block;
  z-index: 3;
}

.archive-service1-buttonsbutton-wrapper2,
.archive-service1-buttonsbutton29 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-buttonsbutton29 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow-x: auto;
  padding: 6px 10px 6px 12px;
  gap: 5.4px;
  z-index: 2;
}

.archive-service1-buttonsbutton-wrapper2 {
  padding: 0 8px;
  color: #344054;
}

.archive-service1-frame-parent22,
.archive-service1-second-listing-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.archive-service1-frame-parent22 {
  align-self: stretch;
  justify-content: flex-start;
  gap: 47px;
}

.archive-service1-second-listing-details {
  width: 233px;
  justify-content: flex-end;
  padding: 0 0 6px;
  box-sizing: border-box;
  min-width: 233px;
  font-size: 14px;
  color: #101828;
}

.archive-service1-listing-detail {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 7.3px;
  max-width: 100%;
  color: #fff;
}

.archive-service1-pair-container-parent,
.archive-service1-third-listing-details {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.archive-service1-pair-container-parent {
  justify-content: flex-start;
  gap: 8px;
  margin-left: -9px;
}

.archive-service1-third-listing-details {
  width: 538px;
  justify-content: flex-end;
  padding: 0 37px;
  box-sizing: border-box;
  max-width: 100%;
}

.archive-service1-frame-parent19,
.archive-service1-frame-wrapper11 {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-frame-parent19 {
  align-items: flex-end;
  gap: 108px;
  font-size: 16px;
  color: #475467;
}

.archive-service1-frame-wrapper11 {
  align-items: flex-start;
  padding: 7px 0 0;
  box-sizing: border-box;
  min-width: 445px;
}

.archive-service1-price7,
.archive-service1-total {
  position: relative;
  display: inline-block;
}

.archive-service1-price7 {
  flex: 1;
  letter-spacing: -0.02em;
  line-height: 32px;
  font-weight: 600;
  min-width: 93px;
  white-space: nowrap;
  z-index: 3;
}

.archive-service1-total {
  line-height: 20px;
  min-width: 15px;
  z-index: 2;
}

.archive-service1-price-parent2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 7.5px;
}

.archive-service1-key5 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 83px;
  z-index: 4;
}

.archive-service1-radar-1-1-parent3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  z-index: 2;
}

.archive-service1-pair-container-container,
.archive-service1-pair-container-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.archive-service1-pair-container-wrapper {
  justify-content: center;
  padding: 0 21px 0 20px;
  font-size: 12px;
  color: #475467;
}

.archive-service1-pair-container-container {
  justify-content: flex-start;
  padding: 0 30px;
}

.archive-service1-frame-parent24,
.archive-service1-pair-container-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.archive-service1-pair-container-group {
  width: 143px;
  align-items: flex-start;
  font-size: 12px;
  color: #475467;
}

.archive-service1-frame-parent24 {
  flex: 1;
  align-items: flex-end;
  gap: 253px;
}

.archive-service1-fourth-listing,
.archive-service1-frame-parent18 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.archive-service1-fourth-listing {
  width: 316px;
  justify-content: flex-start;
  position: relative;
  min-width: 316px;
  margin-left: -33px;
  text-align: center;
  font-size: 20px;
  color: #6941c6;
}

.archive-service1-frame-parent18 {
  align-self: stretch;
  justify-content: center;
  max-width: 100%;
  row-gap: 20px;
  text-align: left;
  font-size: 18px;
  color: #101828;
}

.archive-service1-frame-parent17 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
  text-align: center;
  font-size: 12px;
  color: #475467;
}

.archive-service1-text99 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 72px;
}

.archive-service1-buttonsbutton30 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  flex-direction: row;
  justify-content: flex-start;
  padding: 6px 12px;
  gap: 6px;
  z-index: 1;
}

.archive-service1-buttonsbutton30,
.archive-service1-expand-action,
.archive-service1-frame-parent16 {
  display: flex;
  align-items: flex-start;
}

.archive-service1-expand-action {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 20px;
  font-size: 14px;
  color: #344054;
}

.archive-service1-frame-parent16 {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: 280px;
  max-width: 100%;
  font-size: 18px;
  color: #101828;
}

.archive-service1-arrow-square-up-right-icon6,
.archive-service1-arrow-square-up-right-icon7,
.archive-service1-arrow-square-up-right-icon8 {
  height: 24px;
  width: 24px;
  position: absolute;
  margin: 0 !important;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 2;
}

.archive-service1-arrow-square-up-right-icon6 {
  top: 206px;
  right: 0;
}

.archive-service1-arrow-square-up-right-icon7,
.archive-service1-arrow-square-up-right-icon8 {
  right: -3px;
  bottom: 310px;
}

.archive-service1-arrow-square-up-right-icon8 {
  bottom: 97px;
  z-index: 3;
}

.archive-service1-magnifier-1-icon8 {
  height: 20px;
  width: 20px;
  position: absolute;
  margin: 0 !important;
  top: 75px;
  right: 93px;
  object-fit: cover;
  z-index: 2;
}

.archive-service1-listing-wrapper-inner {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 25px 0 24px;
  box-sizing: border-box;
  max-width: 100%;
  font-size: 12px;
  color: #475467;
}

.archive-service1-text100,
.archive-service1-text101 {
  position: relative;
  font-family: Inter;
}

.archive-service1-text100 {
  margin: 0;
  font-size: 26px;
  line-height: 28px;
  font-weight: 600;
  color: #101828;
  text-align: left;
  display: inline-block;
  min-width: 128px;
}

.archive-service1-text101 {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #6941c6;
  text-align: center;
}

.archive-service1-badge27,
.archive-service1-text-and-badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.archive-service1-badge27 {
  border-radius: 9999px;
  background-color: #f9f5ff;
  border: 1px solid #e9d7fe;
  padding: 0 7px;
  white-space: nowrap;
}

.archive-service1-text-and-badge {
  align-self: stretch;
  padding: 0 359px 0 0;
  gap: 8px;
}

.archive-service1-supporting-text40 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.archive-service1-text-and-supporting-text1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
  min-width: 424px;
  max-width: 100%;
}

.archive-service1-text104 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 45px;
}

.archive-service1-button5 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 13px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.archive-service1-text105 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 92px;
}

.archive-service1-button6 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 13px;
  background-color: #7e56d9;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.archive-service1-dots-vertical-icon9 {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.archive-service1-content7 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 20px 24px 0;
  box-sizing: border-box;
  gap: 16px;
}

.archive-service1-card-header,
.archive-service1-content7,
.archive-service1-divider-icon11 {
  align-self: stretch;
  max-width: 100%;
}

.archive-service1-divider-icon11 {
  position: relative;
  overflow: hidden;
  max-height: 100%;
}

.archive-service1-card-header {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  z-index: 1;
}

.archive-service1-text106 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 51px;
}

.archive-service1-button-group-base9 {
  width: 83px;
  background-color: #f9fafb;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  min-height: 40px;
}

.archive-service1-text107 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 65px;
}

.archive-service1-button-group-base10,
.archive-service1-button-group-base16 {
  background-color: #fff;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 40px;
}

.archive-service1-button-group-base10 {
  cursor: pointer;
  border: 0;
  padding: 10px 15px;
  width: 97px;
  flex-shrink: 0;
  display: flex;
}

.archive-service1-button-group-base10:hover {
  background-color: #e6e6e6;
}

.archive-service1-button-group-base16 {
  align-self: stretch;
  display: none;
  padding: 8px 16px;
  z-index: 1;
}

.archive-service1-text114 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 61px;
}

.archive-service1-button-group-base17 {
  width: 93px;
  background-color: #fff;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  min-height: 40px;
}

.archive-service1-button-group1 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-label3,
.archive-service1-supporting-text41,
.archive-service1-text115 {
  position: relative;
  font-family: Inter;
  text-align: left;
}

.archive-service1-label3 {
  width: 47px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #344054;
  display: none;
}

.archive-service1-supporting-text41,
.archive-service1-text115 {
  font-size: 16px;
  line-height: 24px;
}

.archive-service1-text115 {
  width: calc(100% - 228px);
  border: 0;
  outline: 0;
  font-weight: 500;
  background-color: transparent;
  height: 24px;
  flex: 1;
  color: #101828;
  display: inline-block;
  min-width: 82px;
  padding: 0;
}

.archive-service1-supporting-text41 {
  align-self: stretch;
  width: 54px;
  color: #475467;
  display: none;
}

.archive-service1-content9,
.archive-service1-input-with-label2,
.archive-service1-input2 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-content9 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0 208px 0 0;
  box-sizing: border-box;
  gap: 8px;
}

.archive-service1-input-with-label2,
.archive-service1-input2 {
  align-self: stretch;
}

.archive-service1-input2 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05),
    0 0 0 4px rgba(158, 119, 237, 0.24);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d6bbfb;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
}

.archive-service1-input-with-label2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.archive-service1-hint-text2 {
  width: 320px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: none;
}

.archive-service1-input-dropdown2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  max-width: 400px;
}

.archive-service1-text116 {
  text-decoration: none;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 43px;
}

.archive-service1-actions2,
.archive-service1-button7,
.archive-service1-content8 {
  display: flex;
  flex-direction: row;
}

.archive-service1-button7 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 10px 13px;
  background-color: #fff;
  width: 99px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.archive-service1-actions2,
.archive-service1-content8 {
  max-width: 100%;
}

.archive-service1-actions2 {
  width: 511px;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.archive-service1-content8 {
  align-self: stretch;
  border-radius: 12px;
  align-items: flex-start;
  justify-content: space-between;
  padding: 12px 16px;
  box-sizing: border-box;
  gap: 20px;
}

.archive-service1-filters-bar {
  background-color: #fff;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  z-index: 2;
}

.archive-service1-card-header-parent,
.archive-service1-filters-bar {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-text117,
.archive-service1-text118 {
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  font-family: Inter;
  color: #101828;
  text-align: left;
}

.archive-service1-text118 {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #6941c6;
  text-align: center;
  display: inline-block;
  min-width: 56px;
}

.archive-service1-badge28,
.archive-service1-text-and-badge1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.archive-service1-badge28 {
  width: 72px;
  border-radius: 9999px;
  background-color: #f9f5ff;
  border: 1px solid #e9d7fe;
  box-sizing: border-box;
  padding: 0 7px;
  white-space: nowrap;
}

.archive-service1-text-and-badge1 {
  align-self: stretch;
  padding: 0 666px 0 0;
  gap: 8px;
}

.archive-service1-supporting-text42 {
  width: 697px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.archive-service1-button8,
.archive-service1-button9,
.archive-service1-text-and-supporting-text2 {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  gap: 4px;
}

.archive-service1-text-and-supporting-text2 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 0;
  max-width: 100%;
}

.archive-service1-button8,
.archive-service1-button9 {
  height: 40px;
  border-radius: 8px;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
}

.archive-service1-button9 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  background-color: #fff;
  border: 1px solid #d6bbfb;
  padding: 10px 13px;
}

.archive-service1-text121 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
}

.archive-service1-button10 {
  height: 40px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 13px;
  gap: 4px;
}

.archive-service1-text122 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
}

.archive-service1-button11 {
  height: 40px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #7e56d9;
  border: 1px solid #7e56d9;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 13px;
  gap: 4px;
}

.archive-service1-actions3,
.archive-service1-card-header1 {
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-actions3 {
  display: none;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.archive-service1-card-header1 {
  align-self: stretch;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex-shrink: 0;
}

.archive-service1-checkbox-base {
  margin: 0;
  height: 20px;
  width: 20px;
  position: relative;
  border-radius: 6px;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
}

.archive-service1-text123 {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 34px;
}

.archive-service1-arrow-down-icon {
  width: 16px;
  height: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.archive-service1-table-header-cell,
.archive-service1-table-header-cell-inner,
.archive-service1-text-parent9 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-text-parent9 {
  flex-direction: row;
  gap: 4px;
}

.archive-service1-table-header-cell,
.archive-service1-table-header-cell-inner {
  flex-direction: column;
  padding: 1px 0 0;
}

.archive-service1-table-header-cell {
  width: 441px;
  margin: 0 !important;
  position: absolute;
  top: 0;
  left: -380px;
  background-color: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  flex-direction: row;
  padding: 12px 24px 10px;
  gap: 12px;
}

.archive-service1-checkbox-base1 {
  margin: 0;
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: 6px;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
}

.archive-service1-checkbox-base-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 0 0;
}

.archive-service1-avatar-online-indicator,
.archive-service1-contrast-border1 {
  position: absolute;
  border-radius: 9999px;
  box-sizing: border-box;
  overflow: hidden;
}

.archive-service1-contrast-border1 {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  display: none;
}

.archive-service1-avatar-online-indicator {
  right: -1.5px;
  bottom: -1.5px;
  background-color: #17b26a;
  border: 1.5px solid #fff;
  width: 13px;
  height: 13px;
  z-index: 1;
}

.archive-service1-avatar1 {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 9999px;
  background-image: url("../../../icons/Gerator/archive_page/avatar1@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.archive-service1-supporting-text43,
.archive-service1-text124 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  text-align: left;
  display: inline-block;
}

.archive-service1-text124 {
  font-weight: 500;
  color: #101828;
  min-width: 75px;
}

.archive-service1-supporting-text43 {
  color: #475467;
  min-width: 102px;
  white-space: nowrap;
}

.archive-service1-table-cell {
  width: 405px;
  margin: 0 !important;
  position: absolute;
  top: 44px;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.archive-service1-text125 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #101828;
  text-align: left;
  display: inline-block;
  min-width: 96px;
}

.archive-service1-table-cell1 {
  width: 467px;
  margin: 0 !important;
  position: absolute;
  top: 116px;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.archive-service1-text126 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #101828;
  text-align: left;
  display: inline-block;
  min-width: 84px;
}

.archive-service1-table-cell2 {
  width: 428px;
  margin: 0 !important;
  position: absolute;
  top: 188px;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.archive-service1-supporting-text46,
.archive-service1-text127 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  text-align: left;
  display: inline-block;
}

.archive-service1-text127 {
  font-weight: 500;
  color: #101828;
  min-width: 103px;
  white-space: nowrap;
}

.archive-service1-supporting-text46 {
  color: #475467;
  min-width: 102px;
}

.archive-service1-table-cell3 {
  width: 411px;
  margin: 0 !important;
  position: absolute;
  top: 260px;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.archive-service1-text128 {
  flex: 1;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #667085;
  text-align: center;
  z-index: 1;
}

.archive-service1-avatar5,
.archive-service1-contrast-border5 {
  border-radius: 9999px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-contrast-border5 {
  height: 40px;
  width: 40px;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  padding: 8px 0;
}

.archive-service1-avatar5 {
  background-color: #f2f4f7;
}

.archive-service1-text129 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #101828;
  text-align: left;
  display: inline-block;
  min-width: 82px;
}

.archive-service1-table-cell4 {
  width: 428px;
  margin: 0 !important;
  position: absolute;
  top: 332px;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.archive-service1-text130 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #101828;
  text-align: left;
  display: inline-block;
  min-width: 78px;
}

.archive-service1-table-cell5 {
  width: 428px;
  margin: 0 !important;
  position: absolute;
  bottom: 288px;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.archive-service1-text131 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #101828;
  text-align: left;
  display: inline-block;
  min-width: 74px;
}

.archive-service1-table-cell6,
.archive-service1-table-cell7 {
  width: 411px;
  margin: 0 !important;
  position: absolute;
  bottom: 216px;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.archive-service1-table-cell7 {
  width: 428px;
  bottom: 144px;
}

.archive-service1-text134 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #101828;
  text-align: left;
  display: inline-block;
  min-width: 67px;
}

.archive-service1-table-cell8 {
  width: 428px;
  margin: 0 !important;
  position: absolute;
  bottom: 72px;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.archive-service1-text135 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #101828;
  text-align: left;
  display: inline-block;
  min-width: 95px;
}

.archive-service1-table-cell9 {
  width: 428px;
  margin: 0 !important;
  position: absolute;
  bottom: 0;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
  z-index: 1;
}

.archive-service1-text136 {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 50px;
}

.archive-service1-table-header,
.archive-service1-table-header-cell1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.archive-service1-table-header {
  gap: 4px;
}

.archive-service1-table-header-cell1 {
  width: 255px;
  background-color: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 13px 24px 11px;
}

.archive-service1-text137 {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Inter;
  color: #344054;
  text-align: center;
  display: inline-block;
  min-width: 93px;
}

.archive-service1-table-cell10 {
  width: 255px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 26px 24px 24px;
}

.archive-service1-text138 {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Inter;
  color: #344054;
  text-align: center;
  display: inline-block;
  min-width: 85px;
}

.archive-service1-marker-pin-02-group,
.archive-service1-table-cell11 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.archive-service1-marker-pin-02-group {
  gap: 7px;
}

.archive-service1-table-cell11 {
  width: 247px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 26px 24px 24px;
}

.archive-service1-text139 {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Inter;
  color: #344054;
  text-align: center;
  display: inline-block;
  min-width: 91px;
}

.archive-service1-table-cell15,
.archive-service1-table-cell16,
.archive-service1-table-cell18 {
  width: 279px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 26px 24px 24px;
}

.archive-service1-table-cell16,
.archive-service1-table-cell18 {
  width: 271px;
}

.archive-service1-table-cell18 {
  width: 239px;
}

.archive-service1-column,
.archive-service1-table-header-cell-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-column {
  width: 120px;
  flex-direction: column;
  padding: 0;
  box-sizing: border-box;
  z-index: 2;
}

.archive-service1-table-header-cell-parent {
  flex-direction: row;
  position: relative;
}

.archive-service1-frame-wrapper12 {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-text147 {
  width: calc(100% - 16px);
  border: 0;
  outline: 0;
  font-weight: 500;
  font-family: Inter;
  font-size: 12px;
  background-color: transparent;
  height: 18px;
  flex: 1;
  position: relative;
  line-height: 18px;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 41px;
  padding: 0;
}

.archive-service1-help-icon {
  height: 16px;
  width: 16px;
  position: relative;
}

.archive-service1-table-header-cell2,
.archive-service1-table-header1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.archive-service1-table-header1 {
  width: 88px;
  gap: 4px;
}

.archive-service1-table-header-cell2 {
  width: 297px;
  background-color: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 13px 24px 11px;
}

.archive-service1-text148 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  white-space: nowrap;
}

.archive-service1-table-cell20,
.archive-service1-table-cell21 {
  width: 297px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 26px 24px 24px;
}

.archive-service1-table-cell21 {
  width: 407px;
  max-width: 232%;
  flex-shrink: 0;
}

.archive-service1-text153 {
  width: 172px;
  border: 0;
  outline: 0;
  font-family: Inter;
  font-size: 14px;
  background-color: transparent;
  height: 20px;
  position: relative;
  line-height: 20px;
  color: #475467;
  text-align: left;
  display: inline-block;
  white-space: nowrap;
  padding: 0;
}

.archive-service1-table-cell25 {
  width: 328px;
  border-bottom: 1px solid #eaecf0;
  flex-direction: row;
  align-items: center;
  padding: 26px 24px 24px;
  max-width: 187%;
  flex-shrink: 0;
}

.archive-service1-column1,
.archive-service1-table-cell25,
.archive-service1-table-cell29 {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
}

.archive-service1-table-cell29 {
  width: 287px;
  border-bottom: 1px solid #eaecf0;
  flex-direction: row;
  align-items: center;
  padding: 26px 24px 24px;
}

.archive-service1-column1 {
  width: 176px;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  z-index: 1;
}

.archive-service1-text158 {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 37px;
}

.archive-service1-table-cell30,
.archive-service1-table-header-cell3,
.archive-service1-table-header2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.archive-service1-table-header2 {
  flex: 1;
  justify-content: flex-start;
  gap: 4px;
}

.archive-service1-table-cell30,
.archive-service1-table-header-cell3 {
  border-bottom: 1px solid #eaecf0;
  justify-content: center;
}

.archive-service1-table-header-cell3 {
  cursor: pointer;
  border: 0;
  padding: 13px 41px 11px;
  background-color: #f9fafb;
  align-self: stretch;
}

.archive-service1-table-cell30 {
  padding: 26px 50px 24px;
}

.archive-service1-column2,
.archive-service1-dropdown6 {
  width: 21px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-column2 {
  width: 120px;
  align-items: flex-end;
  padding: 0;
  box-sizing: border-box;
  z-index: 1;
}

.archive-service1-frame-parent26 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 100%;
  gap: 20px;
}

.archive-service1-text159 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 59px;
}

.archive-service1-buttonsbutton31 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 6px 11px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.archive-service1-button-wrap,
.archive-service1-button-wrap-wrapper {
  display: flex;
  justify-content: flex-start;
}

.archive-service1-button-wrap {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
}

.archive-service1-button-wrap-wrapper {
  width: 326px;
  flex-direction: column;
  align-items: flex-start;
  padding: 2px 0 0;
  box-sizing: border-box;
  max-width: 100%;
}

.archive-service1-number {
  width: 7px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #18222f;
  text-align: center;
  display: inline-block;
  min-width: 7px;
}

.archive-service1-content11,
.archive-service1-pagination-number-base {
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-content11 {
  flex: 1;
  padding: 10px 16px;
}

.archive-service1-pagination-number-base {
  width: 40px;
  background-color: #f9fafb;
  overflow: hidden;
  flex-shrink: 0;
}

.archive-service1-number1 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #475467;
  text-align: center;
  display: inline-block;
  min-width: 9px;
}

.archive-service1-content12,
.archive-service1-pagination-number-base1 {
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-content12 {
  flex: 1;
  padding: 10px 15px;
}

.archive-service1-pagination-number-base1 {
  width: 40px;
  overflow: hidden;
  flex-shrink: 0;
}

.archive-service1-number2 {
  width: 10px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #475467;
  text-align: center;
  display: inline-block;
  min-width: 10px;
}

.archive-service1-content13,
.archive-service1-pagination-number-base2 {
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-content13 {
  padding: 10px 15px;
}

.archive-service1-pagination-number-base2 {
  overflow: hidden;
}

.archive-service1-number3 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #475467;
  text-align: center;
  display: inline-block;
  min-width: 13px;
}

.archive-service1-content14 {
  flex: 1;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 13px;
}

.archive-service1-number6 {
  width: 16px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #475467;
  text-align: center;
  display: inline-block;
  min-width: 16px;
}

.archive-service1-content17,
.archive-service1-pagination-number-base-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-content17 {
  border-radius: 8px;
  flex-direction: row;
  padding: 10px 12px;
}

.archive-service1-pagination-number-base-wrapper {
  flex-direction: column;
  padding: 0 10px 0 0;
}

.archive-service1-text160 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 32px;
}

.archive-service1-button-wrap1,
.archive-service1-buttonsbutton32 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.archive-service1-buttonsbutton32 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 6px 11px;
  background-color: #fff;
  width: 84px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
  gap: 4px;
}

.archive-service1-button-wrap1 {
  align-self: stretch;
  justify-content: flex-end;
}

.archive-service1-next-page-button {
  flex: 1;
  flex-direction: column;
  padding: 2px 0 0;
  box-sizing: border-box;
  min-width: 212px;
}

.archive-service1-next-page-button,
.archive-service1-page-navigation,
.archive-service1-pagination,
.archive-service1-table {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-page-navigation {
  flex: 1;
  flex-direction: row;
  gap: 2px;
  min-width: 409px;
}

.archive-service1-pagination,
.archive-service1-table {
  align-self: stretch;
  box-sizing: border-box;
}

.archive-service1-pagination {
  border-top: 1px solid #eaecf0;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 12px 24px 16px;
  gap: 12px;
  z-index: 3;
}

.archive-service1-table {
  box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  overflow: hidden;
  flex-direction: column;
  z-index: 1;
}

.archive-service1-text161,
.archive-service1-text162 {
  position: relative;
  font-family: Inter;
  display: inline-block;
}

.archive-service1-text161 {
  text-decoration: none;
  font-size: 26px;
  line-height: 28px;
  font-weight: 600;
  color: #101828;
  text-align: left;
  min-width: 128px;
}

.archive-service1-text162 {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #6941c6;
  text-align: center;
  min-width: 73px;
}

.archive-service1-badge29,
.archive-service1-text-and-badge2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.archive-service1-badge29 {
  width: 89px;
  border-radius: 9999px;
  background-color: #f9f5ff;
  border: 1px solid #e9d7fe;
  box-sizing: border-box;
  padding: 0 7px;
  white-space: nowrap;
}

.archive-service1-text-and-badge2 {
  align-self: stretch;
  padding: 0 440px 0 0;
  gap: 8px;
}

.archive-service1-text-and-supporting-text3 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
  min-width: 432px;
  max-width: 100%;
}

.archive-service1-text166 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 79px;
}

.archive-service1-button-group-base18,
.archive-service1-button-group-base19 {
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  min-height: 40px;
}

.archive-service1-button-group-base18 {
  cursor: pointer;
  border: 0;
  background-color: #f9fafb;
  width: 85px;
  white-space: nowrap;
}

.archive-service1-button-group-base18:hover {
  background-color: #e0e0e0;
}

.archive-service1-button-group-base19 {
  background-color: #fff;
}

.archive-service1-text175 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 89px;
}

.archive-service1-button-group2 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 20px;
}

.archive-service1-text176 {
  width: calc(100% - 318px);
  border: 0;
  outline: 0;
  font-weight: 500;
  font-family: Inter;
  font-size: 16px;
  background-color: transparent;
  height: 24px;
  flex: 1;
  position: relative;
  line-height: 24px;
  color: #101828;
  text-align: left;
  display: inline-block;
  min-width: 28px;
  padding: 0;
}

.archive-service1-card-header-group,
.archive-service1-content20 {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-content20 {
  flex-direction: row;
  align-items: center;
  padding: 0 298px 0 0;
  box-sizing: border-box;
  gap: 8px;
}

.archive-service1-card-header-group {
  flex-direction: column;
  align-items: flex-start;
}

.archive-service1-featured-icon {
  width: 48px;
  height: 48px;
  position: relative;
  border-radius: 10px;
}

.archive-service1-supporting-text55,
.archive-service1-text178 {
  align-self: stretch;
  position: relative;
  font-family: Inter;
  text-align: center;
}

.archive-service1-text178 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #101828;
}

.archive-service1-supporting-text55 {
  font-size: 14px;
  line-height: 20px;
  color: #475467;
}

.archive-service1-content21,
.archive-service1-text-and-supporting-text4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.archive-service1-text-and-supporting-text4 {
  width: 352px;
  gap: 4px;
  max-width: 352px;
}

.archive-service1-content21 {
  align-self: stretch;
  padding: 0 20px;
  gap: 16px;
}

.archive-service1-text179 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 98px;
}

.archive-service1-buttonsbutton33 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 15px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.archive-service1-text180 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 94px;
}

.archive-service1-buttonsbutton34 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 15px;
  background-color: #7e56d9;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.archive-service1-actions6,
.archive-service1-empty-state1 {
  justify-content: flex-start;
}

.archive-service1-actions6 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
}

.archive-service1-empty-state1 {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.archive-service1-empty-state,
.archive-service1-empty-state1,
.archive-service1-frame-form {
  display: flex;
  max-width: 100%;
}

.archive-service1-empty-state {
  width: 100%;
  margin: 0 !important;
  position: absolute;
  right: 0;
  bottom: 151px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.archive-service1-frame-form {
  margin: 0;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 418px;
  box-sizing: border-box;
  position: relative;
  gap: 23.5px;
}

.archive-service1-bifocal-optometry-measurement-icon {
  width: 391px;
  position: relative;
  max-height: 100%;
  object-fit: cover;
  max-width: 100%;
}

.archive-service1-supporting-text56,
.archive-service1-text181 {
  align-self: stretch;
  position: relative;
}

.archive-service1-text181 {
  margin: 0;
  font-size: inherit;
  line-height: 30px;
  font-weight: 600;
  font-family: inherit;
}

.archive-service1-supporting-text56 {
  font-size: 16px;
  line-height: 24px;
  color: #475467;
}

.archive-service1-content22,
.archive-service1-text-and-supporting-text5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.archive-service1-text-and-supporting-text5 {
  width: 352px;
  gap: 8px;
  max-width: 352px;
}

.archive-service1-content22 {
  align-self: stretch;
  padding: 0 20px;
  box-sizing: border-box;
  gap: 20px;
  max-width: 100%;
}

.archive-service1-text183 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 88px;
}

.archive-service1-empty-state2,
.archive-service1-empty-state3 {
  display: flex;
  align-items: center;
  max-width: 100%;
}

.archive-service1-empty-state3 {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: 32px;
}

.archive-service1-empty-state2 {
  align-self: stretch;
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  padding: 41.5px 0;
  box-sizing: border-box;
  z-index: 1;
  text-align: center;
  font-size: 20px;
}

.archive-service1-listing-container,
.archive-service1-listing-wrapper {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}

.archive-service1-listing-wrapper {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
  text-align: left;
  font-size: 18px;
  color: #101828;
  font-family: Inter;
}

.archive-service1-listing-container {
  width: 1075px;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 29px;
  box-sizing: border-box;
  flex-shrink: 0;
}

.archive-service1-frame-child5 {
  height: 282px;
  width: 1016px;
  position: absolute;
  margin: 0 !important;
  right: -844px;
  bottom: -110px;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  z-index: 1;
}

.archive-service1-rectangle-icon {
  height: 148px;
  width: 148px;
  position: relative;
  border-radius: 12px;
  object-fit: cover;
  z-index: 2;
}

.archive-service1-rectangle-parent2 {
  top: 1098px;
  left: 418px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-frame-child6,
.archive-service1-rectangle-parent2,
.archive-service1-rectangle-parent3 {
  margin: 0 !important;
  position: absolute;
}

.archive-service1-frame-child6 {
  border: 1px solid #eaecf0;
  background-color: #fff;
  height: 282px;
  width: 1016px;
  outline: 0;
  right: -844px;
  bottom: -110px;
  border-radius: 12px;
  box-sizing: border-box;
  z-index: 1;
}

.archive-service1-rectangle-parent3 {
  top: 1404px;
  left: 418px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-dots-vertical-icon22,
.archive-service1-list-footer {
  position: absolute;
  margin: 0 !important;
  z-index: 2;
}

.archive-service1-list-footer {
  top: 1098px;
  right: 212px;
  line-height: 28px;
  font-weight: 600;
}

.archive-service1-dots-vertical-icon22 {
  width: 20px;
  height: 20px;
  top: 1088px;
  right: 44px;
  overflow: hidden;
  flex-shrink: 0;
}

.archive-service1-listing-footer-graphics-child {
  height: 208px;
  width: 1016px;
  position: absolute;
  margin: 0 !important;
  top: calc(50% - 104px);
  right: -870px;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  z-index: 1;
}

.archive-service1-f4b711e503bc72fe396e5043e0c2-7-icon {
  height: 84px;
  width: 84px;
  position: relative;
  object-fit: cover;
  z-index: 3;
}

.archive-service1-listing-footer-graphics {
  margin: 0 !important;
  position: absolute;
  top: 2769px;
  left: 456px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-footer-text {
  position: relative;
  line-height: 20px;
}

.archive-service1-footer-text-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.archive-service1-gerator-10-2-icon {
  height: 38px;
  width: 38px;
  position: relative;
  object-fit: cover;
}

.archive-service1-logomark1 {
  box-shadow: 0 1.3px 3.75px rgba(16, 24, 40, 0.1),
    0 1.3px 2.5px rgba(16, 24, 40, 0.06);
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-frame-parent27,
.archive-service1-logomark,
.archive-service1-logomark1 {
  display: flex;
  flex-direction: row;
}

.archive-service1-logomark {
  filter: drop-shadow(0 1.3px 3.75px rgba(16, 24, 40, 0.1)) drop-shadow(0 1.3px 2.5px rgba(16, 24, 40, 0.06));
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-frame-parent27 {
  align-items: center;
  justify-content: center;
  gap: 29px;
}

.archive-service1-content23,
.archive-service1-logo1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-content23 {
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: center;
  gap: 20px;
}

.archive-service1-container1,
.archive-service1-footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.archive-service1-container1 {
  width: 100%;
  align-items: flex-start;
  padding: 0 32px;
  box-sizing: border-box;
  max-width: 1280px;
}

.archive-service1-footer {
  align-self: stretch;
  background-color: #fff;
  overflow: hidden;
  align-items: center;
  padding: 48px 80px;
  z-index: 1;
  text-align: left;
  font-size: 16px;
  color: #475467;
  font-family: Inter;
}

.archive-service1-divider-icon14 {
  height: 1488px;
  width: 2px;
  position: absolute;
  margin: 0 !important;
  top: -11px;
  right: 0;
  object-fit: contain;
  z-index: 4;
}

.archive-service1-frame-child8 {
  width: 338px;
  height: 1488px;
  position: relative;
  background-color: #fff;
  display: none;
  max-width: 100%;
  z-index: 0;
}

.archive-service1-frame-child9 {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 1px;
  bottom: 1px;
  left: 0;
  background-color: #fff;
  z-index: 1;
}

.archive-service1-dot-icon10,
.archive-service1-dot-icon11,
.archive-service1-dot-icon7,
.archive-service1-dot-icon8,
.archive-service1-dot-icon9 {
  width: 10px;
  height: 10px;
  position: relative;
  display: none;
  z-index: 2;
}

.archive-service1-dot-icon10,
.archive-service1-dot-icon11,
.archive-service1-dot-icon8,
.archive-service1-dot-icon9 {
  z-index: 3;
}

.archive-service1-dot-icon10,
.archive-service1-dot-icon11,
.archive-service1-dot-icon9 {
  z-index: 4;
}

.archive-service1-dot-icon10,
.archive-service1-dot-icon11 {
  z-index: 5;
}

.archive-service1-dot-icon11 {
  z-index: 6;
}

.archive-service1-dot-icon12 {
  width: 10px;
  height: 10px;
  z-index: 7;
}

.archive-service1-dot-icon12,
.archive-service1-dot-icon13,
.archive-service1-featured-icon1 {
  position: relative;
  display: none;
}

.archive-service1-dot-icon13 {
  width: 10px;
  height: 10px;
  z-index: 8;
}

.archive-service1-featured-icon1 {
  height: 40px;
  width: 40px;
  border-radius: 8px;
}

.archive-service1-supporting-text57,
.archive-service1-text184 {
  align-self: stretch;
  position: relative;
}

.archive-service1-text184 {
  text-decoration: none;
  line-height: 28px;
  font-weight: 600;
  color: inherit;
}

.archive-service1-supporting-text57 {
  font-size: 14px;
  line-height: 20px;
  color: #475467;
}

.archive-service1-text-and-supporting-text6 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  max-width: 100%;
}

.archive-service1-button-close-x,
.archive-service1-content24 {
  display: flex;
  flex-direction: row;
}

.archive-service1-content24 {
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 24px 0 0;
  box-sizing: border-box;
  gap: 16px;
  max-width: 100%;
}

.archive-service1-button-close-x {
  margin: 0 !important;
  position: absolute;
  top: 12px;
  right: 16px;
  border-radius: 8px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 10px;
  z-index: 1;
}

.archive-service1-slide-out-menu-header {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 16px 0 24px;
  box-sizing: border-box;
  position: relative;
  gap: 8px;
  max-width: 100%;
  z-index: 2;
}

.archive-service1-slide-out-title {
  text-decoration: none;
  position: relative;
  line-height: 24px;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  min-width: 35px;
  z-index: 2;
}

.archive-service1-slide-out-title-parent {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}

.archive-service1-frame-wrapper13,
.archive-service1-slide-out-menu-header-parent {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-frame-wrapper13 {
  flex-direction: row;
  padding: 0 33px 0 24px;
  font-size: 14px;
  color: #344054;
}

.archive-service1-slide-out-menu-header-parent {
  flex-direction: column;
  gap: 24px;
  max-width: 100%;
}

.archive-service1-background,
.archive-service1-progress-line {
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  bottom: 0;
  border-radius: 9999px;
}

.archive-service1-background {
  width: 305px;
  right: -56.2px;
  background-color: #eaecf0;
  z-index: 2;
}

.archive-service1-progress-line {
  width: 100%;
  right: -0.2px;
  background-color: #7e56d9;
}

.archive-service1-text185 {
  position: relative;
  line-height: 18px;
  font-weight: 600;
  display: inline-block;
  min-width: 9px;
}

.archive-service1-content25,
.archive-service1-tooltip1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-content25 {
  flex: 1;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  padding: 6px 11px;
}

.archive-service1-tooltip1 {
  margin-top: -42px;
  margin-left: -4.5px;
  width: 33px;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0 4px 6px -2px rgba(16, 24, 40, 0.03);
  flex-shrink: 0;
}

.archive-service1-control-handle,
.archive-service1-handle {
  height: 24px;
  width: 24px;
  box-sizing: border-box;
  flex-shrink: 0;
}

.archive-service1-handle {
  position: relative;
  box-shadow: 0 4px 8px -2px rgba(16, 24, 40, 0.1),
    0 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 9999px;
  background-color: #fff;
  border: 2px solid #7e56d9;
  overflow: hidden;
}

.archive-service1-control-handle {
  margin-top: -8px;
  border-radius: 12px;
  border: 1.5px solid #7e56d9;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  z-index: 1;
}

.archive-service1-text186 {
  position: relative;
  line-height: 18px;
  font-weight: 600;
  display: inline-block;
  min-width: 80px;
}

.archive-service1-content26 {
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  padding: 6px 11px;
}

.archive-service1-background-parent,
.archive-service1-content26,
.archive-service1-progress,
.archive-service1-tooltip2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-tooltip2 {
  margin-top: -42px;
  margin-left: -40px;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0 4px 6px -2px rgba(16, 24, 40, 0.03);
  flex-shrink: 0;
}

.archive-service1-background-parent,
.archive-service1-progress {
  position: relative;
}

.archive-service1-progress {
  height: 8px;
  width: 228.8px;
  gap: 193px;
  z-index: 3;
}

.archive-service1-background-parent {
  padding: 0 20px 0 0;
}

.archive-service1-label5 {
  width: 36px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.archive-service1-text187 {
  flex: 1;
  position: relative;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.archive-service1-content27 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.archive-service1-help-icon1 {
  height: 16px;
  width: 16px;
  position: relative;
  display: none;
}

.archive-service1-input4 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 30px;
  gap: 8px;
  font-size: 16px;
  color: #101828;
}

.archive-service1-input-field,
.archive-service1-input-with-label4 {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.archive-service1-input-with-label4 {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-input-field {
  flex: 1;
  align-items: center;
  justify-content: center;
}

.archive-service1-text188 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
  display: inline-block;
  min-width: 8px;
}

.archive-service1-input-fields {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  z-index: 2;
}

.archive-service1-input6 {
  margin: 0;
  height: 18px;
  width: 16px;
}

.archive-service1-checkboxes {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 8px;
  gap: 12px;
  z-index: 2;
}

.archive-service1-input-fields-parent {
  align-self: stretch;
  gap: 24px;
  text-align: left;
  font-size: 14px;
}

.archive-service1-frame-parent31,
.archive-service1-frame-wrapper14,
.archive-service1-input-fields-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-frame-parent31 {
  align-self: stretch;
  gap: 32px;
}

.archive-service1-frame-wrapper14 {
  flex: 1;
  padding: 9px 0 0;
}

.archive-service1-frame-child10 {
  height: 163px;
  width: 5px;
  position: relative;
  border-radius: 8px;
  background-color: #dadada;
  z-index: 2;
}

.archive-service1-frame-parent30 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 24px;
  gap: 28px;
  text-align: center;
  font-size: 12px;
}

.archive-service1-divider-icon15 {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 2;
}

.archive-service1-text194 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 83px;
}

.archive-service1-content-parent,
.archive-service1-content29 {
  flex-direction: row;
  align-items: center;
}

.archive-service1-content29 {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  gap: 12px;
}

.archive-service1-content-parent {
  gap: 42px;
}

.archive-service1-content-parent,
.archive-service1-input10,
.archive-service1-section {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}

.archive-service1-section {
  flex-direction: column;
  align-items: flex-start;
  z-index: 2;
}

.archive-service1-input10 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
  gap: 8px;
  font-size: 16px;
  color: #101828;
}

.archive-service1-input-field2,
.archive-service1-section-parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
}

.archive-service1-section-parent {
  gap: 8px;
}

.archive-service1-text198 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 71px;
}

.archive-service1-checkboxes1 {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 0 8px;
  gap: 12px;
}

.archive-service1-checkboxes1,
.archive-service1-section-wrapper,
.archive-service1-section1 {
  display: flex;
  align-items: flex-start;
}

.archive-service1-section1 {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
  z-index: 2;
}

.archive-service1-section-wrapper {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 33px 0 24px;
}

.archive-service1-text203,
.archive-service1-text207 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 116px;
}

.archive-service1-text207 {
  min-width: 44px;
}

.archive-service1-section3 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 2;
}

.archive-service1-text208,
.archive-service1-text209 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 62px;
}

.archive-service1-text209 {
  min-width: 59px;
}

.archive-service1-search-lg,
.archive-service1-text210 {
  position: relative;
  z-index: 2;
}

.archive-service1-search-lg {
  cursor: pointer;
  margin: 0;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.archive-service1-text210 {
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 55px;
}

.archive-service1-content37,
.archive-service1-input-fields2,
.archive-service1-input19 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.archive-service1-content37 {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 24px;
  flex: 1;
  justify-content: flex-start;
  font-family: Inter;
  font-size: 16px;
  color: #9ea1a8;
  min-width: 166px;
}

.archive-service1-input-fields2,
.archive-service1-input19 {
  align-self: stretch;
}

.archive-service1-input19 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  justify-content: flex-start;
  padding: 8px 13px;
  gap: 8px;
}

.archive-service1-input-fields2 {
  justify-content: center;
  z-index: 2;
}

.archive-service1-divider-icon22 {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 2;
}

.archive-service1-divider-icon23 {
  height: 0.2px;
  width: 338px;
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: 0;
  z-index: 3;
}

.archive-service1-text211 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 112px;
  z-index: 2;
}

.archive-service1-frame-wrapper17 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 24px;
}

.archive-service1-text212 {
  display: inline-block;
  min-width: 76px;
}

.archive-service1-text212,
.archive-service1-text213 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  z-index: 2;
}

.archive-service1-frame-parent37 {
  flex-direction: row;
  gap: 11px;
}

.archive-service1-frame-parent36,
.archive-service1-frame-parent37,
.archive-service1-frame-wrapper19 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-frame-wrapper19 {
  flex-direction: row;
  padding: 0 24px;
}

.archive-service1-frame-parent36 {
  align-self: stretch;
  flex-direction: column;
  gap: 15.3px;
  max-width: 100%;
}

.archive-service1-divider-icon31 {
  width: 338px;
  position: relative;
  max-height: 100%;
  max-width: 100%;
  z-index: 3;
}

.archive-service1-text216 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 118px;
  z-index: 2;
}

.archive-service1-frame-parent28,
.archive-service1-frame-parent29 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-frame-parent29 {
  align-items: flex-end;
  gap: 15.6px;
  font-size: 14px;
  color: #344054;
}

.archive-service1-frame-parent28 {
  align-items: flex-start;
  padding: 0 1px 0 0;
  box-sizing: border-box;
  gap: 56px;
}

.archive-service1-dot-icon20 {
  width: 10px;
  height: 10px;
  position: relative;
  display: none;
  z-index: 10;
}

.archive-service1-divider-icon35 {
  height: 1px;
  width: calc(100% - 1px);
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 1px;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  z-index: 2;
}

.archive-service1-text217 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 69px;
}

.archive-service1-buttonsbutton37 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.archive-service1-text218 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 48px;
}

.archive-service1-buttonsbutton38 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 13px;
  background-color: #fff;
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.archive-service1-text219 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 40px;
}

.archive-service1-buttonsbutton39 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 13px;
  background-color: #7e56d9;
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.archive-service1-actions9,
.archive-service1-buttonsbutton39,
.archive-service1-content38 {
  display: flex;
  flex-direction: row;
}

.archive-service1-actions9 {
  width: 164px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}

.archive-service1-content38 {
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  gap: 20px;
}

.archive-service1-divider-parent6,
.archive-service1-footer1 {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}

.archive-service1-footer1 {
  flex: 1;
  border-top: 1px solid #eaecf0;
  flex-direction: column;
  align-items: center;
  z-index: 4;
}

.archive-service1-divider-parent6 {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 1px 0 0;
  position: relative;
}

.archive-service1-divider-icon37 {
  height: 0.2px;
  width: 338px;
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: -1px;
  z-index: 3;
}

.archive-service1-frame-parent41 {
  align-self: stretch;
  flex-direction: column;
  font-size: 14px;
  color: #6941c6;
}

.archive-service1-frame-parent41,
.archive-service1-rectangle-parent4 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-rectangle-parent4 {
  flex: 1;
  flex-direction: column;
  position: relative;
  gap: 61px;
  z-index: 3;

}

.archive-service1-slide-out {
  width: 25%;
  margin: 0 !important;
  /* position: absolute;
  top: 1px;
  left: 0; */
  flex-direction: row;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-listings-search-results-list {
  max-width: 1524px;
  height: max-content;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  /* padding: 81px 0px 0px 0px; */
  box-sizing: border-box;
  /* gap: 999px; */
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  font-size: 18px;
  color: #101828;
  font-family: Inter;
  margin: 0 auto;
  padding: 7px 0 0;
}

.archive-service1-hiddenbutton-container{
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0px 32px;
}
.archive-service1-button-container{
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0px 32px;
}

.archive-service1-sort-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  padding: 5px 32px 0px 32px;

}

/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap"); */

.archive-service-section-wrapper-custom {
  display: initial !important;
}


.archive-service1-text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #344054;
  overflow: hidden;
}


.archive-service1-filter-selected-div {
  width: 100%;
  /* min-height: 370px;
 
  position: relative; */
}

.archive-service1-listing-container {

  gap: 20px;
  /* margin: 260px auto 0; */
 
  display: grid;
  justify-content: flex-end;
  padding: 25px 29px;
  box-sizing: border-box;
  max-width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  flex-shrink: 0;
  
  
  align-items: flex-start;
  width: 100%;
  display: flex;
  /* max-height: 95vh; */
  height: max-content;
  
  overflow-y: scroll;
  
  scrollbar-width: none;
 
  -ms-overflow-style: none;

}




.archive-service1-listing-container::-webkit-scrollbar {
  display: none;

}

.archive-service1-mid-container {
  width: 100%;
  height: max-content;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  /* padding: 81px 0px 0px 0px; */
  box-sizing: border-box;
  /* gap: 999px; */
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  font-size: 18px;
  color: #101828;
  font-family: Inter;
  /* min-height: 1624px; */
  /* margin: 0 auto; */
}

.archive-service1-divider-icon7-cstm {
  position: initial !important;
}


.archive-service1-listings-search-results-list-child {
  align-self: stretch;
  height: 5871px;
  position: relative;
  background-color: #fff;
  display: none;
  z-index: 0;
}

.archive-service1-frame-child {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
}

.archive-service1-gerator-04-1-icon {
  height: 32px;
  width: 121px;
  position: relative;
  object-fit: cover;
}

.archive-service1-logo,
.archive-service1-logo-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-logo {
  flex-direction: row;
  z-index: 1;
}

.archive-service1-logo-wrapper {
  flex-direction: column;
  padding: 6px 0 0;
}

.archive-service1-bar-chart-01-icon,
.archive-service1-dot-icon {
  height: 10px;
  width: 10px;
  position: relative;
  display: none;
}

.archive-service1-bar-chart-01-icon {
  height: 24px;
  width: 24px;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.archive-service1-text {
  text-decoration: none;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 30px;
  white-space: nowrap;
}

.archive-service1-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.archive-service1-text1 {
  align-self: stretch;
  position: relative;
  line-height: 18px;
  font-weight: 500;
}

.archive-service1-badge {
  height: 22px;
  border-radius: 9999px;
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 7px;
  text-align: center;
  font-size: 12px;
}

.archive-service1-chevron-down-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.archive-service1-nav-item-base {
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 12px;
  gap: 8px;
}

.archive-service1-text2,
.archive-service1-text3 {
  position: relative;
  font-family: Inter;
}

.archive-service1-text2 {
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #18222f;
  text-align: left;
  display: inline-block;
  min-width: 29px;
  white-space: nowrap;
}

.archive-service1-text3 {
  align-self: stretch;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #344054;
  text-align: center;
}

.archive-service1-badge1,
.archive-service1-nav-item-base1 {
  background-color: #f9fafb;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.archive-service1-badge1 {
  height: 22px;
  border-radius: 9999px;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
  padding: 2px 7px;
}

.archive-service1-nav-item-base1 {
  cursor: pointer;
  border: 0;
  padding: 8px 12px;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  gap: 8px;
}

.archive-service1-text4 {
  text-decoration: none;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 38px;
  white-space: nowrap;
}

.archive-service1-navigation,
.archive-service1-navigation1 {
  display: flex;
  justify-content: flex-start;
}

.archive-service1-navigation1 {
  flex-direction: row;
  align-items: center;
  gap: 4px;
  z-index: 1;
}

.archive-service1-navigation {
  flex-direction: column;
  align-items: flex-start;
  padding: 2px 0 0;
}

.archive-service1-label {
  width: 47px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
  white-space: nowrap;
}

.archive-service1-search-lg-icon,
.archive-service1-text6 {
  position: relative;
  overflow: hidden;
}

.archive-service1-search-lg-icon {
  height: 20px;
  width: 20px;
  flex-shrink: 0;
}

.archive-service1-text6 {
  width: calc(100% - 20px);
  border: 0;
  outline: 0;
  font-family: Inter;
  font-size: 16px;
  background-color: transparent;
  height: 24px;
  flex: 1;
  line-height: 24px;
  color: #667085;
  text-align: left;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 158px;
  padding: 0;
}

.archive-service1-content3,
.archive-service1-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.archive-service1-content3 {
  flex: 1;
  gap: 8px;
}

.archive-service1-input {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  padding: 8px 13px;
}

.archive-service1-frame-parent,
.archive-service1-input-with-label,
.archive-service1-navigation-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-input-with-label {
  flex: 1;
  flex-direction: column;
  gap: 6px;
  z-index: 1;
  font-size: 14px;
}

.archive-service1-frame-parent,
.archive-service1-navigation-parent {
  flex-direction: row;
  max-width: 100%;
}

.archive-service1-navigation-parent {
  flex: 1;
  gap: 38px;
}

.archive-service1-frame-parent {
  width: 754px;
  gap: 70px;
}

.archive-service1-icon {
  height: 19px;
  width: 19px;
  position: relative;
}

.archive-service1-nav-item-button {
  width: 40px;
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  box-sizing: border-box;
  z-index: 1;
}

.archive-service1-nav-item-button-child {
  height: 18.3px;
  width: 14.6px;
  position: relative;
}

.archive-service1-contrast-border {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 9999px;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
}

.archive-service1-avatar {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 9999px;
  background-image: url("../../../icons/Gerator/archive_page/avatar@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;
}

.archive-service1-user-buttons {
  justify-content: flex-start;
  gap: 15px;
}

.archive-service1-breadcrumb-button-base,
.archive-service1-rectangle-parent,
.archive-service1-user-buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.archive-service1-rectangle-parent {
  width: 100%;
  margin: 0 !important;
  position: absolute;
  top: 7px;
  right: 0;
  left: 0;
  justify-content: space-between;
  padding: 14px 64px 14px 31px;
  box-sizing: border-box;
  gap: 20px;
  max-width: 100%;
  z-index: 1;
  text-align: left;
  font-size: 16px;
  color: #344054;
  font-family: Inter;
}

.archive-service1-breadcrumb-button-base {
  border-radius: 6px;
  justify-content: flex-start;
  padding: 4px;
}

.archive-service1-chevron-right-icon {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.archive-service1-text7 {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  min-width: 74px;
}

.archive-service1-breadcrumb-button-base1 {
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}

.archive-service1-text8 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 500;
}

.archive-service1-breadcrumb-button-base2 {
  align-self: stretch;
  border-radius: 6px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 7px;
}

.archive-service1-chevron-right-icon2 {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.archive-service1-text9 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  white-space: nowrap;
}

.archive-service1-breadcrumb-button-base3 {
  align-self: stretch;
  border-radius: 6px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}

.archive-service1-text10 {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 64px;
}

.archive-service1-breadcrumb-button-base4 {
  border-radius: 6px;
  background-color: #f9fafb;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  color: #344054;
}

.archive-service1-tabs {
  gap: 8px;
}

.archive-service1-breadcrumbs,
.archive-service1-tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.archive-service1-divider-icon {
  width: 262px;
  height: 1px;
  position: relative;
  display: none;
}

.archive-service1-page-header {
  align-self: stretch;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.archive-service1-container {
  padding: 0 32px;
}

.archive-service1-container,
.archive-service1-header-section {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-main-child,
.archive-service1-main-item {
  width: 1px;
  height: 29px;
  position: relative;
  border-right: 1px solid #000;
  box-sizing: border-box;
  flex-shrink: 0;
}

.archive-service1-main-item {
  height: 43px;
}

.archive-service1-main,
.archive-service1-text11 {
  align-self: stretch;
}

.archive-service1-main {
  height: 80px;
  background-color: #fff;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px 0 0;
  box-sizing: border-box;
  gap: 32px;
  z-index: 1;
  color: #475467;
}

.archive-service1-text11 {
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 500;
}

.archive-service1-breadcrumb-button-base6,
.archive-service1-breadcrumb-button-base7 {
  align-self: stretch;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.archive-service1-breadcrumb-button-base6 {
  flex: 1;
  padding: 4px 8px;
}

.archive-service1-breadcrumb-button-base7 {
  padding: 4px 7px;
}

.archive-service1-breadcrumbs1,
.archive-service1-tabs1 {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.archive-service1-tabs1 {
  height: 28px;
  flex: 1;
  display: flex;
  gap: 8px;
}

.archive-service1-breadcrumbs1 {
  width: 278px;
  display: none;
}

.archive-service1-page-title {
  align-self: stretch;
  position: relative;
  line-height: 38px;
  font-weight: 600;
}

.archive-service1-supporting-text {
  width: 445px;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  display: none;
  max-width: 100%;
}

.archive-service1-text-and-supporting-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  min-width: 320px;
  max-width: 100%;
}

.archive-service1-label1,
.archive-service1-text15 {
  position: relative;
}

.archive-service1-label1 {
  width: 47px;
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.archive-service1-text15 {
  align-self: stretch;
  flex: 1;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.archive-service1-content5 {
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.archive-service1-content5,
.archive-service1-input-with-label1,
.archive-service1-input1 {
  align-self: stretch;
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.archive-service1-input1 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 10px 13px;
  font-size: 16px;
  color: #667085;
}

.archive-service1-input-with-label1 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.archive-service1-hint-text {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  color: #475467;
  display: none;
}

.archive-service1-input-dropdown {
  height: 44px;
  width: 320px;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  min-width: 200px;
  max-width: 320px;
  font-size: 14px;
  color: #344054;
}


.archive-service1-divider-icon1 {
  align-self: stretch;
  max-width: 100%;
}

.archive-service1-content4 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 20px 16px;
  font-size: 26px;
  color: #101828;
  align-self: stretch;
  max-width: 100%;
}

.archive-service1-divider-icon1 {
  position: relative;
  overflow: hidden;
  max-height: 100%;
  display: none;
}

.archive-service1-page-header1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-page-header1 {
  flex: 1;
  background-color: #fff;
  flex-direction: column;
  gap: 20px;
  z-index: 1;
  height: max-content;
}

.archive-service1-secondary-content {
  width: 100%;
  flex-direction: row;
  padding: 0 32px;
  box-sizing: border-box;
  color: #475467;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;

}

.archive-service1-text16 {
  text-decoration: none;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 53px;
}

.archive-service1-button-group-base {
  cursor: pointer;
  border: 0;
  padding: 10px 15px 10px 16px;
  background-color: #f9fafb;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 40px;
  white-space: nowrap;
}

.archive-service1-button-group-base:hover {
  background-color: #e0e0e0;
}

.archive-service1-text17 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 70px;
}

.archive-service1-button-group-base1 {
  background-color: #fff;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 15px 10px 16px;
  min-height: 40px;
}

.archive-service1-button-group-base2,
.archive-service1-button-group-base3,
.archive-service1-button-group-base4,
.archive-service1-button-group-base5,
.archive-service1-button-group-base6,
.archive-service1-button-group-base7 {
  align-self: stretch;
  background-color: #fff;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  min-height: 40px;
  z-index: 7;
}

.archive-service1-button-group-base3,
.archive-service1-button-group-base4,
.archive-service1-button-group-base5,
.archive-service1-button-group-base6,
.archive-service1-button-group-base7 {
  z-index: 6;
}

.archive-service1-button-group-base4,
.archive-service1-button-group-base5,
.archive-service1-button-group-base6,
.archive-service1-button-group-base7 {
  z-index: 5;
}

.archive-service1-button-group-base5,
.archive-service1-button-group-base6,
.archive-service1-button-group-base7 {
  z-index: 4;
}

.archive-service1-button-group-base6,
.archive-service1-button-group-base7 {
  z-index: 3;
}

.archive-service1-button-group-base7 {
  z-index: 2;
}

.archive-service1-text24 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 89px;
}

.archive-service1-chevron-right {
  margin: 0;
  width: 16px;
  height: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.archive-service1-breadcrumb-button-base11,
.archive-service1-breadcrumb-button-base12 {
  align-self: stretch;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-breadcrumb-button-base11 {
  flex: 1;
  padding: 4px 8px;
}

.archive-service1-breadcrumb-button-base12 {
  width: 28px;
  padding: 4px 7px;
  box-sizing: border-box;
}

.archive-service1-text28 {
  align-self: stretch;
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.archive-service1-breadcrumb-button-base14 {
  width: 54px;
  border-radius: 6px;
  background-color: #f9fafb;
  padding: 4px 8px;
  box-sizing: border-box;
  color: #344054;
}

.archive-service1-breadcrumb-button-base14,
.archive-service1-breadcrumbs2,
.archive-service1-button-group,
.archive-service1-tabs2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-tabs2 {
  align-self: stretch;
  flex: 1;
  gap: 8px;
}

.archive-service1-breadcrumbs2{
  margin: 0 !important;
  position: absolute;
}

.archive-service1-breadcrumbs2 {
  height: 28px;
  width: 278px;
  right: -2px;
  bottom: -20px;
  color: #475467;
}

.archive-service1-button-group {
  /* top: 304px;
  left: 52px; */
  width: max-content;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  z-index: 1;
  margin: 0 !important;
  
}

.archive-service1-hint-text1 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  display: none;
}

.archive-service1-sliders-02-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 20px;
}

.archive-service1-menu-label {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.archive-service1-text-padding {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.archive-service1-placeholder-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 20px;
}

.archive-service1-buttonsbutton {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 13px;
  gap: 4px;
  color: #344054;
}

.archive-service1-input-dropdown1 {
  /* margin: 0 !important;
  position: absolute;
  top: 304px;
  right: 29px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  max-width: 320px;
  z-index: 1;
  color: #475467;
}

/* .archive-service1-divider-icon2,
.archive-service1-divider-icon3 {
  width: 1077px;
  height: 1px;
  right: 0;
  z-index: 1;
} */

.archive-service1-divider-icon2 {
  /* position: absolute;
  margin: 0 !important;
  top: 356px;
  right: 0; */
  width: 100%;
  height: 1px;

  z-index: 1;
}

.archive-service1-divider-icon3 {
  /* right: 0;
  top: 291px;
  position: absolute;
  margin: 0 !important; */
  width: 100%;
  height: 1px;
  
  z-index: 1;
}


.archive-service1-filter-items{
  position: absolute;
  margin: 0 !important;
}

.archive-service1-supporting-text1 {
  width: 678px;
  /* position: absolute;
  
  top: 122px;
  left: 52px; */
  margin: 0 !important;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  display: inline-block;
  z-index: 1;
}

.archive-service1-filter-items {
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
}

.archive-service1-close-icons-child {
  height: 148px;
  width: 148px;
  position: relative;
  border-radius: 12px;
  object-fit: cover;
  z-index: 1;
}

.archive-service1-text29 {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  z-index: 1;
}

.archive-service1-dots {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 1px;
}

.archive-service1-dot-icon3 {
  height: 8px;
  width: 8px;
  position: relative;
  display: none;
}

.archive-service1-text30 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 66px;
}

.archive-service1-badge3 {
  width: 84px;
  border-radius: 9999px;
  background-color: #edfbf5;
  border: 1px solid #41d992;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 8px;
  gap: 6px;
  mix-blend-mode: normal;
  z-index: 1;
  text-align: center;
  font-size: 14px;
  color: #17b26a;
}

.archive-service1-supporting-text2 {
  position: relative;
  letter-spacing: -0.17px;
  line-height: 24px;
  display: inline-block;
  min-width: 83px;
  z-index: 1;
}

.archive-service1-marker-pin-02-icon {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.archive-service1-location {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 124px;
  z-index: 1;
}

.archive-service1-dot-item-location-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 7px;
}

.archive-service1-type-of-work {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 109px;
  z-index: 1;
}

.archive-service1-dot-item-type-parent {
  gap: 8px;
}

.archive-service1-completed,
.archive-service1-dot-item-content,
.archive-service1-dot-item-innermost,
.archive-service1-dot-item-type-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-dot-item-content {
  flex: 1;
  gap: 20px;
  max-width: 100%;
}

.archive-service1-completed,
.archive-service1-dot-item-innermost {
  box-sizing: border-box;
}

.archive-service1-dot-item-innermost {
  align-self: stretch;
  padding: 0 0 0 1px;
  max-width: 100%;
}

.archive-service1-completed {
  width: 50px;
  border-radius: 8px;
  background-color: #fdb022;
  padding: 1px 4px;
  z-index: 1;
}

.archive-service1-supporting-text3 {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 117px;
  z-index: 1;
}

.archive-service1-supporting-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1px 0 0;
  color: #475467;
}

.archive-service1-completed-parent {
  flex-direction: row;
  gap: 8px;
  color: #fff;
}

.archive-service1-badge4,
.archive-service1-completed-parent,
.archive-service1-dot-item-inner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-dot-item-inner {
  align-self: stretch;
  flex-direction: column;
  gap: 15px;
  max-width: 100%;
}

.archive-service1-badge4 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  flex-direction: row;
  padding: 0 7px;
  min-width: 112px;
  white-space: nowrap;
  z-index: 1;
}

.archive-service1-text33 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 61px;
}

.archive-service1-badge5,
.archive-service1-badge6 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 7px;
  z-index: 1;
}

.archive-service1-badge5 {
  width: 77px;
  white-space: nowrap;
}

.archive-service1-badge6 {
  width: 76px;
}

.archive-service1-badge7,
.archive-service1-badges {
  flex: 1;
  flex-direction: row;
}

.archive-service1-badge7 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 7px;
  min-width: 107px;
  white-space: nowrap;
  z-index: 1;
}

.archive-service1-badges {
  gap: 8px;
}

.archive-service1-badges,
.archive-service1-badges-wrapper,
.archive-service1-dot-item {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-badges-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 1px;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.archive-service1-dot-item {
  flex: 1;
  flex-direction: column;
  gap: 15px;
}

.archive-service1-text36 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 77px;
}

.archive-service1-badge8 {
  width: 93px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 7px;
  z-index: 1;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.archive-service1-dot-item-parent,
.archive-service1-dots-parent,
.archive-service1-dots-vertical {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-dot-item-parent {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-end;
  padding: 0 23px 0 0;
  box-sizing: border-box;
  gap: 7px;
  font-size: 16px;
  color: #475467;
}

.archive-service1-dots-parent,
.archive-service1-dots-vertical {
  flex-direction: column;
  align-items: flex-start;
}

.archive-service1-dots-parent {
  align-self: stretch;
  gap: 16px;
}

.archive-service1-dots-vertical {
  flex: 1;
  padding: 10px 0 0;
  box-sizing: border-box;
}

.archive-service1-price {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 32px;
  font-weight: 600;
  white-space: nowrap;
  z-index: 1;
}

.archive-service1-deal-title {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 83px;
  z-index: 2;
}

.archive-service1-magnifier-1-icon {
  height: 20px;
  width: 20px;
  position: relative;
  object-fit: cover;
  min-height: 20px;
}

.archive-service1-deal-inner,
.archive-service1-radar-1-1-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.archive-service1-radar-1-1-parent {
  flex: 1;
  justify-content: flex-start;
  gap: 8px;
  z-index: 1;
}

.archive-service1-deal-inner {
  align-self: stretch;
  padding: 0 4px 0 3px;
}

.archive-service1-card-deal,
.archive-service1-deal-details,
.archive-service1-deal-inner {
  justify-content: flex-start;
}

.archive-service1-deal-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.archive-service1-card-deal {
  align-self: stretch;
  flex-direction: row;
  padding: 0 30px;
  font-size: 12px;
  color: #475467;
}

.archive-service1-card-deal,
.archive-service1-frame-wrapper,
.archive-service1-price-parent {
  display: flex;
  align-items: flex-start;
}

.archive-service1-price-parent {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
}

.archive-service1-frame-wrapper {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 10px 0 0;
}

.archive-service1-dots-vertical-group {
  width: 153px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 26px;
  text-align: center;
  font-size: 20px;
  color: #6941c6;
}

.archive-service1-dots-vertical-parent {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  min-width: 525px;
  max-width: 100%;
}

.archive-service1-close-icons,
.archive-service1-divider-icon4 {
  align-self: stretch;
  max-width: 100%;
}

.archive-service1-close-icons {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-end;
  gap: 23px;
}

.archive-service1-divider-icon4 {
  position: relative;
  overflow: hidden;
  max-height: 100%;
  z-index: 1;
}

.archive-service1-user-content-child {
  height: 52px;
  width: 52px;
  position: relative;
  object-fit: cover;
  z-index: 1;
}

.archive-service1-name,
.archive-service1-role {
  position: relative;
  display: inline-block;
}

.archive-service1-name {
  line-height: 23px;
  font-weight: 600;
  min-width: 77px;
  z-index: 1;
}

.archive-service1-role {
  height: 19px;
  font-size: 12px;
  line-height: 24px;
  color: #6941c6;
  flex-shrink: 0;
  min-width: 94px;
  z-index: 2;
}

.archive-service1-inner-info {
  flex-direction: column;
}

.archive-service1-inner-info,
.archive-service1-inner-info-wrapper,
.archive-service1-user-content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-inner-info-wrapper {
  flex-direction: column;
  padding: 3px 0 0;
}

.archive-service1-user-content {
  flex: 1;
  flex-direction: row;
  gap: 8px;
  min-width: 100px;
  color: #101828;
}

.archive-service1-eye-icon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.archive-service1-view-label {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 34px;
}

.archive-service1-buttonsbutton1 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 6px 12px;
  background-color: #fff;
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  z-index: 1;
}

.archive-service1-icon1 {
  width: 16.7px;
  height: 16.7px;
  position: relative;
}

.archive-service1-icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 3.7px 0 0;
}

.archive-service1-lock-label {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 56px;
}

.archive-service1-buttonsbutton2 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow-x: auto;
  padding: 6px 11px;
  gap: 5.5px;
  z-index: 1;
}

.archive-service1-buttonsbutton2,
.archive-service1-buttonsbutton3,
.archive-service1-user-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-buttonsbutton3 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow-x: auto;
  padding: 6px 10px 6px 12px;
  gap: 5.4px;
  z-index: 1;
}

.archive-service1-user-item {
  width: 556.4px;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 16.3px;
  max-width: calc(100% - 44px);
}

.archive-service1-arrow-square-up-right-icon {
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.archive-service1-arrow-square-up-right-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 14px 0 0;
}

.archive-service1-arrow-square-up-right-wrapper1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 28px 0 0;
}

.archive-service1-user-listings {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 100%;
  gap: 20px;
}

.archive-service1-filter-items-parent,
.archive-service1-listing-separator {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}

.archive-service1-listing-separator {
  align-self: stretch;
  padding: 0 10px 0 0;
  gap: 16px;
  font-size: 14px;
  color: #344054;
}

.archive-service1-filter-items-parent {
  width: 1016px;
  margin: 0 !important;
  position: absolute;
  right: 30px;
  bottom: 291px;
  padding: 14px 14px 17px 24px;
  gap: 24px;
  z-index: 1;
  font-size: 18px;
  color: #101828;
}

.archive-service1-frame-inner {
  height: 148px;
  width: 148px;
  position: relative;
  border-radius: 12px;
  object-fit: cover;
  min-height: 148px;
  z-index: 1;
}

.archive-service1-dots-vertical-icon1 {
  height: 20px;
  width: 20px;
  position: absolute;
  margin: 0 !important;
  top: -10px;
  right: -10px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.archive-service1-price-value,
.archive-service1-text37 {
  position: relative;
  display: inline-block;
}

.archive-service1-text37 {
  line-height: 28px;
  font-weight: 600;
  max-width: 100%;
  z-index: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #344054;
  overflow: hidden;
}

.archive-service1-price-value {
  line-height: 20px;
  font-weight: 500;
  min-width: 55px;
}

.archive-service1-badge9 {
  width: 73px;
  border-radius: 9999px;
  background-color: #fff9ef;
  border: 1px solid #f5b231;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 8px;
  gap: 6px;
  mix-blend-mode: normal;
  z-index: 1;
}

.archive-service1-supporting-text4 {
  position: relative;
  font-size: 16px;
  letter-spacing: -0.17px;
  line-height: 24px;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 83px;
  z-index: 1;
}

.archive-service1-badge-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.archive-service1-deal-type-parent {
  flex-direction: row;
  gap: 8px;
  text-align: left;
  font-size: 16px;
  color: #fff;
}

.archive-service1-badge10,
.archive-service1-badge11,
.archive-service1-deal-type-parent,
.archive-service1-frame-parent1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-badge10 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  padding: 0 7px;
  white-space: nowrap;
  z-index: 1;
  color: #344054;
}

.archive-service1-badge11,
.archive-service1-frame-parent1 {
  flex: 1;
}

.archive-service1-frame-parent1 {
  flex-direction: column;
  gap: 15px;
  min-width: 114px;
  color: #f5b231;
}

.archive-service1-badge11 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  padding: 0 7px;
  white-space: nowrap;
  z-index: 1;
}

.archive-service1-sub-badge {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 60px;
}

.archive-service1-badge12 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  justify-content: flex-start;
  padding: 0 7px;
  z-index: 1;
}

.archive-service1-badge-group,
.archive-service1-badge12,
.archive-service1-frame-wrapper1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.archive-service1-badge-group {
  flex: 1;
  justify-content: flex-start;
  gap: 8px;
}

.archive-service1-frame-wrapper1 {
  align-self: stretch;
  justify-content: flex-end;
  padding: 0 6px 0 0;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.archive-service1-deal-location-parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 56px;
  min-width: 109px;
  text-align: left;
  font-size: 16px;
}

.archive-service1-badge13,
.archive-service1-inner-work {
  flex-direction: row;
  align-items: flex-start;
}

.archive-service1-inner-work {
  display: flex;
  justify-content: flex-end;
  padding: 0 10px 0 18px;
}

.archive-service1-badge13 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  padding: 0 7px;
  white-space: nowrap;
  z-index: 1;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.archive-service1-badge13,
.archive-service1-badge14,
.archive-service1-card-work,
.archive-service1-frame-div {
  display: flex;
  justify-content: flex-start;
}

.archive-service1-card-work {
  flex: 0.9649;
  flex-direction: column;
  align-items: flex-end;
  padding: 0 6px 0 0;
  box-sizing: border-box;
  gap: 56px;
  min-width: 111px;
  text-align: left;
  font-size: 16px;
}

.archive-service1-badge14,
.archive-service1-frame-div {
  flex-direction: row;
}

.archive-service1-badge14 {
  width: 93px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  align-items: flex-start;
  padding: 0 7px;
  z-index: 1;
  color: #344054;
}

.archive-service1-frame-div {
  align-self: stretch;
  align-items: flex-end;
  padding: 0 2px 0 0;
  gap: 2px;
}

.archive-service1-frame-container,
.archive-service1-price-value1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-frame-container {
  flex: 1;
  flex-direction: column;
  padding: 8px 0 0;
  box-sizing: border-box;
  max-width: 80%;
}

.archive-service1-price-value1 {
  flex-direction: row;
  padding: 0 30px;
  font-size: 12px;
  color: #475467;
}

.archive-service1-inner-icons {
  flex-direction: row;
  gap: 8px;
  z-index: 1;
}

.archive-service1-inner-icons,
.archive-service1-inner-icons-wrapper,
.archive-service1-second-price {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-inner-icons-wrapper {
  flex-direction: row;
  padding: 0 34px 0 33px;
}

.archive-service1-second-price {
  width: 20%;
  flex-direction: column;
  gap: 2px;
  font-size: 20px;
  color: #6941c6;
}

.archive-service1-frame-group {
  align-self: stretch;
  flex-direction: row;
  /* gap: 38px; */
  text-align: center;
  font-size: 14px;
  color: #475467;
}

.archive-service1-dots-vertical-container,
.archive-service1-frame-group,
.archive-service1-rectangle-container,
.archive-service1-text-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-text-parent {
  flex: 1;
  flex-direction: column;
  gap: 8px;
}

.archive-service1-dots-vertical-container,
.archive-service1-rectangle-container {
  flex-direction: row;
}

.archive-service1-dots-vertical-container {
  flex: 1;
  position: relative;
  min-width: 517px;
}

.archive-service1-rectangle-container {
  align-self: stretch;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 24px;
}

.archive-service1-role1 {
  height: 19px;
  position: relative;
  font-size: 12px;
  line-height: 24px;
  color: #6941c6;
  display: inline-block;
  flex-shrink: 0;
  min-width: 96px;
  z-index: 2;
}

.archive-service1-frame-parent4 {
  flex: 1;
  flex-direction: row;
  gap: 8px;
  min-width: 101px;
  color: #101828;
}

.archive-service1-buttonsbutton-wrapper,
.archive-service1-frame-parent3,
.archive-service1-frame-parent4 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-buttonsbutton-wrapper {
  width: 90px;
  flex-direction: column;
  padding: 6px 0 0;
  box-sizing: border-box;
}

.archive-service1-frame-parent3 {
  width: 556.4px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 15.7px;
  max-width: calc(100% - 44px);
}

.archive-service1-frame-child1 {
  width: 24px;
  height: 24px;
  position: relative;
  z-index: 1;
}

.archive-service1-divider-parent,
.archive-service1-rectangle-group,
.archive-service1-rectangle-group1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-divider-parent {
  align-self: stretch;
  gap: 16px;
  font-size: 14px;
  color: #344054;
}

.archive-service1-rectangle-group {
  width: 100%;
  /* margin: 0 !important; */
  /* position: absolute; */
  /* right: 30px; */
  /* bottom: -15px; */
  padding: 24px 24px 17px;
  box-sizing: border-box;
  gap: 24px;
  /* z-index: 1; */
  font-size: 18px;
  color: #101828;
  /* top: 400px; */
  height: max-content;
  border: 1px solid #eaecf0;
  border-radius: 12px;

}

.archive-service1-rectangle-group1 {
  width: 1016px;
  margin: 0 !important;
  position: absolute;
  right: 30px;
  bottom: -15px;
  padding: 24px 24px 17px;
  box-sizing: border-box;
  gap: 24px;
  z-index: 1;
  font-size: 18px;
  color: #101828;
  top: 700px;
  height: max-content;
}

.archive-service1-text47 {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 51px;
}

.archive-service1-buttonsbutton7 {
  margin: 0 !important;
  position: absolute;
  top: 173px;
  left: 45px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 13px 8px 15px;
  gap: 6px;
  z-index: 1;
}

.archive-service1-text48 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
}

.archive-service1-buttonsbutton8 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 13px 8px 15px;
  background-color: #fff;
  margin: 0 !important;
  position: absolute;
  top: 173px;
  left: 164px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  z-index: 1;
}

.archive-service1-text49 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 74px;
}

.archive-service1-buttonsbutton9 {
  margin: 0 !important;
  position: absolute;
  top: 235px;
  left: 45px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 13px 8px 15px;
  gap: 5px;
  z-index: 1;
}

.archive-service1-text50 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 90px;
}

.archive-service1-buttonsbutton10 {
  left: 370px;
}

.archive-service1-buttonsbutton10,
.archive-service1-buttonsbutton11,
.archive-service1-buttonsbutton12 {
  margin: 0 !important;
  position: absolute;
  top: 173px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 13px 8px 15px;
  gap: 5px;
  z-index: 1;
}

.archive-service1-buttonsbutton11 {
  cursor: pointer;
  right: 377px;
}

.archive-service1-buttonsbutton12 {
  right: 262px;
}

.archive-service1-text53 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 63px;
}

.archive-service1-buttonsbutton13 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 13px 8px 15px;
  background-color: #fff;
  margin: 0 !important;
  position: absolute;
  top: 235px;
  left: 186px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  z-index: 1;
}

.archive-service1-text54 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 93px;
}

.archive-service1-buttonsbutton14 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 13px 8px 15px;
  background-color: #fff;
  margin: 0 !important;
  position: absolute;
  top: 235px;
  left: 317px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  z-index: 1;
}

.archive-service1-text55 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 106px;
}

.archive-service1-buttonsbutton15 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 13px 8px 15px;
  background-color: #fff;
  margin: 0 !important;
  position: absolute;
  top: 235px;
  left: 477px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  z-index: 1;
}

.archive-service1-text56 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 77px;
}

.archive-service1-buttonsbutton16 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 13px 8px 15px;
  background-color: #fff;
  margin: 0 !important;
  position: absolute;
  top: 173px;
  right: 116px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  z-index: 1;
}

.archive-service1-text57 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 78px;
}

.archive-service1-buttonsbutton17 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 14px;
  background-color: #fff;
  margin: 0 !important;
  /* position: absolute;
  top: 235px;
  right: 198px; */
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  z-index: 1;
}

.archive-service1-text58 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #eaecf0;
  text-align: left;
  display: inline-block;
  min-width: 75px;
}

.archive-service1-buttonsbutton18 {
  cursor: pointer;
  border: 0;
  padding: 10px 14px;
  background-color: #7e56d9;
  margin: 0 !important;
  /* position: absolute;
  top: 235px;
  right: 57px; */
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  z-index: 1;
}

.archive-service1-text59 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #475467;
  text-align: left;
}

.archive-service1-button,
.archive-service1-text-padding1 {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.archive-service1-text-padding1 {
  align-self: stretch;
  display: flex;
  padding: 0 2px;
}

.archive-service1-button {
  border-radius: 8px;
  overflow: hidden;
  display: none;
  padding: 10px 14px;
  gap: 4px;
}

.archive-service1-button,
.archive-service1-button1,
.archive-service1-text60 {
  align-self: stretch;
}

.archive-service1-text60 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #6941c6;
  text-align: left;
}

.archive-service1-button1 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d6bbfb;
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 13px;
  gap: 4px;
}

.archive-service1-filter-label {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 72px;
}


.archive-service1-button2 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  justify-content: center;
  padding: 8px 13px;
  gap: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.archive-service1-actions {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  margin: 0 !important;
  /* position: absolute;
  top: 80px;
  right: 26px; */
  justify-content: flex-start;
  gap: 12px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.archive-service1-divider-icon6,
.archive-service1-filters {
  max-width: 100%;
  flex-shrink: 0;
}

.archive-service1-filters {
  width: 75%;
  /* display: flex; */
  height: max-content;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* padding: 0 0 1518px 0px; */
  box-sizing: border-box;

  /* position: relative; */
  font-size: 14px;
  color: #344054;
}

.archive-service1-divider-icon6 {
  height: 1px;
  top: 70px;
  overflow: hidden;
  z-index: 1;
}

.archive-service1-divider-icon6,
.archive-service1-divider-icon7,
.archive-service1-listing-border {
  width: 100%;
  position: absolute;
  margin: 0 !important;
  right: 0;
  left: 0;
}

.archive-service1-divider-icon7 {
  height: 1px;
  bottom: 127px;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.archive-service1-listing-border {
  height: 100%;
  top: 0;
  bottom: 0;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
}

.archive-service1-text-group,
.archive-service1-work-type {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-work-type {
  flex-direction: row;
  gap: 20px;
  font-size: 16px;
  color: #475467;
}

.archive-service1-text-group {
  flex-direction: column;
  gap: 8px;
  max-width: 100%;
}

.archive-service1-supporting-text8 {
  position: relative;
  font-size: 12px;
  line-height: 19px;
  color: #475467;
  display: inline-block;
  min-width: 104px;
  z-index: 1;
}

.archive-service1-last-active,
.archive-service1-name-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-last-active {
  font-size: 14px;
  color: #101828;
}

.archive-service1-name-container {
  gap: 16px;
}

.archive-service1-supporting-text9 {
  position: relative;
  font-size: 12px;
  line-height: 19px;
  color: #475467;
  display: inline-block;
  min-width: 113px;
  z-index: 1;
}

.archive-service1-applications,
.archive-service1-provider-name {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.archive-service1-applications {
  flex-direction: column;
  align-items: flex-start;
  min-width: 151px;
  font-size: 14px;
  color: #101828;
}

.archive-service1-provider-name {
  flex-direction: row;
  align-items: flex-end;
  gap: 10px;
  min-width: 272px;
  max-width: 100%;
}

.archive-service1-text64 {
  align-self: stretch;
  position: relative;
  line-height: 23px;
  font-weight: 500;
  z-index: 1;
}

.archive-service1-supporting-text10 {
  width: 113px;
  position: relative;
  font-size: 12px;
  line-height: 19px;
  color: #475467;
  display: inline-block;
  z-index: 1;
}

.archive-service1-invites {
  width: 233px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  color: #101828;
}

.archive-service1-card-actions,
.archive-service1-provider-details {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-provider-details {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
  align-content: flex-end;
  gap: 34px;
  font-size: 16px;
  color: #fff;
}

.archive-service1-card-actions {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.archive-service1-dots-vertical-icon2 {
  height: 20px;
  width: 20px;
  position: absolute;
  margin: 0 !important;
  top: -19px;
  right: -10px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.archive-service1-text65 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 41px;
  z-index: 1;
}

.archive-service1-financial-details {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 21px 0 20px;
}

.archive-service1-price2,
.archive-service1-rate {
  position: relative;
  display: inline-block;
}

.archive-service1-price2 {
  flex: 1;
  letter-spacing: -0.02em;
  line-height: 32px;
  font-weight: 600;
  min-width: 93px;
  white-space: nowrap;
  z-index: 2;
}

.archive-service1-rate {
  line-height: 20px;
  min-width: 15px;
  z-index: 1;
}

.archive-service1-daily-rate-amount {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5px 0 0;
  font-size: 12px;
  color: #475467;
}

.archive-service1-price3 {
  flex: 1;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 32px;
  font-weight: 600;
  display: inline-block;
  min-width: 93px;
  white-space: nowrap;
  z-index: 1;
}

.archive-service1-price-group {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 7.5px;
  font-size: 20px;
  color: #6941c6;
}

.archive-service1-text66 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 83px;
  z-index: 3;
}

.archive-service1-deal-icons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 4px 0 3px;
}

.archive-service1-deal-container {
  flex-direction: column;
  gap: 4px;
}

.archive-service1-card-actions1,
.archive-service1-deal-container,
.archive-service1-listing-border-parent,
.archive-service1-listing-card-actions {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-card-actions1 {
  align-self: stretch;
  flex-direction: row;
  position: relative;
}

.archive-service1-listing-border-parent,
.archive-service1-listing-card-actions {
  box-sizing: border-box;
}

.archive-service1-listing-card-actions {
  width: 316px;
  flex-direction: column;
  padding: 9px 0 0;
  margin-left: -33px;
  text-align: center;
  font-size: 12px;
  color: #475467;
}

.archive-service1-listing-border-parent {
  width: 1016px;
  margin: 0 !important;
  position: absolute;
  right: -24px;
  bottom: 33px;
  flex-direction: row;
  padding: 24px;
  z-index: 1;
}

.archive-service1-collapsed-image {
  height: 160px;
  width: 160px;
  position: absolute;
  margin: 0 !important;
  bottom: 0;
  left: 0;
  border-radius: 12px;
  background-color: #f5f5f5;
  z-index: 2;
}

.archive-service1-f4b711e503bc72fe396e5043e0c2-1-icon {
  height: 84px;
  width: 84px;
  position: absolute;
  margin: 0 !important;
  bottom: 38px;
  left: 38px;
  object-fit: cover;
  z-index: 3;
}

.archive-service1-collapsed-names-child {
  height: 52px;
  width: 52px;
  position: relative;
  object-fit: cover;
  z-index: 2;
}

.archive-service1-name3,
.archive-service1-role2 {
  position: relative;
  display: inline-block;
}

.archive-service1-name3 {
  line-height: 23px;
  font-weight: 600;
  min-width: 77px;
  z-index: 2;
}

.archive-service1-role2 {
  align-self: stretch;
  height: 19px;
  font-size: 12px;
  line-height: 24px;
  color: #6941c6;
  flex-shrink: 0;
  z-index: 3;
}

.archive-service1-collapsed-name-details,
.archive-service1-collapsed-names {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-collapsed-name-details {
  flex: 1;
  flex-direction: column;
  padding: 3px 0 0;
}

.archive-service1-collapsed-names {
  align-self: stretch;
  flex-direction: row;
  gap: 8px;
}

.archive-service1-collapsed-name-role,
.archive-service1-collapsed-names-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-collapsed-names-parent {
  align-self: stretch;
  gap: 254px;
}

.archive-service1-collapsed-name-role {
  width: 166px;
  padding: 153px 0 0;
  box-sizing: border-box;
}

.archive-service1-detail-header,
.archive-service1-details-image {
  position: absolute;
  margin: 0 !important;
}

.archive-service1-detail-header {
  top: 262px;
  right: -124px;
  line-height: 28px;
  font-weight: 600;
  z-index: 2;
}

.archive-service1-details-image {
  height: 208px;
  width: 1016px;
  right: -306px;
  bottom: -24px;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  z-index: 1;
}

.archive-service1-detail-header1,
.archive-service1-divider-icon8 {
  position: absolute;
  margin: 0 !important;
  z-index: 2;
}

.archive-service1-detail-header1 {
  bottom: 132px;
  left: 13px;
  line-height: 28px;
  font-weight: 600;
}

.archive-service1-divider-icon8 {
  height: 1px;
  width: 968px;
  right: -282px;
  bottom: 70px;
}

.archive-service1-badge15 {
  flex: 1;
  border-radius: 9999px;
  background-color: #eceef1;
  border: 1px solid #98a2b3;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 8px;
  gap: 6px;
  mix-blend-mode: normal;
  z-index: 2;
}

.archive-service1-supporting-text11 {
  position: relative;
  font-size: 16px;
  letter-spacing: -0.17px;
  line-height: 24px;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 83px;
  z-index: 2;
}

.archive-service1-badge-value {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.archive-service1-second-value {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 41px;
}

.archive-service1-second-badge-count {
  width: 50px;
  border-radius: 8px;
  background-color: #fdb022;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1px 4px;
  box-sizing: border-box;
  z-index: 2;
}

.archive-service1-supporting-text12 {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 117px;
  z-index: 2;
}

.archive-service1-third-badge-count {
  position: relative;
  line-height: 20px;
  font-weight: 500;
}

.archive-service1-badge16,
.archive-service1-first-badge {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-badge16 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  padding: 0 7px;
  white-space: nowrap;
  z-index: 2;
  color: #344054;
}

.archive-service1-first-badge {
  width: 181px;
  flex-direction: column;
  gap: 15px;
  min-width: 181px;
}

.archive-service1-marker-pin-02-icon2 {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 2;
}

.archive-service1-location2,
.archive-service1-type-of-work3 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 124px;
  z-index: 2;
}

.archive-service1-type-of-work3 {
  min-width: 109px;
}

.archive-service1-location-content,
.archive-service1-location-details {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.archive-service1-location-content {
  justify-content: flex-start;
  gap: 20px;
}

.archive-service1-location-details {
  justify-content: flex-end;
  padding: 0 5px;
}

.archive-service1-badge-count {
  width: 61px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 61px;
}

.archive-service1-badge17,
.archive-service1-badge18 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  padding: 0 7px;
  z-index: 2;
}

.archive-service1-badge17 {
  width: 77px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  white-space: nowrap;
}

.archive-service1-badge18 {
  width: 76px;
}

.archive-service1-badge-container,
.archive-service1-badge18,
.archive-service1-badge19 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-badge19 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  padding: 0 7px;
  white-space: nowrap;
  z-index: 2;
}

.archive-service1-badge-container {
  align-self: stretch;
  gap: 8px;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.archive-service1-location-details-parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 56px;
  min-width: 217px;
  max-width: 100%;
  margin-left: -1px;
  text-align: left;
  font-size: 16px;
  color: #475467;
}

.archive-service1-badge-name,
.archive-service1-buttonsbutton19,
.archive-service1-buttonsbutton20,
.archive-service1-buttonsbutton21 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-badge-name {
  flex: 1;
  max-width: 100%;
  row-gap: 20px;
}

.archive-service1-buttonsbutton19,
.archive-service1-buttonsbutton20,
.archive-service1-buttonsbutton21 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  z-index: 2;
}

.archive-service1-buttonsbutton19 {
  cursor: pointer;
  padding: 6px 12px;
  width: 90px;
  overflow: hidden;
  flex-shrink: 0;
  gap: 6px;
}

.archive-service1-buttonsbutton20,
.archive-service1-buttonsbutton21 {
  flex: 1;
  overflow-x: auto;
  padding: 6px 11px;
  gap: 5.5px;
  min-width: 86px;
}

.archive-service1-buttonsbutton21 {
  padding: 6px 10px 6px 12px;
  gap: 5.4px;
}

.archive-service1-buttonsbutton-parent {
  width: 385.4px;
  flex-direction: row;
  gap: 16px;
  text-align: left;
  color: #344054;
}

.archive-service1-badge-details,
.archive-service1-buttonsbutton-parent,
.archive-service1-divider-group {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-badge-details {
  flex: 1;
  flex-direction: column;
  padding: 0 0 8px;
  box-sizing: border-box;
  gap: 47px;
}

.archive-service1-divider-group {
  align-self: stretch;
  flex-direction: row;
  position: relative;
  text-align: center;
  font-size: 14px;
  color: #98a2b3;
}

.archive-service1-divider-icon9 {
  height: 1px;
  width: 968px;
  position: absolute;
  margin: 0 !important;
  right: -282px;
  bottom: 62px;
  z-index: 2;
}

.archive-service1-name-value {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 51px;
}

.archive-service1-badge20 {
  width: 69px;
  border-radius: 9999px;
  background-color: #fffdfd;
  border: 1px solid #da2d20;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 8px;
  gap: 6px;
  mix-blend-mode: normal;
  z-index: 2;
}

.archive-service1-badge22,
.archive-service1-first-badge-content {
  flex: 1;
  justify-content: flex-start;
}

.archive-service1-first-badge-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  min-width: 114px;
  text-align: center;
  font-size: 14px;
  color: #da2d20;
}

.archive-service1-badge22 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  padding: 0 7px;
  white-space: nowrap;
  z-index: 2;
}

.archive-service1-badge-list-item,
.archive-service1-badge22,
.archive-service1-badge23 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.archive-service1-badge23 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  justify-content: flex-start;
  padding: 0 7px;
  z-index: 2;
}

.archive-service1-badge-list-item {
  align-self: stretch;
  justify-content: flex-end;
  padding: 0 2px 0 0;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.archive-service1-location-item,
.archive-service1-work-calendar {
  display: flex;
  justify-content: flex-start;
}

.archive-service1-location-item {
  width: 163px;
  flex-direction: column;
  align-items: flex-end;
  gap: 56px;
}

.archive-service1-work-calendar {
  flex-direction: row;
  align-items: flex-start;
  padding: 0 14px;
}

.archive-service1-work-type-value {
  width: 149px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
}

.archive-service1-badge24,
.archive-service1-buttonsbutton23,
.archive-service1-first-badge-details,
.archive-service1-work-item {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-badge24 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  padding: 0 7px;
  white-space: nowrap;
  z-index: 2;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.archive-service1-buttonsbutton23,
.archive-service1-first-badge-details,
.archive-service1-work-item {
  flex: 1;
}

.archive-service1-work-item {
  flex-direction: column;
  gap: 56px;
  min-width: 107px;
}

.archive-service1-buttonsbutton23,
.archive-service1-first-badge-details {
  flex-direction: row;
  gap: 5.5px;
}

.archive-service1-first-badge-details {
  max-width: 100%;
}

.archive-service1-buttonsbutton23 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  padding: 6px 11px;
  min-width: 86px;
  z-index: 2;
}

.archive-service1-buttonsbutton-group {
  width: 385.4px;
  flex-direction: row;
  gap: 16px;
  font-size: 14px;
  color: #344054;
}

.archive-service1-buttonsbutton-group,
.archive-service1-second-badge-divider,
.archive-service1-second-badge-list-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-second-badge-list-parent {
  flex: 1;
  flex-direction: column;
  gap: 47px;
}

.archive-service1-second-badge-divider {
  align-self: stretch;
  flex-direction: row;
  position: relative;
}

.archive-service1-supporting-text15 {
  position: relative;
  letter-spacing: -0.06px;
  line-height: 24px;
  z-index: 2;
}

.archive-service1-supporting-text17 {
  position: relative;
  font-size: 12px;
  line-height: 19px;
  color: #475467;
  display: inline-block;
  min-width: 104px;
  z-index: 3;
}

.archive-service1-summary-content-parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  min-width: 114px;
}

.archive-service1-price-details-title {
  align-self: stretch;
  position: relative;
  line-height: 23px;
  font-weight: 500;
  z-index: 2;
}

.archive-service1-supporting-text18 {
  width: 104px;
  position: relative;
  font-size: 12px;
  line-height: 19px;
  color: #475467;
  display: inline-block;
  z-index: 3;
}

.archive-service1-summary-location-name {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 1px;
  font-size: 14px;
  color: #101828;
}

.archive-service1-location-summary,
.archive-service1-summary-details,
.archive-service1-summary-location-name {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-location-summary {
  width: 152px;
  flex-direction: column;
  gap: 58px;
  min-width: 152px;
}

.archive-service1-summary-details {
  flex: 1;
  flex-direction: row;
  gap: 8px;
  min-width: 218px;
  max-width: 100%;
}

.archive-service1-third-badge-details {
  width: 490px;
  flex-direction: row;
  padding: 0 13px;
  box-sizing: border-box;
  max-width: 100%;
}

.archive-service1-badge25,
.archive-service1-frame-parent5,
.archive-service1-third-badge-details {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-frame-parent5 {
  flex: 1;
  flex-direction: column;
  gap: 107px;
  max-width: 100%;
  font-size: 16px;
  color: #475467;
}

.archive-service1-badge25 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  padding: 0 7px;
  z-index: 2;
}

.archive-service1-first-badges {
  align-self: stretch;
  flex-direction: column;
  gap: 282px;
}

.archive-service1-expanded-content,
.archive-service1-expanded-listing,
.archive-service1-first-badges,
.archive-service1-work-type-badge-list {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-work-type-badge-list {
  width: 93px;
  flex-direction: column;
  padding: 80px 0 0;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.archive-service1-expanded-content,
.archive-service1-expanded-listing {
  max-width: 100%;
}

.archive-service1-expanded-content {
  align-self: stretch;
  flex-direction: row;
  gap: 8px;
}

.archive-service1-expanded-listing {
  flex: 1;
  flex-direction: column;
  padding: 8px 33px 0 0;
  box-sizing: border-box;
  min-width: 422px;
  font-size: 18px;
}

.archive-service1-arrow-square-up-right-icon1 {
  height: 24px;
  width: 24px;
  top: 167px;
  right: 0;
}

.archive-service1-arrow-square-up-right-icon1,
.archive-service1-arrow-square-up-right-icon2,
.archive-service1-dots-vertical-icon3,
.archive-service1-dots-vertical-icon4 {
  position: absolute;
  margin: 0 !important;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 2;
}

.archive-service1-arrow-square-up-right-icon2 {
  height: 24px;
  width: 24px;
  right: 0;
  bottom: 206px;
}

.archive-service1-dots-vertical-icon3,
.archive-service1-dots-vertical-icon4 {
  height: 20px;
  width: 20px;
  right: -10px;
}

.archive-service1-dots-vertical-icon3 {
  top: 260px;
}

.archive-service1-dots-vertical-icon4 {
  bottom: 117px;
}

.archive-service1-price4 {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 32px;
  font-weight: 600;
  white-space: nowrap;
  z-index: 2;
}

.archive-service1-radar-1-1-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  z-index: 2;
}

.archive-service1-text81 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 29px;
  z-index: 3;
}

.archive-service1-text-wrapper3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 57px;
}

.archive-service1-price6 {
  align-self: stretch;
  position: relative;
  font-size: 20px;
  letter-spacing: -0.02em;
  line-height: 32px;
  font-weight: 600;
  color: #6941c6;
  white-space: nowrap;
  z-index: 2;
}

.archive-service1-frame-parent7 {
  align-self: stretch;
  flex-direction: column;
  font-size: 12px;
  color: #475467;
}

.archive-service1-arrow-square-up-right-parent,
.archive-service1-frame-parent6,
.archive-service1-frame-parent7 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-frame-parent6 {
  flex: 1;
  flex-direction: column;
  gap: 229px;
}

.archive-service1-arrow-square-up-right-parent {
  width: 143px;
  flex-direction: row;
  position: relative;
  text-align: center;
  font-size: 20px;
  color: #6941c6;
}

.archive-service1-collapsed-details {
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 5px;
}

.archive-service1-collapsed-content,
.archive-service1-collapsed-details,
.archive-service1-collapsed-listing {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-collapsed-content {
  position: relative;
}

.archive-service1-collapsed-listing {
  padding: 0 0 521px 24px;
  box-sizing: border-box;
  font-size: 14px;
}

.archive-service1-dots-vertical-icon5 {
  height: 20px;
  width: 20px;
  position: absolute;
  margin: 0 !important;
  top: 14px;
  right: 14px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.archive-service1-f4b711e503bc72fe396e5043e0c2-1-icon1 {
  height: 84px;
  width: 84px;
  position: absolute;
  margin: 0 !important;
  top: calc(50% - 42px);
  left: 62px;
  object-fit: cover;
  z-index: 2;
}

.archive-service1-business-image,
.archive-service1-text83 {
  position: relative;
  z-index: 1;
}

.archive-service1-business-image {
  height: 160px;
  width: 160px;
  border-radius: 12px;
  background-color: #f5f5f5;
}

.archive-service1-text83 {
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  min-width: 128px;
}

.archive-service1-text-wrapper4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 8px 0 0;
  font-size: 18px;
  color: #101828;
}

.archive-service1-icon9 {
  width: 12px;
  height: 9.3px;
  position: relative;
  z-index: 1;
}

.archive-service1-business-verified {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px 0 0;
}

.archive-service1-span {
  font-weight: 500;
}

.archive-service1-supporting-text19 {
  height: 19px;
  position: relative;
  line-height: 24px;
  display: inline-block;
  flex-shrink: 0;
  min-width: 63px;
  z-index: 1;
}

.archive-service1-business-role {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4px 16px 0 0;
}

.archive-service1-rating-child {
  width: 16px;
  height: 16px;
  position: relative;
  z-index: 1;
}

.archive-service1-supporting-text20 {
  height: 19px;
  position: relative;
  line-height: 24px;
  display: inline-block;
  flex-shrink: 0;
  min-width: 70.9px;
  z-index: 1;
}

.archive-service1-reviews {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4px 0 0;
}

.archive-service1-application-count,
.archive-service1-business-location,
.archive-service1-business-name-role {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.archive-service1-application-count,
.archive-service1-business-location {
  gap: 7px;
  font-size: 16px;
}

.archive-service1-application-count {
  gap: 8px;
  color: #fff;
}

.archive-service1-badge-one,
.archive-service1-badge-three,
.archive-service1-badge-two {
  position: relative;
  line-height: 23px;
  font-weight: 500;
  z-index: 1;
}

.archive-service1-badge-three,
.archive-service1-badge-two {
  display: inline-block;
}

.archive-service1-badge-two {
  min-width: 103px;
}

.archive-service1-badge-three {
  width: 151px;
  flex-shrink: 0;
}

.archive-service1-badges1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 34px;
}

.archive-service1-supporting-text22 {
  position: relative;
  line-height: 19px;
  display: inline-block;
  min-width: 92.1px;
  z-index: 2;
}

.archive-service1-badge-support {
  width: 161.9px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 20px 0 0;
  box-sizing: border-box;
}

.archive-service1-supporting-text23,
.archive-service1-supporting-text24 {
  position: relative;
  line-height: 19px;
  display: inline-block;
  flex-shrink: 0;
  z-index: 2;
}

.archive-service1-supporting-text23 {
  min-width: 70.9px;
}

.archive-service1-supporting-text24 {
  width: 104px;
}

.archive-service1-badge-support-parent {
  width: 469px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  max-width: 100%;
  font-size: 12px;
  color: #475467;
}

.archive-service1-badges-parent {
  align-self: stretch;
  flex-direction: column;
  font-size: 14px;
  color: #101828;
}

.archive-service1-badges-parent,
.archive-service1-business-details,
.archive-service1-business-listing {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-business-details {
  flex: 1;
  flex-direction: column;
  gap: 16px;
  min-width: 335px;
}

.archive-service1-business-listing {
  width: 700px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 24px;
}

.archive-service1-text84 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 55px;
}

.archive-service1-buttonsbutton25 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 6px 12px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  z-index: 1;
}

.archive-service1-business-actions,
.archive-service1-listing-card-my-radar-bus {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 60px 0 0;
}

.archive-service1-listing-card-my-radar-bus {
  width: 1016px;
  margin: 0 !important;
  position: absolute;
  right: -24px;
  bottom: 265px;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 40px 24px 24px;
  box-sizing: border-box;
  gap: 20px;
  z-index: 1;
  font-size: 12px;
  color: #475467;
}

.archive-service1-text85 {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: center;
  display: inline-block;
  min-width: 66px;
}

.archive-service1-content6 {
  cursor: pointer;
  border: 0;
  padding: 8px 12px;
  background-color: #0c111d;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  white-space: nowrap;
}

.archive-service1-content6:hover {
  background-color: #40454f;
}

.archive-service1-tooltip-icon {
  width: 28px;
  height: 6px;
  position: relative;
}

.archive-service1-tooltip {
  margin: 0 !important;
  position: absolute;
  top: 119px;
  right: 0;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0 4px 6px -2px rgba(16, 24, 40, 0.03);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  z-index: 3;
}

.archive-service1-arrow-square-up-right-icon3,
.archive-service1-arrow-square-up-right-icon4,
.archive-service1-arrow-square-up-right-icon5 {
  height: 24px;
  width: 24px;
  position: absolute;
  margin: 0 !important;
  right: 0;
  bottom: 511px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 2;
}

.archive-service1-arrow-square-up-right-icon4,
.archive-service1-arrow-square-up-right-icon5 {
  bottom: 298px;
  z-index: 3;
}

.archive-service1-arrow-square-up-right-icon5 {
  bottom: 66px;
  z-index: 4;
}

.archive-service1-list-header {
  width: 992px;
  position: relative;
  max-width: 100%;
}

.archive-service1-frame-parent11,
.archive-service1-group-div,
.archive-service1-list-header,
.archive-service1-text-parent4 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-text-parent4 {
  gap: 16px;
  font-size: 18px;
  color: #101828;
}

.archive-service1-frame-parent11,
.archive-service1-group-div {
  gap: 7.3px;
}

.archive-service1-group-div {
  width: 1016px;
  margin: 0 !important;
  position: absolute;
  bottom: 64px;
  left: calc(50% - 508px);
  justify-content: space-between;
  padding: 24px 40px 24px 24px;
  box-sizing: border-box;
  gap: 20px;
  z-index: 1;
}

.archive-service1-divider-icon10 {
  height: 1px;
  width: 100%;
  top: 175px;
  right: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
}

.archive-service1-divider-icon10,
.archive-service1-dots-vertical-icon7,
.archive-service1-text92 {
  position: absolute;
  margin: 0 !important;
  z-index: 2;
}

.archive-service1-text92 {
  top: -9px;
  left: 0;
  line-height: 28px;
  font-weight: 600;
}

.archive-service1-dots-vertical-icon7 {
  height: 20px;
  width: 20px;
  top: -19px;
  right: -10px;
  overflow: hidden;
  flex-shrink: 0;
}

.archive-service1-text93 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 36px;
  z-index: 2;
}

.archive-service1-dots-vertical-parent1,
.archive-service1-text-wrapper7 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-text-wrapper7 {
  flex: 1;
}

.archive-service1-dots-vertical-parent1 {
  width: 177px;
  position: relative;
}

.archive-service1-rectangle-textarea,
.archive-service1-text94 {
  position: absolute;
  margin: 0 !important;
}

.archive-service1-text94 {
  bottom: 132px;
  left: 184px;
  line-height: 28px;
  font-weight: 600;
  z-index: 2;
}

.archive-service1-rectangle-textarea {
  border: 1px solid #eaecf0;
  background-color: #fff;
  height: 301px;
  width: 1016px;
  outline: 0;
  top: -60px;
  right: -307px;
  border-radius: 12px;
  box-sizing: border-box;
  z-index: 1;
}

.archive-service1-supporting-text31 {
  position: relative;
  letter-spacing: -0.17px;
  line-height: 24px;
  display: inline-block;
  min-width: 83px;
  z-index: 2;
}

.archive-service1-price-label {
  position: relative;
  line-height: 23px;
  font-weight: 500;
  z-index: 2;
}

.archive-service1-supporting-text34 {
  position: relative;
  font-size: 12px;
  line-height: 19px;
  color: #475467;
  display: inline-block;
  min-width: 113px;
  z-index: 3;
}

.archive-service1-contact-details,
.archive-service1-detail-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.archive-service1-detail-item {
  align-items: flex-end;
  gap: 10px;
  max-width: 100%;
}

.archive-service1-contact-details {
  flex: 1;
  align-items: flex-start;
  gap: 8px;
  min-width: 104px;
}

.archive-service1-text95 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 70px;
}

.archive-service1-buttonsbutton27,
.archive-service1-buttonsbutton28,
.archive-service1-contact-actions {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-buttonsbutton27 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 6px 12px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  flex-direction: row;
  gap: 6px;
  z-index: 2;
}

.archive-service1-buttonsbutton28,
.archive-service1-contact-actions {
  flex-direction: column;
  padding: 6px 5px 0 0;
}

.archive-service1-buttonsbutton28 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow-x: auto;
  flex-direction: row;
  padding: 6px 11px;
  gap: 5.5px;
  z-index: 2;
}

.archive-service1-lock-action1 {
  flex-direction: column;
  padding: 6px 0 0;
  color: #344054;
}

.archive-service1-contact-details-parent,
.archive-service1-detail-item-parent,
.archive-service1-lock-action1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-contact-details-parent {
  align-self: stretch;
  flex-direction: row;
  gap: 11px;
  font-size: 14px;
  color: #101828;
}

.archive-service1-detail-item-parent {
  flex: 1;
  flex-direction: column;
  gap: 41px;
  min-width: 289px;
  max-width: 100%;
}

.archive-service1-supporting-text35 {
  width: 113px;
  position: relative;
  font-size: 12px;
  line-height: 19px;
  color: #475467;
  display: inline-block;
  z-index: 3;
}

.archive-service1-buttonsbutton-wrapper2,
.archive-service1-buttonsbutton29 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-buttonsbutton29 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow-x: auto;
  padding: 6px 10px 6px 12px;
  gap: 5.4px;
  z-index: 2;
}

.archive-service1-buttonsbutton-wrapper2 {
  padding: 0 8px;
  color: #344054;
}

.archive-service1-frame-parent22,
.archive-service1-second-listing-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.archive-service1-frame-parent22 {
  align-self: stretch;
  justify-content: flex-start;
  gap: 47px;
}

.archive-service1-second-listing-details {
  width: 233px;
  justify-content: flex-end;
  padding: 0 0 6px;
  box-sizing: border-box;
  min-width: 233px;
  font-size: 14px;
  color: #101828;
}

.archive-service1-listing-detail {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 7.3px;
  max-width: 100%;
  color: #fff;
}

.archive-service1-pair-container-parent,
.archive-service1-third-listing-details {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.archive-service1-pair-container-parent {
  justify-content: flex-start;
  gap: 8px;
  margin-left: -9px;
}

.archive-service1-third-listing-details {
  width: 538px;
  justify-content: flex-end;
  padding: 0 37px;
  box-sizing: border-box;
  max-width: 100%;
}

.archive-service1-frame-parent19,
.archive-service1-frame-wrapper11 {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-frame-parent19 {
  align-items: flex-end;
  gap: 108px;
  font-size: 16px;
  color: #475467;
}

.archive-service1-frame-wrapper11 {
  align-items: flex-start;
  padding: 7px 0 0;
  box-sizing: border-box;
  min-width: 445px;
}

.archive-service1-price7,
.archive-service1-total {
  position: relative;
  display: inline-block;
}

.archive-service1-price7 {
  flex: 1;
  letter-spacing: -0.02em;
  line-height: 32px;
  font-weight: 600;
  min-width: 93px;
  white-space: nowrap;
  z-index: 3;
}

.archive-service1-total {
  line-height: 20px;
  min-width: 15px;
  z-index: 2;
}

.archive-service1-price-parent2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 7.5px;
}

.archive-service1-key5 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 83px;
  z-index: 4;
}

.archive-service1-radar-1-1-parent3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  z-index: 2;
}

.archive-service1-pair-container-container,
.archive-service1-pair-container-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.archive-service1-pair-container-wrapper {
  justify-content: center;
  padding: 0 21px 0 20px;
  font-size: 12px;
  color: #475467;
}

.archive-service1-pair-container-container {
  justify-content: flex-start;
  padding: 0 30px;
}

.archive-service1-frame-parent24,
.archive-service1-pair-container-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.archive-service1-pair-container-group {
  width: 143px;
  align-items: flex-start;
  font-size: 12px;
  color: #475467;
}

.archive-service1-frame-parent24 {
  flex: 1;
  align-items: flex-end;
  gap: 253px;
}

.archive-service1-fourth-listing,
.archive-service1-frame-parent18 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.archive-service1-fourth-listing {
  width: 316px;
  justify-content: flex-start;
  position: relative;
  min-width: 316px;
  margin-left: -33px;
  text-align: center;
  font-size: 20px;
  color: #6941c6;
}

.archive-service1-frame-parent18 {
  align-self: stretch;
  justify-content: center;
  max-width: 100%;
  row-gap: 20px;
  text-align: left;
  font-size: 18px;
  color: #101828;
}

.archive-service1-frame-parent17 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
  text-align: center;
  font-size: 12px;
  color: #475467;
}

.archive-service1-text99 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 72px;
}

.archive-service1-buttonsbutton30 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  flex-direction: row;
  justify-content: flex-start;
  padding: 6px 12px;
  gap: 6px;
  z-index: 1;
}

.archive-service1-buttonsbutton30,
.archive-service1-expand-action,
.archive-service1-frame-parent16 {
  display: flex;
  align-items: flex-start;
}

.archive-service1-expand-action {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 20px;
  font-size: 14px;
  color: #344054;
}

.archive-service1-frame-parent16 {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: 280px;
  max-width: 100%;
  font-size: 18px;
  color: #101828;
}

.archive-service1-arrow-square-up-right-icon6,
.archive-service1-arrow-square-up-right-icon7,
.archive-service1-arrow-square-up-right-icon8 {
  height: 24px;
  width: 24px;
  position: absolute;
  margin: 0 !important;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 2;
}

.archive-service1-arrow-square-up-right-icon6 {
  top: 206px;
  right: 0;
}

.archive-service1-arrow-square-up-right-icon7,
.archive-service1-arrow-square-up-right-icon8 {
  right: -3px;
  bottom: 310px;
}

.archive-service1-arrow-square-up-right-icon8 {
  bottom: 97px;
  z-index: 3;
}

.archive-service1-magnifier-1-icon8 {
  height: 20px;
  width: 20px;
  position: absolute;
  margin: 0 !important;
  top: 75px;
  right: 93px;
  object-fit: cover;
  z-index: 2;
}

.archive-service1-listing-wrapper-inner {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 25px 0 24px;
  box-sizing: border-box;
  max-width: 100%;
  font-size: 12px;
  color: #475467;
}

.archive-service1-text100,
.archive-service1-text101 {
  position: relative;
  font-family: Inter;
}

.archive-service1-text100 {
  margin: 0;
  font-size: 26px;
  line-height: 28px;
  font-weight: 600;
  color: #101828;
  text-align: left;
  display: inline-block;
  min-width: 128px;
}

.archive-service1-text101 {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #6941c6;
  text-align: center;
}

.archive-service1-badge27,
.archive-service1-text-and-badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.archive-service1-badge27 {
  border-radius: 9999px;
  background-color: #f9f5ff;
  border: 1px solid #e9d7fe;
  padding: 0 7px;
  white-space: nowrap;
}

.archive-service1-text-and-badge {
  align-self: stretch;
  padding: 0 359px 0 0;
  gap: 8px;
}

.archive-service1-supporting-text40 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.archive-service1-text-and-supporting-text1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
  min-width: 424px;
  max-width: 100%;
}

.archive-service1-text104 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 45px;
}

.archive-service1-button5 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 13px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.archive-service1-text105 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 92px;
}

.archive-service1-button6 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 13px;
  background-color: #7e56d9;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.archive-service1-dots-vertical-icon9 {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.archive-service1-content7 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 20px 24px 0;
  box-sizing: border-box;
  gap: 16px;
}

.archive-service1-card-header,
.archive-service1-content7,
.archive-service1-divider-icon11 {
  align-self: stretch;
  max-width: 100%;
}

.archive-service1-divider-icon11 {
  position: relative;
  overflow: hidden;
  max-height: 100%;
}

.archive-service1-card-header {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  z-index: 1;
}

.archive-service1-text106 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 51px;
}

.archive-service1-button-group-base9 {
  width: 83px;
  background-color: #f9fafb;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  min-height: 40px;
}

.archive-service1-text107 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 65px;
}

.archive-service1-button-group-base10,
.archive-service1-button-group-base16 {
  background-color: #fff;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 40px;
}

.archive-service1-button-group-base10 {
  cursor: pointer;
  border: 0;
  padding: 10px 15px;
  width: 97px;
  flex-shrink: 0;
  display: flex;
}

.archive-service1-button-group-base10:hover {
  background-color: #e6e6e6;
}

.archive-service1-button-group-base16 {
  align-self: stretch;
  display: none;
  padding: 8px 16px;
  z-index: 1;
}

.archive-service1-text114 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 61px;
}

.archive-service1-button-group-base17 {
  width: 93px;
  background-color: #fff;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  min-height: 40px;
}

.archive-service1-button-group1 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-label3,
.archive-service1-supporting-text41,
.archive-service1-text115 {
  position: relative;
  font-family: Inter;
  text-align: left;
}

.archive-service1-label3 {
  width: 47px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #344054;
  display: none;
}

.archive-service1-supporting-text41,
.archive-service1-text115 {
  font-size: 16px;
  line-height: 24px;
}

.archive-service1-text115 {
  width: calc(100% - 228px);
  border: 0;
  outline: 0;
  font-weight: 500;
  background-color: transparent;
  height: 24px;
  flex: 1;
  color: #101828;
  display: inline-block;
  min-width: 82px;
  padding: 0;
}

.archive-service1-supporting-text41 {
  align-self: stretch;
  width: 54px;
  color: #475467;
  display: none;
}

.archive-service1-content9,
.archive-service1-input-with-label2,
.archive-service1-input2 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-content9 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0 208px 0 0;
  box-sizing: border-box;
  gap: 8px;
}

.archive-service1-input-with-label2,
.archive-service1-input2 {
  align-self: stretch;
}

.archive-service1-input2 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05),
    0 0 0 4px rgba(158, 119, 237, 0.24);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d6bbfb;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
}

.archive-service1-input-with-label2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.archive-service1-hint-text2 {
  width: 320px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: none;
}

.archive-service1-input-dropdown2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  max-width: 400px;
}

.archive-service1-text116 {
  text-decoration: none;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 43px;
}

.archive-service1-actions2,
.archive-service1-button7,
.archive-service1-content8 {
  display: flex;
  flex-direction: row;
}

.archive-service1-button7 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 10px 13px;
  background-color: #fff;
  width: 99px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.archive-service1-actions2,
.archive-service1-content8 {
  max-width: 100%;
}

.archive-service1-actions2 {
  width: 511px;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.archive-service1-content8 {
  align-self: stretch;
  border-radius: 12px;
  align-items: flex-start;
  justify-content: space-between;
  padding: 12px 16px;
  box-sizing: border-box;
  gap: 20px;
}

.archive-service1-filters-bar {
  background-color: #fff;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  z-index: 2;
}

.archive-service1-card-header-parent,
.archive-service1-filters-bar {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-text117,
.archive-service1-text118 {
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  font-family: Inter;
  color: #101828;
  text-align: left;
}

.archive-service1-text118 {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #6941c6;
  text-align: center;
  display: inline-block;
  min-width: 56px;
}

.archive-service1-badge28,
.archive-service1-text-and-badge1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.archive-service1-badge28 {
  width: 72px;
  border-radius: 9999px;
  background-color: #f9f5ff;
  border: 1px solid #e9d7fe;
  box-sizing: border-box;
  padding: 0 7px;
  white-space: nowrap;
}

.archive-service1-text-and-badge1 {
  align-self: stretch;
  padding: 0 666px 0 0;
  gap: 8px;
}

.archive-service1-supporting-text42 {
  width: 697px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.archive-service1-button8,
.archive-service1-button9,
.archive-service1-text-and-supporting-text2 {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  gap: 4px;
}

.archive-service1-text-and-supporting-text2 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 0;
  max-width: 100%;
}

.archive-service1-button8,
.archive-service1-button9 {
  height: 40px;
  border-radius: 8px;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
}

.archive-service1-button9 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  background-color: #fff;
  border: 1px solid #d6bbfb;
  padding: 10px 13px;
}

.archive-service1-text121 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
}

.archive-service1-button10 {
  height: 40px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 13px;
  gap: 4px;
}

.archive-service1-text122 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
}

.archive-service1-button11 {
  height: 40px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #7e56d9;
  border: 1px solid #7e56d9;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 13px;
  gap: 4px;
}

.archive-service1-actions3,
.archive-service1-card-header1 {
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-actions3 {
  display: none;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.archive-service1-card-header1 {
  align-self: stretch;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex-shrink: 0;
}

.archive-service1-checkbox-base {
  margin: 0;
  height: 20px;
  width: 20px;
  position: relative;
  border-radius: 6px;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
}

.archive-service1-text123 {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 34px;
}

.archive-service1-arrow-down-icon {
  width: 16px;
  height: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.archive-service1-table-header-cell,
.archive-service1-table-header-cell-inner,
.archive-service1-text-parent9 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-text-parent9 {
  flex-direction: row;
  gap: 4px;
}

.archive-service1-table-header-cell,
.archive-service1-table-header-cell-inner {
  flex-direction: column;
  padding: 1px 0 0;
}

.archive-service1-table-header-cell {
  width: 441px;
  margin: 0 !important;
  position: absolute;
  top: 0;
  left: -380px;
  background-color: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  flex-direction: row;
  padding: 12px 24px 10px;
  gap: 12px;
}

.archive-service1-checkbox-base1 {
  margin: 0;
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: 6px;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
}

.archive-service1-checkbox-base-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 0 0;
}

.archive-service1-avatar-online-indicator,
.archive-service1-contrast-border1 {
  position: absolute;
  border-radius: 9999px;
  box-sizing: border-box;
  overflow: hidden;
}

.archive-service1-contrast-border1 {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  display: none;
}

.archive-service1-avatar-online-indicator {
  right: -1.5px;
  bottom: -1.5px;
  background-color: #17b26a;
  border: 1.5px solid #fff;
  width: 13px;
  height: 13px;
  z-index: 1;
}

.archive-service1-avatar1 {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 9999px;
  background-image: url("../../../icons/Gerator/archive_page/avatar1@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.archive-service1-supporting-text43,
.archive-service1-text124 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  text-align: left;
  display: inline-block;
}

.archive-service1-text124 {
  font-weight: 500;
  color: #101828;
  min-width: 75px;
}

.archive-service1-supporting-text43 {
  color: #475467;
  min-width: 102px;
  white-space: nowrap;
}

.archive-service1-table-cell {
  width: 405px;
  margin: 0 !important;
  position: absolute;
  top: 44px;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.archive-service1-text125 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #101828;
  text-align: left;
  display: inline-block;
  min-width: 96px;
}

.archive-service1-table-cell1 {
  width: 467px;
  margin: 0 !important;
  position: absolute;
  top: 116px;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.archive-service1-text126 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #101828;
  text-align: left;
  display: inline-block;
  min-width: 84px;
}

.archive-service1-table-cell2 {
  width: 428px;
  margin: 0 !important;
  position: absolute;
  top: 188px;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.archive-service1-supporting-text46,
.archive-service1-text127 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  text-align: left;
  display: inline-block;
}

.archive-service1-text127 {
  font-weight: 500;
  color: #101828;
  min-width: 103px;
  white-space: nowrap;
}

.archive-service1-supporting-text46 {
  color: #475467;
  min-width: 102px;
}

.archive-service1-table-cell3 {
  width: 411px;
  margin: 0 !important;
  position: absolute;
  top: 260px;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.archive-service1-text128 {
  flex: 1;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #667085;
  text-align: center;
  z-index: 1;
}

.archive-service1-avatar5,
.archive-service1-contrast-border5 {
  border-radius: 9999px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-contrast-border5 {
  height: 40px;
  width: 40px;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  padding: 8px 0;
}

.archive-service1-avatar5 {
  background-color: #f2f4f7;
}

.archive-service1-text129 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #101828;
  text-align: left;
  display: inline-block;
  min-width: 82px;
}

.archive-service1-table-cell4 {
  width: 428px;
  margin: 0 !important;
  position: absolute;
  top: 332px;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.archive-service1-text130 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #101828;
  text-align: left;
  display: inline-block;
  min-width: 78px;
}

.archive-service1-table-cell5 {
  width: 428px;
  margin: 0 !important;
  position: absolute;
  bottom: 288px;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.archive-service1-text131 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #101828;
  text-align: left;
  display: inline-block;
  min-width: 74px;
}

.archive-service1-table-cell6,
.archive-service1-table-cell7 {
  width: 411px;
  margin: 0 !important;
  position: absolute;
  bottom: 216px;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.archive-service1-table-cell7 {
  width: 428px;
  bottom: 144px;
}

.archive-service1-text134 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #101828;
  text-align: left;
  display: inline-block;
  min-width: 67px;
}

.archive-service1-table-cell8 {
  width: 428px;
  margin: 0 !important;
  position: absolute;
  bottom: 72px;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.archive-service1-text135 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #101828;
  text-align: left;
  display: inline-block;
  min-width: 95px;
}

.archive-service1-table-cell9 {
  width: 428px;
  margin: 0 !important;
  position: absolute;
  bottom: 0;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
  z-index: 1;
}

.archive-service1-text136 {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 50px;
}

.archive-service1-table-header,
.archive-service1-table-header-cell1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.archive-service1-table-header {
  gap: 4px;
}

.archive-service1-table-header-cell1 {
  width: 255px;
  background-color: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 13px 24px 11px;
}

.archive-service1-text137 {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Inter;
  color: #344054;
  text-align: center;
  display: inline-block;
  min-width: 93px;
}

.archive-service1-table-cell10 {
  width: 255px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 26px 24px 24px;
}

.archive-service1-text138 {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Inter;
  color: #344054;
  text-align: center;
  display: inline-block;
  min-width: 85px;
}

.archive-service1-marker-pin-02-group,
.archive-service1-table-cell11 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.archive-service1-marker-pin-02-group {
  gap: 7px;
}

.archive-service1-table-cell11 {
  width: 247px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 26px 24px 24px;
}

.archive-service1-text139 {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Inter;
  color: #344054;
  text-align: center;
  display: inline-block;
  min-width: 91px;
}

.archive-service1-table-cell15,
.archive-service1-table-cell16,
.archive-service1-table-cell18 {
  width: 279px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 26px 24px 24px;
}

.archive-service1-table-cell16,
.archive-service1-table-cell18 {
  width: 271px;
}

.archive-service1-table-cell18 {
  width: 239px;
}

.archive-service1-column,
.archive-service1-table-header-cell-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-column {
  width: 120px;
  flex-direction: column;
  padding: 0;
  box-sizing: border-box;
  z-index: 2;
}

.archive-service1-table-header-cell-parent {
  flex-direction: row;
  position: relative;
}

.archive-service1-frame-wrapper12 {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-text147 {
  width: calc(100% - 16px);
  border: 0;
  outline: 0;
  font-weight: 500;
  font-family: Inter;
  font-size: 12px;
  background-color: transparent;
  height: 18px;
  flex: 1;
  position: relative;
  line-height: 18px;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 41px;
  padding: 0;
}

.archive-service1-help-icon {
  height: 16px;
  width: 16px;
  position: relative;
}

.archive-service1-table-header-cell2,
.archive-service1-table-header1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.archive-service1-table-header1 {
  width: 88px;
  gap: 4px;
}

.archive-service1-table-header-cell2 {
  width: 297px;
  background-color: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 13px 24px 11px;
}

.archive-service1-text148 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  white-space: nowrap;
}

.archive-service1-table-cell20,
.archive-service1-table-cell21 {
  width: 297px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 26px 24px 24px;
}

.archive-service1-table-cell21 {
  width: 407px;
  max-width: 232%;
  flex-shrink: 0;
}

.archive-service1-text153 {
  width: 172px;
  border: 0;
  outline: 0;
  font-family: Inter;
  font-size: 14px;
  background-color: transparent;
  height: 20px;
  position: relative;
  line-height: 20px;
  color: #475467;
  text-align: left;
  display: inline-block;
  white-space: nowrap;
  padding: 0;
}

.archive-service1-table-cell25 {
  width: 328px;
  border-bottom: 1px solid #eaecf0;
  flex-direction: row;
  align-items: center;
  padding: 26px 24px 24px;
  max-width: 187%;
  flex-shrink: 0;
}

.archive-service1-column1,
.archive-service1-table-cell25,
.archive-service1-table-cell29 {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
}

.archive-service1-table-cell29 {
  width: 287px;
  border-bottom: 1px solid #eaecf0;
  flex-direction: row;
  align-items: center;
  padding: 26px 24px 24px;
}

.archive-service1-column1 {
  width: 176px;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  z-index: 1;
}

.archive-service1-text158 {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 37px;
}

.archive-service1-table-cell30,
.archive-service1-table-header-cell3,
.archive-service1-table-header2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.archive-service1-table-header2 {
  flex: 1;
  justify-content: flex-start;
  gap: 4px;
}

.archive-service1-table-cell30,
.archive-service1-table-header-cell3 {
  border-bottom: 1px solid #eaecf0;
  justify-content: center;
}

.archive-service1-table-header-cell3 {
  cursor: pointer;
  border: 0;
  padding: 13px 41px 11px;
  background-color: #f9fafb;
  align-self: stretch;
}

.archive-service1-table-cell30 {
  padding: 26px 50px 24px;
}

.archive-service1-column2,
.archive-service1-dropdown6 {
  width: 21px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-column2 {
  width: 120px;
  align-items: flex-end;
  padding: 0;
  box-sizing: border-box;
  z-index: 1;
}

.archive-service1-frame-parent26 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 100%;
  gap: 20px;
}

.archive-service1-text159 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 59px;
}

.archive-service1-buttonsbutton31 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 6px 11px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.archive-service1-button-wrap,
.archive-service1-button-wrap-wrapper {
  display: flex;
  justify-content: flex-start;
}

.archive-service1-button-wrap {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
}

.archive-service1-button-wrap-wrapper {
  width: 326px;
  flex-direction: column;
  align-items: flex-start;
  padding: 2px 0 0;
  box-sizing: border-box;
  max-width: 100%;
}

.archive-service1-number {
  width: 7px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #18222f;
  text-align: center;
  display: inline-block;
  min-width: 7px;
}

.archive-service1-content11,
.archive-service1-pagination-number-base {
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-content11 {
  flex: 1;
  padding: 10px 16px;
}

.archive-service1-pagination-number-base {
  width: 40px;
  background-color: #f9fafb;
  overflow: hidden;
  flex-shrink: 0;
}

.archive-service1-number1 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #475467;
  text-align: center;
  display: inline-block;
  min-width: 9px;
}

.archive-service1-content12,
.archive-service1-pagination-number-base1 {
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-content12 {
  flex: 1;
  padding: 10px 15px;
}

.archive-service1-pagination-number-base1 {
  width: 40px;
  overflow: hidden;
  flex-shrink: 0;
}

.archive-service1-number2 {
  width: 10px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #475467;
  text-align: center;
  display: inline-block;
  min-width: 10px;
}

.archive-service1-content13,
.archive-service1-pagination-number-base2 {
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-content13 {
  padding: 10px 15px;
}

.archive-service1-pagination-number-base2 {
  overflow: hidden;
}

.archive-service1-number3 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #475467;
  text-align: center;
  display: inline-block;
  min-width: 13px;
}

.archive-service1-content14 {
  flex: 1;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 13px;
}

.archive-service1-number6 {
  width: 16px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #475467;
  text-align: center;
  display: inline-block;
  min-width: 16px;
}

.archive-service1-content17,
.archive-service1-pagination-number-base-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-content17 {
  border-radius: 8px;
  flex-direction: row;
  padding: 10px 12px;
}

.archive-service1-pagination-number-base-wrapper {
  flex-direction: column;
  padding: 0 10px 0 0;
}

.archive-service1-text160 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 32px;
}

.archive-service1-button-wrap1,
.archive-service1-buttonsbutton32 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.archive-service1-buttonsbutton32 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 6px 11px;
  background-color: #fff;
  width: 84px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
  gap: 4px;
}

.archive-service1-button-wrap1 {
  align-self: stretch;
  justify-content: flex-end;
}

.archive-service1-next-page-button {
  flex: 1;
  flex-direction: column;
  padding: 2px 0 0;
  box-sizing: border-box;
  min-width: 212px;
}

.archive-service1-next-page-button,
.archive-service1-page-navigation,
.archive-service1-pagination,
.archive-service1-table {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-page-navigation {
  flex: 1;
  flex-direction: row;
  gap: 2px;
  min-width: 409px;
}

.archive-service1-pagination,
.archive-service1-table {
  align-self: stretch;
  box-sizing: border-box;
}

.archive-service1-pagination {
  border-top: 1px solid #eaecf0;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 12px 24px 16px;
  gap: 12px;
  z-index: 3;
}

.archive-service1-table {
  box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  overflow: hidden;
  flex-direction: column;
  z-index: 1;
}

.archive-service1-text161,
.archive-service1-text162 {
  position: relative;
  font-family: Inter;
  display: inline-block;
}

.archive-service1-text161 {
  text-decoration: none;
  font-size: 26px;
  line-height: 28px;
  font-weight: 600;
  color: #101828;
  text-align: left;
  min-width: 128px;
}

.archive-service1-text162 {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #6941c6;
  text-align: center;
  min-width: 73px;
}

.archive-service1-badge29,
.archive-service1-text-and-badge2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.archive-service1-badge29 {
  width: 89px;
  border-radius: 9999px;
  background-color: #f9f5ff;
  border: 1px solid #e9d7fe;
  box-sizing: border-box;
  padding: 0 7px;
  white-space: nowrap;
}

.archive-service1-text-and-badge2 {
  align-self: stretch;
  padding: 0 440px 0 0;
  gap: 8px;
}

.archive-service1-text-and-supporting-text3 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
  min-width: 432px;
  max-width: 100%;
}

.archive-service1-text166 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 79px;
}

.archive-service1-button-group-base18,
.archive-service1-button-group-base19 {
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  min-height: 40px;
}

.archive-service1-button-group-base18 {
  cursor: pointer;
  border: 0;
  background-color: #f9fafb;
  width: 85px;
  white-space: nowrap;
}

.archive-service1-button-group-base18:hover {
  background-color: #e0e0e0;
}

.archive-service1-button-group-base19 {
  background-color: #fff;
}

.archive-service1-text175 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 89px;
}

.archive-service1-button-group2 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 20px;
}

.archive-service1-text176 {
  width: calc(100% - 318px);
  border: 0;
  outline: 0;
  font-weight: 500;
  font-family: Inter;
  font-size: 16px;
  background-color: transparent;
  height: 24px;
  flex: 1;
  position: relative;
  line-height: 24px;
  color: #101828;
  text-align: left;
  display: inline-block;
  min-width: 28px;
  padding: 0;
}

.archive-service1-card-header-group,
.archive-service1-content20 {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-content20 {
  flex-direction: row;
  align-items: center;
  padding: 0 298px 0 0;
  box-sizing: border-box;
  gap: 8px;
}

.archive-service1-card-header-group {
  flex-direction: column;
  align-items: flex-start;
}

.archive-service1-featured-icon {
  width: 48px;
  height: 48px;
  position: relative;
  border-radius: 10px;
}

.archive-service1-supporting-text55,
.archive-service1-text178 {
  align-self: stretch;
  position: relative;
  font-family: Inter;
  text-align: center;
}

.archive-service1-text178 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #101828;
}

.archive-service1-supporting-text55 {
  font-size: 14px;
  line-height: 20px;
  color: #475467;
}

.archive-service1-content21,
.archive-service1-text-and-supporting-text4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.archive-service1-text-and-supporting-text4 {
  width: 352px;
  gap: 4px;
  max-width: 352px;
}

.archive-service1-content21 {
  align-self: stretch;
  padding: 0 20px;
  gap: 16px;
}

.archive-service1-text179 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 98px;
}

.archive-service1-buttonsbutton33 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 15px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.archive-service1-text180 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 94px;
}

.archive-service1-buttonsbutton34 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 15px;
  background-color: #7e56d9;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.archive-service1-actions6,
.archive-service1-empty-state1 {
  justify-content: flex-start;
}

.archive-service1-actions6 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
}

.archive-service1-empty-state1 {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.archive-service1-empty-state,
.archive-service1-empty-state1,
.archive-service1-frame-form {
  display: flex;
  max-width: 100%;
}

.archive-service1-empty-state {
  width: 100%;
  margin: 0 !important;
  position: absolute;
  right: 0;
  bottom: 151px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.archive-service1-frame-form {
  margin: 0;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 418px;
  box-sizing: border-box;
  position: relative;
  gap: 23.5px;
}

.archive-service1-bifocal-optometry-measurement-icon {
  width: 391px;
  position: relative;
  max-height: 100%;
  object-fit: cover;
  max-width: 100%;
}

.archive-service1-supporting-text56,
.archive-service1-text181 {
  align-self: stretch;
  position: relative;
}

.archive-service1-text181 {
  margin: 0;
  font-size: inherit;
  line-height: 30px;
  font-weight: 600;
  font-family: inherit;
}

.archive-service1-supporting-text56 {
  font-size: 16px;
  line-height: 24px;
  color: #475467;
}

.archive-service1-content22,
.archive-service1-text-and-supporting-text5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.archive-service1-text-and-supporting-text5 {
  width: 352px;
  gap: 8px;
  max-width: 352px;
}

.archive-service1-content22 {
  align-self: stretch;
  padding: 0 20px;
  box-sizing: border-box;
  gap: 20px;
  max-width: 100%;
}

.archive-service1-text183 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 88px;
}

.archive-service1-empty-state2,
.archive-service1-empty-state3 {
  display: flex;
  align-items: center;
  max-width: 100%;
}

.archive-service1-empty-state3 {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: 32px;
}

.archive-service1-empty-state2 {
  align-self: stretch;
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  padding: 41.5px 0;
  box-sizing: border-box;
  z-index: 1;
  text-align: center;
  font-size: 20px;
}


.archive-service1-listing-wrapper {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}

.archive-service1-listing-wrapper {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
  text-align: left;
  font-size: 18px;
  color: #101828;
  font-family: Inter;
}



.archive-service1-frame-child5 {
  height: 282px;
  width: 1016px;
  position: absolute;
  margin: 0 !important;
  right: -844px;
  bottom: -110px;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  z-index: 1;
}

.archive-service1-rectangle-icon {
  height: 148px;
  width: 148px;
  position: relative;
  border-radius: 12px;
  object-fit: cover;
  z-index: 2;
}

.archive-service1-rectangle-parent2 {
  top: 1098px;
  left: 418px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-frame-child6,
.archive-service1-rectangle-parent2,
.archive-service1-rectangle-parent3 {
  margin: 0 !important;
  position: absolute;
}

.archive-service1-frame-child6 {
  border: 1px solid #eaecf0;
  background-color: #fff;
  height: 282px;
  width: 1016px;
  outline: 0;
  right: -844px;
  bottom: -110px;
  border-radius: 12px;
  box-sizing: border-box;
  z-index: 1;
}

.archive-service1-rectangle-parent3 {
  top: 1404px;
  left: 418px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-dots-vertical-icon22,
.archive-service1-list-footer {
  position: absolute;
  margin: 0 !important;
  z-index: 2;
}

.archive-service1-list-footer {
  top: 1098px;
  right: 212px;
  line-height: 28px;
  font-weight: 600;
}

.archive-service1-dots-vertical-icon22 {
  width: 20px;
  height: 20px;
  top: 1088px;
  right: 44px;
  overflow: hidden;
  flex-shrink: 0;
}

.archive-service1-listing-footer-graphics-child {
  height: 208px;
  width: 1016px;
  position: absolute;
  margin: 0 !important;
  top: calc(50% - 104px);
  right: -870px;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  z-index: 1;
}

.archive-service1-f4b711e503bc72fe396e5043e0c2-7-icon {
  height: 84px;
  width: 84px;
  position: relative;
  object-fit: cover;
  z-index: 3;
}

.archive-service1-listing-footer-graphics {
  margin: 0 !important;
  position: absolute;
  top: 2769px;
  left: 456px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-footer-text {
  position: relative;
  line-height: 20px;
}

.archive-service1-footer-text-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.archive-service1-gerator-10-2-icon {
  height: 38px;
  width: 38px;
  position: relative;
  object-fit: cover;
}

.archive-service1-logomark1 {
  box-shadow: 0 1.3px 3.75px rgba(16, 24, 40, 0.1),
    0 1.3px 2.5px rgba(16, 24, 40, 0.06);
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-frame-parent27,
.archive-service1-logomark,
.archive-service1-logomark1 {
  display: flex;
  flex-direction: row;
}

.archive-service1-logomark {
  filter: drop-shadow(0 1.3px 3.75px rgba(16, 24, 40, 0.1)) drop-shadow(0 1.3px 2.5px rgba(16, 24, 40, 0.06));
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-frame-parent27 {
  align-items: center;
  justify-content: center;
  gap: 29px;
}

.archive-service1-content23,
.archive-service1-logo1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-content23 {
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: center;
  gap: 20px;
}

.archive-service1-container1,
.archive-service1-footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.archive-service1-container1 {
  width: 100%;
  align-items: flex-start;
  padding: 0 32px;
  box-sizing: border-box;
  max-width: 1280px;
}

.archive-service1-footer {
  align-self: stretch;
  background-color: #fff;
  overflow: hidden;
  align-items: center;
  padding: 48px 80px;
  z-index: 1;
  text-align: left;
  font-size: 16px;
  color: #475467;
  font-family: Inter;
}

.archive-service1-divider-icon14 {
  height: 1488px;
  width: 2px;
  position: absolute;
  margin: 0 !important;
  top: -11px;
  right: 0;
  object-fit: contain;
  z-index: 4;
}

.archive-service1-frame-child8 {
  width: 338px;
  height: 1488px;
  position: relative;
  background-color: #fff;
  display: none;
  max-width: 100%;
  z-index: 0;
}

.archive-service1-frame-child9 {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 1px;
  bottom: 1px;
  left: 0;
  background-color: #fff;
  z-index: 1;
}

.archive-service1-dot-icon10,
.archive-service1-dot-icon11,
.archive-service1-dot-icon7,
.archive-service1-dot-icon8,
.archive-service1-dot-icon9 {
  width: 10px;
  height: 10px;
  position: relative;
  display: none;
  z-index: 2;
}

.archive-service1-dot-icon10,
.archive-service1-dot-icon11,
.archive-service1-dot-icon8,
.archive-service1-dot-icon9 {
  z-index: 3;
}

.archive-service1-dot-icon10,
.archive-service1-dot-icon11,
.archive-service1-dot-icon9 {
  z-index: 4;
}

.archive-service1-dot-icon10,
.archive-service1-dot-icon11 {
  z-index: 5;
}

.archive-service1-dot-icon11 {
  z-index: 6;
}

.archive-service1-dot-icon12 {
  width: 10px;
  height: 10px;
  z-index: 7;
}

.archive-service1-dot-icon12,
.archive-service1-dot-icon13,
.archive-service1-featured-icon1 {
  position: relative;
  display: none;
}

.archive-service1-dot-icon13 {
  width: 10px;
  height: 10px;
  z-index: 8;
}

.archive-service1-featured-icon1 {
  height: 40px;
  width: 40px;
  border-radius: 8px;
}

.archive-service1-supporting-text57,
.archive-service1-text184 {
  align-self: stretch;
  position: relative;
}

.archive-service1-text184 {
  text-decoration: none;
  line-height: 28px;
  font-weight: 600;
  color: inherit;
}

.archive-service1-supporting-text57 {
  font-size: 14px;
  line-height: 20px;
  color: #475467;
}

.archive-service1-text-and-supporting-text6 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  max-width: 100%;
}

.archive-service1-button-close-x,
.archive-service1-content24 {
  display: flex;
  flex-direction: row;
}

.archive-service1-content24 {
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 24px 0 0;
  box-sizing: border-box;
  gap: 16px;
  max-width: 100%;
}

.archive-service1-button-close-x {
  margin: 0 !important;
  position: absolute;
  top: 12px;
  right: 16px;
  border-radius: 8px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 10px;
  z-index: 1;
}

.archive-service1-slide-out-menu-header {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 16px 0 24px;
  box-sizing: border-box;
  position: relative;
  gap: 8px;
  max-width: 100%;
  z-index: 2;
}

.archive-service1-slide-out-title {
  text-decoration: none;
  position: relative;
  line-height: 24px;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  min-width: 35px;
  z-index: 2;
}

.archive-service1-slide-out-title-parent {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}

.archive-service1-frame-wrapper13,
.archive-service1-slide-out-menu-header-parent {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-frame-wrapper13 {
  flex-direction: row;
  padding: 0 33px 0 24px;
  font-size: 14px;
  color: #344054;
}

.archive-service1-slide-out-menu-header-parent {
  flex-direction: column;
  gap: 24px;
  max-width: 100%;
}

.archive-service1-background,
.archive-service1-progress-line {
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  bottom: 0;
  border-radius: 9999px;
}

.archive-service1-background {
  width: 305px;
  right: -56.2px;
  background-color: #eaecf0;
  z-index: 2;
}

.archive-service1-progress-line {
  width: 100%;
  right: -0.2px;
  background-color: #7e56d9;
}

.archive-service1-text185 {
  position: relative;
  line-height: 18px;
  font-weight: 600;
  display: inline-block;
  min-width: 9px;
}

.archive-service1-content25,
.archive-service1-tooltip1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-content25 {
  flex: 1;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  padding: 6px 11px;
}

.archive-service1-tooltip1 {
  margin-top: -42px;
  margin-left: -4.5px;
  width: 33px;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0 4px 6px -2px rgba(16, 24, 40, 0.03);
  flex-shrink: 0;
}

.archive-service1-control-handle,
.archive-service1-handle {
  height: 24px;
  width: 24px;
  box-sizing: border-box;
  flex-shrink: 0;
}

.archive-service1-handle {
  position: relative;
  box-shadow: 0 4px 8px -2px rgba(16, 24, 40, 0.1),
    0 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 9999px;
  background-color: #fff;
  border: 2px solid #7e56d9;
  overflow: hidden;
}

.archive-service1-control-handle {
  margin-top: -8px;
  border-radius: 12px;
  border: 1.5px solid #7e56d9;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  z-index: 1;
}

.archive-service1-text186 {
  position: relative;
  line-height: 18px;
  font-weight: 600;
  display: inline-block;
  min-width: 80px;
}

.archive-service1-content26 {
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  padding: 6px 11px;
}

.archive-service1-background-parent,
.archive-service1-content26,
.archive-service1-progress,
.archive-service1-tooltip2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-tooltip2 {
  margin-top: -42px;
  margin-left: -40px;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0 4px 6px -2px rgba(16, 24, 40, 0.03);
  flex-shrink: 0;
}

.archive-service1-background-parent,
.archive-service1-progress {
  position: relative;
}

.archive-service1-progress {
  height: 8px;
  width: 228.8px;
  gap: 193px;
  z-index: 3;
}

.archive-service1-background-parent {
  padding: 0 20px 0 0;
}

.archive-service1-label5 {
  width: 36px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.archive-service1-text187 {
  flex: 1;
  position: relative;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.archive-service1-content27 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.archive-service1-help-icon1 {
  height: 16px;
  width: 16px;
  position: relative;
  display: none;
}

.archive-service1-input4 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 30px;
  gap: 8px;
  font-size: 16px;
  color: #101828;
}

.archive-service1-input-field,
.archive-service1-input-with-label4 {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.archive-service1-input-with-label4 {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-input-field {
  flex: 1;
  align-items: center;
  justify-content: center;
}

.archive-service1-text188 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
  display: inline-block;
  min-width: 8px;
}

.archive-service1-input-fields {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  z-index: 2;
}

.archive-service1-input6 {
  margin: 0;
  height: 18px;
  width: 16px;
}

.archive-service1-checkboxes {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 8px;
  gap: 12px;
  z-index: 2;
}

.archive-service1-input-fields-parent {
  align-self: stretch;
  gap: 24px;
  text-align: left;
  font-size: 14px;
}

.archive-service1-frame-parent31,
.archive-service1-frame-wrapper14,
.archive-service1-input-fields-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-frame-parent31 {
  align-self: stretch;
  gap: 32px;
}

.archive-service1-frame-wrapper14 {
  flex: 1;
  padding: 9px 0 0;
}

.archive-service1-frame-child10 {
  height: 163px;
  width: 5px;
  position: relative;
  border-radius: 8px;
  background-color: #dadada;
  z-index: 2;
}

.archive-service1-frame-parent30 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 24px;
  gap: 28px;
  text-align: center;
  font-size: 12px;
}

.archive-service1-divider-icon15 {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 2;
}

.archive-service1-text194 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 83px;
}

.archive-service1-content-parent,
.archive-service1-content29 {
  flex-direction: row;
  align-items: center;
}

.archive-service1-content29 {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  gap: 12px;
}

.archive-service1-content-parent {
  gap: 42px;
}

.archive-service1-content-parent,
.archive-service1-input10,
.archive-service1-section {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}

.archive-service1-section {
  flex-direction: column;
  align-items: flex-start;
  z-index: 2;
}

.archive-service1-input10 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
  gap: 8px;
  font-size: 16px;
  color: #101828;
}

.archive-service1-input-field2,
.archive-service1-section-parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
}

.archive-service1-section-parent {
  gap: 8px;
}

.archive-service1-text198 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 71px;
}

.archive-service1-checkboxes1 {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 0 8px;
  gap: 12px;
}

.archive-service1-checkboxes1,
.archive-service1-section-wrapper,
.archive-service1-section1 {
  display: flex;
  align-items: flex-start;
}

.archive-service1-section1 {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
  z-index: 2;
}

.archive-service1-section-wrapper {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 33px 0 24px;
}

.archive-service1-text203,
.archive-service1-text207 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 116px;
}

.archive-service1-text207 {
  min-width: 44px;
}

.archive-service1-section3 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 2;
}

.archive-service1-text208,
.archive-service1-text209 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 62px;
}

.archive-service1-text209 {
  min-width: 59px;
}

.archive-service1-search-lg,
.archive-service1-text210 {
  position: relative;
  z-index: 2;
}

.archive-service1-search-lg {
  cursor: pointer;
  margin: 0;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.archive-service1-text210 {
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 55px;
}

.archive-service1-content37,
.archive-service1-input-fields2,
.archive-service1-input19 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.archive-service1-content37 {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 24px;
  flex: 1;
  justify-content: flex-start;
  font-family: Inter;
  font-size: 16px;
  color: #9ea1a8;
  min-width: 166px;
}

.archive-service1-input-fields2,
.archive-service1-input19 {
  align-self: stretch;
}

.archive-service1-input19 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  justify-content: flex-start;
  padding: 8px 13px;
  gap: 8px;
}

.archive-service1-input-fields2 {
  justify-content: center;
  z-index: 2;
}

.archive-service1-divider-icon22 {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 2;
}

.archive-service1-divider-icon23 {
  height: 0.2px;
  width: 338px;
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: 0;
  z-index: 3;
}

.archive-service1-text211 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 112px;
  z-index: 2;
}

.archive-service1-frame-wrapper17 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 24px;
}

.archive-service1-text212 {
  display: inline-block;
  min-width: 76px;
}

.archive-service1-text212,
.archive-service1-text213 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  z-index: 2;
}

.archive-service1-frame-parent37 {
  flex-direction: row;
  gap: 11px;
}

.archive-service1-frame-parent36,
.archive-service1-frame-parent37,
.archive-service1-frame-wrapper19 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.archive-service1-frame-wrapper19 {
  flex-direction: row;
  padding: 0 24px;
}

.archive-service1-frame-parent36 {
  align-self: stretch;
  flex-direction: column;
  gap: 15.3px;
  max-width: 100%;
}

.archive-service1-divider-icon31 {
  width: 338px;
  position: relative;
  max-height: 100%;
  max-width: 100%;
  z-index: 3;
}

.archive-service1-text216 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 118px;
  z-index: 2;
}

.archive-service1-frame-parent28,
.archive-service1-frame-parent29 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-frame-parent29 {
  align-items: flex-end;
  gap: 15.6px;
  font-size: 14px;
  color: #344054;
}

.archive-service1-frame-parent28 {
  align-items: flex-start;
  padding: 0 1px 0 0;
  box-sizing: border-box;
  gap: 56px;
}

.archive-service1-dot-icon20 {
  width: 10px;
  height: 10px;
  position: relative;
  display: none;
  z-index: 10;
}

.archive-service1-divider-icon35 {
  height: 1px;
  width: calc(100% - 1px);
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 1px;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  z-index: 2;
}

.archive-service1-text217 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 69px;
}

.archive-service1-buttonsbutton37 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.archive-service1-text218 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 48px;
}

.archive-service1-buttonsbutton38 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 13px;
  background-color: #fff;
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.archive-service1-text219 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 40px;
}

.archive-service1-buttonsbutton39 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 13px;
  background-color: #7e56d9;
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.archive-service1-actions9,
.archive-service1-buttonsbutton39,
.archive-service1-content38 {
  display: flex;
  flex-direction: row;
}

.archive-service1-actions9 {
  width: 164px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}

.archive-service1-content38 {
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  gap: 20px;
}

.archive-service1-divider-parent6,
.archive-service1-footer1 {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}

.archive-service1-footer1 {
  flex: 1;
  border-top: 1px solid #eaecf0;
  flex-direction: column;
  align-items: center;
  z-index: 4;
}

.archive-service1-divider-parent6 {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 1px 0 0;
  position: relative;
}

.archive-service1-divider-icon37 {
  height: 0.2px;
  width: 338px;
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: -1px;
  z-index: 3;
}

.archive-service1-frame-parent41 {
  align-self: stretch;
  flex-direction: column;
  font-size: 14px;
  color: #6941c6;
}

.archive-service1-frame-parent41,
.archive-service1-rectangle-parent4,
.archive-service1-slide-out {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.archive-service1-rectangle-parent4 {
  flex: 1;
  flex-direction: column;
  position: relative;
  gap: 61px;
  z-index: 3;

}

.archive-service1-slide-out {
  width: 25%;
  margin: 0 !important;
  /* position: absolute;
  top: 1px;
  left: 0; */
  flex-direction: row;
}

.archive-service1-listings-search-results-list {
  /* max-width: 1424px; */
  height: max-content;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  /* padding: 81px 0px 0px 0px; */
  box-sizing: border-box;
  /* gap: 999px; */
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  font-size: 18px;
  color: #101828;
  font-family: Inter;
  margin: 0 auto;
}

/* @media screen and (max-width: 1275px) {

  .archive-service1-collapsed-listing,
  .archive-service1-frame-form {
    padding-bottom: 339px;
    box-sizing: border-box;
  }

  .archive-service1-frame-form {
    padding-bottom: 272px;
  }

  .archive-service1-container1 {
    max-width: 100%;
  }
}

@media screen and (max-width: 1100px) {
  .archive-service1-filters {
    padding-bottom: 543px;
    box-sizing: border-box;
  }

  .archive-service1-fourth-listing {
    flex: 1;
  }

  .archive-service1-frame-parent18 {
    flex-wrap: wrap;
  }

  .archive-service1-frame-parent16 {
    gap: 140px;
  }

  .archive-service1-content8 {
    flex-wrap: wrap;
  }

  .archive-service1-text-and-badge1 {
    flex-wrap: wrap;
    padding-right: 333px;
    box-sizing: border-box;
  }

  .archive-service1-frame-parent26 {
    flex-wrap: wrap;
  }

  .archive-service1-frame-form {
    padding-bottom: 177px;
    box-sizing: border-box;
  }

  .archive-service1-listings-search-results-list {
    height: auto;
  }
}

@media screen and (max-width: 750px) {
  .archive-service1-navigation-parent {
    gap: 19px;
  }

  .archive-service1-frame-parent {
    gap: 35px;
  }

  .archive-service1-rectangle-parent {
    padding-right: 32px;
    box-sizing: border-box;
  }

  .archive-service1-main {
    gap: 16px;
  }

  .archive-service1-badges,
  .archive-service1-dot-item-content,
  .archive-service1-dot-item-parent {
    flex-wrap: wrap;
  }

  .archive-service1-dots-vertical {
    min-width: 100%;
  }

  .archive-service1-dots-vertical-parent {
    flex-wrap: wrap;
    min-width: 100%;
  }

  .archive-service1-frame-div {
    flex-wrap: wrap;
  }

  .archive-service1-frame-container {
    min-width: 100%;
  }

  .archive-service1-frame-group {
    flex-wrap: wrap;
  }

  .archive-service1-dots-vertical-container {
    min-width: 100%;
  }

  .archive-service1-filters {
    padding-bottom: 353px;
    box-sizing: border-box;
  }

  .archive-service1-collapsed-name-role {
    padding-top: 99px;
    box-sizing: border-box;
  }

  .archive-service1-first-badge,
  .archive-service1-location-content {
    flex: 1;
  }

  .archive-service1-badge-name {
    flex-wrap: wrap;
  }

  .archive-service1-badge-details {
    gap: 23px;
  }

  .archive-service1-first-badge-details {
    flex-wrap: wrap;
  }

  .archive-service1-second-badge-list-parent {
    gap: 23px;
  }

  .archive-service1-frame-parent5 {
    gap: 53px;
  }

  .archive-service1-expanded-content {
    flex-wrap: wrap;
  }

  .archive-service1-expanded-listing {
    min-width: 100%;
  }

  .archive-service1-collapsed-listing {
    padding-bottom: 220px;
    box-sizing: border-box;
  }

  .archive-service1-badge-support-parent,
  .archive-service1-badges1,
  .archive-service1-detail-item,
  .archive-service1-frame-parent11 {
    flex-wrap: wrap;
  }

  .archive-service1-second-listing-details {
    flex: 1;
  }

  .archive-service1-listing-detail {
    flex-wrap: wrap;
  }

  .archive-service1-frame-parent19 {
    gap: 54px;
  }

  .archive-service1-frame-wrapper11 {
    min-width: 100%;
  }

  .archive-service1-fourth-listing {
    margin-left: 0;
  }

  .archive-service1-frame-parent16 {
    gap: 70px;
  }

  .archive-service1-text-and-badge {
    flex-wrap: wrap;
    padding-right: 179px;
    box-sizing: border-box;
  }

  .archive-service1-text-and-supporting-text1 {
    min-width: 100%;
  }

  .archive-service1-actions2 {
    flex-wrap: wrap;
  }

  .archive-service1-text-and-badge1 {
    padding-right: 166px;
    box-sizing: border-box;
  }

  .archive-service1-text-and-supporting-text2 {
    min-width: 100%;
  }

  .archive-service1-actions3 {
    flex-wrap: wrap;
  }

  .archive-service1-page-navigation {
    flex-wrap: wrap;
    min-width: 100%;
  }

  .archive-service1-text-and-badge2 {
    flex-wrap: wrap;
    padding-right: 220px;
    box-sizing: border-box;
  }

  .archive-service1-text-and-supporting-text3 {
    min-width: 100%;
  }

  .archive-service1-empty-state3 {
    gap: 16px;
  }

  .archive-service1-empty-state2 {
    padding-top: 27px;
    padding-bottom: 27px;
    box-sizing: border-box;
  }

  .archive-service1-container1 {
    gap: 16px;
  }

  .archive-service1-footer {
    gap: 32px;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
  }

  .archive-service1-listings-search-results-list {
    gap: 49px;
  }
}

@media screen and (max-width: 450px) {
  .archive-service1-frame-parent {
    gap: 17px;
  }

  .archive-service1-page-title {
    font-size: 21px;
    line-height: 30px;
  }

  .archive-service1-price {
    font-size: 16px;
    line-height: 26px;
  }

  .archive-service1-card-work {
    flex: 1;
  }

  .archive-service1-provider-name {
    flex-wrap: wrap;
  }

  .archive-service1-price2,
  .archive-service1-price3 {
    font-size: 16px;
    line-height: 26px;
  }

  .archive-service1-badge-container,
  .archive-service1-location-content {
    flex-wrap: wrap;
  }

  .archive-service1-location-details-parent {
    gap: 28px;
    margin-left: 0;
  }

  .archive-service1-buttonsbutton-group,
  .archive-service1-buttonsbutton-parent {
    flex-wrap: wrap;
  }

  .archive-service1-location-summary {
    flex: 1;
  }

  .archive-service1-summary-details {
    flex-wrap: wrap;
  }

  .archive-service1-frame-parent5 {
    gap: 27px;
  }

  .archive-service1-work-type-badge-list {
    padding-top: 52px;
    box-sizing: border-box;
  }

  .archive-service1-price4,
  .archive-service1-price6 {
    font-size: 16px;
    line-height: 26px;
  }

  .archive-service1-business-name-role,
  .archive-service1-contact-details-parent {
    flex-wrap: wrap;
  }

  .archive-service1-detail-item-parent {
    gap: 20px;
  }

  .archive-service1-pair-container-parent {
    margin-left: 0;
  }

  .archive-service1-frame-parent19 {
    gap: 27px;
  }

  .archive-service1-price7 {
    font-size: 16px;
    line-height: 26px;
  }

  .archive-service1-price-parent2 {
    flex-wrap: wrap;
  }

  .archive-service1-frame-parent24 {
    gap: 126px;
  }

  .archive-service1-text100 {
    font-size: 21px;
    line-height: 22px;
  }

  .archive-service1-content9,
  .archive-service1-text-and-badge {
    padding-right: 20px;
    box-sizing: border-box;
  }

  .archive-service1-input-dropdown2 {
    max-width: 100%;
  }

  .archive-service1-text161 {
    font-size: 21px;
    line-height: 22px;
  }

  .archive-service1-text-and-badge2 {
    padding-right: 20px;
    box-sizing: border-box;
  }

  .archive-service1-button-group2 {
    flex-wrap: wrap;
  }

  .archive-service1-content20 {
    padding-right: 20px;
    box-sizing: border-box;
  }

  .archive-service1-frame-form {
    padding-bottom: 115px;
    box-sizing: border-box;
  }

  .archive-service1-text181 {
    font-size: 16px;
    line-height: 24px;
  }

  .archive-service1-footer {
    gap: 16px;
  }

  .archive-service1-listings-search-results-list {
    gap: 25px;
  }
} */

@media screen and (max-width: 1275px) {

  .archive-service1-collapsed-listing,
  .archive-service1-frame-form {
    padding-bottom: 339px;
    box-sizing: border-box;
  }

  .archive-service1-frame-form {
    padding-bottom: 272px;
  }

  .archive-service1-container1 {
    max-width: 100%;
  }
}

@media screen and (max-width: 1100px) {
  .archive-service1-filters {
    padding-bottom: 543px;
    box-sizing: border-box;
  }

  .archive-service1-fourth-listing {
    flex: 1;
  }

  .archive-service1-frame-parent18 {
    flex-wrap: wrap;
  }

  .archive-service1-frame-parent16 {
    gap: 140px;
  }

  .archive-service1-content8 {
    flex-wrap: wrap;
  }

  .archive-service1-text-and-badge1 {
    flex-wrap: wrap;
    padding-right: 333px;
    box-sizing: border-box;
  }

  .archive-service1-frame-parent26 {
    flex-wrap: wrap;
  }

  .archive-service1-frame-form {
    padding-bottom: 177px;
    box-sizing: border-box;
  }

  .archive-service1-listings-search-results-list {
    height: auto;
  }
}

@media screen and (max-width: 750px) {
  .archive-service1-navigation-parent {
    gap: 19px;
  }

  .archive-service1-frame-parent {
    gap: 35px;
  }

  .archive-service1-rectangle-parent {
    padding-right: 32px;
    box-sizing: border-box;
  }

  .archive-service1-main {
    gap: 16px;
  }

  .archive-service1-badges,
  .archive-service1-dot-item-content,
  .archive-service1-dot-item-parent {
    flex-wrap: wrap;
  }

  .archive-service1-dots-vertical {
    min-width: 100%;
  }

  .archive-service1-dots-vertical-parent {
    flex-wrap: wrap;
    min-width: 100%;
  }

  .archive-service1-frame-div {
    flex-wrap: wrap;
  }

  .archive-service1-frame-container {
    min-width: 100%;
  }

  .archive-service1-frame-group {
    flex-wrap: wrap;
  }

  .archive-service1-dots-vertical-container {
    min-width: 100%;
  }

  .archive-service1-filters {
    padding-bottom: 353px;
    box-sizing: border-box;
  }

  .archive-service1-collapsed-name-role {
    padding-top: 99px;
    box-sizing: border-box;
  }

  .archive-service1-first-badge,
  .archive-service1-location-content {
    flex: 1;
  }

  .archive-service1-badge-name {
    flex-wrap: wrap;
  }

  .archive-service1-badge-details {
    gap: 23px;
  }

  .archive-service1-first-badge-details {
    flex-wrap: wrap;
  }

  .archive-service1-second-badge-list-parent {
    gap: 23px;
  }

  .archive-service1-frame-parent5 {
    gap: 53px;
  }

  .archive-service1-expanded-content {
    flex-wrap: wrap;
  }

  .archive-service1-expanded-listing {
    min-width: 100%;
  }

  .archive-service1-collapsed-listing {
    padding-bottom: 220px;
    box-sizing: border-box;
  }

  .archive-service1-badge-support-parent,
  .archive-service1-badges1,
  .archive-service1-detail-item,
  .archive-service1-frame-parent11 {
    flex-wrap: wrap;
  }

  .archive-service1-second-listing-details {
    flex: 1;
  }

  .archive-service1-listing-detail {
    flex-wrap: wrap;
  }

  .archive-service1-frame-parent19 {
    gap: 54px;
  }

  .archive-service1-frame-wrapper11 {
    min-width: 100%;
  }

  .archive-service1-fourth-listing {
    margin-left: 0;
  }

  .archive-service1-frame-parent16 {
    gap: 70px;
  }

  .archive-service1-text-and-badge {
    flex-wrap: wrap;
    padding-right: 179px;
    box-sizing: border-box;
  }

  .archive-service1-text-and-supporting-text1 {
    min-width: 100%;
  }

  .archive-service1-actions2 {
    flex-wrap: wrap;
  }

  .archive-service1-text-and-badge1 {
    padding-right: 166px;
    box-sizing: border-box;
  }

  .archive-service1-text-and-supporting-text2 {
    min-width: 100%;
  }

  .archive-service1-actions3 {
    flex-wrap: wrap;
  }

  .archive-service1-page-navigation {
    flex-wrap: wrap;
    min-width: 100%;
  }

  .archive-service1-text-and-badge2 {
    flex-wrap: wrap;
    padding-right: 220px;
    box-sizing: border-box;
  }

  .archive-service1-text-and-supporting-text3 {
    min-width: 100%;
  }

  .archive-service1-empty-state3 {
    gap: 16px;
  }

  .archive-service1-empty-state2 {
    padding-top: 27px;
    padding-bottom: 27px;
    box-sizing: border-box;
  }

  .archive-service1-container1 {
    gap: 16px;
  }

  .archive-service1-footer {
    gap: 32px;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
  }

  .archive-service1-listings-search-results-list {
    gap: 49px;
  }
}

@media screen and (max-width: 450px) {
  .archive-service1-frame-parent {
    gap: 17px;
  }

  .archive-service1-page-title {
    font-size: 21px;
    line-height: 30px;
  }

  .archive-service1-price {
    font-size: 16px;
    line-height: 26px;
  }

  .archive-service1-card-work {
    flex: 1;
  }

  .archive-service1-provider-name {
    flex-wrap: wrap;
  }

  .archive-service1-price2,
  .archive-service1-price3 {
    font-size: 16px;
    line-height: 26px;
  }

  .archive-service1-badge-container,
  .archive-service1-location-content {
    flex-wrap: wrap;
  }

  .archive-service1-location-details-parent {
    gap: 28px;
    margin-left: 0;
  }

  .archive-service1-buttonsbutton-group,
  .archive-service1-buttonsbutton-parent {
    flex-wrap: wrap;
  }

  .archive-service1-location-summary {
    flex: 1;
  }

  .archive-service1-summary-details {
    flex-wrap: wrap;
  }

  .archive-service1-frame-parent5 {
    gap: 27px;
  }

  .archive-service1-work-type-badge-list {
    padding-top: 52px;
    box-sizing: border-box;
  }

  .archive-service1-price4,
  .archive-service1-price6 {
    font-size: 16px;
    line-height: 26px;
  }

  .archive-service1-business-name-role,
  .archive-service1-contact-details-parent {
    flex-wrap: wrap;
  }

  .archive-service1-detail-item-parent {
    gap: 20px;
  }

  .archive-service1-pair-container-parent {
    margin-left: 0;
  }

  .archive-service1-frame-parent19 {
    gap: 27px;
  }

  .archive-service1-price7 {
    font-size: 16px;
    line-height: 26px;
  }

  .archive-service1-price-parent2 {
    flex-wrap: wrap;
  }

  .archive-service1-frame-parent24 {
    gap: 126px;
  }

  .archive-service1-text100 {
    font-size: 21px;
    line-height: 22px;
  }

  .archive-service1-content9,
  .archive-service1-text-and-badge {
    padding-right: 20px;
    box-sizing: border-box;
  }

  .archive-service1-input-dropdown2 {
    max-width: 100%;
  }

  .archive-service1-text161 {
    font-size: 21px;
    line-height: 22px;
  }

  .archive-service1-text-and-badge2 {
    padding-right: 20px;
    box-sizing: border-box;
  }

  .archive-service1-button-group2 {
    flex-wrap: wrap;
  }

  .archive-service1-content20 {
    padding-right: 20px;
    box-sizing: border-box;
  }

  .archive-service1-frame-form {
    padding-bottom: 115px;
    box-sizing: border-box;
  }

  .archive-service1-text181 {
    font-size: 16px;
    line-height: 24px;
  }

  .archive-service1-footer {
    gap: 16px;
  }

  .archive-service1-listings-search-results-list {
    gap: 25px;
  }
}